import React from "react";
import Compo from "@smartly-city/compo";
import { Navigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import type {
  LightingIoT_LuminaireJournalModel,
  LightingIoT_LuminaireModel,
} from "src/graphql/types";
import { useLuminaireJournalsFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaireJournals";
import { useLuminaireJournalByIdFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaireJournalById";
import LuminaireJournalTabDailyEnergyConsumptionChart from "./charts/LuminaireJournalTabDailyEnergyConsumptionChart";
import styled from "styled-components";
import LuminaireJournalTabMonthlyEnergyConsumptionChart from "./charts/LuminaireJournalTabMonthlyEnergyConsumptionChart";
import LuminaireJournalTabStatus from "./LuminaireJournalTabStatus";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";

export interface LuminaireJournalTabProps {
  luminaire: LightingIoT_LuminaireModel;
}

const LuminaireJournalTab: React.FC<LuminaireJournalTabProps> = (props) => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();

  const { data: shortData } = useLuminaireJournalsFromLightingIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: { ofLuminaireId: props.luminaire.id },
      },
    },
  });

  const { data } = useLuminaireJournalByIdFromLightingIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        id: shortData.LightingIoT_luminaireJournals.items[0]?.id,
      },
    },
    skip: shortData.LightingIoT_luminaireJournals.count === 0,
  });

  const journal = data?.LightingIoT_luminaireJournalById
    ?.journal as LightingIoT_LuminaireJournalModel;

  if (!journal) {
    return <Navigate to="/" />;
  }

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("page_luminaire_view.journal")} />
      <LuminaireJournalTabDailyEnergyConsumptionChart journal={journal} />
      <LuminaireJournalTabMonthlyEnergyConsumptionChart journal={journal} />
      <LuminaireJournalTabStatus journal={journal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LuminaireJournalTab;
