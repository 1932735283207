import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StatisticsFromFailuresQueryVariables = Types.Exact<{
  input: Types.Failures_GetStatisticsInput;
}>;


export type StatisticsFromFailuresQuery = { __typename?: 'Query', Failures_statistics: { __typename?: 'Failures_GetStatisticsPayload', statistics: { __typename?: 'Failures_StatisticsModel', activeFailureCount: number, resolvedFailureCount: number, manualFailureCount: number, failureCountByDay: Array<{ __typename?: 'Failures_StatisticsFailureCountModel', date: any, type: Types.Failures_FailureTypeModel, count: number }> } } };


export const StatisticsFromFailuresDocument = gql`
    query statisticsFromFailures($input: Failures_GetStatisticsInput!) {
  Failures_statistics(input: $input) {
    statistics {
      activeFailureCount
      resolvedFailureCount
      manualFailureCount
      failureCountByDay {
        date
        type
        count
      }
    }
  }
}
    `;

/**
 * __useStatisticsFromFailuresQuery__
 *
 * To run a query within a React component, call `useStatisticsFromFailuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsFromFailuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsFromFailuresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStatisticsFromFailuresQuery(baseOptions: Apollo.QueryHookOptions<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables> & ({ variables: StatisticsFromFailuresQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables>(StatisticsFromFailuresDocument, options);
      }
export function useStatisticsFromFailuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables>(StatisticsFromFailuresDocument, options);
        }
export function useStatisticsFromFailuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables>(StatisticsFromFailuresDocument, options);
        }
export type StatisticsFromFailuresQueryHookResult = ReturnType<typeof useStatisticsFromFailuresQuery>;
export type StatisticsFromFailuresLazyQueryHookResult = ReturnType<typeof useStatisticsFromFailuresLazyQuery>;
export type StatisticsFromFailuresSuspenseQueryHookResult = ReturnType<typeof useStatisticsFromFailuresSuspenseQuery>;
export type StatisticsFromFailuresQueryResult = Apollo.QueryResult<StatisticsFromFailuresQuery, StatisticsFromFailuresQueryVariables>;