import * as yup from "yup";

export const detailsValidationSchema = yup.object().shape({
  details: yup
    .object()
    .fromFormik()
    .required()
    .shape({
      power: yup.number().fromFormik().min(0).optional(),
      contractedPower: yup.number().fromFormik().min(0).optional(),
      fuseType: yup.string().fromFormik().optional(),
      fuseAmpRating: yup.number().fromFormik().integer().min(0).optional(),
      recipientCode: yup.string().fromFormik().optional(),
      notes: yup.string().fromFormik().optional(),
    }),
});
