import React from "react";
import { LuminaireFiltersContext } from "./LuminaireFiltersContext";
import type {
  LuminaireFiltersStatus,
  LuminaireFilters,
} from "./LuminaireFiltersContext";
import { useFiltersUtils } from "src/core/utils/filters";

export interface LuminaireFiltersProviderProps {
  children?: React.ReactNode;
}

const LuminaireFiltersProvider: React.FC<LuminaireFiltersProviderProps> = (
  props
) => {
  const initStatus: LuminaireFiltersStatus = {
    name: false,
    typeName: false,
    scheduleName: false,
    controllerId: false,
    hasController: false,
    hasLightingSchedule: false,
    communicationDate: false,
    energyMeteringDate: false,
    signalStrength: false,
    signalToNoiseRatio: false,
    temperature: false,
    consumption: false,
    powerFactor: false,
    voltage: false,
  };

  const utils = useFiltersUtils<LuminaireFilters>({}, initStatus);

  return (
    <LuminaireFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </LuminaireFiltersContext.Provider>
  );
};

export default LuminaireFiltersProvider;
