import React from "react";
import Compo from "@smartly-city/compo";
import List from "../../../../core/components/List";
import OperationsHeader from "src/core/components/OperationsHeader";
import PowerBoxListRows from "./PowerBoxList.rows";
import PowerBoxListRowsSkeleton from "./PowerBoxList.rows.skeleton";
import { usePagination } from "src/core/utils/pagination";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { PowerBoxFiltersForm } from "../PowerBoxFiltersForm";
import type { Lighting_PowerBoxRowModel } from "src/graphql/types";

export interface PowerBoxListProps {
  checked?: string[];
  className?: string;
  paged?: boolean;
  filtered?: boolean;
  paginationKey?: string;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (powerBox: Lighting_PowerBoxRowModel) => React.ReactNode;
  onClick?: (powerBox: Lighting_PowerBoxRowModel) => void;
  onCheck?: (powerBox: Lighting_PowerBoxRowModel, value: boolean) => void;
}

const PowerBoxList: React.FC<PowerBoxListProps> = (props) => {
  const ITEMS_AMOUNT = 10;

  const { t } = useLightingTranslation();
  const pagination = usePagination(props.paginationKey);
  const [rows, setRows] = React.useState<Lighting_PowerBoxRowModel[]>([]);

  const sections = {} as any;

  if (props.withCheckbox) {
    sections.operations = {
      key: "operations",
      title: t("operations"),
      icon: Compo.CogIcon,
      content: (
        <OperationsHeader
          onSelectAll={
            props.onCheck
              ? () => rows.forEach((row) => props.onCheck?.(row, true))
              : undefined
          }
          onUnselectAll={
            props.onCheck
              ? () => rows.forEach((row) => props.onCheck?.(row, false))
              : undefined
          }
        />
      ),
    };
  }

  if (props.filtered) {
    sections.filters = {
      key: "filters",
      title: t("filters"),
      icon: Compo.FilterIcon,
      content: (
        <PowerBoxFiltersForm
          onSubmit={() => {
            props.paged && pagination.page !== 1 && pagination.setPage(1);
          }}
        />
      ),
    };
  }

  return (
    <List
      className={props.className}
      header={<Compo.ListHeader wide sections={Object.values(sections)} />}
      pagination={props.paged ? pagination : undefined}
    >
      <React.Suspense fallback={<PowerBoxListRowsSkeleton />}>
        <PowerBoxListRows
          rows={rows}
          setRows={setRows}
          checked={props.checked}
          page={props.paged ? pagination.page : undefined}
          pagedAmount={ITEMS_AMOUNT}
          withCheckbox={props.withCheckbox}
          withRadio={props.withRadio}
          actions={props.actions}
          onClick={props.onClick}
          onCheck={props.onCheck}
        />
      </React.Suspense>
    </List>
  );
};

export default PowerBoxList;
