import React from "react";
import styled from "styled-components";
import Compo, { useModal, useDialog } from "@smartly-city/compo";
import { toast } from "react-toastify";
import type { Lighting_PoleModel } from "src/graphql/types";
import { PoleForm } from "src/modules/lighting/containers/PoleForm";
import { PoleDeleteForm } from "src/modules/lighting/containers/PoleDeleteForm";
import { usePermissionMatch } from "src/core/contexts";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { buildAddressString } from "src/modules/lighting/utils/address";
import { LuminaireList, LuminaireListSkeleton } from "./elements/LuminaireList";
import { LuminaireForm } from "src/modules/lighting/containers/LuminaireForm";
import { useNavigate } from "react-router-dom";

export interface PoleDetailsTabProps {
  pole: Lighting_PoleModel;
  editPermitted: boolean;
  deletePermitted: boolean;
}

const PoleDetailsTab: React.FC<PoleDetailsTabProps> = (props) => {
  const { t } = useLightingTranslation();
  const navigate = useNavigate();
  const luminaireModal = useModal();
  const editModal = useModal();
  const deleteDialog = useDialog();
  const getLuminairePermitted = usePermissionMatch("lighting.luminaire.get");
  const createLuminairePermitted = usePermissionMatch(
    "lighting.luminaire.create"
  );

  const pole = props.pole;
  const addressString = buildAddressString(pole.address);

  return (
    <React.Fragment>
      <Compo.Header title={pole.name}>
        {props.editPermitted && (
          <Compo.Button
            filled
            icon={Compo.PencilIcon}
            onClick={() => editModal.setIsOpen(true)}
          />
        )}
        {props.deletePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => deleteDialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.Header type="h2" title={t("location")} />
      <Compo.LinkTile
        wide
        text={addressString}
        icon={Compo.DocumentDuplicateIcon}
        onClick={async () => {
          await navigator.clipboard.writeText(addressString);
          toast.success(t("notification.address_copied"));
        }}
      />
      <Compo.Header type="h2" title={t("power_box")} />
      <Compo.LinkTile
        wide
        text={pole.powerBoxName}
        icon={Compo.ExternalLinkIcon}
        onClick={() => navigate(`/lighting/power-box/${pole.powerBoxId}`)}
      />
      {getLuminairePermitted && (
        <React.Fragment>
          <HeaderWithDistance outline type="h2" title={t("luminaires")}>
            {createLuminairePermitted && (
              <Compo.Button
                small
                filled
                icon={Compo.PlusIcon}
                onClick={() => luminaireModal.setIsOpen(true)}
              />
            )}
          </HeaderWithDistance>
          <React.Suspense fallback={<LuminaireListSkeleton />}>
            <LuminaireList pole={pole} />
          </React.Suspense>
        </React.Fragment>
      )}
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow wide description={String(pole.ordinalNumber)}>
        {t("page_pole_view.ordinal_number")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={
          t(`pole_type.${pole.details.type.toLowerCase()}`) ?? undefined
        }
      >
        {t("page_pole_view.type")}
      </Compo.ListRow>
      <Compo.ListRow wide description={`${pole.details.height} m`}>
        {t("page_pole_view.height")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={
          t(`grid_type.${pole.details.gridType.toLowerCase()}`) ?? undefined
        }
      >
        {t("page_pole_view.grid_type")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={
          t(`line_type.${pole.details.lineType.toLowerCase()}`) ?? undefined
        }
      >
        {t("page_pole_view.line_type")}
      </Compo.ListRow>
      {pole.details.lineCrossSection && (
        <Compo.ListRow
          wide
          description={`${t(pole.details.lineCrossSection)} mm²`}
        >
          {t("page_pole_view.line_cross_section")}
        </Compo.ListRow>
      )}
      {luminaireModal.isOpen &&
        luminaireModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireForm
              poleId={pole.id}
              onClose={() => luminaireModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {editModal.isOpen &&
        editModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <PoleForm pole={pole} onClose={() => editModal.setIsOpen(false)} />
          </React.Suspense>
        )}
      {deleteDialog.isOpen &&
        deleteDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <PoleDeleteForm
              pole={pole}
              onClose={() => deleteDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default PoleDetailsTab;
