import * as yup from "yup";
import { Lighting_SourceOfLightModel } from "src/graphql/types";
import type { Lighting_LuminaireTypeDefaultDetailsModel } from "src/graphql/types";

export const validationSchema = yup.object().shape({
  model: yup.string().fromFormik().required(),
  manufacturer: yup.string().fromFormik().required(),
  details: yup
    .object()
    .fromFormik()
    .shape({
      sourceOfLight: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_SourceOfLightModel))
        .required(),
      power: yup.number().fromFormik().min(0).required(),
      sourcePower: yup.number().fromFormik().min(0).required(),
      manufacturerCode: yup.string().fromFormik().optional(),
    }),
  ledDetails: yup
    .object()
    .fromFormik()
    .nullable()
    .default(null)
    .when("details", {
      is: (details: Lighting_LuminaireTypeDefaultDetailsModel) =>
        details.sourceOfLight === Lighting_SourceOfLightModel.Led,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .shape({
      hasControllerSocket: yup.bool().fromFormik().required(),
      optics: yup.string().fromFormik().optional(),
      driverType: yup.string().fromFormik().optional(),
      driverCurrent: yup.number().fromFormik().integer().min(0).optional(),
      amountOfLed: yup.number().fromFormik().integer().min(0).optional(),
    }),
});
