import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddLuminairesToLightingScheduleFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_AddLuminairesToLightingScheduleInput;
}>;


export type AddLuminairesToLightingScheduleFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_addLuminairesToLightingSchedule: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const AddLuminairesToLightingScheduleFromLightingIoTDocument = gql`
    mutation addLuminairesToLightingScheduleFromLightingIoT($input: LightingIoT_AddLuminairesToLightingScheduleInput!) {
  LightingIoT_addLuminairesToLightingSchedule(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type AddLuminairesToLightingScheduleFromLightingIoTMutationFn = Apollo.MutationFunction<AddLuminairesToLightingScheduleFromLightingIoTMutation, AddLuminairesToLightingScheduleFromLightingIoTMutationVariables>;

/**
 * __useAddLuminairesToLightingScheduleFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useAddLuminairesToLightingScheduleFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLuminairesToLightingScheduleFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLuminairesToLightingScheduleFromLightingIoTMutation, { data, loading, error }] = useAddLuminairesToLightingScheduleFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLuminairesToLightingScheduleFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<AddLuminairesToLightingScheduleFromLightingIoTMutation, AddLuminairesToLightingScheduleFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLuminairesToLightingScheduleFromLightingIoTMutation, AddLuminairesToLightingScheduleFromLightingIoTMutationVariables>(AddLuminairesToLightingScheduleFromLightingIoTDocument, options);
      }
export type AddLuminairesToLightingScheduleFromLightingIoTMutationHookResult = ReturnType<typeof useAddLuminairesToLightingScheduleFromLightingIoTMutation>;
export type AddLuminairesToLightingScheduleFromLightingIoTMutationResult = Apollo.MutationResult<AddLuminairesToLightingScheduleFromLightingIoTMutation>;
export type AddLuminairesToLightingScheduleFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<AddLuminairesToLightingScheduleFromLightingIoTMutation, AddLuminairesToLightingScheduleFromLightingIoTMutationVariables>;