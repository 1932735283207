import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTrafficIoTTranslation } from "../../utils/translation";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useIndexCountersFromTrafficIoTMutation } from "src/graphql/__generated__/TrafficIoTIndexCounters";
import { useIndexCounterJournalsFromTrafficIoTMutation } from "src/graphql/__generated__/TrafficIoTIndexCounterJournals";
import { useValidateCountersIndexFromTrafficIoTMutation } from "src/graphql/__generated__/TrafficIoTValidateCountersIndex";
import { useValidateCounterJournalsIndexFromTrafficIoTMutation } from "src/graphql/__generated__/TrafficIoTValidateCounterJournalsIndex";

const Administration: React.FC = () => {
  const { t } = useTrafficIoTTranslation();
  const area = useCurrentArea();
  const [tab, setTab] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  const hasValidateCountersIndexPermission = usePermissionMatch(
    "traffic_iot.admin.validate_counters_index"
  );
  const [validateCounters] = useValidateCountersIndexFromTrafficIoTMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleValidateCounters = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateCounters();
    if (result.data?.TrafficIoT_Admin_validateCountersIndex.isSuccess) {
      toast.success(t(`notification.counters_index_validated`));
    } else {
      result.data?.TrafficIoT_Admin_validateCountersIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateCounterJournalsIndexPermission = usePermissionMatch(
    "traffic_iot.admin.validate_counter_journals_index"
  );
  const [validateCounterJournals] =
    useValidateCounterJournalsIndexFromTrafficIoTMutation({
      variables: { input: { areaId: area.id } },
    });
  const handleValidateCounterJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateCounterJournals();
    if (result.data?.TrafficIoT_Admin_validateCounterJournalsIndex.isSuccess) {
      toast.success(t(`notification.counter_journals_index_validated`));
    } else {
      result.data?.TrafficIoT_Admin_validateCounterJournalsIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexCountersPermission = usePermissionMatch(
    "traffic_iot.admin.index_counters"
  );
  const [indexCounters] = useIndexCountersFromTrafficIoTMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleIndexCounters = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexCounters();
    if (result.data?.TrafficIoT_Admin_indexCounters.isSuccess) {
      toast.success(t(`notification.counters_indexed`));
    } else {
      result.data?.TrafficIoT_Admin_indexCounters.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexCounterJournalsPermission = usePermissionMatch(
    "traffic_iot.admin.index_counter_journals"
  );
  const [indexCounterJournals] = useIndexCounterJournalsFromTrafficIoTMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleIndexCounterJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexCounterJournals();
    if (result.data?.TrafficIoT_Admin_indexCounterJournals.isSuccess) {
      toast.success(t(`notification.counter_journals_indexed`));
    } else {
      result.data?.TrafficIoT_Admin_indexCounterJournals.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  return (
    <Page wide permission="traffic_iot.admin.get">
      <Compo.Tabs
        wide
        current={tab}
        onChange={(index) => setTab(index)}
        tabs={[
          {
            icon: Compo.SearchIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidateCountersIndexPermission}
                  onClick={handleValidateCounters}
                >
                  {t("page_administration.validate_counters_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateCounterJournalsIndexPermission
                  }
                  onClick={handleValidateCounterJournals}
                >
                  {t("page_administration.validate_counter_journals_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexCountersPermission}
                  onClick={handleIndexCounters}
                >
                  {t("page_administration.index_counters")}
                </Compo.Button>

                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexCounterJournalsPermission}
                  onClick={handleIndexCounterJournals}
                >
                  {t("page_administration.index_counter_journals")}
                </Compo.Button>
              </Wrapper>
            ),
          },
        ]}
      />
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem;
`;

export default Administration;
