import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useCurrentArea } from "src/core/contexts";
import { clearFailuresCache } from "../../utils/cache";
import { useFailuresTranslation } from "../../utils/translation";
import { useResolveManualFailureErrorFromFailuresMutation } from "src/graphql/__generated__/FailuresResolveManualFailureError";
import { setTextFieldValue, toStringValue } from "src/core/utils/formik";
import type {
  Failures_FailureErrorModel,
  Failures_FailureModel,
  Failures_FailureRowModel,
} from "src/graphql/types";
import * as yup from "yup";

export interface FailureErrorResolveFormProps {
  failure: Failures_FailureModel | Failures_FailureRowModel;
  error: Failures_FailureErrorModel;
  onClose: () => void;
}

export interface FailureErrorResolveFormContext {
  errorReasonDescription: string | null;
  errorResolveDescription: string | null;
  errorSparePartsDescription: string | null;
}

const FailureErrorResolveForm: React.FC<FailureErrorResolveFormProps> = (
  props
) => {
  const { t } = useFailuresTranslation();
  const area = useCurrentArea();

  const [resolve] = useResolveManualFailureErrorFromFailuresMutation({
    update: (cache, result) => {
      if (
        result.errors ||
        !result.data?.Failures_resolveManualFailureError.isSuccess
      )
        return;
      clearFailuresCache(cache);
    },
  });

  const formik = useFormik<FailureErrorResolveFormContext>({
    initialValues: {
      errorReasonDescription: null,
      errorResolveDescription: null,
      errorSparePartsDescription: null,
    },
    validationSchema: yup.object({
      errorResolveDescription: yup
        .string()
        .required()
        .fromFormik()
        .min(5)
        .max(1000),
      errorReasonDescription: yup
        .string()
        .nullable()
        .fromFormik()
        .min(5)
        .max(1000),
      errorSparePartsDescription: yup
        .string()
        .nullable()
        .fromFormik()
        .min(5)
        .max(1000),
    }),
    onSubmit: async (values) => {
      const { data } = await resolve({
        variables: {
          input: {
            failureId: props.failure.id,
            failureVersion: props.failure.version,
            areaId: area.id,
            errorId: props.error.id,
            errorReasonDescription: values.errorReasonDescription,
            errorResolveDescription: values.errorResolveDescription ?? "",
            errorSparePartsDescription: values.errorSparePartsDescription,
          },
        },
      });
      const result = data?.Failures_resolveManualFailureError;

      if (result?.isSuccess) {
        toast.success(t(`notification.failure_error_resolved`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={<Compo.Header title={t("form_failure_error_resolve.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.TextInput
            wide
            disabled={formik.isSubmitting}
            error={
              formik.touched.errorResolveDescription &&
              formik.errors.errorResolveDescription
            }
            label={t("form_failure_error_resolve.error_resolve_description")}
            value={toStringValue(formik.values.errorResolveDescription)}
            onChange={setTextFieldValue(formik, "errorResolveDescription")}
          />
          <Compo.TextInput
            wide
            disabled={formik.isSubmitting}
            error={
              formik.touched.errorReasonDescription &&
              formik.errors.errorReasonDescription
            }
            label={t("form_failure_error_resolve.error_reason_description")}
            value={toStringValue(formik.values.errorReasonDescription)}
            onChange={setTextFieldValue(formik, "errorReasonDescription")}
          />
          <Compo.TextInput
            wide
            disabled={formik.isSubmitting}
            error={
              formik.touched.errorSparePartsDescription &&
              formik.errors.errorSparePartsDescription
            }
            label={t(
              "form_failure_error_resolve.error_spare_parts_description"
            )}
            value={toStringValue(formik.values.errorSparePartsDescription)}
            onChange={setTextFieldValue(formik, "errorSparePartsDescription")}
          />
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default FailureErrorResolveForm;
