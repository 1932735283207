import React from "react";
import Compo from "@smartly-city/compo";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import {
  Lighting_GridTypeModel,
  Lighting_LineTypeModel,
  Lighting_PoleTypeModel,
} from "src/graphql/types";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { useFormikContext } from "formik";
import type { PoleFormContext } from "../../PoleForm";

const PoleFormDefaultDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PoleFormContext>();

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="type"
        name="type"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.type")}
        label={t("form_pole.type")}
        value={toStringValue(formik.values.details?.type)}
        onChange={setTextFieldValue(formik, "details.type")}
      >
        <option value="" />
        {Object.values(Lighting_PoleTypeModel).map((type) => (
          <option key={type} value={type}>
            {t(`pole_type.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.TextInput
        wide
        id="height"
        name="height"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.height")}
        label={t("form_pole.height")}
        value={toStringValue(formik.values.details?.height)}
        onChange={setFloatFieldValue(formik, "details.height")}
      />
      <Compo.SelectInput
        wide
        id="grid-type"
        name="grid-type"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.gridType")}
        label={t("form_pole.grid_type")}
        value={toStringValue(formik.values.details?.gridType)}
        onChange={setTextFieldValue(formik, "details.gridType")}
      >
        <option value="" />
        {Object.values(Lighting_GridTypeModel).map((type) => (
          <option key={type} value={type}>
            {t(`grid_type.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.SelectInput
        wide
        id="line-type"
        name="line-type"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.lineType")}
        label={t("form_pole.line_type")}
        value={toStringValue(formik.values.details?.lineType)}
        onChange={setTextFieldValue(formik, "details.lineType")}
      >
        <option value="" />
        {Object.values(Lighting_LineTypeModel).map((type) => (
          <option key={type} value={type}>
            {t(`line_type.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.TextInput
        wide
        unit="mm²"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.lineCrossSection")}
        label={t("form_pole.line_cross_section")}
        value={toStringValue(formik.values.details?.lineCrossSection)}
        onChange={setTextFieldValue(formik, "details.lineCrossSection")}
      />
    </React.Fragment>
  );
};

export default PoleFormDefaultDetailsInputs;
