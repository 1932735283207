import React from "react";
import { Outlet } from "react-router-dom";
import { LayerFocusProxy, LayerOverrideProxy } from "@smartly-city/compo-map";
import { COUNTER_LAYER_ID, useCounterLayerBuilder } from "../utils/layers";
import {
  CounterFiltersProvider,
  useCounterFilters,
} from "../contexts/CounterFilters";
import { useCurrentArea } from "src/core/contexts";
import { useCountersFromTrafficIoTLazyQuery } from "src/graphql/__generated__/TrafficIoTCounters";
import { toCounterQueryFilters } from "../utils/filters";
import { useCounterJournalsFromTrafficIoTLazyQuery } from "src/graphql/__generated__/TrafficIoTCounterJournals";
import type { MapLayer } from "@smartly-city/compo-map";

const TrafficIoTCountersProxy: React.FC = () => {
  return (
    <CounterFiltersProvider>
      <TrafficIoTCounterFiltersProxy>
        <LayerFocusProxy layerId={COUNTER_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </TrafficIoTCounterFiltersProxy>
    </CounterFiltersProvider>
  );
};

const TrafficIoTCounterFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = useCounterLayerBuilder();
  const { filters } = useCounterFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getCounters] = useCountersFromTrafficIoTLazyQuery();
  const [getCounterJournals] = useCounterJournalsFromTrafficIoTLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data: countersData } = await getCounters({
      variables: {
        input: { areaId: area.id, filters: toCounterQueryFilters(filters) },
      },
    });
    const { data: journalsData } = await getCounterJournals({
      variables: {
        input: { areaId: area.id },
      },
    });
    const counters = countersData?.TrafficIoT_counters.items ?? [];
    const journals = journalsData?.TrafficIoT_counterJournals.items ?? [];
    setData(builder(counters, journals));
  }, [area.id, filters, getCounters, getCounterJournals, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={COUNTER_LAYER_ID} data={data}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default TrafficIoTCountersProxy;
