import React from "react";

export interface PageContextState {
  apiTitle: string;
  setApiTitle: (apiTitle: string) => void;
  sidebarVisible: boolean;
  setSidebarVisible: (visible: boolean) => void;
}

export const PageContext = React.createContext<PageContextState | undefined>(
  undefined
);

export const usePage = (): PageContextState =>
  React.useContext(PageContext) as PageContextState;
