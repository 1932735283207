import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useTrafficIoTTranslation } from "src/modules/traffic_iot/utils/translation";
import CounterPeopleJournalTabDailyCountChart from "./charts/CounterPeopleJournalTabDailyCountChart";
import CounterPeopleJournalTabDailySpeedChart from "./charts/CounterPeopleJournalTabDailySpeedChart";
import CounterPeopleJournalTabMonthlyCountChart from "./charts/CounterPeopleJournalTabMonthlyCountChart";
import CounterPeopleJournalTabMonthlySpeedChart from "./charts/CounterPeopleJournalTabMonthlySpeedChart";
import type { TrafficIoT_CounterJournalModel } from "src/graphql/types";

export interface CounterPeopleJournalTabProps {
  journal: TrafficIoT_CounterJournalModel;
}

const CounterPeopleJournalTab: React.FC<CounterPeopleJournalTabProps> = (
  props
) => {
  const { t } = useTrafficIoTTranslation();

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("page_counter_view.people_journal")} />
      <CounterPeopleJournalTabDailyCountChart journal={props.journal} />
      <CounterPeopleJournalTabDailySpeedChart journal={props.journal} />
      <CounterPeopleJournalTabMonthlyCountChart journal={props.journal} />
      <CounterPeopleJournalTabMonthlySpeedChart journal={props.journal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default CounterPeopleJournalTab;
