import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useCurrentArea } from "src/core/contexts";
import Compo, { media } from "@smartly-city/compo";
import {
  CompassControl,
  LocateUserControl,
  LocatorLayer,
  Map,
  MapModeControl,
  MapProvider,
  reverseGeocode,
  useMapRef,
} from "@smartly-city/compo-map";

interface AddressInputField {
  value?: string;
  error?: string;
  disabledManual?: boolean;
  onChange: (value: string | undefined) => void;
}

interface OptionalAddressInputField {
  value?: string;
  error?: string;
  disabledManual?: boolean;
  onChange: (value: string | undefined) => void;
}

export interface AddressInputProps {
  latitude: AddressInputField;
  longitude: AddressInputField;
  number?: AddressInputField;
  city?: AddressInputField;
  street?: OptionalAddressInputField;
  className?: string;
  disabled?: boolean;
}

const AddressInput: React.FC<AddressInputProps> = (props) => {
  const { t } = useTranslation();
  const area = useCurrentArea();
  const ref = useMapRef();

  return (
    <Wrapper>
      {props.number && props.city && props.street && (
        <InputWrapper>
          <AddressFieldsWrapper>
            <Compo.TextInput
              label={t("input_address.number") as string | undefined}
              value={props.number.value}
              error={props.number.error}
              onChange={props.number.onChange}
              disabled={props.number.disabledManual || props.disabled}
            />
            <Compo.TextInput
              wide
              label={t("input_address.street") as string | undefined}
              value={props.street.value}
              error={props.street.error}
              onChange={props.street.onChange}
              disabled={props.street.disabledManual || props.disabled}
            />
          </AddressFieldsWrapper>
          <AddressFieldsWrapper>
            <Compo.TextInput
              wide
              label={t("input_address.city") as string | undefined}
              value={props.city.value}
              error={props.city.error}
              onChange={props.city.onChange}
              disabled={props.city.disabledManual || props.disabled}
            />
            <StyledButton
              disabled={!(props.latitude.value && props.longitude.value)}
              onClick={async () => {
                if (!props.latitude.value || !props.longitude.value) return;
                const result = await reverseGeocode(
                  parseFloat(props.longitude.value),
                  parseFloat(props.latitude.value)
                );
                props.number?.onChange(result.number);
                props.street?.onChange(result.street);
                props.city?.onChange(result.city);
              }}
            >
              {t("download")}
            </StyledButton>
          </AddressFieldsWrapper>
        </InputWrapper>
      )}
      <MapWrapper>
        <MapProvider
          controlScroll
          doubleClickZoom
          center={[area.center.longitude, area.center.latitude]}
          zoom={10}
          mapRef={ref}
        >
          <StyledMap
            ref={ref}
            controls={
              <Compo.MapMenu>
                <CompassControl />
                <MapModeControl />
                <LocateUserControl />
              </Compo.MapMenu>
            }
          >
            <LocatorLayer
              latitude={
                props.latitude.value
                  ? parseFloat(props.latitude.value)
                  : undefined
              }
              longitude={
                props.longitude.value
                  ? parseFloat(props.longitude.value)
                  : undefined
              }
              onChange={(latitude, longitude) => {
                props.latitude.onChange(latitude.toString());
                props.longitude.onChange(longitude.toString());
              }}
            />
          </StyledMap>
        </MapProvider>
      </MapWrapper>
      <InputWrapper>
        <Compo.TextInput
          wide
          id="latitude"
          name="latitude"
          type="number"
          label={t("input_address.latitude") as string | undefined}
          value={props.latitude.value}
          error={props.latitude.error}
          onChange={props.latitude.onChange}
          disabled={props.latitude.disabledManual || props.disabled}
        />
        <Compo.TextInput
          wide
          id="longitude"
          name="longitude"
          type="number"
          label={t("input_address.longitude") as string | undefined}
          value={props.longitude.value}
          error={props.longitude.error}
          onChange={props.longitude.onChange}
          disabled={props.latitude.disabledManual || props.disabled}
        />
      </InputWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const MapWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledButton = styled(Compo.Button)`
  margin-top: 1rem;
`;

const StyledMap = styled(Map)`
  height: 20rem;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  margin: 1rem 0;

  @media ${media.max.md} {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const AddressFieldsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  gap: 1rem;
`;

export default AddressInput;
