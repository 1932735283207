import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNewLuminaireFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_CreateNewLuminaireInput;
}>;


export type CreateNewLuminaireFromLightingMutation = { __typename?: 'Mutation', Lighting_createNewLuminaire: { __typename?: 'Lighting_GraphQLResultOfCreateNewLuminairePayload', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }>, value?: { __typename?: 'Lighting_CreateNewLuminairePayload', id: any } | null } };


export const CreateNewLuminaireFromLightingDocument = gql`
    mutation createNewLuminaireFromLighting($input: Lighting_CreateNewLuminaireInput!) {
  Lighting_createNewLuminaire(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    value {
      id
    }
    created
  }
}
    `;
export type CreateNewLuminaireFromLightingMutationFn = Apollo.MutationFunction<CreateNewLuminaireFromLightingMutation, CreateNewLuminaireFromLightingMutationVariables>;

/**
 * __useCreateNewLuminaireFromLightingMutation__
 *
 * To run a mutation, you first call `useCreateNewLuminaireFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewLuminaireFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewLuminaireFromLightingMutation, { data, loading, error }] = useCreateNewLuminaireFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewLuminaireFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewLuminaireFromLightingMutation, CreateNewLuminaireFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewLuminaireFromLightingMutation, CreateNewLuminaireFromLightingMutationVariables>(CreateNewLuminaireFromLightingDocument, options);
      }
export type CreateNewLuminaireFromLightingMutationHookResult = ReturnType<typeof useCreateNewLuminaireFromLightingMutation>;
export type CreateNewLuminaireFromLightingMutationResult = Apollo.MutationResult<CreateNewLuminaireFromLightingMutation>;
export type CreateNewLuminaireFromLightingMutationOptions = Apollo.BaseMutationOptions<CreateNewLuminaireFromLightingMutation, CreateNewLuminaireFromLightingMutationVariables>;