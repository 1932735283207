import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTRemoveLuminaireFromLightingSchedule";
import { useLightingIoTTranslation } from "../../utils/translation";
import type { LightingIoT_LuminaireModel } from "src/graphql/types";

export interface LuminaireLightingScheduleDeleteFormProps {
  onClose: () => void;
  luminaire: LightingIoT_LuminaireModel;
}

const LuminaireLightingScheduleDeleteForm: React.FC<
  LuminaireLightingScheduleDeleteFormProps
> = (props) => {
  const { t } = useLightingIoTTranslation();

  const luminaire = props.luminaire;

  const [removeFromSchedule] =
    useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation({
      variables: {
        input: {
          areaId: luminaire.areaId,
          luminaireId: luminaire.id,
          luminaireVersion: luminaire.version,
        },
      },
      update: (cache, result) => {
        if (
          result.errors ||
          !result.data?.LightingIoT_removeLuminaireFromLightingSchedule
            .isSuccess
        )
          return;
        cache.evict({
          fieldName: "LightingIoT_luminaireById",
        });
        cache.gc();
      },
    });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const { data } = await removeFromSchedule();
      const result = data?.LightingIoT_removeLuminaireFromLightingSchedule;

      if (result?.isSuccess) {
        toast.success(t(`notification.luminaire_removed_from_schedule`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={
          <Compo.Header
            title={t("form_remove_luminiare_from_schedule.title")}
          />
        }
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={formik.isSubmitting}
              type="submit"
              variant="alert"
            >
              {t("delete")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <Text>
          {t("form_remove_luminiare_from_schedule.text", {
            luminaire,
          })}
        </Text>
      </Compo.ModalBox>
    </form>
  );
};

const Text = styled.span`
  margin: 1rem;
  display: inline-block;
`;

export default LuminaireLightingScheduleDeleteForm;
