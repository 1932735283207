import moment from "moment";

export const parseTimeSpan = (isoTimeSpan: string): string => {
  return moment
    .utc(moment.duration(isoTimeSpan).asMilliseconds())
    .format("HH:mm");
};

export const convertTimeSpanToIso = (timeSpan: string): string => {
  return moment.duration(timeSpan).toISOString();
};
