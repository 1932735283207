import { dateStringAsDateTimeString } from "src/core/utils/date";
import type { LuminaireFilters } from "../contexts/LuminaireFilters";
import type { LightingIoT_LuminaireFiltersModelInput } from "src/graphql/types";

export const toLuminaireQueryFilters = (
  filters?: LuminaireFilters
): LightingIoT_LuminaireFiltersModelInput | null => {
  if (!filters) return null;
  const journalFilters = {
    minCommunicationDate:
      dateStringAsDateTimeString(
        filters.communicationDate?.min?.toLocaleDateString(),
        "min"
      ) ?? null,
    maxCommunicationDate:
      dateStringAsDateTimeString(
        filters.communicationDate?.max?.toLocaleDateString(),
        "max"
      ) ?? null,
    minEnergyMeteringDate:
      dateStringAsDateTimeString(
        filters.energyMeteringDate?.min?.toLocaleDateString(),
        "min"
      ) ?? null,
    maxEnergyMeteringDate:
      dateStringAsDateTimeString(
        filters.energyMeteringDate?.max?.toLocaleDateString(),
        "max"
      ) ?? null,
    minSignalStrength: filters.signalStrength?.min ?? null,
    maxSignalStrength: filters.signalStrength?.max ?? null,
    minSignalToNoiseRatio: filters.signalToNoiseRatio?.min ?? null,
    maxSignalToNoiseRatio: filters.signalToNoiseRatio?.max ?? null,
    minTemperature: filters.temperature?.min ?? null,
    maxTemperature: filters.temperature?.max ?? null,
    minConsumption: filters.consumption?.min ?? null,
    maxConsumption: filters.consumption?.max ?? null,
    minPowerFactor: filters.powerFactor?.min ?? null,
    maxPowerFactor: filters.powerFactor?.max ?? null,
    minVoltage: filters.voltage?.min ?? null,
    maxVoltage: filters.voltage?.max ?? null,
  };

  const isUsingJournal = Object.values(journalFilters).some(
    (filter) => filter !== null
  );

  const res = {
    matchesName: filters.name ?? null,
    matchesTypeName: filters.typeName ?? null,
    matchesLightingScheduleName: filters.scheduleName ?? null,
    matchesControllerId: filters.controllerId ?? null,
    withController: filters.hasController ?? null,
    withLightingSchedule: filters.hasLightingSchedule ?? null,
    usingJournal: isUsingJournal ? journalFilters : null,
  };

  return res;
};
