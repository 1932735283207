import React from "react";
import { Outlet, Route } from "react-router-dom";
import ReportCollection from "../pages/ReportCollection/ReportCollection";

const routes = (
  <Route path="reports" element={<Outlet />}>
    <Route index element={<ReportCollection />} />
  </Route>
);

export default routes;
