import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSignInUserFromSecurityMutation } from "src/graphql/__generated__/SecuritySignInUser";
import { useIsSignedInSuspenseQuery } from "src/graphql/__generated__/IsSignedIn";
import * as yup from "yup";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSignedInResult = useIsSignedInSuspenseQuery();
  const [signIn] = useSignInUserFromSecurityMutation({ errorPolicy: "ignore" });

  React.useEffect(() => {
    if (isSignedInResult.data?.isSignedIn) {
      navigate("/", { replace: true });
    }
  }, [isSignedInResult.data?.isSignedIn, navigate]);

  const handleSubmit = async (values: LoginFormValues): Promise<void> => {
    const result = await signIn({
      variables: {
        input: {
          email: values.email,
          password: values.password,
        },
      },
    });

    if (result.data?.Security_signInUser?.success) {
      window.location.pathname = "/";
    } else {
      toast.error(t("notification.invalid_credentials"));
    }
  };

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <PageWrapper>
      <Container>
        <ContentWrapper>
          <LogoWrapper>
            <img src="/images/logo-vi.png" height="100%" alt="Logo" />
          </LogoWrapper>
          <MessageWrapper>
            <Message>{t("page_login.title")}</Message>
          </MessageWrapper>
          <Form onSubmit={formik.handleSubmit}>
            <Compo.TextInput
              wide
              id="email-input"
              name="email"
              type="email"
              label={t("email")}
              disabled={formik.isSubmitting}
              error={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={(v) => formik.handleChange("email")(v ?? "")}
            />
            <Compo.TextInput
              wide
              id="password-input"
              name="password"
              type="password"
              label={t("password")}
              disabled={formik.isSubmitting}
              error={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onChange={(v) => formik.handleChange("password")(v ?? "")}
            />
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("page_login.login_button_text")}
            </Compo.Button>
          </Form>
        </ContentWrapper>
      </Container>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eef6ff;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 40rem;
  background-color: #ffffff;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 25rem;
  max-width: 100%;
  padding: 1.5rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 2.5rem;
`;

const MessageWrapper = styled.div``;

const Message = styled.h1`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export default LoginPage;
