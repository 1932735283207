import React from "react";
import styled from "styled-components";
import Compo from "@smartly-city/compo";
import type {
  Lighting_PoleModel,
  Lighting_PoleBracketDetailsModel,
} from "src/graphql/types";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";

export interface PoleBracketDetailsTabProps {
  pole: Lighting_PoleModel;
}

const PoleBracketDetailsTab: React.FC<PoleBracketDetailsTabProps> = (props) => {
  const { t } = useLightingTranslation();
  const pole = props.pole;
  const bracketDetails =
    pole.bracketDetails as Lighting_PoleBracketDetailsModel;

  return (
    <React.Fragment>
      <HeaderWithDistance outline type="h2" title={t("bracket_details")} />
      <Compo.ListRow wide description={`${bracketDetails.bracketLength} m`}>
        {t("page_pole_view.bracket_length")}
      </Compo.ListRow>
      <Compo.ListRow wide description={`${bracketDetails.tiltAngle} °`}>
        {t("page_pole_view.tilt_angle")}
      </Compo.ListRow>
      {bracketDetails.dateOfBracketPainting && (
        <Compo.ListRow
          wide
          description={new Date(
            bracketDetails.dateOfBracketPainting
          ).toLocaleDateString()}
        >
          {t("page_pole_view.date_of_bracket_painting")}
        </Compo.ListRow>
      )}
      <Compo.ListRow
        wide
        description={
          (bracketDetails.bracketQualifiedForReplacement
            ? t("yes")
            : t("no")) ?? undefined
        }
      >
        {t("page_pole_view.bracket_qualified_for_replacement")}
      </Compo.ListRow>
      {bracketDetails.newBracketLengthAfterReplacement && (
        <Compo.ListRow
          wide
          description={`${bracketDetails.newBracketLengthAfterReplacement} m`}
        >
          {t("page_pole_view.new_bracket_length_after_replacement")}
        </Compo.ListRow>
      )}
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default PoleBracketDetailsTab;
