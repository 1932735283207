import React from "react";
import Compo from "@smartly-city/compo";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import type { Lighting_LuminaireModel } from "src/graphql/types";
import { useDeleteLuminaireFromLightingMutation } from "src/graphql/__generated__/LightingDeleteLuminaire";
import styled from "styled-components";
import { useLightingTranslation } from "../../utils/translation";

export interface LuminaireConfirmDeleteProps {
  onClose: () => void;
  luminaire: Lighting_LuminaireModel;
}

const LuminaireDeleteForm: React.FC<LuminaireConfirmDeleteProps> = (props) => {
  const { t } = useLightingTranslation();
  const navigate = useNavigate();

  const luminaire = props.luminaire;

  const [deleteLuminaire] = useDeleteLuminaireFromLightingMutation({
    variables: {
      input: {
        id: luminaire.id,
        version: luminaire.version,
        areaId: luminaire.areaId,
      },
    },
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_deleteLuminaire.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_luminaireById",
      });
      cache.evict({
        fieldName: "Lighting_luminaires",
      });
      cache.gc();
    },
  });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const { data } = await deleteLuminaire();
      const result = data?.Lighting_deleteLuminaire;

      if (result?.isSuccess) {
        toast.success(t(`notification.luminaire_deleted`));
        props.onClose();
        navigate("..", { replace: true });
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={<Compo.Header title={t("form_luminaire_delete.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={formik.isSubmitting}
              type="submit"
              variant="alert"
            >
              {t("delete")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <Text>{t("form_luminaire_delete.text", { luminaire })}</Text>
      </Compo.ModalBox>
    </form>
  );
};

const Text = styled.span`
  margin: 1rem;
  display: inline-block;
`;

export default LuminaireDeleteForm;
