import React from "react";
import { AreaSettingsContext } from "./AreaSettingsContext";
import type { Lighting_AreaSettingsModel } from "src/graphql/types";

export interface AreaSettingsProviderProps {
  settings?: Lighting_AreaSettingsModel;
  children?: React.ReactNode;
}

const AreaSettingsProvider: React.FC<AreaSettingsProviderProps> = (props) => {
  const [settings] = React.useState<Lighting_AreaSettingsModel | undefined>(
    props.settings
  );

  return (
    <AreaSettingsContext.Provider
      value={{
        settings,
      }}
    >
      {props.children}
    </AreaSettingsContext.Provider>
  );
};

export default AreaSettingsProvider;
