import React from "react";
import type { MapLayer } from "@smartly-city/compo-map";
import type {
  WeatherIoT_StationJournalRowModel,
  WeatherIoT_StationRowModel,
} from "src/graphql/types";
import { daysBetween } from "src/core/utils/date";

export const COUNTER_LAYER_ID = "station";

export const useStationLayerBuilder = (): ((
  stations: WeatherIoT_StationRowModel[],
  journals: WeatherIoT_StationJournalRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (stations, journals) => ({
      id: COUNTER_LAYER_ID,
      color: "#767676",
      items: stations.map((station) => ({
        id: station.id,
        coordinates: station.location,
        name: station.name,
        color: getStationColor(
          journals.find((j) => j.stationId === station.id)
        ),
      })),
    }),
    []
  );
};

const getStationColor = (
  journal?: WeatherIoT_StationJournalRowModel
): string => {
  const communicatedAgo = journal ? getCommunicatedDays(journal) : null;
  switch (true) {
    case communicatedAgo && communicatedAgo < 3:
      return "#3CB56C";
    case communicatedAgo && communicatedAgo < 7:
      return "#F9E850";
    case communicatedAgo && communicatedAgo < 14:
      return "#FFB03A";
    case communicatedAgo && communicatedAgo < 30:
      return "#FF3A3A";
    default:
      return "#767676";
  }
};

const getCommunicatedDays = (
  journal: WeatherIoT_StationJournalRowModel
): number | undefined => {
  return journal.status.communicationDate
    ? daysBetween(new Date(journal.status.communicationDate), new Date())
    : undefined;
};
