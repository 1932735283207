import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FailuresPagedFromFailuresQueryVariables = Types.Exact<{
  input: Types.Failures_GetFailuresPagedInput;
}>;


export type FailuresPagedFromFailuresQuery = { __typename?: 'Query', Failures_failuresPaged: { __typename?: 'Failures_GetFailuresPagedPayload', page: number, count: number, total: number, items: Array<{ __typename?: 'Failures_FailureRowModel', id: any, version: number, areaId: any, type: Types.Failures_FailureTypeModel, state: Types.Failures_FailureStateModel, active: boolean, objectId: any, objectName: string, objectLocation: { __typename?: 'Failures_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const FailuresPagedFromFailuresDocument = gql`
    query failuresPagedFromFailures($input: Failures_GetFailuresPagedInput!) {
  Failures_failuresPaged(input: $input) {
    page
    count
    total
    items {
      id
      version
      areaId
      type
      state
      active
      objectId
      objectName
      objectLocation {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useFailuresPagedFromFailuresQuery__
 *
 * To run a query within a React component, call `useFailuresPagedFromFailuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailuresPagedFromFailuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailuresPagedFromFailuresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFailuresPagedFromFailuresQuery(baseOptions: Apollo.QueryHookOptions<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables> & ({ variables: FailuresPagedFromFailuresQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables>(FailuresPagedFromFailuresDocument, options);
      }
export function useFailuresPagedFromFailuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables>(FailuresPagedFromFailuresDocument, options);
        }
export function useFailuresPagedFromFailuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables>(FailuresPagedFromFailuresDocument, options);
        }
export type FailuresPagedFromFailuresQueryHookResult = ReturnType<typeof useFailuresPagedFromFailuresQuery>;
export type FailuresPagedFromFailuresLazyQueryHookResult = ReturnType<typeof useFailuresPagedFromFailuresLazyQuery>;
export type FailuresPagedFromFailuresSuspenseQueryHookResult = ReturnType<typeof useFailuresPagedFromFailuresSuspenseQuery>;
export type FailuresPagedFromFailuresQueryResult = Apollo.QueryResult<FailuresPagedFromFailuresQuery, FailuresPagedFromFailuresQueryVariables>;