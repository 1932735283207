import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";

const CounterListRowsSkeleton: React.FC = () => {
  return (
    <Wrapper>
      {Array(10)
        .fill(0)
        .map((_, index) => (
          <Compo.ListRowSkeleton key={index} wide button />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  overflow: hidden;
`;

export default CounterListRowsSkeleton;
