import React from "react";
import styled from "styled-components";
import Compo, { media } from "@smartly-city/compo";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useStatisticsFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresStatistics";
import { useFailuresTranslation } from "../../utils/translation";
import { useNavigate } from "react-router-dom";
import { FailureHistoryChart } from "./elements/FailureHistoryChart";

const FailuresStatistics: React.FC = () => {
  const { t } = useFailuresTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const hasStatisticsPermission = usePermissionMatch("failures.statistics.get");
  const hasFailuresPermission = usePermissionMatch("failures.failure.get");

  const { data } = useStatisticsFromFailuresSuspenseQuery({
    variables: { input: { areaId: area?.id } },
  });
  const statistics = data?.Failures_statistics.statistics;

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("api_failures")}>
        {hasStatisticsPermission && (
          <Compo.Button
            small
            filled
            icon={Compo.ExternalLinkIcon}
            onClick={() => navigate("/failures")}
          />
        )}
      </Compo.Header>
      {hasStatisticsPermission && data ? (
        <ContentWrapper>
          <TilesWrapper>
            <Compo.DashboardTile
              wide
              alert
              title={t("dashboard.active_failures")}
              onClick={
                hasFailuresPermission ? () => navigate("/failures") : undefined
              }
            >
              {statistics.activeFailureCount.toString()}
            </Compo.DashboardTile>
            <Compo.DashboardTile
              wide
              success
              title={t("dashboard.resolved_failures")}
              onClick={
                hasFailuresPermission ? () => navigate("/failures") : undefined
              }
            >
              {statistics.resolvedFailureCount.toString()}
            </Compo.DashboardTile>
            <Compo.DashboardTile
              wide
              title={t("dashboard.manual_failures")}
              onClick={
                hasFailuresPermission ? () => navigate("/failures") : undefined
              }
            >
              {statistics.manualFailureCount.toString()}
            </Compo.DashboardTile>
          </TilesWrapper>
          <ChartWrapper>
            <FailureHistoryChart statistics={statistics} />
          </ChartWrapper>
        </ContentWrapper>
      ) : (
        <StyledActionTile text={t("banner.no_statistics_access")} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem 1rem;
  background-color: #fff;
  border-radius: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${media.max.sm} {
    flex-direction: column;
  }
`;

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3%;
  gap: 1rem;

  @media ${media.max.sm} {
    width: 100%;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  width: 66.6%;
  margin-left: 1rem;

  @media ${media.max.sm} {
    margin-top: 1rem;
    margin-left: 0;
    width: 100%;
  }
`;

const StyledActionTile = styled(Compo.ActionTile)`
  width: 100%;
  padding: 7.5rem 7.5rem;
`;

export default FailuresStatistics;
