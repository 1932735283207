import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireTypesPagedFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetLuminaireTypesPagedInput;
}>;


export type LuminaireTypesPagedFromLightingQuery = { __typename?: 'Query', Lighting_luminaireTypesPaged: { __typename?: 'Lighting_GetLuminaireTypesPagedPayload', count: number, total: number, items: Array<{ __typename?: 'Lighting_LuminaireTypeRowModel', id: any, version: number, areaId: any, name: string }> } };


export const LuminaireTypesPagedFromLightingDocument = gql`
    query luminaireTypesPagedFromLighting($input: Lighting_GetLuminaireTypesPagedInput!) {
  Lighting_luminaireTypesPaged(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      name
    }
  }
}
    `;

/**
 * __useLuminaireTypesPagedFromLightingQuery__
 *
 * To run a query within a React component, call `useLuminaireTypesPagedFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireTypesPagedFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireTypesPagedFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireTypesPagedFromLightingQuery(baseOptions: Apollo.QueryHookOptions<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables> & ({ variables: LuminaireTypesPagedFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables>(LuminaireTypesPagedFromLightingDocument, options);
      }
export function useLuminaireTypesPagedFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables>(LuminaireTypesPagedFromLightingDocument, options);
        }
export function useLuminaireTypesPagedFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables>(LuminaireTypesPagedFromLightingDocument, options);
        }
export type LuminaireTypesPagedFromLightingQueryHookResult = ReturnType<typeof useLuminaireTypesPagedFromLightingQuery>;
export type LuminaireTypesPagedFromLightingLazyQueryHookResult = ReturnType<typeof useLuminaireTypesPagedFromLightingLazyQuery>;
export type LuminaireTypesPagedFromLightingSuspenseQueryHookResult = ReturnType<typeof useLuminaireTypesPagedFromLightingSuspenseQuery>;
export type LuminaireTypesPagedFromLightingQueryResult = Apollo.QueryResult<LuminaireTypesPagedFromLightingQuery, LuminaireTypesPagedFromLightingQueryVariables>;