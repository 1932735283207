import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexCounterJournalsFromTrafficIoTMutationVariables = Types.Exact<{
  input: Types.TrafficIoT_Admin_IndexCounterJournalsInput;
}>;


export type IndexCounterJournalsFromTrafficIoTMutation = { __typename?: 'Mutation', TrafficIoT_Admin_indexCounterJournals: { __typename?: 'TrafficIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'TrafficIoT_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'TrafficIoT_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const IndexCounterJournalsFromTrafficIoTDocument = gql`
    mutation indexCounterJournalsFromTrafficIoT($input: TrafficIoT_Admin_IndexCounterJournalsInput!) {
  TrafficIoT_Admin_indexCounterJournals(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type IndexCounterJournalsFromTrafficIoTMutationFn = Apollo.MutationFunction<IndexCounterJournalsFromTrafficIoTMutation, IndexCounterJournalsFromTrafficIoTMutationVariables>;

/**
 * __useIndexCounterJournalsFromTrafficIoTMutation__
 *
 * To run a mutation, you first call `useIndexCounterJournalsFromTrafficIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexCounterJournalsFromTrafficIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexCounterJournalsFromTrafficIoTMutation, { data, loading, error }] = useIndexCounterJournalsFromTrafficIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexCounterJournalsFromTrafficIoTMutation(baseOptions?: Apollo.MutationHookOptions<IndexCounterJournalsFromTrafficIoTMutation, IndexCounterJournalsFromTrafficIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexCounterJournalsFromTrafficIoTMutation, IndexCounterJournalsFromTrafficIoTMutationVariables>(IndexCounterJournalsFromTrafficIoTDocument, options);
      }
export type IndexCounterJournalsFromTrafficIoTMutationHookResult = ReturnType<typeof useIndexCounterJournalsFromTrafficIoTMutation>;
export type IndexCounterJournalsFromTrafficIoTMutationResult = Apollo.MutationResult<IndexCounterJournalsFromTrafficIoTMutation>;
export type IndexCounterJournalsFromTrafficIoTMutationOptions = Apollo.BaseMutationOptions<IndexCounterJournalsFromTrafficIoTMutation, IndexCounterJournalsFromTrafficIoTMutationVariables>;