import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import type { HttpBackendOptions } from "i18next-http-backend";
import moment from "moment";

void i18n
  .use(I18NextHttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>(
    {
      ns: ["global", "lighting", "lighting_iot"],
      fallbackNS: "global",
      lng: navigator.language,
      fallbackLng: "en-US",
      interpolation: {
        escapeValue: false,
      },
    },
    async () => {
      try {
        await import("moment/locale/" + i18n.language);
        moment().locale(i18n.language);
      } catch {}
    }
  );

export default i18n;
