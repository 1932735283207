import Compo from "@smartly-city/compo";
import React from "react";
import styled from "styled-components";

const PoleViewSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.TabsSkeleton wide>
        <ContentWrapper>
          <Compo.HeaderSkeleton />
          <Compo.HeaderSkeleton type="h2" />
          <Compo.LinkTileSkeleton wide />
          <Compo.HeaderSkeleton type="h2" />
          <Compo.LinkTileSkeleton wide />
          <Compo.HeaderSkeleton type="h2" />
          <Compo.LinkTileSkeleton wide />
          <Compo.HeaderSkeleton type="h2" />
          <Compo.ListRowSkeleton wide />
          <Compo.ListRowSkeleton wide />
          <Compo.HeaderSkeleton type="h2" />
          <Compo.ListRowSkeleton wide />
          <Compo.ListRowSkeleton wide />
          <Compo.HeaderSkeleton type="h2" />
          <Compo.ListRowSkeleton wide />
          <Compo.ListRowSkeleton wide />
        </ContentWrapper>
      </Compo.TabsSkeleton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

export default PoleViewSkeleton;
