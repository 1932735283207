import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PolesPagedFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetPolesPagedInput;
}>;


export type PolesPagedFromLightingQuery = { __typename?: 'Query', Lighting_polesPaged: { __typename?: 'Lighting_GetPolesPagedPayload', count: number, total: number, items: Array<{ __typename?: 'Lighting_PoleRowModel', id: any, version: number, ordinalNumber: number, areaId: any, powerBoxId: any, powerBoxName: string, name: string, location: { __typename?: 'Lighting_CoordinatesModel', latitude: number, longitude: number } }> } };


export const PolesPagedFromLightingDocument = gql`
    query polesPagedFromLighting($input: Lighting_GetPolesPagedInput!) {
  Lighting_polesPaged(input: $input) {
    count
    total
    items {
      id
      version
      ordinalNumber
      areaId
      powerBoxId
      powerBoxName
      name
      location {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __usePolesPagedFromLightingQuery__
 *
 * To run a query within a React component, call `usePolesPagedFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolesPagedFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolesPagedFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePolesPagedFromLightingQuery(baseOptions: Apollo.QueryHookOptions<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables> & ({ variables: PolesPagedFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables>(PolesPagedFromLightingDocument, options);
      }
export function usePolesPagedFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables>(PolesPagedFromLightingDocument, options);
        }
export function usePolesPagedFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables>(PolesPagedFromLightingDocument, options);
        }
export type PolesPagedFromLightingQueryHookResult = ReturnType<typeof usePolesPagedFromLightingQuery>;
export type PolesPagedFromLightingLazyQueryHookResult = ReturnType<typeof usePolesPagedFromLightingLazyQuery>;
export type PolesPagedFromLightingSuspenseQueryHookResult = ReturnType<typeof usePolesPagedFromLightingSuspenseQuery>;
export type PolesPagedFromLightingQueryResult = Apollo.QueryResult<PolesPagedFromLightingQuery, PolesPagedFromLightingQueryVariables>;