import React from "react";
import { Outlet } from "react-router-dom";
import {
  LayerFocusProxy,
  LayerOverrideProxy,
  type MapLayer,
} from "@smartly-city/compo-map";
import { FAILURE_LAYER_ID, useFailureLayerBuilder } from "../utils/layers";
import {
  FailureFiltersProvider,
  useFailureFilters,
} from "../contexts/FailureFilters";
import { useCurrentArea } from "src/core/contexts";
import { useFailuresFromFailuresLazyQuery } from "src/graphql/__generated__/FailuresFailures";
import { toFailureQueryFilters } from "../utils/filters";

const FailuresFailuresProxy: React.FC = () => {
  return (
    <FailureFiltersProvider>
      <FailuresFailuresFiltersProxy>
        <LayerFocusProxy layerId={FAILURE_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </FailuresFailuresFiltersProxy>
    </FailureFiltersProvider>
  );
};

const FailuresFailuresFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = useFailureLayerBuilder();
  const { filters } = useFailureFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getFailures] = useFailuresFromFailuresLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data } = await getFailures({
      variables: {
        input: { areaId: area.id, filters: toFailureQueryFilters(filters) },
      },
    });
    const items = data?.Failures_failures.items ?? [];
    setData(builder(items));
  }, [area.id, filters, getFailures, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={FAILURE_LAYER_ID} data={data ?? null}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default FailuresFailuresProxy;
