import React from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  Failures_FailureTypeModel,
  type Failures_StatisticsModel,
} from "src/graphql/types";
import { useFailuresTranslation } from "src/modules/failures/utils/translation";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Colors
);

interface FailureHistoryChartProps {
  statistics: Failures_StatisticsModel;
}

const FailureHistoryChart: React.FC<FailureHistoryChartProps> = (props) => {
  const { t, i18n } = useFailuresTranslation();
  const isEnglish = i18n.language.startsWith("en");

  const failureHistory = props.statistics.failureCountByDay;

  const failureHistoryChartData = {
    datasets: [
      {
        label: t("failure_type.system"),
        data: failureHistory
          .filter((item) => item.type === Failures_FailureTypeModel.System)
          .map((item) => ({ x: item.date, y: item.count })),
      },
      {
        label: t("failure_type.manual"),
        data: failureHistory
          .filter((item) => item.type === Failures_FailureTypeModel.Manual)
          .map((item) => ({ x: item.date, y: item.count })),
      },
    ],
  };

  return (
    <Wrapper>
      <Title>{t("dashboard_failure_history_chart.title")}</Title>
      <StyledLine
        data={failureHistoryChartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: isEnglish ? "MMMM DD" : "DD MMMM",
                displayFormats: {
                  day: isEnglish ? "MMM DD" : "DD MMM",
                },
              },
            },
            y: {
              border: { display: false },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border: 2.5px solid #eef6ff;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

const Title = styled.h3`
  color: #767676;
`;

const StyledLine = styled(Line)`
  max-height: 12.5rem;
  width: 100%;
`;

export default FailureHistoryChart;
