import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNewLightingScheduleFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_CreateNewLightingScheduleInput;
}>;


export type CreateNewLightingScheduleFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_createNewLightingSchedule: { __typename?: 'LightingIoT_GraphQLResultOfCreateNewLightingSchedulePayload', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }>, value?: { __typename?: 'LightingIoT_CreateNewLightingSchedulePayload', id: any } | null } };


export const CreateNewLightingScheduleFromLightingIoTDocument = gql`
    mutation createNewLightingScheduleFromLightingIoT($input: LightingIoT_CreateNewLightingScheduleInput!) {
  LightingIoT_createNewLightingSchedule(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    value {
      id
    }
    created
  }
}
    `;
export type CreateNewLightingScheduleFromLightingIoTMutationFn = Apollo.MutationFunction<CreateNewLightingScheduleFromLightingIoTMutation, CreateNewLightingScheduleFromLightingIoTMutationVariables>;

/**
 * __useCreateNewLightingScheduleFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useCreateNewLightingScheduleFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewLightingScheduleFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewLightingScheduleFromLightingIoTMutation, { data, loading, error }] = useCreateNewLightingScheduleFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewLightingScheduleFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewLightingScheduleFromLightingIoTMutation, CreateNewLightingScheduleFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewLightingScheduleFromLightingIoTMutation, CreateNewLightingScheduleFromLightingIoTMutationVariables>(CreateNewLightingScheduleFromLightingIoTDocument, options);
      }
export type CreateNewLightingScheduleFromLightingIoTMutationHookResult = ReturnType<typeof useCreateNewLightingScheduleFromLightingIoTMutation>;
export type CreateNewLightingScheduleFromLightingIoTMutationResult = Apollo.MutationResult<CreateNewLightingScheduleFromLightingIoTMutation>;
export type CreateNewLightingScheduleFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<CreateNewLightingScheduleFromLightingIoTMutation, CreateNewLightingScheduleFromLightingIoTMutationVariables>;