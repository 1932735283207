import React from "react";
import Compo, { usePopover } from "@smartly-city/compo";
import styled from "styled-components";
import * as yup from "yup";
import { useLightingTranslation } from "../../utils/translation";
import { useFormik } from "formik";
import { type PoleFilters, usePoleFilters } from "../../contexts/PoleFilters";
import FiltersHeader, { type Filter } from "src/core/components/FiltersHeader";
import { toSnakeCase } from "src/core/utils/string";
import {
  setBooleanFieldValue,
  setDateFieldValue,
  setFloatFieldValue,
  setIntFieldValue,
  setTextFieldValue,
  toDateValue,
  toStringValue,
} from "src/core/utils/formik";
import {
  Lighting_GridTypeModel,
  Lighting_LineTypeModel,
  Lighting_OwnerModel,
  Lighting_PoleLocationModel,
  Lighting_PoleTypeModel,
  Lighting_RoadLightingClassModel,
  Lighting_RoadSurfaceModel,
  Lighting_RoadTypeModel,
  Lighting_SidewalkSideModel,
} from "src/graphql/types";

export interface PoleFiltersFormProps {
  onSubmit?: () => void;
}

export interface PoleFiltersFormContext {
  name: string | null;
  powerBoxName: string | null;
  ordinalNumber: number | null;
  addressNumber: string | null;
  addressStreet: string | null;
  addressTown: string | null;
  type: Lighting_PoleTypeModel | null;
  lineType: Lighting_LineTypeModel | null;
  gridType: Lighting_GridTypeModel | null;
  lineCrossSection: string | null;
  owner: Lighting_OwnerModel | null;
  plotNumber: string | null;
  location: Lighting_PoleLocationModel | null;
  qualifiedForReplacement: boolean | null;
  roadType: Lighting_RoadTypeModel | null;
  roadSurface: Lighting_RoadSurfaceModel | null;
  roadLightingClass: Lighting_RoadLightingClassModel | null;
  sidewalkSide: Lighting_SidewalkSideModel | null;
  minDateOfPainting: Date | null;
  maxDateOfPainting: Date | null;
  minDistanceFromRoadwayEdgeToPole: number | null;
  maxDistanceFromRoadwayEdgeToPole: number | null;
  minDistanceToClosestPole: number | null;
  maxDistanceToClosestPole: number | null;
  minHeight: number | null;
  maxHeight: number | null;
  maxRoadWidth: number | null;
  minRoadWidth: number | null;
  minBracketLength: number | null;
  maxBracketLength: number | null;
  minTiltAngle: number | null;
  maxTiltAngle: number | null;
  bracketQualifiedForReplacement: boolean | null;
  minNewBracketLengthAfterReplacement: number | null;
  maxNewBracketLengthAfterReplacement: number | null;
  minDateOfBracketPainting: Date | null;
  maxDateOfBracketPainting: Date | null;
  minSidewalkWidth: number | null;
  maxSidewalkWidth: number | null;
}

const PoleFiltersForm: React.FC<PoleFiltersFormProps> = (props) => {
  const { t } = useLightingTranslation();
  const popover = usePopover();
  const filters = usePoleFilters();
  const ref = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    popover.buttonRef(ref.current);
  }, [popover, ref]);

  const headerFilterKeys = Object.keys(filters.status) as Array<
    keyof PoleFilters
  >;

  const headerFilters: Filter[] = headerFilterKeys.map((key) => ({
    id: key,
    name: t("form_pole_filters." + toSnakeCase(key)),
    active: filters.status[key] ?? false,
    onAdd: () => filters.activateFilter(key),
  }));

  const formik = useFormik<PoleFiltersFormContext>({
    initialValues: {
      name: filters.filters.name ?? null,
      powerBoxName: filters.filters.powerBoxName ?? null,
      ordinalNumber: filters.filters.ordinalNumber ?? null,
      addressNumber: filters.filters.addressNumber ?? null,
      addressStreet: filters.filters.addressStreet ?? null,
      addressTown: filters.filters.addressTown ?? null,
      type: filters.filters.type ?? null,
      lineType: filters.filters.lineType ?? null,
      gridType: filters.filters.gridType ?? null,
      lineCrossSection: filters.filters.lineCrossSection ?? null,
      owner: filters.filters.owner ?? null,
      plotNumber: filters.filters.plotNumber ?? null,
      location: filters.filters.location ?? null,
      qualifiedForReplacement: filters.filters.qualifiedForReplacement ?? null,
      roadType: filters.filters.roadType ?? null,
      roadSurface: filters.filters.roadSurface ?? null,
      roadLightingClass: filters.filters.roadLightingClass ?? null,
      sidewalkSide: filters.filters.sidewalkSide ?? null,
      minDateOfPainting: filters.filters.dateOfPainting?.min ?? null,
      maxDateOfPainting: filters.filters.dateOfPainting?.max ?? null,
      minDistanceFromRoadwayEdgeToPole:
        filters.filters.distanceFromRoadwayEdgeToPole?.min ?? null,
      maxDistanceFromRoadwayEdgeToPole:
        filters.filters.distanceFromRoadwayEdgeToPole?.max ?? null,
      minDistanceToClosestPole:
        filters.filters.distanceToClosestPole?.min ?? null,
      maxDistanceToClosestPole:
        filters.filters.distanceToClosestPole?.max ?? null,
      minHeight: filters.filters.height?.min ?? null,
      maxHeight: filters.filters.height?.max ?? null,
      minRoadWidth: filters.filters.roadWidth?.min ?? null,
      maxRoadWidth: filters.filters.roadWidth?.max ?? null,
      minBracketLength: filters.filters.bracketLength?.min ?? null,
      maxBracketLength: filters.filters.bracketLength?.max ?? null,
      minTiltAngle: filters.filters.tiltAngle?.min ?? null,
      maxTiltAngle: filters.filters.tiltAngle?.max ?? null,
      bracketQualifiedForReplacement:
        filters.filters.bracketQualifiedForReplacement ?? null,
      minNewBracketLengthAfterReplacement:
        filters.filters.newBracketLengthAfterReplacement?.min ?? null,
      maxNewBracketLengthAfterReplacement:
        filters.filters.newBracketLengthAfterReplacement?.max ?? null,
      minDateOfBracketPainting:
        filters.filters.dateOfBracketPainting?.min ?? null,
      maxDateOfBracketPainting:
        filters.filters.dateOfBracketPainting?.max ?? null,
      minSidewalkWidth: filters.filters.sidewalkWidth?.min ?? null,
      maxSidewalkWidth: filters.filters.sidewalkWidth?.max ?? null,
    },
    validationSchema: yup.object({
      name: yup.string().nullable().fromFormik(),
      powerBoxName: yup.string().nullable().fromFormik(),
      ordinalNumber: yup.string().nullable().fromFormik(),
      addressNumber: yup.string().nullable().fromFormik(),
      addressStreet: yup.string().nullable().fromFormik(),
      addressTown: yup.string().nullable().fromFormik(),
      type: yup
        .string()
        .oneOf(Object.values(Lighting_PoleTypeModel))
        .nullable()
        .fromFormik(),
      lineType: yup
        .string()
        .oneOf(Object.values(Lighting_LineTypeModel))
        .nullable()
        .fromFormik(),
      gridType: yup
        .string()
        .oneOf(Object.values(Lighting_GridTypeModel))
        .nullable()
        .fromFormik(),
      lineCrossSection: yup.string().nullable().fromFormik(),
      owner: yup
        .string()
        .oneOf(Object.values(Lighting_OwnerModel))
        .nullable()
        .fromFormik(),
      plotNumber: yup.string().nullable().fromFormik(),
      location: yup
        .string()
        .oneOf(Object.values(Lighting_PoleLocationModel))
        .nullable()
        .fromFormik(),
      qualifiedForReplacement: yup.bool().nullable().fromFormik(),
      roadType: yup
        .string()
        .oneOf(Object.values(Lighting_RoadTypeModel))
        .nullable()
        .fromFormik(),
      roadSurface: yup
        .string()
        .oneOf(Object.values(Lighting_RoadSurfaceModel))
        .nullable()
        .fromFormik(),
      roadLightingClass: yup
        .string()
        .oneOf(Object.values(Lighting_RoadLightingClassModel))
        .nullable()
        .fromFormik(),
      sidewalkSide: yup
        .string()
        .oneOf(Object.values(Lighting_SidewalkSideModel))
        .nullable()
        .fromFormik(),
      minDateOfPainting: yup.date().nullable().fromFormik(),
      maxDateOfPainting: yup.date().nullable().fromFormik(),
      minDistanceFromRoadwayEdgeToPole: yup.number().nullable().fromFormik(),
      maxDistanceFromRoadwayEdgeToPole: yup.number().nullable().fromFormik(),
      minDistanceToClosestPole: yup.number().nullable().fromFormik(),
      maxDistanceToClosestPole: yup.number().nullable().fromFormik(),
      minHeight: yup.number().nullable().fromFormik(),
      maxHeight: yup.number().nullable().fromFormik(),
      minRoadWidth: yup.number().nullable().fromFormik(),
      maxRoadWidth: yup.number().nullable().fromFormik(),
      minBracketLength: yup.number().nullable().fromFormik(),
      maxBracketLength: yup.number().nullable().fromFormik(),
      minTiltAngle: yup.number().nullable().fromFormik(),
      maxTiltAngle: yup.number().nullable().fromFormik(),
      bracketQualifiedForReplacement: yup.bool().nullable().fromFormik(),
      minNewBracketLengthAfterReplacement: yup.number().nullable().fromFormik(),
      maxNewBracketLengthAfterReplacement: yup.number().nullable().fromFormik(),
      minDateOfBracketPainting: yup.date().nullable().fromFormik(),
      maxDateOfBracketPainting: yup.date().nullable().fromFormik(),
      minSidewalkWidth: yup.number().nullable().fromFormik(),
      maxSidewalkWidth: yup.number().nullable().fromFormik(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      filters.setFilters({
        name: values.name ?? undefined,
        powerBoxName: values.powerBoxName ?? undefined,
        ordinalNumber: values.ordinalNumber ?? undefined,
        addressNumber: values.addressNumber ?? undefined,
        addressStreet: values.addressStreet ?? undefined,
        addressTown: values.addressTown ?? undefined,
        type: values.type ?? undefined,
        height: {
          max: values.maxHeight ?? undefined,
          min: values.minHeight ?? undefined,
        },
        lineType: values.lineType ?? undefined,
        gridType: values.gridType ?? undefined,
        lineCrossSection: values.lineCrossSection ?? undefined,
        owner: values.owner ?? undefined,
        dateOfPainting: {
          max: values.maxDateOfPainting
            ? new Date(values.maxDateOfPainting)
            : undefined,
          min: values.minDateOfPainting
            ? new Date(values.minDateOfPainting)
            : undefined,
        },
        plotNumber: values.plotNumber ?? undefined,
        location: values.location ?? undefined,
        distanceToClosestPole: {
          max: values.maxDistanceToClosestPole ?? undefined,
          min: values.minDistanceToClosestPole ?? undefined,
        },
        qualifiedForReplacement: values.qualifiedForReplacement ?? undefined,
        roadType: values.roadType ?? undefined,
        roadSurface: values.roadSurface ?? undefined,
        roadLightingClass: values.roadLightingClass ?? undefined,
        roadWidth: {
          max: values.maxRoadWidth ?? undefined,
          min: values.minRoadWidth ?? undefined,
        },
        distanceFromRoadwayEdgeToPole: {
          max: values.maxDistanceFromRoadwayEdgeToPole ?? undefined,
          min: values.minDistanceFromRoadwayEdgeToPole ?? undefined,
        },
        bracketLength: {
          max: values.maxBracketLength ?? undefined,
          min: values.minBracketLength ?? undefined,
        },
        tiltAngle: {
          max: values.maxTiltAngle ?? undefined,
          min: values.minTiltAngle ?? undefined,
        },
        bracketQualifiedForReplacement:
          values.bracketQualifiedForReplacement ?? undefined,
        newBracketLengthAfterReplacement: {
          max: values.maxNewBracketLengthAfterReplacement ?? undefined,
          min: values.minNewBracketLengthAfterReplacement ?? undefined,
        },
        dateOfBracketPainting: {
          max: values.maxDateOfBracketPainting
            ? new Date(values.maxDateOfBracketPainting)
            : undefined,
          min: values.minDateOfBracketPainting
            ? new Date(values.minDateOfBracketPainting)
            : undefined,
        },
        sidewalkSide: values.sidewalkSide ?? undefined,
        sidewalkWidth: {
          max: values.maxSidewalkWidth ?? undefined,
          min: values.minSidewalkWidth ?? undefined,
        },
      });
      props.onSubmit?.();
      setSubmitting(false);
    },
    onReset: () => {
      filters.clearFilters();
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FiltersHeader
        filters={headerFilters}
        onSave={formik.submitForm}
        onClear={formik.resetForm}
      />
      {filters.status.name && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("name")}>
          <Compo.TextInput
            wide
            label={t("form_pole_filters.name")}
            value={toStringValue(formik.values.name)}
            onChange={setTextFieldValue(formik, "name")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.powerBoxName && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("powerBoxName")}
        >
          <Compo.TextInput
            wide
            label={t("form_pole_filters.power_box_name")}
            value={toStringValue(formik.values.powerBoxName)}
            onChange={setTextFieldValue(formik, "powerBoxName")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.ordinalNumber && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("ordinalNumber")}
        >
          <Compo.TextInput
            wide
            label={t("form_pole_filters.ordinal_number")}
            value={toStringValue(formik.values.ordinalNumber)}
            onChange={setIntFieldValue(formik, "ordinalNumber")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.addressNumber && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("addressNumber")}
        >
          <Compo.TextInput
            wide
            label={t("form_pole_filters.address_number")}
            error={formik.errors.addressNumber}
            value={toStringValue(formik.values.addressNumber)}
            onChange={setTextFieldValue(formik, "addressNumber")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.addressStreet && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("addressStreet")}
        >
          <Compo.TextInput
            wide
            label={t("form_pole_filters.address_street")}
            error={formik.errors.addressStreet}
            value={toStringValue(formik.values.addressStreet)}
            onChange={setTextFieldValue(formik, "addressStreet")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.addressTown && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("addressTown")}
        >
          <Compo.TextInput
            wide
            label={t("form_pole_filters.address_town")}
            error={formik.errors.addressTown}
            value={toStringValue(formik.values.addressTown)}
            onChange={setTextFieldValue(formik, "addressTown")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.type && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("type")}>
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.type")}
            value={toStringValue(formik.values.type)}
            onChange={setTextFieldValue(formik, "type")}
          >
            <option />
            {Object.values(Lighting_PoleTypeModel).map((type) => (
              <option key={type} value={type}>
                {t(`pole_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.height && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("height")}>
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.min_height")}
            value={toStringValue(formik.values.minHeight)}
            onChange={setFloatFieldValue(formik, "minHeight")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.max_height")}
            value={toStringValue(formik.values.maxHeight)}
            onChange={setFloatFieldValue(formik, "maxHeight")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.lineType && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("lineType")}>
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.line_type")}
            value={toStringValue(formik.values.lineType)}
            onChange={setTextFieldValue(formik, "lineType")}
          >
            <option />
            {Object.values(Lighting_LineTypeModel).map((type) => (
              <option key={type} value={type}>
                {t(`line_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.gridType && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("gridType")}>
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.grid_type")}
            value={toStringValue(formik.values.gridType)}
            onChange={setTextFieldValue(formik, "gridType")}
          >
            <option />
            {Object.values(Lighting_GridTypeModel).map((type) => (
              <option key={type} value={type}>
                {t(`grid_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.lineCrossSection && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("lineCrossSection")}
        >
          <Compo.TextInput
            wide
            label={t("form_pole_filters.line_cross_section")}
            value={toStringValue(formik.values.lineCrossSection)}
            onChange={setTextFieldValue(formik, "lineCrossSection")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.owner && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("owner")}>
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.owner")}
            value={toStringValue(formik.values.owner)}
            onChange={setTextFieldValue(formik, "owner")}
          >
            <option />
            {Object.values(Lighting_OwnerModel).map((type) => (
              <option key={type} value={type}>
                {t(`owner.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.dateOfPainting && (
        <Compo.FilterBox
          vertical
          onClose={() => filters.deactivateFilter("dateOfPainting")}
        >
          <Compo.DateInput
            wide
            label={t("form_pole_filters.min_date_of_painting")}
            value={toDateValue(formik.values.minDateOfPainting)}
            onChange={setDateFieldValue(formik, "minDateOfPainting")}
          />
          <Compo.DateInput
            wide
            label={t("form_pole_filters.max_date_of_painting")}
            value={toDateValue(formik.values.maxDateOfPainting)}
            onChange={setDateFieldValue(formik, "maxDateOfPainting")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.plotNumber && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("plotNumber")}>
          <Compo.TextInput
            wide
            label={t("form_pole_filters.plot_number")}
            value={toStringValue(formik.values.plotNumber)}
            onChange={setTextFieldValue(formik, "plotNumber")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.location && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("location")}>
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.location")}
            value={toStringValue(formik.values.location)}
            onChange={setTextFieldValue(formik, "location")}
          >
            <option />
            {Object.values(Lighting_PoleLocationModel).map((type) => (
              <option key={type} value={type}>
                {t(`pole_location.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.distanceToClosestPole && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("distanceToClosestPole")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.min_distance_to_closest_pole")}
            value={toStringValue(formik.values.minDistanceToClosestPole)}
            onChange={setFloatFieldValue(formik, "minDistanceToClosestPole")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.max_distance_to_closest_pole")}
            value={toStringValue(formik.values.maxDistanceToClosestPole)}
            onChange={setFloatFieldValue(formik, "maxDistanceToClosestPole")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.qualifiedForReplacement && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("qualifiedForReplacement")}
        >
          <Compo.Toggle
            wide
            label={t("form_pole_filters.qualified_for_replacement") ?? ""}
            checked={formik.values.qualifiedForReplacement ?? false}
            onChange={setBooleanFieldValue(formik, "qualifiedForReplacement")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.roadType && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("roadType")}>
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.road_type")}
            value={toStringValue(formik.values.roadType)}
            onChange={setTextFieldValue(formik, "roadType")}
          >
            <option />
            {Object.values(Lighting_RoadTypeModel).map((type) => (
              <option key={type} value={type}>
                {t(`road_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.roadSurface && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("roadSurface")}
        >
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.road_surface")}
            value={toStringValue(formik.values.roadSurface)}
            onChange={setTextFieldValue(formik, "roadSurface")}
          >
            <option />
            {Object.values(Lighting_RoadSurfaceModel).map((type) => (
              <option key={type} value={type}>
                {t(`road_surface.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.roadLightingClass && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("roadLightingClass")}
        >
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.road_lighting_class")}
            value={toStringValue(formik.values.roadLightingClass)}
            onChange={setTextFieldValue(formik, "roadLightingClass")}
          >
            <option />
            {Object.values(Lighting_RoadLightingClassModel).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.roadWidth && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("roadWidth")}>
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.min_road_width")}
            value={toStringValue(formik.values.minRoadWidth)}
            onChange={setFloatFieldValue(formik, "minRoadWidth")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.max_road_width")}
            value={toStringValue(formik.values.maxRoadWidth)}
            onChange={setFloatFieldValue(formik, "maxRoadWidth")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.distanceFromRoadwayEdgeToPole && (
        <Compo.FilterBox
          onClose={() =>
            filters.deactivateFilter("distanceFromRoadwayEdgeToPole")
          }
        >
          <Compo.TextInput
            wide
            type="number"
            label={t(
              "form_pole_filters.min_distance_from_roadway_edge_to_pole"
            )}
            value={toStringValue(
              formik.values.minDistanceFromRoadwayEdgeToPole
            )}
            onChange={setFloatFieldValue(
              formik,
              "minDistanceFromRoadwayEdgeToPole"
            )}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t(
              "form_pole_filters.max_distance_from_roadway_edge_to_pole"
            )}
            value={toStringValue(
              formik.values.maxDistanceFromRoadwayEdgeToPole
            )}
            onChange={setFloatFieldValue(
              formik,
              "maxDistanceFromRoadwayEdgeToPole"
            )}
          />
        </Compo.FilterBox>
      )}
      {filters.status.bracketLength && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("bracketLength")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.min_bracket_length")}
            value={toStringValue(formik.values.minBracketLength)}
            onChange={setFloatFieldValue(formik, "minBracketLength")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.max_bracket_length")}
            value={toStringValue(formik.values.maxBracketLength)}
            onChange={setFloatFieldValue(formik, "maxBracketLength")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.tiltAngle && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("tiltAngle")}>
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.min_tilt_angle")}
            value={toStringValue(formik.values.minTiltAngle)}
            onChange={setFloatFieldValue(formik, "minTiltAngle")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.max_tilt_angle")}
            value={toStringValue(formik.values.maxTiltAngle)}
            onChange={setFloatFieldValue(formik, "maxTiltAngle")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.bracketQualifiedForReplacement && (
        <Compo.FilterBox
          onClose={() =>
            filters.deactivateFilter("bracketQualifiedForReplacement")
          }
        >
          <Compo.Toggle
            wide
            label={
              t("form_pole_filters.bracket_qualified_for_replacement") ?? ""
            }
            checked={formik.values.bracketQualifiedForReplacement ?? false}
            onChange={setBooleanFieldValue(
              formik,
              "bracketQualifiedForReplacement"
            )}
          />
        </Compo.FilterBox>
      )}
      {filters.status.newBracketLengthAfterReplacement && (
        <Compo.FilterBox
          vertical
          onClose={() =>
            filters.deactivateFilter("newBracketLengthAfterReplacement")
          }
        >
          <Compo.TextInput
            wide
            type="number"
            label={t(
              "form_pole_filters.min_new_bracket_length_after_replacement"
            )}
            value={toStringValue(
              formik.values.minNewBracketLengthAfterReplacement
            )}
            onChange={setFloatFieldValue(
              formik,
              "minNewBracketLengthAfterReplacement"
            )}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t(
              "form_pole_filters.max_new_bracket_length_after_replacement"
            )}
            value={toStringValue(
              formik.values.maxNewBracketLengthAfterReplacement
            )}
            onChange={setFloatFieldValue(
              formik,
              "maxNewBracketLengthAfterReplacement"
            )}
          />
        </Compo.FilterBox>
      )}
      {filters.status.dateOfBracketPainting && (
        <Compo.FilterBox
          vertical
          onClose={() => filters.deactivateFilter("dateOfBracketPainting")}
        >
          <Compo.DateInput
            wide
            label={t("form_pole_filters.min_date_of_bracket_painting")}
            value={toDateValue(formik.values.minDateOfBracketPainting)}
            onChange={setDateFieldValue(formik, "minDateOfBracketPainting")}
          />
          <Compo.DateInput
            wide
            label={t("form_pole_filters.max_date_of_bracket_painting")}
            value={toDateValue(formik.values.maxDateOfBracketPainting)}
            onChange={setDateFieldValue(formik, "maxDateOfBracketPainting")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.sidewalkSide && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("sidewalkSide")}
        >
          <Compo.SelectInput
            wide
            label={t("form_pole_filters.sidewalk_side")}
            value={toStringValue(formik.values.sidewalkSide)}
            onChange={setTextFieldValue(formik, "sidewalkSide")}
          >
            <option />
            {Object.values(Lighting_SidewalkSideModel).map((type) => (
              <option key={type} value={type}>
                {t(`sidewalk_side.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.sidewalkWidth && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("sidewalkWidth")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.min_sidewalk_width")}
            value={toStringValue(formik.values.minSidewalkWidth)}
            onChange={setFloatFieldValue(formik, "minSidewalkWidth")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_pole_filters.max_sidewalk_width")}
            value={toStringValue(formik.values.maxSidewalkWidth)}
            onChange={setFloatFieldValue(formik, "maxSidewalkWidth")}
          />
        </Compo.FilterBox>
      )}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0.25rem;
`;

export default PoleFiltersForm;
