import * as yup from "yup";
import { Lighting_SidewalkSideModel } from "src/graphql/types";

export const sidewalkDetailsValidationSchema = yup.object().shape({
  sidewalkDetails: yup
    .object()
    .nullable()
    .shape({
      sidewalkSide: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_SidewalkSideModel))
        .required(),
      widthOfSidewalk: yup.number().fromFormik().min(0).required(),
    }),
});
