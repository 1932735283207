import type { DeepPartial } from "src/core/utils/types";
import type { Lighting_PowerBoxDefaultDetailsModel } from "src/graphql/types";

export const detailsDefaultValues: DeepPartial<Lighting_PowerBoxDefaultDetailsModel> =
  {
    power: null as any,
    contractedPower: null as any,
    fuseType: null as any,
    fuseAmpRating: null as any,
    recipientCode: null as any,
    notes: null as any,
  };
