import React from "react";
import Compo, { useModal } from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import { Navigate, useParams } from "react-router-dom";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useFailureByIdFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresFailureById";
import FailureErrorViewSkeleton from "./FailureErrorView.skeleton";
import {
  type Failures_FailureModel,
  Failures_FailureTypeModel,
  Failures_FailureStateModel,
  Failures_FailureErrorStageModel,
} from "src/graphql/types";
import { useFailuresTranslation } from "../../utils/translation";
import { isFailureErrorNew, isFailureErrorResolved } from "../../utils/failure";
import { FailureErrorResolveForm } from "../../containers/FailureErrorResolveForm";

const FailureErrorView: React.FC = () => (
  <Page permission="failures.failure.get">
    <React.Suspense fallback={<FailureErrorViewSkeleton />}>
      <FailureErrorViewContent />
    </React.Suspense>
  </Page>
);

const FailureErrorViewContent: React.FC = () => {
  const { t } = useFailuresTranslation();
  const area = useCurrentArea();
  const params = useParams<{ id: string; errorId: string }>();
  const resolveModal = useModal();

  const hasResolveErrorPermission = usePermissionMatch(
    "failures.failure.resolve_manual_error"
  );

  const { data } = useFailureByIdFromFailuresSuspenseQuery({
    variables: { input: { id: params.id as string, areaId: area.id } },
  });

  const failure = data.Failures_failureById.failure as Failures_FailureModel;
  const _error = failure.errors.find((e) => e.id === params.errorId);

  if (!failure || !params.errorId || !_error) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Wrapper>
      <Compo.Header
        title={t("failure_error_type." + _error.type.toLowerCase())}
      >
        <Compo.Chip
          small
          text={t("failure_error_stage." + _error.stage.toLowerCase())}
          variant={
            isFailureErrorNew(_error)
              ? "alert"
              : isFailureErrorResolved(_error)
              ? "success"
              : "default"
          }
        />
      </Compo.Header>
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={
          t("failure_error_group." + _error.group.toLowerCase()) ?? ""
        }
      >
        {t("page_failure_error_view.group")}
      </Compo.ListRow>
      {_error.description && (
        <React.Fragment>
          <HeaderWithDistance
            outline
            type="h2"
            title={t("page_failure_error_view.description")}
          />
          {_error.description.whatWasTheReason && (
            <DescriptionWrapper>
              <h3>{t("page_failure_error_view.error_reason_description")}</h3>
              <DescriptionTextWrapper>
                {_error.description.whatWasTheReason}
              </DescriptionTextWrapper>
            </DescriptionWrapper>
          )}
          {_error.description.howWasResolved && (
            <DescriptionWrapper>
              <h3>{t("page_failure_error_view.error_resolve_description")}</h3>
              <DescriptionTextWrapper>
                {_error.description.howWasResolved}
              </DescriptionTextWrapper>
            </DescriptionWrapper>
          )}
          {_error.description.whatSparePartsWereUsed && (
            <DescriptionWrapper>
              <h3>
                {t("page_failure_error_view.error_spare_parts_description")}
              </h3>
              <DescriptionTextWrapper>
                {_error.description.whatSparePartsWereUsed}
              </DescriptionTextWrapper>
            </DescriptionWrapper>
          )}
        </React.Fragment>
      )}
      {failure.type === Failures_FailureTypeModel.Manual &&
        failure.state !== Failures_FailureStateModel.Rejected &&
        failure.state !== Failures_FailureStateModel.Resolved &&
        _error.stage === Failures_FailureErrorStageModel.Active && (
          <React.Fragment>
            <HeaderWithDistance
              outline
              type="h2"
              title={t("page_failure_error_view.actions")}
            />
            <ButtonsWrapper>
              <Compo.Button
                wide
                outline
                disabled={!hasResolveErrorPermission}
                onClick={() => resolveModal.setIsOpen(true)}
              >
                {t("page_failure_error_view.resolve")}
              </Compo.Button>
            </ButtonsWrapper>
          </React.Fragment>
        )}
      {resolveModal.isOpen &&
        resolveModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <FailureErrorResolveForm
              failure={failure}
              error={_error}
              onClose={() => resolveModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.25rem;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  line-height: 20px;
  cursor: default;
  margin: 0.5rem;
`;

const DescriptionTextWrapper = styled.span`
  border: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
`;

export default FailureErrorView;
