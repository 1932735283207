import React from "react";
import Administration from "../pages/Administration";
import LightingIoTMapProxy from "../proxies/LightingIoTMapProxy";
import { Outlet, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { LuminaireCollection } from "../pages/LuminaireCollection";
import { LightingScheduleCollection } from "../pages/LightingScheduleCollection";
import { LuminaireView } from "../pages/LuminaireView";
import { LightingScheduleView } from "../pages/LightingScheduleView";
import { LuminaireJobCollection } from "../pages/LuminaireJobCollection";
import LightingIoTLuminairesProxy from "../proxies/LightingIoTLuminairesProxy";
import LightingIoTLuminairesItemProxy from "../proxies/LightingIoTLuminairesItemProxy";

const routes = (
  <Route element={<LightingIoTMapProxy />}>
    <Route path="lighting-iot" element={<Outlet />}>
      <Route index element={<Home />} />
      <Route path="admin" element={<Administration />} />
      <Route path="luminaire" element={<Outlet />}>
        <Route element={<LightingIoTLuminairesProxy />}>
          <Route index element={<LuminaireCollection />} />
          <Route element={<LightingIoTLuminairesItemProxy />}>
            <Route path=":id" element={<LuminaireView />} />
          </Route>
        </Route>
      </Route>
      <Route path="lighting-schedule" element={<Outlet />}>
        <Route index element={<LightingScheduleCollection />} />
        <Route path=":id" element={<LightingScheduleView />} />
      </Route>
      <Route path="luminaire-job" element={<Outlet />}>
        <Route index element={<LuminaireJobCollection />} />
      </Route>
    </Route>
  </Route>
);

export default routes;
