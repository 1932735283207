import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddControllerToLuminaireFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_AddControllerToLuminaireInput;
}>;


export type AddControllerToLuminaireFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_addControllerToLuminaire: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const AddControllerToLuminaireFromLightingIoTDocument = gql`
    mutation addControllerToLuminaireFromLightingIoT($input: LightingIoT_AddControllerToLuminaireInput!) {
  LightingIoT_addControllerToLuminaire(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type AddControllerToLuminaireFromLightingIoTMutationFn = Apollo.MutationFunction<AddControllerToLuminaireFromLightingIoTMutation, AddControllerToLuminaireFromLightingIoTMutationVariables>;

/**
 * __useAddControllerToLuminaireFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useAddControllerToLuminaireFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddControllerToLuminaireFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addControllerToLuminaireFromLightingIoTMutation, { data, loading, error }] = useAddControllerToLuminaireFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddControllerToLuminaireFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<AddControllerToLuminaireFromLightingIoTMutation, AddControllerToLuminaireFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddControllerToLuminaireFromLightingIoTMutation, AddControllerToLuminaireFromLightingIoTMutationVariables>(AddControllerToLuminaireFromLightingIoTDocument, options);
      }
export type AddControllerToLuminaireFromLightingIoTMutationHookResult = ReturnType<typeof useAddControllerToLuminaireFromLightingIoTMutation>;
export type AddControllerToLuminaireFromLightingIoTMutationResult = Apollo.MutationResult<AddControllerToLuminaireFromLightingIoTMutation>;
export type AddControllerToLuminaireFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<AddControllerToLuminaireFromLightingIoTMutation, AddControllerToLuminaireFromLightingIoTMutationVariables>;