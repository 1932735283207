import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentUserPermissionsFromSecurityQueryVariables = Types.Exact<{
  areaId?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
}>;


export type GetCurrentUserPermissionsFromSecurityQuery = { __typename?: 'Query', Security_getCurrentUserPermissions?: { __typename?: 'Security_GetCurrentUserPermissionsResponse', permissions: Array<string | null> } | null };


export const GetCurrentUserPermissionsFromSecurityDocument = gql`
    query getCurrentUserPermissionsFromSecurity($areaId: UUID) {
  Security_getCurrentUserPermissions(areaId: $areaId) {
    permissions
  }
}
    `;

/**
 * __useGetCurrentUserPermissionsFromSecurityQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPermissionsFromSecurityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPermissionsFromSecurityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPermissionsFromSecurityQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetCurrentUserPermissionsFromSecurityQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>(GetCurrentUserPermissionsFromSecurityDocument, options);
      }
export function useGetCurrentUserPermissionsFromSecurityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>(GetCurrentUserPermissionsFromSecurityDocument, options);
        }
export function useGetCurrentUserPermissionsFromSecuritySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>(GetCurrentUserPermissionsFromSecurityDocument, options);
        }
export type GetCurrentUserPermissionsFromSecurityQueryHookResult = ReturnType<typeof useGetCurrentUserPermissionsFromSecurityQuery>;
export type GetCurrentUserPermissionsFromSecurityLazyQueryHookResult = ReturnType<typeof useGetCurrentUserPermissionsFromSecurityLazyQuery>;
export type GetCurrentUserPermissionsFromSecuritySuspenseQueryHookResult = ReturnType<typeof useGetCurrentUserPermissionsFromSecuritySuspenseQuery>;
export type GetCurrentUserPermissionsFromSecurityQueryResult = Apollo.QueryResult<GetCurrentUserPermissionsFromSecurityQuery, GetCurrentUserPermissionsFromSecurityQueryVariables>;