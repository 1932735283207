import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminairesFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetLuminairesInput;
}>;


export type LuminairesFromLightingQuery = { __typename?: 'Query', Lighting_luminaires: { __typename?: 'Lighting_GetLuminairesPayload', count: number, items: Array<{ __typename?: 'Lighting_LuminaireRowModel', id: any, version: number, areaId: any, poleId: any, poleName: string, typeId: any, typeName: string, ordinalNumber: number }> } };


export const LuminairesFromLightingDocument = gql`
    query luminairesFromLighting($input: Lighting_GetLuminairesInput!) {
  Lighting_luminaires(input: $input) {
    count
    items {
      id
      version
      areaId
      poleId
      poleName
      typeId
      typeName
      ordinalNumber
    }
  }
}
    `;

/**
 * __useLuminairesFromLightingQuery__
 *
 * To run a query within a React component, call `useLuminairesFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminairesFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminairesFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminairesFromLightingQuery(baseOptions: Apollo.QueryHookOptions<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables> & ({ variables: LuminairesFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables>(LuminairesFromLightingDocument, options);
      }
export function useLuminairesFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables>(LuminairesFromLightingDocument, options);
        }
export function useLuminairesFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables>(LuminairesFromLightingDocument, options);
        }
export type LuminairesFromLightingQueryHookResult = ReturnType<typeof useLuminairesFromLightingQuery>;
export type LuminairesFromLightingLazyQueryHookResult = ReturnType<typeof useLuminairesFromLightingLazyQuery>;
export type LuminairesFromLightingSuspenseQueryHookResult = ReturnType<typeof useLuminairesFromLightingSuspenseQuery>;
export type LuminairesFromLightingQueryResult = Apollo.QueryResult<LuminairesFromLightingQuery, LuminairesFromLightingQueryVariables>;