import React from "react";
import type { MapLayer } from "@smartly-city/compo-map";
import type {
  TrafficIoT_CounterJournalRowModel,
  TrafficIoT_CounterRowModel,
} from "src/graphql/types";
import { daysBetween } from "src/core/utils/date";

export const COUNTER_LAYER_ID = "counter";

export const useCounterLayerBuilder = (): ((
  counters: TrafficIoT_CounterRowModel[],
  journals: TrafficIoT_CounterJournalRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (counters, journals) => ({
      id: COUNTER_LAYER_ID,
      color: "#767676",
      items: counters.map((counter) => ({
        id: counter.id,
        coordinates: counter.location,
        name: counter.name,
        color: getCounterColor(
          journals.find((j) => j.counterId === counter.id)
        ),
      })),
    }),
    []
  );
};

const getCounterColor = (
  journal?: TrafficIoT_CounterJournalRowModel
): string => {
  const communicatedAgo = journal ? getCommunicatedDays(journal) : null;
  switch (true) {
    case communicatedAgo && communicatedAgo < 3:
      return "#3CB56C";
    case communicatedAgo && communicatedAgo < 7:
      return "#F9E850";
    case communicatedAgo && communicatedAgo < 14:
      return "#FFB03A";
    case communicatedAgo && communicatedAgo < 30:
      return "#FF3A3A";
    default:
      return "#767676";
  }
};

const getCommunicatedDays = (
  journal: TrafficIoT_CounterJournalRowModel
): number | undefined => {
  return journal.status.communicationDate
    ? daysBetween(new Date(journal.status.communicationDate), new Date())
    : undefined;
};
