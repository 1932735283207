import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import AddressInput from "src/core/components/AddressInput";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useWeatherIoTTranslation } from "../../utils/translation";
import { useAddStationFromWeatherIoTMutation } from "src/graphql/__generated__/WeatherIoTAddStation";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import * as yup from "yup";

export interface StationFormProps {
  onClose: () => void;
}

export interface StationFormContext {
  name: string;
  smartlyId: string;
  latitude: number;
  longitude: number;
}

const StationForm: React.FC<StationFormProps> = (props) => {
  const { t } = useWeatherIoTTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();

  const [add] = useAddStationFromWeatherIoTMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.WeatherIoT_addStation.isSuccess)
        return;
      cache.evict({
        fieldName: "WeatherIoT_stations",
      });
      cache.evict({
        fieldName: "WeatherIoT_stationsPaged",
      });
      cache.gc();
    },
  });

  const handleSubmit = async (values: StationFormContext): Promise<void> => {
    const { data } = await add({
      variables: {
        input: {
          areaId: area.id,
          name: values.name,
          smartlyId: values.smartlyId,
          location: {
            latitude: values.latitude,
            longitude: values.longitude,
          },
        },
      },
    });

    const result = data?.WeatherIoT_addStation;
    if (result?.isSuccess) {
      toast.success(t("notification.station_added"));
      navigate(result.value?.id);
    } else {
      result?.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
  };

  const formik = useFormik<StationFormContext>({
    initialValues: {
      name: null as any,
      smartlyId: null as any,
      latitude: null as any,
      longitude: null as any,
    },
    validationSchema: yup.object().shape({
      name: yup.string().fromFormik().required(),
      smartlyId: yup.string().fromFormik().min(11).max(11).required(),
      latitude: yup.number().fromFormik().min(-90).max(90).required(),
      longitude: yup.number().fromFormik().min(-180).max(180).required(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={<Compo.Header title={t("form_station.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.TextInput
            wide
            name="name"
            disabled={formik.isSubmitting}
            error={formik.touched.name && formik.errors.name}
            label={t("form_station.name")}
            value={toStringValue(formik.values.name)}
            onChange={setTextFieldValue(formik, "name")}
          />
          <Compo.TextInput
            wide
            name="smartly-id"
            disabled={formik.isSubmitting}
            error={formik.touched.smartlyId && formik.errors.smartlyId}
            label={t("form_station.smartly_id")}
            value={toStringValue(formik.values.smartlyId)}
            onChange={setTextFieldValue(formik, "smartlyId")}
          />
          <AddressInput
            disabled={formik.isSubmitting}
            latitude={{
              value: toStringValue(formik.values.latitude),
              error: getFieldError(formik, "latitude"),
              onChange: setFloatFieldValue(formik, "latitude"),
            }}
            longitude={{
              value: toStringValue(formik.values.longitude),
              error: getFieldError(formik, "longitude"),
              onChange: setFloatFieldValue(formik, "longitude"),
            }}
          />
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default StationForm;
