import React from "react";
import { Navigate, Route } from "react-router-dom";
import { DashboardPage } from "src/core/pages/Dashboard";
import ApiProxy from "src/core/proxies/ApiProxy";
import LoginPage from "src/core/pages/Login/LoginPage";
import LogoutPage from "src/core/pages/Logout/LogoutPage";
import AppProxy from "../core/proxies/AppProxy";
import AuthProxy from "../core/proxies/AuthProxy";
import lightingRoutes from "src/modules/lighting/routing";
import lightingIoTRoutes from "src/modules/lighting_iot/routing";
import reportRoutes from "src/modules/reports/routing";
import failureRoutes from "src/modules/failures/routing";
import trafficIoTRoutes from "src/modules/traffic_iot/routing";
import weatherIoTRoutes from "src/modules/weather_iot/routing";

const routes = (
  <React.Fragment>
    <Route path="/login" element={<LoginPage />} />
    <Route element={<AuthProxy />}>
      <Route path="/logout" element={<LogoutPage />} />
      <Route element={<AppProxy />}>
        <Route element={<ApiProxy public api="dashboard" />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route id="dashboard" path="/dashboard" element={<DashboardPage />} />
        </Route>
        <Route element={<ApiProxy api="lighting" />}>{lightingRoutes}</Route>
        <Route element={<ApiProxy api="lighting_iot" />}>
          {lightingIoTRoutes}
        </Route>
        <Route element={<ApiProxy api="reports" />}>{reportRoutes}</Route>
        <Route element={<ApiProxy api="failures" />}>{failureRoutes}</Route>
        <Route element={<ApiProxy api="traffic_iot" />}>
          {trafficIoTRoutes}
        </Route>
        <Route element={<ApiProxy api="weather_iot" />}>
          {weatherIoTRoutes}
        </Route>
      </Route>
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Route>
    <Route path="/404" element={<Navigate to="/" />} />
  </React.Fragment>
);

export default routes;
