import React from "react";
import { Outlet } from "react-router-dom";
import { LayerFocusProxy, LayerOverrideProxy } from "@smartly-city/compo-map";
import { COUNTER_LAYER_ID, useStationLayerBuilder } from "../utils/layers";
import {
  StationFiltersProvider,
  useStationFilters,
} from "../contexts/StationFilters";
import { useCurrentArea } from "src/core/contexts";
import { useStationsFromWeatherIoTLazyQuery } from "src/graphql/__generated__/WeatherIoTStations";
import { toStationQueryFilters } from "../utils/filters";
import { useStationJournalsFromWeatherIoTLazyQuery } from "src/graphql/__generated__/WeatherIoTStationJournals";
import type { MapLayer } from "@smartly-city/compo-map";

const WeatherIoTStationsProxy: React.FC = () => {
  return (
    <StationFiltersProvider>
      <WeatherIoTStationFiltersProxy>
        <LayerFocusProxy layerId={COUNTER_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </WeatherIoTStationFiltersProxy>
    </StationFiltersProvider>
  );
};

const WeatherIoTStationFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = useStationLayerBuilder();
  const { filters } = useStationFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getStations] = useStationsFromWeatherIoTLazyQuery();
  const [getStationJournals] = useStationJournalsFromWeatherIoTLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data: stationsData } = await getStations({
      variables: {
        input: { areaId: area.id, filters: toStationQueryFilters(filters) },
      },
    });
    const { data: journalsData } = await getStationJournals({
      variables: {
        input: { areaId: area.id },
      },
    });
    const stations = stationsData?.WeatherIoT_stations.items ?? [];
    const journals = journalsData?.WeatherIoT_stationJournals.items ?? [];
    setData(builder(stations, journals));
  }, [area.id, filters, getStations, getStationJournals, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={COUNTER_LAYER_ID} data={data}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default WeatherIoTStationsProxy;
