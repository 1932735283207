import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireJobsPagedFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLuminaireJobsPagedInput;
}>;


export type LuminaireJobsPagedFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_luminaireJobsPaged: { __typename?: 'LightingIoT_GetLuminaireJobsPagedPayload', count: number, total: number, items: Array<{ __typename?: 'LightingIoT_LuminaireJobRowModel', id: any, version: number, areaId: any, type: Types.LightingIoT_LuminaireJobTypeModel, state: Types.LightingIoT_LuminaireJobStateModel }> } };


export const LuminaireJobsPagedFromLightingIoTDocument = gql`
    query luminaireJobsPagedFromLightingIoT($input: LightingIoT_GetLuminaireJobsPagedInput!) {
  LightingIoT_luminaireJobsPaged(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      type
      state
    }
  }
}
    `;

/**
 * __useLuminaireJobsPagedFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLuminaireJobsPagedFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireJobsPagedFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireJobsPagedFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireJobsPagedFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables> & ({ variables: LuminaireJobsPagedFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables>(LuminaireJobsPagedFromLightingIoTDocument, options);
      }
export function useLuminaireJobsPagedFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables>(LuminaireJobsPagedFromLightingIoTDocument, options);
        }
export function useLuminaireJobsPagedFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables>(LuminaireJobsPagedFromLightingIoTDocument, options);
        }
export type LuminaireJobsPagedFromLightingIoTQueryHookResult = ReturnType<typeof useLuminaireJobsPagedFromLightingIoTQuery>;
export type LuminaireJobsPagedFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLuminaireJobsPagedFromLightingIoTLazyQuery>;
export type LuminaireJobsPagedFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLuminaireJobsPagedFromLightingIoTSuspenseQuery>;
export type LuminaireJobsPagedFromLightingIoTQueryResult = Apollo.QueryResult<LuminaireJobsPagedFromLightingIoTQuery, LuminaireJobsPagedFromLightingIoTQueryVariables>;