import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateWorkTimeReportFromReportsMutationVariables = Types.Exact<{
  input: Types.Reports_GenerateWorkTimeReportInput;
}>;


export type GenerateWorkTimeReportFromReportsMutation = { __typename?: 'Mutation', Reports_generateWorkTimeReport: { __typename?: 'Reports_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Reports_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Reports_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const GenerateWorkTimeReportFromReportsDocument = gql`
    mutation generateWorkTimeReportFromReports($input: Reports_GenerateWorkTimeReportInput!) {
  Reports_generateWorkTimeReport(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type GenerateWorkTimeReportFromReportsMutationFn = Apollo.MutationFunction<GenerateWorkTimeReportFromReportsMutation, GenerateWorkTimeReportFromReportsMutationVariables>;

/**
 * __useGenerateWorkTimeReportFromReportsMutation__
 *
 * To run a mutation, you first call `useGenerateWorkTimeReportFromReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWorkTimeReportFromReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWorkTimeReportFromReportsMutation, { data, loading, error }] = useGenerateWorkTimeReportFromReportsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateWorkTimeReportFromReportsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWorkTimeReportFromReportsMutation, GenerateWorkTimeReportFromReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWorkTimeReportFromReportsMutation, GenerateWorkTimeReportFromReportsMutationVariables>(GenerateWorkTimeReportFromReportsDocument, options);
      }
export type GenerateWorkTimeReportFromReportsMutationHookResult = ReturnType<typeof useGenerateWorkTimeReportFromReportsMutation>;
export type GenerateWorkTimeReportFromReportsMutationResult = Apollo.MutationResult<GenerateWorkTimeReportFromReportsMutation>;
export type GenerateWorkTimeReportFromReportsMutationOptions = Apollo.BaseMutationOptions<GenerateWorkTimeReportFromReportsMutation, GenerateWorkTimeReportFromReportsMutationVariables>;