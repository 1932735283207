import React from "react";
import type {
  Lighting_GridTypeModel,
  Lighting_LineTypeModel,
  Lighting_OwnerModel,
  Lighting_PoleLocationModel,
  Lighting_PoleTypeModel,
  Lighting_RoadLightingClassModel,
  Lighting_RoadSurfaceModel,
  Lighting_RoadTypeModel,
  Lighting_SidewalkSideModel,
} from "src/graphql/types";

export interface PoleFilters {
  name?: string;
  powerBoxName?: string;
  ordinalNumber?: number;
  addressNumber?: string;
  addressStreet?: string;
  addressTown?: string;
  type?: Lighting_PoleTypeModel;
  height?: {
    max?: number;
    min?: number;
  };
  lineType?: Lighting_LineTypeModel;
  gridType?: Lighting_GridTypeModel;
  lineCrossSection?: string;
  owner?: Lighting_OwnerModel;
  dateOfPainting?: {
    max?: Date;
    min?: Date;
  };
  plotNumber?: string;
  location?: Lighting_PoleLocationModel;
  distanceToClosestPole?: {
    max?: number;
    min?: number;
  };
  qualifiedForReplacement?: boolean;
  roadType?: Lighting_RoadTypeModel;
  roadSurface?: Lighting_RoadSurfaceModel;
  roadLightingClass?: Lighting_RoadLightingClassModel;
  roadWidth?: {
    max?: number;
    min?: number;
  };
  distanceFromRoadwayEdgeToPole?: {
    max?: number;
    min?: number;
  };
  bracketLength?: {
    max?: number;
    min?: number;
  };
  tiltAngle?: {
    max?: number;
    min?: number;
  };
  bracketQualifiedForReplacement?: boolean;
  newBracketLengthAfterReplacement?: {
    max?: number;
    min?: number;
  };
  dateOfBracketPainting?: {
    max?: Date;
    min?: Date;
  };
  sidewalkSide?: Lighting_SidewalkSideModel;
  sidewalkWidth?: {
    max?: number;
    min?: number;
  };
}

export type PoleFiltersStatus = Record<keyof PoleFilters, boolean>;

export interface PoleFiltersContextState {
  filters: PoleFilters;
  status: PoleFiltersStatus;
  setFilters: (filters: PoleFilters) => void;
  deactivateFilter: (name: keyof PoleFilters) => void;
  activateFilter: (name: keyof PoleFilters) => void;
  clearFilters: () => void;
}

export const PoleFiltersContext = React.createContext<
  PoleFiltersContextState | undefined
>(undefined);

export const usePoleFilters = (): PoleFiltersContextState => {
  const context = React.useContext(PoleFiltersContext);
  return context as PoleFiltersContextState;
};
