import React from "react";
import Compo from "@smartly-city/compo";
import { useList } from "src/core/components/List";
import { useCurrentArea } from "src/core/contexts";
import { useFailureFilters } from "../../contexts/FailureFilters";
import { toFailureQueryFilters } from "../../utils/filters";
import { useFailuresTranslation } from "../../utils/translation";
import { useFailuresFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresFailures";
import { useFailuresPagedFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresFailuresPaged";
import { isFailureNew, isFailureResolved } from "../../utils/failure";
import type { Failures_FailureRowModel } from "src/graphql/types";

export interface FailureListRowsProps {
  rows: Failures_FailureRowModel[];
  setRows: (rows: Failures_FailureRowModel[]) => void;
  checked?: string[];
  page?: number;
  pagedAmount?: number;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (failure: Failures_FailureRowModel) => React.ReactNode;
  onClick?: (failure: Failures_FailureRowModel) => void;
  onCheck?: (failure: Failures_FailureRowModel, value: boolean) => void;
}

const FailureListRows: React.FC<FailureListRowsProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 10;
  const { setRows } = props;

  const { t } = useFailuresTranslation();
  const area = useCurrentArea();
  const { setCurrentPage } = useList();
  const filters = useFailureFilters();
  const queryFilters = toFailureQueryFilters(filters.filters);

  const checkType = props.withCheckbox
    ? "checkbox"
    : props.withRadio
    ? "radio"
    : undefined;

  const { data } = useFailuresFromFailuresSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: queryFilters,
      },
    },
    skip: props.page !== undefined,
  });
  const { data: pagedData } = useFailuresPagedFromFailuresSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        page: props.page ?? 1,
        amount: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
        filters: queryFilters,
      },
    },
    skip: props.page === undefined,
  });

  const failures =
    data?.Failures_failures.items ?? pagedData?.Failures_failuresPaged.items;
  const failuresTotal =
    pagedData?.Failures_failuresPaged.total ?? failures?.length ?? 0;

  React.useEffect(() => {
    setRows(failures ?? []);
  }, [setRows, failures]);

  React.useEffect(() => {
    setCurrentPage({
      pageSize: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
      pageTotal: failuresTotal,
    });
  }, [setCurrentPage, failuresTotal, props.pagedAmount]);

  return (
    <React.Fragment>
      {props.rows.length === 0 && (
        <Compo.ListRowEmpty wide text={t("message.no_failures")} />
      )}
      {props.rows.map((failure) => (
        <Compo.ListRow
          wide
          key={failure.id}
          buttons={
            <Compo.Chip
              small
              text={t("failure_state." + failure.state.toLowerCase())}
              variant={
                isFailureNew(failure)
                  ? "alert"
                  : isFailureResolved(failure)
                  ? "success"
                  : "default"
              }
            />
          }
          checkType={checkType}
          checked={props.checked?.includes(failure.id)}
          onCheck={
            props.onCheck
              ? (value) => props.onCheck?.(failure, value)
              : undefined
          }
          onClick={props.onClick ? () => props.onClick?.(failure) : undefined}
        >
          {failure.objectName}
        </Compo.ListRow>
      ))}
    </React.Fragment>
  );
};

export default FailureListRows;
