import React from "react";
import Compo, { type Types } from "@smartly-city/compo";
import styled from "styled-components";

interface ButtonWithListProps extends Types.ButtonProps {
  popover: Types.PopoverUtils;
  content?: React.ReactNode;
}

const ButtonWithList: React.FC<ButtonWithListProps> = (props) => {
  const { content, popover, ...buttonProps } = props;

  const ref = props.innerRef ?? React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    popover.buttonRef(ref.current);
  }, [popover, ref]);

  return (
    <React.Fragment>
      <Compo.Button
        {...buttonProps}
        innerRef={ref}
        onClick={() => popover.setIsOpen(true)}
      />
      {popover.isOpen &&
        popover.withPopoverWrapper(<Panel>{props.content}</Panel>)}
    </React.Fragment>
  );
};

const Panel = styled(Compo.Panel)`
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default ButtonWithList;
