import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LightingSchedulesFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLightingSchedulesInput;
}>;


export type LightingSchedulesFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_lightingSchedules: { __typename?: 'LightingIoT_GetLightingSchedulesPayload', count: number, total: number, items: Array<{ __typename?: 'LightingIoT_LightingScheduleRowModel', id: any, version: number, name: string, areaId: any }> } };


export const LightingSchedulesFromLightingIoTDocument = gql`
    query lightingSchedulesFromLightingIoT($input: LightingIoT_GetLightingSchedulesInput!) {
  LightingIoT_lightingSchedules(input: $input) {
    count
    total
    items {
      id
      version
      name
      areaId
    }
  }
}
    `;

/**
 * __useLightingSchedulesFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLightingSchedulesFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLightingSchedulesFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLightingSchedulesFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLightingSchedulesFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables> & ({ variables: LightingSchedulesFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables>(LightingSchedulesFromLightingIoTDocument, options);
      }
export function useLightingSchedulesFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables>(LightingSchedulesFromLightingIoTDocument, options);
        }
export function useLightingSchedulesFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables>(LightingSchedulesFromLightingIoTDocument, options);
        }
export type LightingSchedulesFromLightingIoTQueryHookResult = ReturnType<typeof useLightingSchedulesFromLightingIoTQuery>;
export type LightingSchedulesFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLightingSchedulesFromLightingIoTLazyQuery>;
export type LightingSchedulesFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLightingSchedulesFromLightingIoTSuspenseQuery>;
export type LightingSchedulesFromLightingIoTQueryResult = Apollo.QueryResult<LightingSchedulesFromLightingIoTQuery, LightingSchedulesFromLightingIoTQueryVariables>;