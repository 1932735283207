import type { CounterFilters } from "../contexts/CounterFilters";
import type { TrafficIoT_CounterFiltersModelInput } from "src/graphql/types";

export const toCounterQueryFilters = (
  filters?: CounterFilters
): TrafficIoT_CounterFiltersModelInput | null => {
  if (!filters) return null;

  return {
    matchesName: filters.name ?? null,
  };
};
