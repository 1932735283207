import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectsFromReportsQueryVariables = Types.Exact<{
  input: Types.Reports_GetObjectsInput;
}>;


export type ObjectsFromReportsQuery = { __typename?: 'Query', Reports_objects: { __typename?: 'Reports_GetObjectsPayload', count: number, total: number, items: Array<{ __typename?: 'Reports_ObjectRowModel', id: any, version: number, areaId: any, type: Types.Reports_ObjectTypeModel, name: string }> } };


export const ObjectsFromReportsDocument = gql`
    query objectsFromReports($input: Reports_GetObjectsInput!) {
  Reports_objects(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      type
      name
    }
  }
}
    `;

/**
 * __useObjectsFromReportsQuery__
 *
 * To run a query within a React component, call `useObjectsFromReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectsFromReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectsFromReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useObjectsFromReportsQuery(baseOptions: Apollo.QueryHookOptions<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables> & ({ variables: ObjectsFromReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables>(ObjectsFromReportsDocument, options);
      }
export function useObjectsFromReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables>(ObjectsFromReportsDocument, options);
        }
export function useObjectsFromReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables>(ObjectsFromReportsDocument, options);
        }
export type ObjectsFromReportsQueryHookResult = ReturnType<typeof useObjectsFromReportsQuery>;
export type ObjectsFromReportsLazyQueryHookResult = ReturnType<typeof useObjectsFromReportsLazyQuery>;
export type ObjectsFromReportsSuspenseQueryHookResult = ReturnType<typeof useObjectsFromReportsSuspenseQuery>;
export type ObjectsFromReportsQueryResult = Apollo.QueryResult<ObjectsFromReportsQuery, ObjectsFromReportsQueryVariables>;