import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveLuminaireFromLightingScheduleFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_RemoveLuminaireFromLightingScheduleInput;
}>;


export type RemoveLuminaireFromLightingScheduleFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_removeLuminaireFromLightingSchedule: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const RemoveLuminaireFromLightingScheduleFromLightingIoTDocument = gql`
    mutation removeLuminaireFromLightingScheduleFromLightingIoT($input: LightingIoT_RemoveLuminaireFromLightingScheduleInput!) {
  LightingIoT_removeLuminaireFromLightingSchedule(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type RemoveLuminaireFromLightingScheduleFromLightingIoTMutationFn = Apollo.MutationFunction<RemoveLuminaireFromLightingScheduleFromLightingIoTMutation, RemoveLuminaireFromLightingScheduleFromLightingIoTMutationVariables>;

/**
 * __useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLuminaireFromLightingScheduleFromLightingIoTMutation, { data, loading, error }] = useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLuminaireFromLightingScheduleFromLightingIoTMutation, RemoveLuminaireFromLightingScheduleFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLuminaireFromLightingScheduleFromLightingIoTMutation, RemoveLuminaireFromLightingScheduleFromLightingIoTMutationVariables>(RemoveLuminaireFromLightingScheduleFromLightingIoTDocument, options);
      }
export type RemoveLuminaireFromLightingScheduleFromLightingIoTMutationHookResult = ReturnType<typeof useRemoveLuminaireFromLightingScheduleFromLightingIoTMutation>;
export type RemoveLuminaireFromLightingScheduleFromLightingIoTMutationResult = Apollo.MutationResult<RemoveLuminaireFromLightingScheduleFromLightingIoTMutation>;
export type RemoveLuminaireFromLightingScheduleFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<RemoveLuminaireFromLightingScheduleFromLightingIoTMutation, RemoveLuminaireFromLightingScheduleFromLightingIoTMutationVariables>;