import React from "react";
import Compo from "@smartly-city/compo";
import { useList } from "src/core/components/List";
import { useCurrentArea } from "src/core/contexts";
import { useLuminaireFilters } from "../../contexts/LuminaireFilters";
import { toLuminaireQueryFilters } from "../../utils/filters";
import { useLightingIoTTranslation } from "../../utils/translation";
import { useLuminairesFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaires";
import { useLuminairesPagedFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminairesPaged";
import type { LightingIoT_LuminaireRowModel } from "src/graphql/types";

export interface LuminaireListRowsProps {
  rows: LightingIoT_LuminaireRowModel[];
  setRows: (rows: LightingIoT_LuminaireRowModel[]) => void;
  checked?: string[];
  page?: number;
  pagedAmount?: number;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (luminaire: LightingIoT_LuminaireRowModel) => React.ReactNode;
  onClick?: (luminaire: LightingIoT_LuminaireRowModel) => void;
  onCheck?: (luminaire: LightingIoT_LuminaireRowModel, value: boolean) => void;
}

const LuminaireListRows: React.FC<LuminaireListRowsProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 10;
  const { setRows } = props;

  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const { setCurrentPage } = useList();
  const filters = useLuminaireFilters();
  const queryFilters = toLuminaireQueryFilters(filters.filters);

  const checkType = props.withCheckbox
    ? "checkbox"
    : props.withRadio
    ? "radio"
    : undefined;

  const { data } = useLuminairesFromLightingIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: queryFilters,
      },
    },
    skip: props.page !== undefined,
  });
  const { data: pagedData } = useLuminairesPagedFromLightingIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        page: props.page ?? 1,
        amount: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
        filters: queryFilters,
      },
    },
    skip: props.page === undefined,
  });

  const luminaires =
    data?.LightingIoT_luminaires.items ??
    pagedData?.LightingIoT_luminairesPaged.items;
  const luminairesTotal =
    pagedData?.LightingIoT_luminairesPaged.total ?? luminaires?.length ?? 0;

  React.useEffect(() => {
    setRows(luminaires ?? []);
  }, [setRows, luminaires]);

  React.useEffect(() => {
    setCurrentPage({
      pageSize: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
      pageTotal: luminairesTotal,
    });
  }, [setCurrentPage, luminairesTotal, props.pagedAmount]);

  return (
    <React.Fragment>
      {props.rows.length === 0 && (
        <Compo.ListRowEmpty wide text={t("message.no_luminaires")} />
      )}
      {props.rows.map((luminaire) => (
        <Compo.ListRow
          wide
          key={luminaire.id}
          checkType={checkType}
          checked={props.checked?.includes(luminaire.id)}
          onCheck={
            props.onCheck
              ? (value) => props.onCheck?.(luminaire, value)
              : undefined
          }
          onClick={props.onClick ? () => props.onClick?.(luminaire) : undefined}
        >
          {luminaire.name}
        </Compo.ListRow>
      ))}
    </React.Fragment>
  );
};

export default LuminaireListRows;
