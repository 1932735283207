import React from "react";
import Compo, { usePopover } from "@smartly-city/compo";
import * as yup from "yup";
import { useLightingTranslation } from "../../utils/translation";
import { useFormik } from "formik";
import {
  type PowerBoxFilters,
  usePowerBoxFilters,
} from "../../contexts/PowerBoxFilters";
import {
  setFloatFieldValue,
  setIntFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import FiltersHeader, { type Filter } from "src/core/components/FiltersHeader";
import { toSnakeCase } from "src/core/utils/string";
import styled from "styled-components";

export interface PowerBoxFiltersFormProps {
  onSubmit?: () => void;
}

export interface PowerBoxFiltersFormContext {
  addressNumber: string | null;
  addressStreet: string | null;
  addressTown: string | null;
  fuseType: string | null;
  name: string | null;
  notes: string | null;
  ordinalNumber: number | null;
  recipientCode: string | null;
  minPower: number | null;
  maxPower: number | null;
  minContractedPower: number | null;
  maxContractedPower: number | null;
  minFuseAmpRating: number | null;
  maxFuseAmpRating: number | null;
}

const PowerBoxFiltersForm: React.FC<PowerBoxFiltersFormProps> = (props) => {
  const { t } = useLightingTranslation();
  const popover = usePopover();
  const filters = usePowerBoxFilters();
  const ref = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    popover.buttonRef(ref.current);
  }, [popover, ref]);

  const headerFilterKeys = Object.keys(filters.status) as Array<
    keyof PowerBoxFilters
  >;

  const headerFilters: Filter[] = headerFilterKeys.map((key) => ({
    id: key,
    name: t("form_power_box_filters." + toSnakeCase(key)),
    active: filters.status[key] ?? false,
    onAdd: () => filters.activateFilter(key),
  }));

  const formik = useFormik<PowerBoxFiltersFormContext>({
    initialValues: {
      addressNumber: filters.filters.addressNumber ?? null,
      addressStreet: filters.filters.addressStreet ?? null,
      addressTown: filters.filters.addressTown ?? null,
      fuseType: filters.filters.fuseType ?? null,
      name: filters.filters.name ?? null,
      notes: filters.filters.notes ?? null,
      ordinalNumber: filters.filters.ordinalNumber ?? null,
      recipientCode: filters.filters.recipientCode ?? null,
      minPower: filters.filters.power?.min ?? null,
      maxPower: filters.filters.power?.max ?? null,
      minContractedPower: filters.filters.contractedPower?.min ?? null,
      maxContractedPower: filters.filters.contractedPower?.max ?? null,
      minFuseAmpRating: filters.filters.fuseAmpRating?.min ?? null,
      maxFuseAmpRating: filters.filters.fuseAmpRating?.max ?? null,
    },
    validationSchema: yup.object({
      addressNumber: yup.string().nullable().fromFormik(),
      addressStreet: yup.string().nullable().fromFormik(),
      addressTown: yup.string().nullable().fromFormik(),
      fuseType: yup.string().nullable().fromFormik(),
      name: yup.string().nullable().fromFormik(),
      notes: yup.string().nullable().fromFormik(),
      ordinalNumber: yup.string().nullable().fromFormik(),
      recipientCode: yup.string().nullable().fromFormik(),
      minPower: yup.number().integer().nullable().fromFormik(),
      maxPower: yup.number().integer().nullable().fromFormik(),
      minContractedPower: yup.number().integer().nullable().fromFormik(),
      maxContractedPower: yup.number().integer().nullable().fromFormik(),
      minFuseAmpRating: yup.number().nullable().fromFormik(),
      maxFuseAmpRating: yup.number().nullable().fromFormik(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      filters.setFilters({
        name: values.name ?? undefined,
        ordinalNumber: values.ordinalNumber ?? undefined,
        addressNumber: values.addressNumber ?? undefined,
        addressStreet: values.addressStreet ?? undefined,
        addressTown: values.addressTown ?? undefined,
        power: {
          min: values.minPower ?? undefined,
          max: values.maxPower ?? undefined,
        },
        contractedPower: {
          min: values.minContractedPower ?? undefined,
          max: values.maxContractedPower ?? undefined,
        },
        fuseAmpRating: {
          min: values.minFuseAmpRating ?? undefined,
          max: values.maxFuseAmpRating ?? undefined,
        },
        fuseType: values.fuseType ?? undefined,
        recipientCode: values.recipientCode ?? undefined,
        notes: values.notes ?? undefined,
      });
      props.onSubmit?.();
      setSubmitting(false);
    },
    onReset: () => {
      filters.clearFilters();
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FiltersHeader
        filters={headerFilters}
        onSave={formik.submitForm}
        onClear={formik.resetForm}
      />
      {filters.status.name && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("name")}>
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.name")}
            value={toStringValue(formik.values.name)}
            onChange={setTextFieldValue(formik, "name")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.ordinalNumber && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("ordinalNumber")}
        >
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.ordinal_number")}
            value={toStringValue(formik.values.ordinalNumber)}
            onChange={setIntFieldValue(formik, "ordinalNumber")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.addressNumber && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("addressNumber")}
        >
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.address_number")}
            error={formik.errors.addressNumber}
            value={toStringValue(formik.values.addressNumber)}
            onChange={setTextFieldValue(formik, "addressNumber")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.addressStreet && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("addressStreet")}
        >
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.address_street")}
            error={formik.errors.addressStreet}
            value={toStringValue(formik.values.addressStreet)}
            onChange={setTextFieldValue(formik, "addressStreet")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.addressTown && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("addressTown")}
        >
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.address_town")}
            error={formik.errors.addressTown}
            value={toStringValue(formik.values.addressTown)}
            onChange={setTextFieldValue(formik, "addressTown")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.power && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("power")}>
          <Compo.TextInput
            wide
            type="number"
            label={t("form_power_box_filters.min_power")}
            value={toStringValue(formik.values.minPower)}
            onChange={setFloatFieldValue(formik, "minPower")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_power_box_filters.max_power")}
            value={toStringValue(formik.values.maxPower)}
            onChange={setFloatFieldValue(formik, "maxPower")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.contractedPower && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("contractedPower")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_power_box_filters.min_contracted_power")}
            value={toStringValue(formik.values.minContractedPower)}
            onChange={setFloatFieldValue(formik, "minContractedPower")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_power_box_filters.max_contracted_power")}
            value={toStringValue(formik.values.maxContractedPower)}
            onChange={setFloatFieldValue(formik, "maxContractedPower")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.fuseAmpRating && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("fuseAmpRating")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_power_box_filters.min_fuse_amp_rating")}
            value={toStringValue(formik.values.minFuseAmpRating)}
            onChange={setIntFieldValue(formik, "minFuseAmpRating")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_power_box_filters.max_fuse_amp_rating")}
            value={toStringValue(formik.values.maxFuseAmpRating)}
            onChange={setIntFieldValue(formik, "maxFuseAmpRating")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.fuseType && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("fuseType")}>
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.fuse_type")}
            value={toStringValue(formik.values.fuseType)}
            onChange={setTextFieldValue(formik, "fuseType")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.recipientCode && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("recipientCode")}
        >
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.recipient_code")}
            value={toStringValue(formik.values.recipientCode)}
            onChange={setTextFieldValue(formik, "recipientCode")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.notes && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("notes")}>
          <Compo.TextInput
            wide
            label={t("form_power_box_filters.notes")}
            value={toStringValue(formik.values.notes)}
            onChange={setTextFieldValue(formik, "notes")}
          />
        </Compo.FilterBox>
      )}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0.25rem;
`;

export default PowerBoxFiltersForm;
