import { useTranslation } from "react-i18next";
import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export const useLightingTranslation = (
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<"lighting"> => {
  return useTranslation("lighting", options);
};
