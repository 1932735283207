import React from "react";
import { Navigate } from "react-router-dom";
import { usePermissionMatch } from "src/core/contexts";
import styled from "styled-components";

export interface PageProps {
  className?: string;
  children?: React.ReactNode;
  permission?: string;
  wide?: boolean;
}

const Page: React.FC<PageProps> = (props) => {
  const permitted = usePermissionMatch(props.permission ?? "");

  if (props.permission && !permitted) {
    return <Navigate replace to="/404" />;
  } else {
    return (
      <Wrapper className={props.className} $wide={props.wide ?? false}>
        {props.children}
      </Wrapper>
    );
  }
};

const Wrapper = styled.div<{ $wide: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${(props) => (props.$wide ? 0 : "1.5rem")};
  overflow-y: auto;
  overflow-x: hidden;
`;

export default Page;
