import React from "react";
import Compo, { usePopover } from "@smartly-city/compo";
import * as yup from "yup";
import { useLightingIoTTranslation } from "../../utils/translation";
import { useFormik } from "formik";
import {
  type LuminaireFilters,
  useLuminaireFilters,
} from "../../contexts/LuminaireFilters";
import {
  setBooleanFieldValue,
  setDateFieldValue,
  setFloatFieldValue,
  setIntFieldValue,
  setTextFieldValue,
  toDateValue,
  toStringValue,
} from "src/core/utils/formik";
import FiltersHeader, { type Filter } from "src/core/components/FiltersHeader";
import { toSnakeCase } from "src/core/utils/string";
import styled from "styled-components";

export interface LuminaireFiltersFormProps {
  onSubmit?: () => void;
}

export interface LuminaireFiltersFormContext {
  name: string | null;
  typeName: string | null;
  scheduleName: string | null;
  controllerId: string | null;
  hasController: boolean | null;
  hasLightingSchedule: boolean | null;
  minCommunicationDate: Date | null;
  maxCommunicationDate: Date | null;
  minEnergyMeteringDate: Date | null;
  maxEnergyMeteringDate: Date | null;
  minSignalStrength: number | null;
  maxSignalStrength: number | null;
  minSignalToNoiseRatio: number | null;
  maxSignalToNoiseRatio: number | null;
  minTemperature: number | null;
  maxTemperature: number | null;
  minConsumption: number | null;
  maxConsumption: number | null;
  minPowerFactor: number | null;
  maxPowerFactor: number | null;
  minVoltage: number | null;
  maxVoltage: number | null;
}

const LuminaireFiltersForm: React.FC<LuminaireFiltersFormProps> = (props) => {
  const { t } = useLightingIoTTranslation();
  const popover = usePopover();
  const filters = useLuminaireFilters();
  const ref = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    popover.buttonRef(ref.current);
  }, [popover, ref]);

  const headerFilterKeys = Object.keys(filters.status) as Array<
    keyof LuminaireFilters
  >;

  const headerFilters: Filter[] = headerFilterKeys.map((key) => ({
    id: key,
    name: t("form_luminaire_filters." + toSnakeCase(key)),
    active: filters.status[key] ?? false,
    onAdd: () => filters.activateFilter(key),
  }));

  const formik = useFormik<LuminaireFiltersFormContext>({
    initialValues: {
      name: filters.filters.name ?? null,
      typeName: filters.filters.typeName ?? null,
      scheduleName: filters.filters.scheduleName ?? null,
      controllerId: filters.filters.controllerId ?? null,
      hasController: filters.filters.hasController ?? null,
      hasLightingSchedule: filters.filters.hasLightingSchedule ?? null,
      minCommunicationDate: filters.filters.communicationDate?.min ?? null,
      maxCommunicationDate: filters.filters.communicationDate?.max ?? null,
      minEnergyMeteringDate: filters.filters.energyMeteringDate?.min ?? null,
      maxEnergyMeteringDate: filters.filters.energyMeteringDate?.max ?? null,
      minSignalStrength: filters.filters.signalStrength?.min ?? null,
      maxSignalStrength: filters.filters.signalStrength?.max ?? null,
      minSignalToNoiseRatio: filters.filters.signalToNoiseRatio?.min ?? null,
      maxSignalToNoiseRatio: filters.filters.signalToNoiseRatio?.max ?? null,
      minTemperature: filters.filters.temperature?.min ?? null,
      maxTemperature: filters.filters.temperature?.max ?? null,
      minConsumption: filters.filters.consumption?.min ?? null,
      maxConsumption: filters.filters.consumption?.max ?? null,
      minPowerFactor: filters.filters.powerFactor?.min ?? null,
      maxPowerFactor: filters.filters.powerFactor?.max ?? null,
      minVoltage: filters.filters.voltage?.min ?? null,
      maxVoltage: filters.filters.voltage?.max ?? null,
    },
    validationSchema: yup.object({
      name: yup.string().nullable().fromFormik(),
      typeName: yup.string().nullable().fromFormik(),
      scheduleName: yup.string().nullable().fromFormik(),
      controllerId: yup.string().nullable().fromFormik(),
      hasController: yup.bool().nullable().fromFormik(),
      hasLightingSchedule: yup.bool().nullable().fromFormik(),
      minCommunicationDate: yup.date().nullable().fromFormik(),
      maxCommunicationDate: yup.date().nullable().fromFormik(),
      minEnergyMeteringDate: yup.date().nullable().fromFormik(),
      maxEnergyMeteringDate: yup.date().nullable().fromFormik(),
      minSignalStrength: yup.number().integer().nullable().fromFormik(),
      maxSignalStrength: yup.number().integer().nullable().fromFormik(),
      minSignalToNoiseRatio: yup.number().integer().nullable().fromFormik(),
      maxSignalToNoiseRatio: yup.number().integer().nullable().fromFormik(),
      minTemperature: yup.number().integer().nullable().fromFormik(),
      maxTemperature: yup.number().integer().nullable().fromFormik(),
      minConsumption: yup.number().integer().nullable().fromFormik(),
      maxConsumption: yup.number().integer().nullable().fromFormik(),
      minPowerFactor: yup.number().nullable().fromFormik(),
      maxPowerFactor: yup.number().nullable().fromFormik(),
      minVoltage: yup.number().integer().nullable().fromFormik(),
      maxVoltage: yup.number().integer().nullable().fromFormik(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      filters.setFilters({
        name: values.name ?? undefined,
        typeName: values.typeName ?? undefined,
        scheduleName: values.scheduleName ?? undefined,
        controllerId: values.controllerId ?? undefined,
        hasController: values.hasController ?? undefined,
        hasLightingSchedule: values.hasLightingSchedule ?? undefined,
        communicationDate: {
          min: values.minCommunicationDate
            ? new Date(values.minCommunicationDate)
            : undefined,
          max: values.maxCommunicationDate
            ? new Date(values.maxCommunicationDate)
            : undefined,
        },
        energyMeteringDate: {
          min: values.minEnergyMeteringDate
            ? new Date(values.minEnergyMeteringDate)
            : undefined,
          max: values.maxEnergyMeteringDate
            ? new Date(values.maxEnergyMeteringDate)
            : undefined,
        },
        signalStrength: {
          min: values.minSignalStrength ?? undefined,
          max: values.maxSignalStrength ?? undefined,
        },
        signalToNoiseRatio: {
          min: values.minSignalToNoiseRatio ?? undefined,
          max: values.maxSignalToNoiseRatio ?? undefined,
        },
        temperature: {
          min: values.minTemperature ?? undefined,
          max: values.maxTemperature ?? undefined,
        },
        consumption: {
          min: values.minConsumption ?? undefined,
          max: values.maxConsumption ?? undefined,
        },
        powerFactor: {
          min: values.minPowerFactor ?? undefined,
          max: values.maxPowerFactor ?? undefined,
        },
        voltage: {
          min: values.minVoltage ?? undefined,
          max: values.maxVoltage ?? undefined,
        },
      });
      props.onSubmit?.();
      setSubmitting(false);
    },
    onReset: () => {
      filters.clearFilters();
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FiltersHeader
        filters={headerFilters}
        onSave={formik.submitForm}
        onClear={formik.resetForm}
      />
      {filters.status.name && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("name")}>
          <Compo.TextInput
            wide
            label={t("form_luminaire_filters.name")}
            value={toStringValue(formik.values.name)}
            onChange={setTextFieldValue(formik, "name")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.typeName && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("typeName")}>
          <Compo.TextInput
            wide
            label={t("form_luminaire_filters.type_name")}
            value={toStringValue(formik.values.typeName)}
            onChange={setTextFieldValue(formik, "typeName")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.scheduleName && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("scheduleName")}
        >
          <Compo.TextInput
            wide
            label={t("form_luminaire_filters.schedule_name")}
            value={toStringValue(formik.values.scheduleName)}
            onChange={setTextFieldValue(formik, "scheduleName")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.controllerId && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("controllerId")}
        >
          <Compo.TextInput
            wide
            label={t("form_luminaire_filters.controller_id")}
            value={toStringValue(formik.values.controllerId)}
            onChange={setTextFieldValue(formik, "controllerId")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.hasController && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("hasController")}
        >
          <Compo.Toggle
            wide
            label={t("form_luminaire_filters.has_controller") ?? ""}
            checked={formik.values.hasController ?? false}
            onChange={setBooleanFieldValue(formik, "hasController")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.hasLightingSchedule && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("hasLightingSchedule")}
        >
          <Compo.Toggle
            wide
            label={t("form_luminaire_filters.has_lighting_schedule") ?? ""}
            checked={formik.values.hasLightingSchedule ?? false}
            onChange={setBooleanFieldValue(formik, "hasLightingSchedule")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.communicationDate && (
        <Compo.FilterBox
          vertical
          onClose={() => filters.deactivateFilter("communicationDate")}
        >
          <Compo.DateInput
            wide
            label={t("form_luminaire_filters.min_communication_date")}
            value={toDateValue(formik.values.minCommunicationDate)}
            onChange={setDateFieldValue(formik, "minCommunicationDate")}
          />
          <Compo.DateInput
            wide
            label={t("form_luminaire_filters.max_communication_date")}
            value={toDateValue(formik.values.maxCommunicationDate)}
            onChange={setDateFieldValue(formik, "maxCommunicationDate")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.energyMeteringDate && (
        <Compo.FilterBox
          vertical
          onClose={() => filters.deactivateFilter("energyMeteringDate")}
        >
          <Compo.DateInput
            wide
            label={t("form_luminaire_filters.min_energy_metering_date")}
            value={toDateValue(formik.values.minEnergyMeteringDate)}
            onChange={setDateFieldValue(formik, "minEnergyMeteringDate")}
          />
          <Compo.DateInput
            wide
            label={t("form_luminaire_filters.max_energy_metering_date")}
            value={toDateValue(formik.values.maxEnergyMeteringDate)}
            onChange={setDateFieldValue(formik, "maxEnergyMeteringDate")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.signalStrength && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("signalStrength")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.min_signal_strength")}
            value={toStringValue(formik.values.minSignalStrength)}
            onChange={setIntFieldValue(formik, "minSignalStrength")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.max_signal_strength")}
            value={toStringValue(formik.values.maxSignalStrength)}
            onChange={setIntFieldValue(formik, "maxSignalStrength")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.signalToNoiseRatio && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("signalToNoiseRatio")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.min_signal_to_noise_ratio")}
            value={toStringValue(formik.values.minSignalToNoiseRatio)}
            onChange={setIntFieldValue(formik, "minSignalToNoiseRatio")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.max_signal_to_noise_ratio")}
            value={toStringValue(formik.values.maxSignalToNoiseRatio)}
            onChange={setIntFieldValue(formik, "maxSignalToNoiseRatio")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.temperature && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("temperature")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.min_temperature")}
            value={toStringValue(formik.values.minTemperature)}
            onChange={setIntFieldValue(formik, "minTemperature")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.max_temperature")}
            value={toStringValue(formik.values.maxTemperature)}
            onChange={setIntFieldValue(formik, "maxTemperature")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.consumption && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("consumption")}
        >
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.min_consumption")}
            value={toStringValue(formik.values.minConsumption)}
            onChange={setIntFieldValue(formik, "minConsumption")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.max_consumption")}
            value={toStringValue(formik.values.maxConsumption)}
            onChange={setIntFieldValue(formik, "maxConsumption")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.powerFactor && (
        <Compo.FilterBox
          onClose={() => filters.deactivateFilter("powerFactor")}
        >
          <Compo.TextInput
            wide
            attributes={{ step: "0.01" }}
            type="number"
            label={t("form_luminaire_filters.min_power_factor")}
            value={toStringValue(formik.values.minPowerFactor)}
            onChange={setFloatFieldValue(formik, "minPowerFactor")}
          />
          <Compo.TextInput
            wide
            attributes={{ step: "0.01" }}
            type="number"
            label={t("form_luminaire_filters.max_power_factor")}
            value={toStringValue(formik.values.maxPowerFactor)}
            onChange={setFloatFieldValue(formik, "maxPowerFactor")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.voltage && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("voltage")}>
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.min_voltage")}
            value={toStringValue(formik.values.minVoltage)}
            onChange={setIntFieldValue(formik, "minVoltage")}
          />
          <Compo.TextInput
            wide
            type="number"
            label={t("form_luminaire_filters.max_voltage")}
            value={toStringValue(formik.values.maxVoltage)}
            onChange={setIntFieldValue(formik, "maxVoltage")}
          />
        </Compo.FilterBox>
      )}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0.25rem;
`;

export default LuminaireFiltersForm;
