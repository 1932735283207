import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import FailureHistoryItemViewSkeleton from "./FailureHistoryItemView.skeleton";
import { useCurrentArea } from "src/core/contexts";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFailuresTranslation } from "../../utils/translation";
import { useFailureByIdFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresFailureById";
import type { Failures_FailureModel } from "src/graphql/types";

const FailureHistoryItemView: React.FC = () => (
  <Page permission="failures.failure.get">
    <React.Suspense fallback={<FailureHistoryItemViewSkeleton />}>
      <FailureHistoryItemViewContent />
    </React.Suspense>
  </Page>
);

const FailureHistoryItemViewContent: React.FC = () => {
  const { t } = useFailuresTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const params = useParams<{ id: string; historyItemId: string }>();

  const { data } = useFailureByIdFromFailuresSuspenseQuery({
    variables: { input: { id: params.id as string, areaId: area.id } },
  });

  const failure = data.Failures_failureById.failure as Failures_FailureModel;
  const item = failure.history.find((e) => e.id === params.historyItemId);

  if (!failure || !params.historyItemId || !item) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Wrapper>
      <Compo.Header
        title={t("failure_history_item_type." + item.type.toLowerCase())}
      ></Compo.Header>
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={new Date(item.occuredAt).toLocaleString()}
      >
        {t("page_failure_history_item_view.date")}
      </Compo.ListRow>
      <HeaderWithDistance
        outline
        type="h2"
        title={t("page_failure_history_item_view.error")}
      />
      {item.errorId && item.errorType && (
        <LinkWrapper>
          <Compo.LinkTile
            wide
            text={t("failure_error_type." + item.errorType.toLowerCase())}
            icon={Compo.ExclamationCircleIcon}
            onClick={() =>
              navigate(
                `/failures/failure/${failure.id}/error/${
                  item.errorId as string
                }`
              )
            }
          />
        </LinkWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

export default FailureHistoryItemView;
