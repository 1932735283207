import React from "react";
import styled from "styled-components";
import Page from "src/core/components/Page";
import Compo, { useDialog, useModal } from "@smartly-city/compo";
import { Navigate, useParams } from "react-router-dom";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { usePowerBoxByIdFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPowerBoxById";
import { PowerBoxForm } from "../../containers/PowerBoxForm";
import PowerBoxViewSkeleton from "./PowerBoxView.skeleton";
import { toast } from "react-toastify";
import type { Lighting_PowerBoxModel } from "src/graphql/types";
import { PowerBoxDeleteForm } from "../../containers/PowerBoxDeleteForm";
import { useLightingTranslation } from "../../utils/translation";
import { buildAddressString } from "../../utils/address";

const PowerBoxView: React.FC = () => (
  <Page permission="lighting.power_box.get">
    <React.Suspense fallback={<PowerBoxViewSkeleton />}>
      <PowerBoxViewContent />
    </React.Suspense>
  </Page>
);

const PowerBoxViewContent: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const editModal = useModal();
  const deleteDialog = useDialog();
  const params = useParams<{ id: string }>();
  const editPermitted = usePermissionMatch("lighting.power_box.update");
  const deletePermitted = usePermissionMatch("lighting.power_box.delete");

  const { data } = usePowerBoxByIdFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id, id: params.id as string } },
  });

  const powerBox = data.Lighting_powerBoxById
    .powerBox as Lighting_PowerBoxModel;
  const addressString = buildAddressString(powerBox.address);

  if (!powerBox) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Wrapper>
      <Compo.Header title={powerBox.name}>
        {editPermitted && (
          <Compo.Button
            filled
            icon={Compo.PencilIcon}
            onClick={() => editModal.setIsOpen(true)}
          />
        )}
        {deletePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => deleteDialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.Header type="h2" title={t("location")} />
      <Compo.LinkTile
        wide
        text={addressString}
        icon={Compo.DocumentDuplicateIcon}
        onClick={async () => {
          await navigator.clipboard.writeText(addressString);
          toast.success(t("notification.address_copied"));
        }}
      />
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow wide description={String(powerBox.ordinalNumber)}>
        {t("page_power_box_view.ordinal_number")}
      </Compo.ListRow>
      {powerBox.details.power && (
        <Compo.ListRow wide description={`${powerBox.details.power} kW`}>
          {t("page_power_box_view.power")}
        </Compo.ListRow>
      )}
      {powerBox.details.contractedPower && (
        <Compo.ListRow
          wide
          description={`${powerBox.details.contractedPower} kW`}
        >
          {t("page_power_box_view.contracted_power")}
        </Compo.ListRow>
      )}
      {powerBox.details.fuseType && (
        <Compo.ListRow wide description={powerBox.details.fuseType}>
          {t("page_power_box_view.fuse_type")}
        </Compo.ListRow>
      )}
      {powerBox.details.fuseAmpRating && (
        <Compo.ListRow wide description={`${powerBox.details.fuseAmpRating} A`}>
          {t("page_power_box_view.fuse_amp_rating")}
        </Compo.ListRow>
      )}
      {powerBox.details.recipientCode && (
        <Compo.ListRow wide description={powerBox.details.recipientCode}>
          {t("page_power_box_view.recipient_code")}
        </Compo.ListRow>
      )}
      {powerBox.details.notes && (
        <Compo.ListRow wide description={powerBox.details.notes}>
          {t("page_power_box_view.notes")}
        </Compo.ListRow>
      )}
      {editModal.isOpen &&
        editModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <PowerBoxForm
              powerBox={powerBox}
              onClose={() => editModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {deleteDialog.isOpen &&
        deleteDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <PowerBoxDeleteForm
              powerBox={powerBox}
              onClose={() => deleteDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default PowerBoxView;
