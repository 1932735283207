import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNewLuminaireTypeFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_CreateNewLuminaireTypeInput;
}>;


export type CreateNewLuminaireTypeFromLightingMutation = { __typename?: 'Mutation', Lighting_createNewLuminaireType: { __typename?: 'Lighting_GraphQLResultOfCreateNewLuminaireTypePayload', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }>, value?: { __typename?: 'Lighting_CreateNewLuminaireTypePayload', id: any } | null } };


export const CreateNewLuminaireTypeFromLightingDocument = gql`
    mutation createNewLuminaireTypeFromLighting($input: Lighting_CreateNewLuminaireTypeInput!) {
  Lighting_createNewLuminaireType(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    value {
      id
    }
    created
  }
}
    `;
export type CreateNewLuminaireTypeFromLightingMutationFn = Apollo.MutationFunction<CreateNewLuminaireTypeFromLightingMutation, CreateNewLuminaireTypeFromLightingMutationVariables>;

/**
 * __useCreateNewLuminaireTypeFromLightingMutation__
 *
 * To run a mutation, you first call `useCreateNewLuminaireTypeFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewLuminaireTypeFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewLuminaireTypeFromLightingMutation, { data, loading, error }] = useCreateNewLuminaireTypeFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewLuminaireTypeFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewLuminaireTypeFromLightingMutation, CreateNewLuminaireTypeFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewLuminaireTypeFromLightingMutation, CreateNewLuminaireTypeFromLightingMutationVariables>(CreateNewLuminaireTypeFromLightingDocument, options);
      }
export type CreateNewLuminaireTypeFromLightingMutationHookResult = ReturnType<typeof useCreateNewLuminaireTypeFromLightingMutation>;
export type CreateNewLuminaireTypeFromLightingMutationResult = Apollo.MutationResult<CreateNewLuminaireTypeFromLightingMutation>;
export type CreateNewLuminaireTypeFromLightingMutationOptions = Apollo.BaseMutationOptions<CreateNewLuminaireTypeFromLightingMutation, CreateNewLuminaireTypeFromLightingMutationVariables>;