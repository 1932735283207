import React from "react";
import MapProxy from "src/core/proxies/MapProxy";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useStationLayerBuilder } from "../utils/layers";
import { useStationsFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStations";
import { useStationJournalsFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStationJournals";
import type { MapItem } from "@smartly-city/compo-map";

const WeatherIoTMapProxy: React.FC = () => {
  const area = useCurrentArea();
  const navigate = useNavigate();
  const stationBuilder = useStationLayerBuilder();

  const { data: stationData } = useStationsFromWeatherIoTSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const stations = stationData.WeatherIoT_stations.items;

  const { data: journalData } = useStationJournalsFromWeatherIoTSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const journals = journalData.WeatherIoT_stationJournals.items;

  const stationLayer = React.useMemo(
    () => stationBuilder(stations, journals),
    [stations, journals, stationBuilder]
  );

  return (
    <MapProxy
      layers={[stationLayer]}
      onItemClick={(item, layer) => {
        const redirectUrl = getActiveItemRedirectUrl(item, layer);
        if (redirectUrl === window.location.pathname) return;
        navigate(redirectUrl);
      }}
    />
  );
};

const getActiveItemRedirectUrl = (active: MapItem, layer: string): string => {
  switch (layer) {
    case "station":
      return `/weather-iot/station/${active.id}`;
    default:
      return ".";
  }
};

export default WeatherIoTMapProxy;
