import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FailureByIdFromFailuresQueryVariables = Types.Exact<{
  input: Types.Failures_GetFailureByIdInput;
}>;


export type FailureByIdFromFailuresQuery = { __typename?: 'Query', Failures_failureById: { __typename?: 'Failures_GetFailureByIdPayload', failure?: { __typename?: 'Failures_FailureModel', id: any, version: number, created: any, changed: any, areaId: any, type: Types.Failures_FailureTypeModel, state: Types.Failures_FailureStateModel, active: boolean, objectId: any, objectName: string, objectLocation: { __typename?: 'Failures_CoordinatesModel', latitude: number, longitude: number, hash?: string | null }, errors: Array<{ __typename?: 'Failures_FailureErrorModel', id: any, type: Types.Failures_FailureErrorTypeModel, group: Types.Failures_FailureErrorGroupModel, stage: Types.Failures_FailureErrorStageModel, description?: { __typename?: 'Failures_FailureErrorDescriptionModel', whatWasTheReason?: string | null, howWasResolved?: string | null, whatSparePartsWereUsed?: string | null } | null }>, history: Array<{ __typename?: 'Failures_FailureHistoryItemModel', id: any, occuredAt: any, type: Types.Failures_FailureHistoryItemTypeModel, errorId?: any | null, errorType?: Types.Failures_FailureErrorTypeModel | null, reporterId?: any | null }> } | null } };


export const FailureByIdFromFailuresDocument = gql`
    query failureByIdFromFailures($input: Failures_GetFailureByIdInput!) {
  Failures_failureById(input: $input) {
    failure {
      id
      version
      created
      changed
      areaId
      type
      state
      active
      objectId
      objectName
      objectLocation {
        latitude
        longitude
        hash
      }
      errors {
        id
        type
        group
        stage
        description {
          whatWasTheReason
          howWasResolved
          whatSparePartsWereUsed
        }
      }
      history {
        id
        occuredAt
        type
        errorId
        errorType
        reporterId
      }
    }
  }
}
    `;

/**
 * __useFailureByIdFromFailuresQuery__
 *
 * To run a query within a React component, call `useFailureByIdFromFailuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailureByIdFromFailuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailureByIdFromFailuresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFailureByIdFromFailuresQuery(baseOptions: Apollo.QueryHookOptions<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables> & ({ variables: FailureByIdFromFailuresQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables>(FailureByIdFromFailuresDocument, options);
      }
export function useFailureByIdFromFailuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables>(FailureByIdFromFailuresDocument, options);
        }
export function useFailureByIdFromFailuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables>(FailureByIdFromFailuresDocument, options);
        }
export type FailureByIdFromFailuresQueryHookResult = ReturnType<typeof useFailureByIdFromFailuresQuery>;
export type FailureByIdFromFailuresLazyQueryHookResult = ReturnType<typeof useFailureByIdFromFailuresLazyQuery>;
export type FailureByIdFromFailuresSuspenseQueryHookResult = ReturnType<typeof useFailureByIdFromFailuresSuspenseQuery>;
export type FailureByIdFromFailuresQueryResult = Apollo.QueryResult<FailureByIdFromFailuresQuery, FailureByIdFromFailuresQueryVariables>;