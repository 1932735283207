import React from "react";
import Compo, { useDialog, useModal } from "@smartly-city/compo";
import styled from "styled-components";
import LuminaireLightingScheduleTabSkeleton from "./LuminaireLightingScheduleTab.skeleton";
import type {
  LightingIoT_LightingSchedulePayload,
  LightingIoT_LuminaireModel,
} from "src/graphql/types";
import { useLightingScheduleByIdFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLightingScheduleById";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { LuminaireLightingScheduleForm } from "src/modules/lighting_iot/containers/LuminaireLightingScheduleForm";
import LuminaireLightingScheduleDeleteForm from "src/modules/lighting_iot/containers/LuminaireLightingScheduleDeleteForm/LuminaireLightingScheduleDeleteForm";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import { parseTimeSpan } from "src/core/utils/timespan";

export interface LuminaireLightingScheduleTabProps {
  luminaire: LightingIoT_LuminaireModel;
}

const LuminaireLightingScheduleTabContent: React.FC<
  LuminaireLightingScheduleTabProps
> = (props) => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const removeFromScheduleDialog = useDialog();
  const removeFromSchedulePermitted = usePermissionMatch(
    "lighting_iot.luminaire.remove_from_lighting_schedule"
  );

  const { data } = useLightingScheduleByIdFromLightingIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        id: props.luminaire.lightingScheduleId ?? "",
      },
    },
  });

  const schedule = data.LightingIoT_lightingScheduleById
    .schedule as LightingIoT_LightingSchedulePayload;

  return (
    <React.Fragment>
      <Compo.Header
        outline
        type="h2"
        title={t("page_luminaire_view.lighting_schedule")}
      >
        {removeFromSchedulePermitted && (
          <Compo.Button
            small
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => removeFromScheduleDialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.ListRow wide description={schedule.name}>
        {t("page_luminaire_view.name")}
      </Compo.ListRow>
      {schedule.startStep.__typename ===
        "LightingIoT_StartOnTimeStepPayload" && (
        <Compo.ListRow
          wide
          description={`${parseTimeSpan(schedule.startStep.startTime)}, ${
            schedule.startStep.brightness
          }%`}
        >
          {t("page_luminaire_view.start_on_time")}
        </Compo.ListRow>
      )}
      {schedule.startStep.__typename ===
        "LightingIoT_StartOnSunsetStepPayload" && (
        <Compo.ListRow
          wide
          description={`${schedule.startStep.offset} min, ${schedule.startStep.brightness}%`}
        >
          {t("page_luminaire_view.start_on_sunset")}
        </Compo.ListRow>
      )}
      {schedule.changeSteps.map(
        (step, index) =>
          step.__typename === "LightingIoT_ChangeOnTimeStepPayload" && (
            <Compo.ListRow
              wide
              key={index}
              description={`${parseTimeSpan(step.changeTime)}, ${
                step.brightness
              }%`}
            >
              {t("page_luminaire_view.change_on_time")}
            </Compo.ListRow>
          )
      )}
      {schedule.stopStep.__typename === "LightingIoT_StopOnTimeStepPayload" && (
        <Compo.ListRow
          wide
          description={`${parseTimeSpan(schedule.stopStep.stopTime)}, ${
            schedule.stopStep.brightness
          }%`}
        >
          {t("page_luminaire_view.stop_on_time")}
        </Compo.ListRow>
      )}
      {schedule.stopStep.__typename ===
        "LightingIoT_StopOnSunriseStepPayload" && (
        <Compo.ListRow
          wide
          description={`${schedule.stopStep.offset} min, ${schedule.stopStep.brightness}%`}
        >
          {t("page_luminaire_view.stop_on_sunrise")}
        </Compo.ListRow>
      )}
      {removeFromScheduleDialog.isOpen &&
        removeFromScheduleDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireLightingScheduleDeleteForm
              luminaire={props.luminaire}
              onClose={() => removeFromScheduleDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const LuminaireLightingScheduleTab: React.FC<
  LuminaireLightingScheduleTabProps
> = (props) => {
  const { t } = useLightingIoTTranslation();
  const addToScheduleModal = useModal();
  const addToSchedulePermitted = usePermissionMatch(
    "lighting_iot.luminaire.add_to_lighting_schedule"
  );

  const getContent = (): React.ReactNode => {
    if (!props.luminaire.lightingScheduleId) {
      return (
        <TileWrapper>
          <Compo.ActionTile
            text={t("page_luminaire_view.add_to_lighting_schedule_tile_text")}
          >
            <Compo.Button
              filled
              icon={Compo.PlusIcon}
              disabled={!addToSchedulePermitted}
              onClick={() => addToScheduleModal.setIsOpen(true)}
            >
              {t("page_luminaire_view.add_to_lighting_schedule_tile_button")}
            </Compo.Button>
          </Compo.ActionTile>
        </TileWrapper>
      );
    }
    return (
      <React.Suspense fallback={<LuminaireLightingScheduleTabSkeleton />}>
        <LuminaireLightingScheduleTabContent {...props} />
      </React.Suspense>
    );
  };

  return (
    <React.Fragment>
      {getContent()}
      {addToScheduleModal.isOpen &&
        addToScheduleModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireLightingScheduleForm
              luminaire={props.luminaire}
              onClose={() => addToScheduleModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const TileWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default LuminaireLightingScheduleTab;
