import React from "react";
import styled from "styled-components";
import Page from "src/core/components/Page";
import Compo, { useDialog, useModal } from "@smartly-city/compo";
import { Navigate, useParams } from "react-router-dom";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useLightingScheduleByIdFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLightingScheduleById";
import { LightingScheduleForm } from "../../containers/LightingScheduleForm";
import LightingScheduleViewSkeleton from "./LightingScheduleView.skeleton";
import type {
  LightingIoT_LightingScheduleChangeStep,
  LightingIoT_LightingSchedulePayload,
  LightingIoT_LightingScheduleStartStep,
  LightingIoT_LightingScheduleStopStep,
} from "src/graphql/types";
import { LightingScheduleDeleteForm } from "../../containers/LightingScheduleDeleteForm";
import { useLightingIoTTranslation } from "../../utils/translation";
import { LuminairesLightingScheduleForm } from "../../containers/LuminairesLightingScheduleForm";
import { LuminairesLightingScheduleDeleteForm } from "../../containers/LuminairesLightingScheduleDeleteForm";
import { parseTimeSpan } from "src/core/utils/timespan";

const LightingScheduleView: React.FC = () => (
  <Page permission="lighting_iot.lighting_schedule.get">
    <React.Suspense fallback={<LightingScheduleViewSkeleton />}>
      <LightingScheduleViewContent />
    </React.Suspense>
  </Page>
);

const LightingScheduleViewContent: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const addLuminairesModal = useModal();
  const removeLuminairesModal = useModal();
  const editModal = useModal();
  const deleteDialog = useDialog();
  const params = useParams<{ id: string }>();
  const addLuminairesPermitted = usePermissionMatch(
    "lighting_iot.luminaire.add_to_lighting_schedule"
  );
  const removeLuminairesPermitted = usePermissionMatch(
    "lighting_iot.luminaire.remove_from_lighting_schedule"
  );
  const editPermitted = usePermissionMatch(
    "lighting_iot.lighting_schedule.update"
  );
  const deletePermitted = usePermissionMatch(
    "lighting_iot.lighting_schedule.delete"
  );

  const { data } = useLightingScheduleByIdFromLightingIoTSuspenseQuery({
    variables: { input: { areaId: area.id, id: params.id as string } },
  });
  const lightingSchedule = data.LightingIoT_lightingScheduleById
    .schedule as LightingIoT_LightingSchedulePayload;

  if (!lightingSchedule) {
    return <Navigate replace to="/404" />;
  }

  const renderStartStep = (
    step: LightingIoT_LightingScheduleStartStep
  ): string => {
    if (step.__typename === "LightingIoT_StartOnTimeStepPayload") {
      return `${parseTimeSpan(step.startTime)}, ${step.brightness}%`;
    }
    if (step.__typename === "LightingIoT_StartOnSunsetStepPayload") {
      return `${step.offset} min, ${step.brightness}%`;
    }
    throw new Error("Invalid start step");
  };

  const renderChangeStep = (
    step: LightingIoT_LightingScheduleChangeStep
  ): string => {
    if (step.__typename === "LightingIoT_ChangeOnTimeStepPayload") {
      return `${parseTimeSpan(step.changeTime)}, ${step.brightness}%`;
    }
    throw new Error("Invalid change step");
  };

  const renderStopStep = (
    step: LightingIoT_LightingScheduleStopStep
  ): string => {
    if (step.__typename === "LightingIoT_StopOnTimeStepPayload") {
      return `${parseTimeSpan(step.stopTime)}, ${step.brightness}%`;
    }
    if (step.__typename === "LightingIoT_StopOnSunriseStepPayload") {
      return `${step.offset} min, ${step.brightness}%`;
    }
    throw new Error("Invalid stop step");
  };

  return (
    <Wrapper>
      <Compo.Header title={lightingSchedule.name}>
        <Compo.ButtonList>
          {addLuminairesPermitted && (
            <Compo.Button
              minimize
              icon={Compo.PlusIcon}
              onClick={() => addLuminairesModal.setIsOpen(true)}
            >
              {t("page_lighting_schedule_view.add_luminaire")}
            </Compo.Button>
          )}
          {removeLuminairesPermitted && (
            <Compo.Button
              minimize
              icon={Compo.TrashIcon}
              onClick={() => removeLuminairesModal.setIsOpen(true)}
            >
              {t("page_lighting_schedule_view.remove_luminaire")}
            </Compo.Button>
          )}
          {editPermitted && (
            <Compo.Button
              minimize
              icon={Compo.PencilIcon}
              onClick={() => editModal.setIsOpen(true)}
            >
              {t("page_lighting_schedule_view.edit")}
            </Compo.Button>
          )}
          {deletePermitted && (
            <Compo.Button
              minimize
              variant="alert"
              icon={Compo.TrashIcon}
              onClick={() => deleteDialog.setIsOpen(true)}
            >
              {t("page_lighting_schedule_view.delete")}
            </Compo.Button>
          )}
        </Compo.ButtonList>
      </Compo.Header>
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={renderStartStep(lightingSchedule.startStep)}
      >
        {t("page_lighting_schedule_view.start_step")}
      </Compo.ListRow>
      {lightingSchedule.changeSteps.map((step, index) => (
        <Compo.ListRow wide key={index} description={renderChangeStep(step)}>
          {t("page_lighting_schedule_view.change_step")}
        </Compo.ListRow>
      ))}
      <Compo.ListRow
        wide
        description={renderStopStep(lightingSchedule.stopStep)}
      >
        {t("page_lighting_schedule_view.stop_step")}
      </Compo.ListRow>
      {addLuminairesModal.isOpen &&
        addLuminairesModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminairesLightingScheduleForm
              schedule={lightingSchedule}
              onClose={() => addLuminairesModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {removeLuminairesModal.isOpen &&
        removeLuminairesModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminairesLightingScheduleDeleteForm
              schedule={lightingSchedule}
              onClose={() => removeLuminairesModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {editModal.isOpen &&
        editModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LightingScheduleForm
              schedule={lightingSchedule}
              onClose={() => editModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {deleteDialog.isOpen &&
        deleteDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LightingScheduleDeleteForm
              lightingSchedule={lightingSchedule}
              onClose={() => deleteDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default LightingScheduleView;
