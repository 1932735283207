import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import CounterBikeJournalTabDailyCountChartSkeleton from "./charts/CounterBikeJournalTabDailyCountChart.skeleton";
import CounterBikeJournalTabDailySpeedChartSkeleton from "./charts/CounterBikeJournalTabDailySpeedChart.skeleton";
import CounterBikeJournalTabMonthlyCountChartSkeleton from "./charts/CounterBikeJournalTabMonthlyCountChart.skeleton";
import CounterBikeJournalTabMonthlySpeedChartSkeleton from "./charts/CounterBikeJournalTabMonthlySpeedChart.skeleton";

const CounterBikeJournalTabSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <CounterBikeJournalTabDailyCountChartSkeleton />
      <CounterBikeJournalTabDailySpeedChartSkeleton />
      <CounterBikeJournalTabMonthlyCountChartSkeleton />
      <CounterBikeJournalTabMonthlySpeedChartSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CounterBikeJournalTabSkeleton;
