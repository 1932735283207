import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateEnergyConsumptionReportFromReportsMutationVariables = Types.Exact<{
  input: Types.Reports_GenerateEnergyConsumptionReportInput;
}>;


export type GenerateEnergyConsumptionReportFromReportsMutation = { __typename?: 'Mutation', Reports_generateEnergyConsumptionReport: { __typename?: 'Reports_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Reports_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Reports_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const GenerateEnergyConsumptionReportFromReportsDocument = gql`
    mutation generateEnergyConsumptionReportFromReports($input: Reports_GenerateEnergyConsumptionReportInput!) {
  Reports_generateEnergyConsumptionReport(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type GenerateEnergyConsumptionReportFromReportsMutationFn = Apollo.MutationFunction<GenerateEnergyConsumptionReportFromReportsMutation, GenerateEnergyConsumptionReportFromReportsMutationVariables>;

/**
 * __useGenerateEnergyConsumptionReportFromReportsMutation__
 *
 * To run a mutation, you first call `useGenerateEnergyConsumptionReportFromReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEnergyConsumptionReportFromReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEnergyConsumptionReportFromReportsMutation, { data, loading, error }] = useGenerateEnergyConsumptionReportFromReportsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateEnergyConsumptionReportFromReportsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEnergyConsumptionReportFromReportsMutation, GenerateEnergyConsumptionReportFromReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEnergyConsumptionReportFromReportsMutation, GenerateEnergyConsumptionReportFromReportsMutationVariables>(GenerateEnergyConsumptionReportFromReportsDocument, options);
      }
export type GenerateEnergyConsumptionReportFromReportsMutationHookResult = ReturnType<typeof useGenerateEnergyConsumptionReportFromReportsMutation>;
export type GenerateEnergyConsumptionReportFromReportsMutationResult = Apollo.MutationResult<GenerateEnergyConsumptionReportFromReportsMutation>;
export type GenerateEnergyConsumptionReportFromReportsMutationOptions = Apollo.BaseMutationOptions<GenerateEnergyConsumptionReportFromReportsMutation, GenerateEnergyConsumptionReportFromReportsMutationVariables>;