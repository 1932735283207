import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import Page from "src/core/components/Page";
import { useFailuresTranslation } from "../../utils/translation";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useIndexFailuresFromFailuresMutation } from "src/graphql/__generated__/FailuresIndexFailures";
import { useIndexObjectsFromFailuresMutation } from "src/graphql/__generated__/FailuresIndexObjects";
import { useIndexReportersFromFailuresMutation } from "src/graphql/__generated__/FailuresIndexReporters";
import { useValidateFailuresIndexFromFailuresMutation } from "src/graphql/__generated__/FailuresValidateFailuresIndex";
import { useValidateObjectsIndexFromFailuresMutation } from "src/graphql/__generated__/FailuresValidateObjectsIndex";
import { useValidateReportersIndexFromFailuresMutation } from "src/graphql/__generated__/FailuresValidateReportersIndex";
import {
  clearFailuresCache,
  clearObjectsCache,
  clearReportersCache,
} from "../../utils/cache";

const Administration: React.FC = () => {
  const { t } = useFailuresTranslation();
  const area = useCurrentArea();
  const [tab, setTab] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  const hasValidateFailuresIndexPermission = usePermissionMatch(
    "failures.admin.validate_failures_index"
  );
  const [validateFailures] = useValidateFailuresIndexFromFailuresMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleValidateFailures = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateFailures();
    if (result.data?.Failures_Admin_validateFailuresIndex.isSuccess) {
      toast.success(t(`notification.failures_index_validated`));
    } else {
      result.data?.Failures_Admin_validateFailuresIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateObjectsIndexPermission = usePermissionMatch(
    "failures.admin.validate_objects_index"
  );
  const [validateObjects] = useValidateObjectsIndexFromFailuresMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleValidateObjects = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateObjects();
    if (result.data?.Failures_Admin_validateObjectsIndex.isSuccess) {
      toast.success(t(`notification.objects_index_validated`));
    } else {
      result.data?.Failures_Admin_validateObjectsIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateReportersIndexPermission = usePermissionMatch(
    "failures.admin.validate_reporters_index"
  );
  const [validateReporters] = useValidateReportersIndexFromFailuresMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleValidateReporters = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateReporters();
    if (result.data?.Failures_Admin_validateReportersIndex.isSuccess) {
      toast.success(t(`notification.reporters_index_validated`));
    } else {
      result.data?.Failures_Admin_validateReportersIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexFailuresPermission = usePermissionMatch(
    "failures.admin.index_failures"
  );
  const [indexFailures] = useIndexFailuresFromFailuresMutation({
    variables: { input: { areaId: area.id } },
    update: clearFailuresCache,
  });
  const handleIndexFailures = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexFailures();
    if (result.data?.Failures_Admin_indexFailures.isSuccess) {
      toast.success(t(`notification.failures_indexed`));
    } else {
      result.data?.Failures_Admin_indexFailures.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexObjectsPermission = usePermissionMatch(
    "failures.admin.index_objects"
  );
  const [indexObjects] = useIndexObjectsFromFailuresMutation({
    variables: { input: { areaId: area.id } },
    update: clearObjectsCache,
  });
  const handleIndexObjects = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexObjects();
    if (result.data?.Failures_Admin_indexObjects.isSuccess) {
      toast.success(t(`notification.objects_indexed`));
    } else {
      result.data?.Failures_Admin_indexObjects.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexReportersPermission = usePermissionMatch(
    "failures.admin.index_reporters"
  );
  const [indexReporters] = useIndexReportersFromFailuresMutation({
    variables: { input: { areaId: area.id } },
    update: clearReportersCache,
  });
  const handleIndexReporters = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexReporters();
    if (result.data?.Failures_Admin_indexReporters.isSuccess) {
      toast.success(t(`notification.reporters_indexed`));
    } else {
      result.data?.Failures_Admin_indexReporters.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  return (
    <Page wide permission="failures.admin.get">
      <Compo.Tabs
        wide
        current={tab}
        onChange={(index) => setTab(index)}
        tabs={[
          {
            icon: Compo.SearchIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasValidateFailuresIndexPermission}
                  onClick={handleValidateFailures}
                >
                  {t("page_administration.validate_failures_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasValidateObjectsIndexPermission}
                  onClick={handleValidateObjects}
                >
                  {t("page_administration.validate_objects_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasValidateReportersIndexPermission}
                  onClick={handleValidateReporters}
                >
                  {t("page_administration.validate_reporters_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasIndexFailuresPermission}
                  onClick={handleIndexFailures}
                >
                  {t("page_administration.index_failures")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasIndexObjectsPermission}
                  onClick={handleIndexObjects}
                >
                  {t("page_administration.index_objects")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasIndexReportersPermission}
                  onClick={handleIndexReporters}
                >
                  {t("page_administration.index_reporters")}
                </Compo.Button>
              </Wrapper>
            ),
          },
        ]}
      />
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem;
`;

export default Administration;
