import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StationJournalsFromWeatherIoTQueryVariables = Types.Exact<{
  input: Types.WeatherIoT_GetStationJournalsInput;
}>;


export type StationJournalsFromWeatherIoTQuery = { __typename?: 'Query', WeatherIoT_stationJournals: { __typename?: 'WeatherIoT_GetStationJournalsPayload', count: number, total: number, items: Array<{ __typename?: 'WeatherIoT_StationJournalRowModel', id: any, version: number, created: any, changed: any, areaId: any, stationId: any, status: { __typename?: 'WeatherIoT_StationJournalStatusModel', communicationDate?: any | null, measurementsDate?: any | null, signalStrength?: number | null, signalToNoiseRatio?: number | null, voltage?: number | null, current?: number | null, airQualityIndex?: number | null } }> } };


export const StationJournalsFromWeatherIoTDocument = gql`
    query stationJournalsFromWeatherIoT($input: WeatherIoT_GetStationJournalsInput!) {
  WeatherIoT_stationJournals(input: $input) {
    count
    total
    items {
      id
      version
      created
      changed
      areaId
      stationId
      status {
        communicationDate
        measurementsDate
        signalStrength
        signalToNoiseRatio
        voltage
        current
        airQualityIndex
      }
    }
  }
}
    `;

/**
 * __useStationJournalsFromWeatherIoTQuery__
 *
 * To run a query within a React component, call `useStationJournalsFromWeatherIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationJournalsFromWeatherIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationJournalsFromWeatherIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStationJournalsFromWeatherIoTQuery(baseOptions: Apollo.QueryHookOptions<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables> & ({ variables: StationJournalsFromWeatherIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables>(StationJournalsFromWeatherIoTDocument, options);
      }
export function useStationJournalsFromWeatherIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables>(StationJournalsFromWeatherIoTDocument, options);
        }
export function useStationJournalsFromWeatherIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables>(StationJournalsFromWeatherIoTDocument, options);
        }
export type StationJournalsFromWeatherIoTQueryHookResult = ReturnType<typeof useStationJournalsFromWeatherIoTQuery>;
export type StationJournalsFromWeatherIoTLazyQueryHookResult = ReturnType<typeof useStationJournalsFromWeatherIoTLazyQuery>;
export type StationJournalsFromWeatherIoTSuspenseQueryHookResult = ReturnType<typeof useStationJournalsFromWeatherIoTSuspenseQuery>;
export type StationJournalsFromWeatherIoTQueryResult = Apollo.QueryResult<StationJournalsFromWeatherIoTQuery, StationJournalsFromWeatherIoTQueryVariables>;