import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PowerBoxesFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetPowerBoxesInput;
}>;


export type PowerBoxesFromLightingQuery = { __typename?: 'Query', Lighting_powerBoxes: { __typename?: 'Lighting_GetPowerBoxesPayload', count: number, items: Array<{ __typename?: 'Lighting_PowerBoxRowModel', id: any, version: number, ordinalNumber: number, areaId: any, name: string, location: { __typename?: 'Lighting_CoordinatesModel', latitude: number, longitude: number } }> } };


export const PowerBoxesFromLightingDocument = gql`
    query powerBoxesFromLighting($input: Lighting_GetPowerBoxesInput!) {
  Lighting_powerBoxes(input: $input) {
    count
    items {
      id
      version
      ordinalNumber
      areaId
      name
      location {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __usePowerBoxesFromLightingQuery__
 *
 * To run a query within a React component, call `usePowerBoxesFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerBoxesFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerBoxesFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePowerBoxesFromLightingQuery(baseOptions: Apollo.QueryHookOptions<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables> & ({ variables: PowerBoxesFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables>(PowerBoxesFromLightingDocument, options);
      }
export function usePowerBoxesFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables>(PowerBoxesFromLightingDocument, options);
        }
export function usePowerBoxesFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables>(PowerBoxesFromLightingDocument, options);
        }
export type PowerBoxesFromLightingQueryHookResult = ReturnType<typeof usePowerBoxesFromLightingQuery>;
export type PowerBoxesFromLightingLazyQueryHookResult = ReturnType<typeof usePowerBoxesFromLightingLazyQuery>;
export type PowerBoxesFromLightingSuspenseQueryHookResult = ReturnType<typeof usePowerBoxesFromLightingSuspenseQuery>;
export type PowerBoxesFromLightingQueryResult = Apollo.QueryResult<PowerBoxesFromLightingQuery, PowerBoxesFromLightingQueryVariables>;