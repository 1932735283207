import React from "react";
import { Outlet } from "react-router-dom";
import { LayerFocusProxy, LayerOverrideProxy } from "@smartly-city/compo-map";
import { LUMINAIRE_LAYER_ID, useLuminaireLayerBuilder } from "../utils/layers";
import {
  LuminaireFiltersProvider,
  useLuminaireFilters,
} from "../contexts/LuminaireFilters";
import { useCurrentArea } from "src/core/contexts";
import { useLuminairesFromLightingIoTLazyQuery } from "src/graphql/__generated__/LightingIoTLuminaires";
import { toLuminaireQueryFilters } from "../utils/filters";
import { useLuminaireJournalsFromLightingIoTLazyQuery } from "src/graphql/__generated__/LightingIoTLuminaireJournals";
import type { MapLayer } from "@smartly-city/compo-map";

const LightingIoTLuminairesProxy: React.FC = () => {
  return (
    <LuminaireFiltersProvider>
      <LightingIoTLuminaireFiltersProxy>
        <LayerFocusProxy layerId={LUMINAIRE_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </LightingIoTLuminaireFiltersProxy>
    </LuminaireFiltersProvider>
  );
};

const LightingIoTLuminaireFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = useLuminaireLayerBuilder();
  const { filters } = useLuminaireFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getLuminaires] = useLuminairesFromLightingIoTLazyQuery();
  const [getLuminaireJournals] = useLuminaireJournalsFromLightingIoTLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data: luminairesData } = await getLuminaires({
      variables: {
        input: { areaId: area.id, filters: toLuminaireQueryFilters(filters) },
      },
    });
    const { data: journalsData } = await getLuminaireJournals({
      variables: {
        input: { areaId: area.id },
      },
    });
    const luminaires = luminairesData?.LightingIoT_luminaires.items ?? [];
    const journals = journalsData?.LightingIoT_luminaireJournals.items ?? [];
    setData(builder(luminaires, journals));
  }, [area.id, filters, getLuminaires, getLuminaireJournals, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={LUMINAIRE_LAYER_ID} data={data}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default LightingIoTLuminairesProxy;
