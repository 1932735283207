import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";

const FailureErrorViewSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <Compo.HeaderSkeleton type="h2" />
      <Compo.LinkTileSkeleton wide />
      <Compo.HeaderSkeleton type="h2" />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
      <Compo.HeaderSkeleton type="h2" />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
      <Compo.HeaderSkeleton type="h2" />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

export default FailureErrorViewSkeleton;
