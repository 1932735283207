import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useFailureByIdFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresFailureById";
import FailureViewSkeleton from "./FailureView.skeleton";
import { useFailuresTranslation } from "../../utils/translation";
import {
  isFailureErrorNew,
  isFailureErrorResolved,
  isFailureNew,
  isFailureResolved,
} from "../../utils/failure";
import type { Failures_FailureModel } from "src/graphql/types";

const FailureView: React.FC = () => (
  <Page permission="failures.failure.get">
    <React.Suspense fallback={<FailureViewSkeleton />}>
      <FailureViewContent />
    </React.Suspense>
  </Page>
);

const FailureViewContent: React.FC = () => {
  const { t } = useFailuresTranslation();
  const navigate = useNavigate();
  const area = useCurrentArea();
  const params = useParams<{ id: string }>();

  const { data } = useFailureByIdFromFailuresSuspenseQuery({
    variables: { input: { id: params.id as string, areaId: area.id } },
  });

  const failure = data.Failures_failureById.failure as Failures_FailureModel;

  if (!failure) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Wrapper>
      <Compo.Header title={failure.objectName}>
        <Compo.Chip
          small
          text={t("failure_state." + failure.state.toLowerCase())}
          variant={
            isFailureNew(failure)
              ? "alert"
              : isFailureResolved(failure)
              ? "success"
              : "default"
          }
        />
      </Compo.Header>
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={t("failure_type." + failure.type.toLowerCase()) ?? ""}
      >
        {t("page_failure_view.failure_type")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={new Date(failure.created).toLocaleDateString()}
      >
        {t("page_failure_view.created")}
      </Compo.ListRow>
      <HeaderWithDistance outline type="h2" title={t("errors")} />
      {failure.errors.map((_error, index) => (
        <Compo.ListRow
          key={index}
          wide
          onClick={() => navigate("error/" + _error.id)}
          buttons={
            <Compo.Chip
              small
              text={t("failure_error_stage." + _error.stage.toLowerCase())}
              variant={
                isFailureErrorNew(_error)
                  ? "alert"
                  : isFailureErrorResolved(_error)
                  ? "success"
                  : "default"
              }
            />
          }
        >
          {t(`failure_error_type.${_error.type.toLowerCase()}`)}
        </Compo.ListRow>
      ))}
      <HeaderWithDistance outline type="h2" title={t("history")} />
      {failure.history
        .toSorted((a, b) =>
          new Date(a.occuredAt) < new Date(b.occuredAt) ? -1 : 1
        )
        .map((item, index: number) => (
          <Compo.ListRow
            key={index}
            wide
            onClick={() => navigate(`history/item/${item.id}`)}
            buttons={<Compo.Chip small text={(index + 1).toString()} />}
          >
            {t(`failure_history_item_type.${item.type.toLowerCase()}`)}
          </Compo.ListRow>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default FailureView;
