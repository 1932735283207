import React from "react";
import Compo, { useMediaQuery, media, colors } from "@smartly-city/compo";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import {
  usePage,
  useCurrentUser,
  useCurrentArea,
  useChangeCurrentArea,
  useApiPermissionMatch,
  usePermissionMatch,
} from "src/core/contexts";
import type { Security_AreaReadModel } from "src/graphql/types";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client";

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const page = usePage();
  const navigate = useNavigate();
  const client = useApolloClient();
  const user = useCurrentUser();
  const area = useCurrentArea();
  const setArea = useChangeCurrentArea();
  const isMobile = useMediaQuery(media.max.md);
  const [open, setOpen] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);

  const isDashboard = useMatch("/dashboard/*");
  const isAreaAdminPermitted = usePermissionMatch("security.area.get");

  const isLightingActive = useMatch("/lighting/*");
  const isLightingEnabled = !!area?.enabledApis.includes("lighting");
  const isLightingPermitted = useApiPermissionMatch("lighting");

  const isLightingIoTActive = useMatch("/lighting-iot/*");
  const isLightingIoTEnabled = !!area?.enabledApis.includes("lighting_iot");
  const isLightingIoTPermitted = useApiPermissionMatch("lighting_iot");

  const isFailuresActive = useMatch("/failures/*");
  const isFailuresEnabled = !!area?.enabledApis.includes("failures");
  const isFailuresPermitted = useApiPermissionMatch("failures");

  const isReportsActive = useMatch("/reports/*");
  const isReportsEnabled = !!area?.enabledApis.includes("reports");
  const isReportsPermitted = useApiPermissionMatch("reports");

  const isTrafficIoTActive = useMatch("/traffic-iot/*");
  const isTrafficIoTEnabled = !!area?.enabledApis.includes("traffic_iot");
  const isTrafficIoTPermitted = useApiPermissionMatch("traffic_iot");

  const isWeatherIoTActive = useMatch("/weather-iot/*");
  const isWeatherIoTEnabled = !!area?.enabledApis.includes("weather_iot");
  const isWeatherIoTPermitted = useApiPermissionMatch("weather_iot");

  const handleClick = (destination: string): void => {
    setOpen(false);
    navigate(destination);
  };

  const handleRefresh = async (): Promise<void> => {
    setRefreshing(true);
    await client.resetStore();
    setTimeout(() => setRefreshing(false), 500);
  };

  return (
    <Compo.Navbar
      logo={<img src="/images/logo-white-vi.png" alt="Logo" />}
      panelLogo={<img src="/images/logo-vi.png" alt="Logo" />}
      title={page.apiTitle}
      open={open}
      setOpen={setOpen}
      buttons={
        <React.Fragment>
          <Compo.Button
            filled={!refreshing}
            disabled={refreshing}
            icon={Compo.RefreshIcon}
            onClick={handleRefresh}
          />
          {isMobile ? (
            <Compo.Button
              filled
              outline={!page.sidebarVisible}
              icon={Compo.MapIcon}
              onClick={() => page.setSidebarVisible(!page.sidebarVisible)}
            />
          ) : undefined}
        </React.Fragment>
      }
    >
      <ContentWrapper>
        <AreaWrapper>
          <Compo.SelectInput
            wide
            id="area-select"
            name="area-select"
            label={t("area")}
            value={area?.id}
            onChange={(id) => {
              navigate("/");
              setArea(
                user.permittedAreas.find(
                  (a) => a.id === id
                ) as Security_AreaReadModel
              );
            }}
          >
            {user.permittedAreas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </Compo.SelectInput>
          {area.id && isAreaAdminPermitted && (
            <AreaIdText>
              {t("area_id")}: {area.id}
            </AreaIdText>
          )}
        </AreaWrapper>
        <Compo.NavbarItem
          wide
          text={t("api_dashboard")}
          icon={Compo.TemplateIcon}
          active={!!isDashboard}
          onClick={() => handleClick("/")}
        />
        <Compo.NavbarItem
          wide
          text={t("api_lighting")}
          icon={Compo.LightBulbIcon}
          onClick={() => handleClick("/lighting")}
          active={!!isLightingActive}
          disabled={!isLightingEnabled || !isLightingPermitted}
        />
        <Compo.NavbarItem
          wide
          text={t("api_lighting_iot")}
          icon={Compo.LightBulbIcon}
          onClick={() => handleClick("/lighting-iot")}
          active={!!isLightingIoTActive}
          disabled={!isLightingIoTEnabled || !isLightingIoTPermitted}
        />
        <Compo.NavbarItem
          wide
          disabled
          text={t("api_sensors_iot")}
          icon={Compo.MeterIcon}
          onClick={() => handleClick("/sensors-iot")}
        />
        <Compo.NavbarItem
          wide
          text={t("api_failures")}
          icon={Compo.ExclamationIcon}
          onClick={() => handleClick("/failures")}
          active={!!isFailuresActive}
          disabled={!isFailuresEnabled || !isFailuresPermitted}
        />
        <Compo.NavbarItem
          wide
          disabled
          text={t("api_alerts")}
          icon={Compo.ShieldCheckIcon}
          onClick={() => handleClick("/alerts")}
        />
        <Compo.NavbarItem
          wide
          text={t("api_reports")}
          icon={Compo.ClipboardCheckIcon}
          onClick={() => handleClick("/reports")}
          active={!!isReportsActive}
          disabled={!isReportsEnabled || !isReportsPermitted}
        />
        <Compo.NavbarItem
          wide
          text={t("api_traffic_iot")}
          icon={Compo.WebCameraIcon}
          onClick={() => handleClick("/traffic-iot")}
          active={!!isTrafficIoTActive}
          disabled={!isTrafficIoTEnabled || !isTrafficIoTPermitted}
        />
        <Compo.NavbarItem
          wide
          text={t("api_weather_iot")}
          icon={Compo.WeatherIcon}
          onClick={() => handleClick("/weather-iot")}
          active={!!isWeatherIoTActive}
          disabled={!isWeatherIoTEnabled || !isWeatherIoTPermitted}
        />
        <Compo.NavbarItem
          wide
          disabled
          text={t("api_waste")}
          icon={Compo.GarbageTruckIcon}
          onClick={() => handleClick("/waste-management")}
        />
        <Compo.NavbarItem
          wide
          disabled
          text={t("api_waste_iot")}
          icon={Compo.GarbageTruckIcon}
          onClick={() => handleClick("/waste-management-iot")}
        />
        <UserWrapper>
          <Compo.NavbarItem
            wide
            text={t("logout")}
            icon={Compo.LogoutIcon}
            onClick={() => handleClick("/logout")}
          />
        </UserWrapper>
      </ContentWrapper>
    </Compo.Navbar>
  );
};

const AreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const AreaIdText = styled.small`
  margin: 0 0.25rem;
  color: ${colors.common.grey};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.75rem;
  padding: 1rem;
  overflow-y: auto;
`;

const UserWrapper = styled.div`
  margin-top: 1rem;
`;

export default Navbar;
