import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import CounterTruckJournalTabDailyCountChartSkeleton from "./charts/CounterTruckJournalTabDailyCountChart.skeleton";
import CounterTruckJournalTabDailySpeedChartSkeleton from "./charts/CounterTruckJournalTabDailySpeedChart.skeleton";
import CounterTruckJournalTabMonthlyCountChartSkeleton from "./charts/CounterTruckJournalTabMonthlyCountChart.skeleton";
import CounterTruckJournalTabMonthlySpeedChartSkeleton from "./charts/CounterTruckJournalTabMonthlySpeedChart.skeleton";

const CounterTruckJournalTabSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <CounterTruckJournalTabDailyCountChartSkeleton />
      <CounterTruckJournalTabDailySpeedChartSkeleton />
      <CounterTruckJournalTabMonthlyCountChartSkeleton />
      <CounterTruckJournalTabMonthlySpeedChartSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CounterTruckJournalTabSkeleton;
