import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StationsFromWeatherIoTQueryVariables = Types.Exact<{
  input: Types.WeatherIoT_GetStationsInput;
}>;


export type StationsFromWeatherIoTQuery = { __typename?: 'Query', WeatherIoT_stations: { __typename?: 'WeatherIoT_GetStationsPayload', count: number, total: number, items: Array<{ __typename?: 'WeatherIoT_StationRowModel', id: any, version: number, areaId: any, name: string, location: { __typename?: 'WeatherIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const StationsFromWeatherIoTDocument = gql`
    query stationsFromWeatherIoT($input: WeatherIoT_GetStationsInput!) {
  WeatherIoT_stations(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      name
      location {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useStationsFromWeatherIoTQuery__
 *
 * To run a query within a React component, call `useStationsFromWeatherIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationsFromWeatherIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationsFromWeatherIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStationsFromWeatherIoTQuery(baseOptions: Apollo.QueryHookOptions<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables> & ({ variables: StationsFromWeatherIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables>(StationsFromWeatherIoTDocument, options);
      }
export function useStationsFromWeatherIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables>(StationsFromWeatherIoTDocument, options);
        }
export function useStationsFromWeatherIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables>(StationsFromWeatherIoTDocument, options);
        }
export type StationsFromWeatherIoTQueryHookResult = ReturnType<typeof useStationsFromWeatherIoTQuery>;
export type StationsFromWeatherIoTLazyQueryHookResult = ReturnType<typeof useStationsFromWeatherIoTLazyQuery>;
export type StationsFromWeatherIoTSuspenseQueryHookResult = ReturnType<typeof useStationsFromWeatherIoTSuspenseQuery>;
export type StationsFromWeatherIoTQueryResult = Apollo.QueryResult<StationsFromWeatherIoTQuery, StationsFromWeatherIoTQueryVariables>;