import React from "react";
import Compo, { useModal } from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import { useNavigate } from "react-router-dom";
import { useTrafficIoTTranslation } from "../../utils/translation";
import CounterList from "../../containers/CounterList/CounterList";
import CounterListSkeleton from "../../containers/CounterList/CounterList.skeleton";
import { usePermissionMatch } from "src/core/contexts";
import { CounterForm } from "../../containers/CounterForm";

const CounterCollection: React.FC = () => {
  const { t } = useTrafficIoTTranslation();
  const navigate = useNavigate();
  const modal = useModal();
  const addPermitted = usePermissionMatch("traffic_iot.counter.add");

  return (
    <Page permission="traffic_iot.counter.get">
      <StyledHeader title={t("page_counter_collection.title")}>
        {addPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </StyledHeader>
      <React.Suspense fallback={<CounterListSkeleton />}>
        <CounterList
          paged
          filtered
          onClick={(counter) => navigate(counter.id)}
        />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <CounterForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  /* margin-bottom: 1.5rem; */
`;

export default CounterCollection;
