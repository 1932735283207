import React from "react";
import Compo, { useModal } from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { LuminaireTypeForm } from "../../containers/LuminaireTypeForm";
import { usePermissionMatch } from "src/core/contexts";
import {
  LuminaireTypeList,
  LuminaireTypeListSkeleton,
} from "./elements/LuminaireTypeList";
import { useLightingTranslation } from "../../utils/translation";

const LuminaireTypeCollection: React.FC = () => {
  const { t } = useLightingTranslation();
  const modal = useModal();
  const createPermitted = usePermissionMatch("lighting.luminaire_type.create");

  return (
    <Page permission="lighting.luminaire_type.get">
      <StyledHeader title={t("page_luminaire_type_collection.title")}>
        {createPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </StyledHeader>
      <React.Suspense fallback={<LuminaireTypeListSkeleton />}>
        <LuminaireTypeList />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireTypeForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default LuminaireTypeCollection;
