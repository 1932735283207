import React from "react";
import type { Lighting_AreaSettingsModel } from "src/graphql/types";

export interface AreaSettingsContextState {
  settings?: Lighting_AreaSettingsModel;
}

export const AreaSettingsContext = React.createContext<
  AreaSettingsContextState | undefined
>(undefined);

export const useAreaSettings = (): Lighting_AreaSettingsModel =>
  React.useContext(AreaSettingsContext)?.settings as Lighting_AreaSettingsModel;
