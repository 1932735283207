import * as yup from "yup";

export const bracketDetailsValidationSchema = yup.object().shape({
  bracketDetails: yup
    .object()
    .nullable()
    .shape({
      bracketLength: yup.number().fromFormik().required().min(0),
      tiltAngle: yup.number().fromFormik().required().min(0).max(45),
      bracketQualifiedForReplacement: yup.boolean().nullable(),
      newBracketLengthAfterReplacement: yup
        .number()
        .fromFormik()
        .min(0)
        .max(45),
      dateOfBracketPainting: yup
        .date()
        .fromFormik()
        .nullable()
        .default(undefined)
        .max(new Date()),
    }),
});
