import React from "react";
import styled from "styled-components";
import Compo, { useDialog, type Types } from "@smartly-city/compo";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useWeatherIoTTranslation } from "../../../../utils/translation";
import { useStationJournalsFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStationJournals";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import type { WeatherIoT_StationModel } from "src/graphql/types";
import { StationDeleteForm } from "src/modules/weather_iot/containers/StationDeleteForm";
import { useStationJournalByIdFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStationJournalById";
import { isEmpty } from "src/core/utils/common";

export interface StationDetailsTabProps {
  station: WeatherIoT_StationModel;
}

const StationDetailsTab: React.FC<StationDetailsTabProps> = (props) => {
  const { t } = useWeatherIoTTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const dialog = useDialog();
  const removePermitted = usePermissionMatch("weather_iot.station.remove");

  const station = props.station;
  const coordinatesString = `${station.location.latitude}, ${station.location.longitude}`;

  const { data: journalRowData } =
    useStationJournalsFromWeatherIoTSuspenseQuery({
      variables: {
        input: {
          areaId: area.id,
          filters: {
            ofStationId: station.id,
          },
        },
      },
    });
  const journalRow = journalRowData.WeatherIoT_stationJournals.items[0];

  const { data: journalData } =
    useStationJournalByIdFromWeatherIoTSuspenseQuery({
      variables: {
        input: {
          id: journalRow.id,
          areaId: area.id,
        },
      },
      skip: !journalRow,
    });
  const journal = journalData?.WeatherIoT_stationJournalById?.journal;

  if (!journalRow || !journal) {
    navigate("/404");
    return <div />;
  }

  const toLocaleDate = (date: string): string => {
    return new Date(date).toLocaleString();
  };

  return (
    <React.Fragment>
      <Compo.Header title={station.name}>
        {removePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => dialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.Header type="h2" title={t("location")} />
      <Compo.LinkTile
        wide
        text={coordinatesString}
        icon={Compo.DocumentDuplicateIcon}
        onClick={async () => {
          await navigator.clipboard.writeText(coordinatesString);
          toast.success(t("notification.coordinates_copied"));
        }}
      />
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow wide description={station.smartlyId}>
        {t("page_station_view.smartly_id")}
      </Compo.ListRow>
      <Separator />
      {!isEmpty(journal.status.communicationDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_station_view.status")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.status.communicationDate)}
          >
            {t("page_station_view.communication_date")}
          </Compo.ListRow>
          {!isEmpty(journal.status.signalStrength) && (
            <Compo.ListRow
              wide
              description={`${journal.status.signalStrength.toString()} RSSI`}
            >
              {t("page_station_view.signal_strength")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.status.signalToNoiseRatio) && (
            <Compo.ListRow
              wide
              description={
                journal.status.signalToNoiseRatio.toString() + " SNR"
              }
            >
              {t("page_station_view.signal_to_noise_ratio")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.status.voltage) && (
            <Compo.ListRow
              wide
              description={journal.status.voltage.toString() + " V"}
            >
              {t("page_station_view.voltage")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.status.current) && (
            <Compo.ListRow
              wide
              description={journal.status.current.toString() + " mA"}
            >
              {t("page_station_view.current")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.status.airQualityIndex) && (
            <Compo.ListRow
              wide
              description={journal.status.airQualityIndex.toString()}
            >
              {t("page_station_view.air_quality_index")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      <Separator />
      {!isEmpty(journal.readings.temperatureDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_station_view.temperature")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.readings.temperatureDate)}
          >
            {t("page_station_view.temperature_date")}
          </Compo.ListRow>
          {!isEmpty(journal.readings.temperatureValue) && (
            <Compo.ListRow
              wide
              description={journal.readings.temperatureValue.toString() + " °C"}
            >
              {t("page_station_view.temperature_value")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {!isEmpty(journal.readings.humidityDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_station_view.humidity")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.readings.humidityDate)}
          >
            {t("page_station_view.humidity_date")}
          </Compo.ListRow>
          {!isEmpty(journal.readings.humidityAbsolute) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.humidityAbsolute.toString() + " g/kg"
              }
            >
              {t("page_station_view.humidity_absolute")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.humidityRelative) && (
            <Compo.ListRow
              wide
              description={journal.readings.humidityRelative.toString() + " %"}
            >
              {t("page_station_view.humidity_relative")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {!isEmpty(journal.readings.pressureDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_station_view.pressure")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.readings.pressureDate)}
          >
            {t("page_station_view.pressure_date")}
          </Compo.ListRow>
          {!isEmpty(journal.readings.pressureValue) && (
            <Compo.ListRow
              wide
              description={journal.readings.pressureValue.toString() + " hPa"}
            >
              {t("page_station_view.pressure_value")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {!isEmpty(journal.readings.ambientLightDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_station_view.ambient_light")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.readings.ambientLightDate)}
          >
            {t("page_station_view.ambient_light_date")}
          </Compo.ListRow>
          {!isEmpty(journal.readings.ambientLightValue) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.ambientLightValue.toString() + " lux"
              }
            >
              {t("page_station_view.ambient_light_value")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {!isEmpty(journal.readings.airQualityDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_station_view.air_quality")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.readings.airQualityDate)}
          >
            {t("page_station_view.air_quality_date")}
          </Compo.ListRow>
          {!isEmpty(journal.readings.airQualityIndex) && (
            <Compo.ListRow
              wide
              buttons={
                <Compo.Chip
                  small
                  text={journal.readings.airQualityIndex.toString() + " AQI"}
                  variant={getAQIChipVariant(journal.readings.airQualityIndex)}
                />
              }
            >
              {t("page_station_view.air_quality_index")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityVolatileOrganicCompounds) && (
            <Compo.ListRow
              wide
              buttons={
                <Compo.Chip
                  small
                  text={
                    journal.readings.airQualityVolatileOrganicCompounds.toString() +
                    " AQI"
                  }
                  variant={getAQIChipVariant(
                    journal.readings.airQualityVolatileOrganicCompounds
                  )}
                />
              }
            >
              {t("page_station_view.air_quality_volatile_organic_compounds")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityCarbonMonoxide) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityCarbonMonoxide.toString() + " ppm"
              }
            >
              {t("page_station_view.air_quality_carbon_monoxide")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityCarbonDioxide) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityCarbonDioxide.toString() + " ppm"
              }
            >
              {t("page_station_view.air_quality_carbon_dioxide")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityOxygen) && (
            <Compo.ListRow
              wide
              description={journal.readings.airQualityOxygen.toString() + " %"}
            >
              {t("page_station_view.air_quality_oxygen")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityOzone) && (
            <Compo.ListRow
              wide
              description={journal.readings.airQualityOzone.toString() + " ppm"}
            >
              {t("page_station_view.air_quality_ozone")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityHydrogenSulphide) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityHydrogenSulphide.toString() + " ppm"
              }
            >
              {t("page_station_view.air_quality_hydrogen_sulphide")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityNitricOxide) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityNitricOxide.toString() + " ppm"
              }
            >
              {t("page_station_view.air_quality_nitric_oxide")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityNitrogenDioxide) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityNitrogenDioxide.toString() + " ppm"
              }
            >
              {t("page_station_view.air_quality_nitrogen_dioxide")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualitySulphurDioxide) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualitySulphurDioxide.toString() + " ppm"
              }
            >
              {t("page_station_view.air_quality_sulphur_dioxide")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM01Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM01Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_0_1")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM03Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM03Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_0_3")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM05Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM05Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_0_5")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM1Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM1Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_1")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM2Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM2Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_2_5")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM4Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM4Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_4")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM5Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM5Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_5")}
            </Compo.ListRow>
          )}
          {!isEmpty(journal.readings.airQualityPM10Concentration) && (
            <Compo.ListRow
              wide
              description={
                journal.readings.airQualityPM10Concentration.toString() +
                " µg/m³"
              }
            >
              {t("page_station_view.air_quality_pm_10")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {dialog.isOpen &&
        dialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <StationDeleteForm
              station={station}
              onClose={() => dialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const getAQIChipVariant = (value: number): keyof Types.ChipTheme => {
  if (value < 50) return "success";
  if (value < 100) return "default";
  return "alert";
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

const Separator = styled.div`
  width: 100%;
  height: 1rem;
`;

export default StationDetailsTab;
