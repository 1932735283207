import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireByIdFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetLuminaireByIdInput;
}>;


export type LuminaireByIdFromLightingQuery = { __typename?: 'Query', Lighting_luminaireById: { __typename?: 'Lighting_GetLuminaireByIdPayload', luminaire?: { __typename?: 'Lighting_LuminaireModel', id: any, version: number, areaId: any, poleId: any, poleName: string, typeId: any, typeName: string, ordinalNumber: number, details: { __typename?: 'Lighting_LuminaireDefaultDetailsModel', fastening: Types.Lighting_FasteningModel, height: number }, maintenanceDetails?: { __typename?: 'Lighting_LuminaireMaintenanceDetailsModel', qualifiedForReplacement: boolean, owner: Types.Lighting_OwnerModel } | null } | null } };


export const LuminaireByIdFromLightingDocument = gql`
    query luminaireByIdFromLighting($input: Lighting_GetLuminaireByIdInput!) {
  Lighting_luminaireById(input: $input) {
    luminaire {
      id
      version
      areaId
      poleId
      poleName
      typeId
      typeName
      ordinalNumber
      details {
        fastening
        height
      }
      maintenanceDetails {
        qualifiedForReplacement
        owner
      }
    }
  }
}
    `;

/**
 * __useLuminaireByIdFromLightingQuery__
 *
 * To run a query within a React component, call `useLuminaireByIdFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireByIdFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireByIdFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireByIdFromLightingQuery(baseOptions: Apollo.QueryHookOptions<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables> & ({ variables: LuminaireByIdFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables>(LuminaireByIdFromLightingDocument, options);
      }
export function useLuminaireByIdFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables>(LuminaireByIdFromLightingDocument, options);
        }
export function useLuminaireByIdFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables>(LuminaireByIdFromLightingDocument, options);
        }
export type LuminaireByIdFromLightingQueryHookResult = ReturnType<typeof useLuminaireByIdFromLightingQuery>;
export type LuminaireByIdFromLightingLazyQueryHookResult = ReturnType<typeof useLuminaireByIdFromLightingLazyQuery>;
export type LuminaireByIdFromLightingSuspenseQueryHookResult = ReturnType<typeof useLuminaireByIdFromLightingSuspenseQuery>;
export type LuminaireByIdFromLightingQueryResult = Apollo.QueryResult<LuminaireByIdFromLightingQuery, LuminaireByIdFromLightingQueryVariables>;