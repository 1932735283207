import React from "react";
import styled from "styled-components";
import Compo from "@smartly-city/compo";
import type {
  Lighting_PoleRoadDetailsModel,
  Lighting_PoleModel,
} from "src/graphql/types";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";

export interface PoleRoadDetailsTabProps {
  pole: Lighting_PoleModel;
}

const PoleRoadDetailsTab: React.FC<PoleRoadDetailsTabProps> = (props) => {
  const { t } = useLightingTranslation();
  const pole = props.pole;
  const roadDetails = pole.roadDetails as Lighting_PoleRoadDetailsModel;

  return (
    <React.Fragment>
      <HeaderWithDistance outline type="h2" title={t("road_details")} />
      <Compo.ListRow
        wide
        description={
          t(`road_type.${roadDetails.roadType.toLowerCase()}`) ?? undefined
        }
      >
        {t("page_pole_view.road_type")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={
          t(`road_surface.${roadDetails.roadSurface.toLowerCase()}`) ??
          undefined
        }
      >
        {t("page_pole_view.road_surface")}
      </Compo.ListRow>
      <Compo.ListRow wide description={roadDetails.roadLightingClass}>
        {t("page_pole_view.road_lighting_class")}
      </Compo.ListRow>
      <Compo.ListRow wide description={`${roadDetails.roadWidth} m`}>
        {t("page_pole_view.road_width")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={`${roadDetails.distanceFromRoadwayEdgeToPole} m`}
      >
        {t("page_pole_view.distance_from_roadway_edge_to_pole")}
      </Compo.ListRow>
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default PoleRoadDetailsTab;
