import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import Navbar from "src/core/containers/Navbar";
import { Outlet } from "react-router-dom";
import { AppProvider, PageProvider } from "src/core/contexts";

const AppProxy: React.FC = () => {
  return (
    <AppProvider>
      <React.Suspense fallback={<Compo.Spinner />}>
        <PageProvider>
          <Navbar />
          <Wrapper>
            <React.Suspense fallback={<Compo.Spinner />}>
              <Outlet />
            </React.Suspense>
          </Wrapper>
        </PageProvider>
      </React.Suspense>
    </AppProvider>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: calc(100% - 5rem);
`;

export default AppProxy;
