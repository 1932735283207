import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateLightingSchedulesIndexFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_Admin_ValidateLightingSchedulesIndexInput;
}>;


export type ValidateLightingSchedulesIndexFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_Admin_validateLightingSchedulesIndex: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const ValidateLightingSchedulesIndexFromLightingIoTDocument = gql`
    mutation validateLightingSchedulesIndexFromLightingIoT($input: LightingIoT_Admin_ValidateLightingSchedulesIndexInput!) {
  LightingIoT_Admin_validateLightingSchedulesIndex(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type ValidateLightingSchedulesIndexFromLightingIoTMutationFn = Apollo.MutationFunction<ValidateLightingSchedulesIndexFromLightingIoTMutation, ValidateLightingSchedulesIndexFromLightingIoTMutationVariables>;

/**
 * __useValidateLightingSchedulesIndexFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useValidateLightingSchedulesIndexFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateLightingSchedulesIndexFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateLightingSchedulesIndexFromLightingIoTMutation, { data, loading, error }] = useValidateLightingSchedulesIndexFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateLightingSchedulesIndexFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<ValidateLightingSchedulesIndexFromLightingIoTMutation, ValidateLightingSchedulesIndexFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateLightingSchedulesIndexFromLightingIoTMutation, ValidateLightingSchedulesIndexFromLightingIoTMutationVariables>(ValidateLightingSchedulesIndexFromLightingIoTDocument, options);
      }
export type ValidateLightingSchedulesIndexFromLightingIoTMutationHookResult = ReturnType<typeof useValidateLightingSchedulesIndexFromLightingIoTMutation>;
export type ValidateLightingSchedulesIndexFromLightingIoTMutationResult = Apollo.MutationResult<ValidateLightingSchedulesIndexFromLightingIoTMutation>;
export type ValidateLightingSchedulesIndexFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<ValidateLightingSchedulesIndexFromLightingIoTMutation, ValidateLightingSchedulesIndexFromLightingIoTMutationVariables>;