import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { usePermissionMatch } from "src/core/contexts";
import { useLightingIoTTranslation } from "../../utils/translation";

const Home: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const navigate = useNavigate();
  const administrationPermitted = usePermissionMatch("lighting_iot.admin.get");
  const luminairePermitted = usePermissionMatch("lighting_iot.luminaire.get");
  const luminaireJobsPermitted = usePermissionMatch(
    "lighting_iot.luminaire_job.get"
  );
  const schedulePermitted = usePermissionMatch(
    "lighting_iot.lighting_schedule.get"
  );

  return (
    <Page>
      <Wrapper>
        <Compo.ModuleTile
          wide
          text={t("luminaires")}
          icon={Compo.LuminaireIcon}
          disabled={!luminairePermitted}
          onClick={() => navigate("/lighting-iot/luminaire")}
        />
        <Compo.ModuleTile
          wide
          text={t("lighting_schedules")}
          icon={Compo.ClockIcon}
          disabled={!schedulePermitted}
          onClick={() => navigate("/lighting-iot/lighting-schedule")}
        />
        <Compo.ModuleTile
          wide
          text={t("luminaire_jobs")}
          icon={Compo.LightingBoltIcon}
          disabled={!luminaireJobsPermitted}
          onClick={() => navigate("/lighting-iot/luminaire-job")}
        />
        {administrationPermitted && (
          <Compo.ModuleTile
            wide
            text={t("administration")}
            icon={Compo.CogIcon}
            onClick={() => navigate("/lighting-iot/admin")}
          />
        )}
      </Wrapper>
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default Home;
