export const daysBetween = (from: Date, to: Date): number => {
  const difference = to.getTime() - from.getTime();
  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const dateStringAsDateTimeString = (
  string: string | null | undefined,
  time: "min" | "max"
): string | null | undefined => {
  if (string === null || string === undefined) return string;
  const timeString = time === "min" ? "00:00:00 Z" : "23:59:59 Z";
  return `${string} ${timeString}`;
};
