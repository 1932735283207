import React from "react";
import Compo from "@smartly-city/compo";
import type { LightingIoT_LuminaireJournalModel } from "src/graphql/types";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import styled from "styled-components";
import { isEmpty } from "src/core/utils/common";

interface LuminaireJournalTabStatusProps {
  journal: LightingIoT_LuminaireJournalModel;
}

const LuminaireJournalTabStatus: React.FC<LuminaireJournalTabStatusProps> = (
  props
) => {
  const { t } = useLightingIoTTranslation();

  return (
    <Wrapper>
      {props.journal.status && (
        <React.Fragment>
          {!isEmpty(props.journal.status.communicationDate) && (
            <Compo.ListRow
              wide
              description={new Date(
                props.journal.status.communicationDate ?? ""
              ).toLocaleString()}
            >
              {t("page_luminaire_view.communication_date")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.energyMeteringDate) && (
            <Compo.ListRow
              wide
              description={new Date(
                props.journal.status.energyMeteringDate ?? ""
              ).toLocaleString()}
            >
              {t("page_luminaire_view.energy_metering_date")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.signalStrength) && (
            <Compo.ListRow
              wide
              description={`${props.journal.status.signalStrength ?? ""} dBm`}
            >
              {t("page_luminaire_view.signal_strength")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.signalToNoiseRatio) && (
            <Compo.ListRow
              wide
              description={`${
                props.journal.status.signalToNoiseRatio ?? ""
              } dB`}
            >
              {t("page_luminaire_view.signal_to_noise_ratio")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.temperature) && (
            <Compo.ListRow
              wide
              description={`${props.journal.status.temperature ?? ""} °C`}
            >
              {t("page_luminaire_view.temperature")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.consumption) && (
            <Compo.ListRow
              wide
              description={`${props.journal.status.consumption ?? ""} W`}
            >
              {t("page_luminaire_view.consumption")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.powerFactor) && (
            <Compo.ListRow
              wide
              description={`${props.journal.status.powerFactor ?? ""}`}
            >
              {t("page_luminaire_view.power_factor")}
            </Compo.ListRow>
          )}
          {!isEmpty(props.journal.status.voltage) && (
            <Compo.ListRow
              wide
              description={`${props.journal.status.voltage ?? ""} V`}
            >
              {t("page_luminaire_view.voltage")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default LuminaireJournalTabStatus;
