import React from "react";
import type { Security_AreaReadModel } from "src/graphql/types";

export interface AppContextState {
  user: CurrentUser;
  area?: Security_AreaReadModel;
  setArea: (area: Security_AreaReadModel) => void;
}

export interface CurrentUser {
  id: string;
  version: number;
  permissions: string[];
  permittedAreas: Security_AreaReadModel[];
}

export const AppContext = React.createContext<AppContextState | undefined>(
  undefined
);

export const useEnabledApiMatch = (apiName: string): boolean | undefined => {
  const appState = React.useContext(AppContext);
  return appState?.area?.enabledApis.includes(apiName);
};

export const useApiPermissionMatch = (
  apiName: string,
  scope?: string
): boolean => {
  const appState = React.useContext(AppContext);
  if (!appState) {
    return false;
  }

  const globalPermissionRegex = new RegExp(
    `^${apiName}\\.${scope ?? ".*"}\\.*`
  );
  const hasGlobalPermission = appState.user.permissions.some((permission) =>
    permission.match(globalPermissionRegex)
  );

  if (hasGlobalPermission) {
    return true;
  }

  if (appState.area) {
    const areaPermissionRegex = new RegExp(
      `^${appState.area.id}\\.${apiName}\\.*.`
    );
    const hasAreaPermission =
      areaPermissionRegex &&
      appState.user.permissions.some((permission) =>
        permission.match(areaPermissionRegex)
      );

    return hasAreaPermission;
  }

  return false;
};

export const usePermissionMatch = (permission: string): boolean => {
  const appState = React.useContext(AppContext);
  if (!appState) {
    return false;
  }

  return (
    appState.user.permissions.includes(permission) ||
    (appState.area
      ? appState.user.permissions.includes(appState.area.id + "." + permission)
      : false)
  );
};

export const useCurrentArea = (): Security_AreaReadModel => {
  const appState = React.useContext(AppContext);
  return (appState as AppContextState).area as Security_AreaReadModel;
};

export const useChangeCurrentArea = (): ((
  area: Security_AreaReadModel
) => void) => {
  const appState = React.useContext(AppContext);
  return (appState as AppContextState).setArea;
};

export const useCurrentUser = (): CurrentUser => {
  const appState = React.useContext(AppContext);
  return (appState as AppContextState).user;
};
