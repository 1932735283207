import React from "react";
import { Outlet, Route } from "react-router-dom";
import FailuresMapProxy from "../proxies/FailuresMapProxy";
import { FailureView } from "../pages/FailureView";
import { FailureCollection } from "../pages/FailureCollection";
import { Administration } from "../pages/Administration";
import { FailureErrorView } from "../pages/FailureErrorView";
import { FailureHistoryItemView } from "../pages/FailureHistoryItemView";
import FailuresFailuresProxy from "../proxies/FailuresFailuresProxy";
import FailuresFailuresItemProxy from "../proxies/FailuresFailuresItemProxy";

const routes = (
  <Route element={<FailuresMapProxy />}>
    <Route path="failures" element={<Outlet />}>
      <Route element={<FailuresFailuresProxy />}>
        <Route index element={<FailureCollection />} />
        <Route path="admin" element={<Administration />} />
        <Route path="failure" element={<Outlet />}>
          <Route element={<FailuresFailuresItemProxy />}>
            <Route path=":id" element={<Outlet />}>
              <Route index element={<FailureView />} />
              <Route path="error" element={<Outlet />}>
                <Route path=":errorId" element={<FailureErrorView />} />
              </Route>
              <Route path="history" element={<Outlet />}>
                <Route path="item" element={<Outlet />}>
                  <Route
                    path=":historyItemId"
                    element={<FailureHistoryItemView />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

export default routes;
