import React from "react";
import styled from "styled-components";
import Compo, { useModal, useDialog } from "@smartly-city/compo";
import { toast } from "react-toastify";
import { usePermissionMatch } from "src/core/contexts";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import type { LightingIoT_LuminaireModel } from "src/graphql/types";
import { LuminaireControllerDeleteForm } from "src/modules/lighting_iot/containers/LuminaireControllerDeleteForm";
import { LuminaireControllerForm } from "src/modules/lighting_iot/containers/LuminaireControllerForm";

export interface LuminaireDetailsTabProps {
  luminaire: LightingIoT_LuminaireModel;
}

const LuminaireDetailsTab: React.FC<LuminaireDetailsTabProps> = (props) => {
  const { t } = useLightingIoTTranslation();
  const addControllerModal = useModal();
  const removeControllerDialog = useDialog();
  const addControllerPermitted = usePermissionMatch(
    "lighting_iot.luminaire.add_controller"
  );
  const removeControllerPermitted = usePermissionMatch(
    "lighting_iot.luminaire.remove_controller"
  );

  const luminaire = props.luminaire;
  const coordinatesString = `${luminaire?.location.latitude}, ${luminaire?.location.longitude}`;

  return (
    <React.Fragment>
      <Compo.Header title={luminaire.name} />
      <Compo.Header type="h2" title={t("location")} />
      <Compo.LinkTile
        wide
        text={coordinatesString}
        icon={Compo.DocumentDuplicateIcon}
        onClick={async () => {
          await navigator.clipboard.writeText(coordinatesString);
          toast.success(t("notification.coordinates_copied"));
        }}
      />
      <HeaderWithDistance outline type="h2" title={t("controller")}>
        {addControllerPermitted && !luminaire.controller && (
          <Compo.Button
            small
            filled
            icon={Compo.PlusIcon}
            onClick={() => addControllerModal.setIsOpen(true)}
          />
        )}
        {removeControllerPermitted && luminaire.controller && (
          <Compo.Button
            small
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => removeControllerDialog.setIsOpen(true)}
          />
        )}
      </HeaderWithDistance>
      {luminaire.controller ? (
        <Compo.ListRow
          wide
          key={luminaire.controller.id}
          description={luminaire.controller.id}
        >
          {t("id")}
        </Compo.ListRow>
      ) : (
        <Compo.ListRowEmpty wide text={t("message.no_controller")} />
      )}
      {addControllerModal.isOpen &&
        addControllerModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireControllerForm
              luminaire={luminaire}
              onClose={() => addControllerModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {removeControllerDialog.isOpen &&
        luminaire.controller &&
        removeControllerDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireControllerDeleteForm
              luminaire={luminaire}
              controller={luminaire.controller}
              onClose={() => removeControllerDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default LuminaireDetailsTab;
