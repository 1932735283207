import React from "react";
import Compo from "@smartly-city/compo";
import { useFormikContext } from "formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import type { PowerBoxFormContext } from "../../PowerBoxForm";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";

const PowerBoxFormDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PowerBoxFormContext>();

  return (
    <React.Fragment>
      <Compo.TextInput
        wide
        type="number"
        unit="kW"
        attributes={{ step: "0.01" }}
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.power")}
        label={t("form_power_box.power")}
        value={toStringValue(formik.values.details?.power)}
        onChange={setFloatFieldValue(formik, "details.power")}
      />
      <Compo.TextInput
        wide
        type="number"
        unit="kW"
        attributes={{ step: "0.01" }}
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.contractedPower")}
        label={t("form_power_box.contracted_power")}
        value={toStringValue(formik.values.details?.contractedPower)}
        onChange={setFloatFieldValue(formik, "details.contractedPower")}
      />
      <Compo.TextInput
        wide
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.fuseType")}
        label={t("form_power_box.fuse_type")}
        value={toStringValue(formik.values.details?.fuseType)}
        onChange={setTextFieldValue(formik, "details.fuseType")}
      />
      <Compo.TextInput
        wide
        type="number"
        unit="A"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.fuseAmpRating")}
        label={t("form_power_box.fuse_amp_rating")}
        value={toStringValue(formik.values.details?.fuseAmpRating)}
        onChange={setFloatFieldValue(formik, "details.fuseAmpRating")}
      />
      <Compo.TextInput
        wide
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.recipientCode")}
        label={t("form_power_box.recipient_code")}
        value={toStringValue(formik.values.details?.recipientCode)}
        onChange={setTextFieldValue(formik, "details.recipientCode")}
      />
      <Compo.TextInput
        wide
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.notes")}
        label={t("form_power_box.notes")}
        value={toStringValue(formik.values.details?.notes)}
        onChange={setTextFieldValue(formik, "details.notes")}
      />
    </React.Fragment>
  );
};

export default PowerBoxFormDetailsInputs;
