import React from "react";
import ReactDOM from "react-dom/client";
import Compo from "@smartly-city/compo";
import TagManager from "react-gtm-module";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import mapboxgl from "mapbox-gl";
import { client } from "./apollo";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "@smartly-city/compo/dist/esm/index.css";
import "@smartly-city/compo-map/dist/esm/index.css";
import { CompoMapProvider } from "@smartly-city/compo-map";
import i18n from "./i18n";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Compo.Provider>
      <CompoMapProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <App />
          <ToastContainer position="bottom-left" />
        </ApolloProvider>
      </CompoMapProvider>
    </Compo.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
