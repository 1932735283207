import React from "react";
import { useSearchParams } from "react-router-dom";

export interface Pagination {
  page: number;
  setPage: (page: number) => void;
}

export const usePagination = (key: string = "p"): Pagination => {
  const [params, setParams] = useSearchParams();

  const getCurrentPage = React.useCallback((): number => {
    return parseInt(params.get(key) ?? "1");
  }, [params, key]);

  const [page, setPage] = React.useState(getCurrentPage());

  React.useEffect(() => {
    const current = getCurrentPage();
    if (page !== current) {
      setPage(current);
    }
  }, [page, getCurrentPage]);

  return {
    page,
    setPage: (page: number) => {
      params.set(key, page.toString());
      setParams(params);
    },
  };
};
