import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import type {
  Lighting_PoleSidewalkDetailsModel,
  Lighting_PoleModel,
} from "src/graphql/types";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";

export interface PoleSidewalkDetailsTabProps {
  pole: Lighting_PoleModel;
}

const PoleSidewalkDetailsTab: React.FC<PoleSidewalkDetailsTabProps> = (
  props
) => {
  const { t } = useLightingTranslation();
  const pole = props.pole;
  const sidewalkDetails =
    pole.sidewalkDetails as Lighting_PoleSidewalkDetailsModel;

  return (
    <React.Fragment>
      <HeaderWithDistance outline type="h2" title={t("sidewalk_details")} />
      <Compo.ListRow
        wide
        description={
          t(`sidewalk_side.${sidewalkDetails.sidewalkSide.toLowerCase()}`) ??
          undefined
        }
      >
        {t("page_pole_view.sidewalk_side")}
      </Compo.ListRow>
      <Compo.ListRow wide description={`${sidewalkDetails.widthOfSidewalk} m`}>
        {t("page_pole_view.width_of_sidewalk")}
      </Compo.ListRow>
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default PoleSidewalkDetailsTab;
