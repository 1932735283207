import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexLightingSchedulesFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_Admin_IndexLightingSchedulesInput;
}>;


export type IndexLightingSchedulesFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_Admin_indexLightingSchedules: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const IndexLightingSchedulesFromLightingIoTDocument = gql`
    mutation indexLightingSchedulesFromLightingIoT($input: LightingIoT_Admin_IndexLightingSchedulesInput!) {
  LightingIoT_Admin_indexLightingSchedules(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type IndexLightingSchedulesFromLightingIoTMutationFn = Apollo.MutationFunction<IndexLightingSchedulesFromLightingIoTMutation, IndexLightingSchedulesFromLightingIoTMutationVariables>;

/**
 * __useIndexLightingSchedulesFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useIndexLightingSchedulesFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexLightingSchedulesFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexLightingSchedulesFromLightingIoTMutation, { data, loading, error }] = useIndexLightingSchedulesFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexLightingSchedulesFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<IndexLightingSchedulesFromLightingIoTMutation, IndexLightingSchedulesFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexLightingSchedulesFromLightingIoTMutation, IndexLightingSchedulesFromLightingIoTMutationVariables>(IndexLightingSchedulesFromLightingIoTDocument, options);
      }
export type IndexLightingSchedulesFromLightingIoTMutationHookResult = ReturnType<typeof useIndexLightingSchedulesFromLightingIoTMutation>;
export type IndexLightingSchedulesFromLightingIoTMutationResult = Apollo.MutationResult<IndexLightingSchedulesFromLightingIoTMutation>;
export type IndexLightingSchedulesFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<IndexLightingSchedulesFromLightingIoTMutation, IndexLightingSchedulesFromLightingIoTMutationVariables>;