import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import LuminaireJournalTabDailyEnergyConsumptionChartSkeleton from "./charts/LuminaireJournalTabDailyEnergyConsumptionChart.skeleton";
import LuminaireJournalTabMonthlyEnergyConsumptionChartSkeleton from "./charts/LuminaireJournalTabMonthlyEnergyConsumptionChart.skeleton";
import LuminaireJournalTabMonthlyWorkTimeChartSkeleton from "./charts/LuminaireJournalTabMonthlyWorkTimeChart.skeleton";

const LuminaireJournalTabSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <LuminaireJournalTabDailyEnergyConsumptionChartSkeleton />
      <LuminaireJournalTabMonthlyEnergyConsumptionChartSkeleton />
      <LuminaireJournalTabMonthlyWorkTimeChartSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default LuminaireJournalTabSkeleton;
