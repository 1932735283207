import React from "react";
import Compo from "@smartly-city/compo";
import {
  Lighting_RoadLightingClassModel,
  Lighting_RoadSurfaceModel,
  Lighting_RoadTypeModel,
} from "src/graphql/types";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { useFormikContext } from "formik";
import type { PoleFormContext } from "../../PoleForm";

const PoleFormRoadDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PoleFormContext>();

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="road-type"
        name="road-type"
        disabled={formik.isSubmitting}
        label={t("form_pole.road_type")}
        error={getFieldError(formik, "roadDetails.roadType")}
        value={toStringValue(formik.values.roadDetails?.roadType)}
        onChange={setTextFieldValue(formik, "roadDetails.roadType")}
      >
        <option value="" />
        {Object.values(Lighting_RoadTypeModel).map((type) => (
          <option key={type} value={type}>
            {t(`road_type.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.SelectInput
        wide
        id="road-surface"
        name="road-surface"
        disabled={formik.isSubmitting}
        label={t("form_pole.road_surface")}
        error={getFieldError(formik, "roadDetails.roadSurface")}
        value={toStringValue(formik.values.roadDetails?.roadSurface)}
        onChange={setTextFieldValue(formik, "roadDetails.roadSurface")}
      >
        <option value="" />
        {Object.values(Lighting_RoadSurfaceModel).map((type) => (
          <option key={type} value={type}>
            {t(`road_surface.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.SelectInput
        wide
        id="road-lighting-class"
        name="road-lighting-class"
        disabled={formik.isSubmitting}
        label={t("form_pole.road_lighting_class")}
        error={getFieldError(formik, "roadDetails.roadLightingClass")}
        value={toStringValue(formik.values.roadDetails?.roadLightingClass)}
        onChange={setTextFieldValue(formik, "roadDetails.roadLightingClass")}
      >
        <option value="" />
        {Object.values(Lighting_RoadLightingClassModel).map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.TextInput
        wide
        id="road-width"
        name="road-width"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "roadDetails.roadWidth")}
        label={t("form_pole.road_width")}
        value={toStringValue(formik.values.roadDetails?.roadWidth)}
        onChange={setFloatFieldValue(formik, "roadDetails.roadWidth")}
      />
      <Compo.TextInput
        wide
        id="distance-from-roadway-edge-to-pole"
        name="distance-from-roadway-edge-to-pole"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        error={getFieldError(
          formik,
          "roadDetails.distanceFromRoadwayEdgeToPole"
        )}
        label={t("form_pole.distance_from_roadway_edge_to_pole")}
        value={toStringValue(
          formik.values.roadDetails?.distanceFromRoadwayEdgeToPole
        )}
        onChange={setFloatFieldValue(
          formik,
          "roadDetails.distanceFromRoadwayEdgeToPole"
        )}
      />
    </React.Fragment>
  );
};

export default PoleFormRoadDetailsInputs;
