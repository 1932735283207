import React from "react";
import type { MapLayer } from "@smartly-city/compo-map";
import type { Failures_FailureRowModel } from "src/graphql/types";

export const FAILURE_LAYER_ID = "failure";

export const useFailureLayerBuilder = (): ((
  failures: Failures_FailureRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (failures) => ({
      id: FAILURE_LAYER_ID,
      color: "#FF3A3A",
      items: failures.map((failure) => ({
        id: failure.id,
        coordinates: failure.objectLocation,
        name: failure.objectName,
        color: failure.active ? "#FF3A3A" : "#3CB56C",
      })),
    }),
    []
  );
};
