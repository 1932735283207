import React from "react";
import Compo from "@smartly-city/compo";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import type {
  Lighting_AreaModel,
  Lighting_AreaSettingsModel,
} from "src/graphql/types";
import { useUpdateAreaSettingsFromLightingMutation } from "src/graphql/__generated__/LightingUpdateAreaSettings";
import { toast } from "react-toastify";
import { setBooleanFieldValue } from "src/core/utils/formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";

export interface AreaSettingsFormProps {
  onClose: () => void;
  areaSettings: Lighting_AreaModel;
}

const AreaSettingsForm: React.FC<AreaSettingsFormProps> = (props) => {
  const { t } = useLightingTranslation();

  const [update] = useUpdateAreaSettingsFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_updateAreaSettings.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_areaById",
      });
      cache.gc();
    },
  });

  const handleSubmit = async (
    values: Lighting_AreaSettingsModel
  ): Promise<void> => {
    const newSettings = {
      areaId: props.areaSettings.id,
      requireMaintenanceDetails: values.requireMaintenanceDetails,
      requireRoadDetails: values.requireRoadDetails,
    };

    const { data } = await update({
      variables: {
        input: newSettings,
      },
    });

    const result = data?.Lighting_updateAreaSettings;
    if (result?.isSuccess) {
      toast.success(t(`notification.area_settings_updated`));
      props.onClose();
    } else {
      result?.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
  };

  const formik = useFormik<Lighting_AreaSettingsModel>({
    initialValues: props.areaSettings.settings,
    validationSchema: yup.object().shape({
      requireMaintenanceDetails: yup.boolean(),
      requireRoadDetails: yup.boolean(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={<Compo.Header title={t("form_area_settings.edit_title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.Toggle
            wide
            checked={formik.values.requireMaintenanceDetails ?? false}
            onChange={setBooleanFieldValue(formik, "requireMaintenanceDetails")}
            label={
              t("form_area_settings.require_maintenance_details") ?? undefined
            }
          />
          <Compo.Toggle
            wide
            checked={formik.values.requireRoadDetails ?? false}
            onChange={setBooleanFieldValue(formik, "requireRoadDetails")}
            label={t("form_area_settings.require_road_details") ?? ""}
          />
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default AreaSettingsForm;
