export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  LightingIoT_TimeSpan: { input: string; output: string; }
  ObjMap: { input: any; output: any; }
  UUID: { input: string; output: string; }
  UnsignedInt: { input: number; output: number; }
};

export type Failures_ActivateExternalUserReporterInput = {
  areaId: Scalars['UUID']['input'];
  reporterId: Scalars['UUID']['input'];
  reporterVersion: Scalars['Int']['input'];
};

export type Failures_AddExternalUserReporterInput = {
  areaId: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
};

export type Failures_AddExternalUserReporterPayload = {
  __typename?: 'Failures_AddExternalUserReporterPayload';
  reporterId: Scalars['UUID']['output'];
};

export type Failures_Admin_IndexFailuresInput = {
  areaId: Scalars['UUID']['input'];
};

export type Failures_Admin_IndexObjectsInput = {
  areaId: Scalars['UUID']['input'];
};

export type Failures_Admin_IndexReportersInput = {
  areaId: Scalars['UUID']['input'];
};

export type Failures_Admin_ValidateFailuresIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type Failures_Admin_ValidateObjectsIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type Failures_Admin_ValidateReportersIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export enum Failures_ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Failures_CoordinatesModel = {
  __typename?: 'Failures_CoordinatesModel';
  hash?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type Failures_DeactivateExternalUserReporterInput = {
  areaId: Scalars['UUID']['input'];
  reporterId: Scalars['UUID']['input'];
  reporterVersion: Scalars['Int']['input'];
};

export enum Failures_ExternalUserReportedFlagModel {
  BranchesGrowIntoPowerLine = 'BRANCHES_GROW_INTO_POWER_LINE',
  BrokenCable = 'BROKEN_CABLE',
  DamagedLuminaire = 'DAMAGED_LUMINAIRE',
  DamagedPole = 'DAMAGED_POLE',
  DirtyLuminaire = 'DIRTY_LUMINAIRE',
  LuminaireLightsUpTooLateOrTooEarly = 'LUMINAIRE_LIGHTS_UP_TOO_LATE_OR_TOO_EARLY',
  LuminaireLitAtDaytime = 'LUMINAIRE_LIT_AT_DAYTIME',
  LuminaireNotLitInNighttime = 'LUMINAIRE_NOT_LIT_IN_NIGHTTIME',
  LuminaireObstructedByBranches = 'LUMINAIRE_OBSTRUCTED_BY_BRANCHES',
  LuminaireTurnsOnAndOff = 'LUMINAIRE_TURNS_ON_AND_OFF',
  MissingPoleDoors = 'MISSING_POLE_DOORS'
}

export type Failures_FailureErrorDescriptionModel = {
  __typename?: 'Failures_FailureErrorDescriptionModel';
  howWasResolved?: Maybe<Scalars['String']['output']>;
  whatSparePartsWereUsed?: Maybe<Scalars['String']['output']>;
  whatWasTheReason?: Maybe<Scalars['String']['output']>;
};

export enum Failures_FailureErrorGroupModel {
  Communication = 'COMMUNICATION',
  Environment = 'ENVIRONMENT',
  Infrastructure = 'INFRASTRUCTURE',
  Operational = 'OPERATIONAL'
}

export type Failures_FailureErrorModel = {
  __typename?: 'Failures_FailureErrorModel';
  description?: Maybe<Failures_FailureErrorDescriptionModel>;
  group: Failures_FailureErrorGroupModel;
  id: Scalars['UUID']['output'];
  stage: Failures_FailureErrorStageModel;
  type: Failures_FailureErrorTypeModel;
};

export enum Failures_FailureErrorStageModel {
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Resolved = 'RESOLVED'
}

export enum Failures_FailureErrorTypeModel {
  BranchesGrowIntoPowerLine = 'BRANCHES_GROW_INTO_POWER_LINE',
  BrokenCable = 'BROKEN_CABLE',
  ControllerConnection = 'CONTROLLER_CONNECTION',
  ControllerMalfunction = 'CONTROLLER_MALFUNCTION',
  DamagedLuminaire = 'DAMAGED_LUMINAIRE',
  DamagedPole = 'DAMAGED_POLE',
  DirtyLuminaire = 'DIRTY_LUMINAIRE',
  InvalidTime = 'INVALID_TIME',
  LuminaireLightsUpTooLateOrTooEarly = 'LUMINAIRE_LIGHTS_UP_TOO_LATE_OR_TOO_EARLY',
  LuminaireLitAtDaytime = 'LUMINAIRE_LIT_AT_DAYTIME',
  LuminaireMalfunction = 'LUMINAIRE_MALFUNCTION',
  LuminaireNotLitInNighttime = 'LUMINAIRE_NOT_LIT_IN_NIGHTTIME',
  LuminaireObstructedByBranches = 'LUMINAIRE_OBSTRUCTED_BY_BRANCHES',
  LuminaireTurnsOnAndOff = 'LUMINAIRE_TURNS_ON_AND_OFF',
  MeteringMalfunction = 'METERING_MALFUNCTION',
  MissingPoleDoors = 'MISSING_POLE_DOORS',
  NoCommunication = 'NO_COMMUNICATION',
  PowerSupplyMalfunction = 'POWER_SUPPLY_MALFUNCTION',
  Unknown = 'UNKNOWN',
  UnstableCommunication = 'UNSTABLE_COMMUNICATION',
  WrongPower = 'WRONG_POWER'
}

export type Failures_FailureFiltersModelInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  matchesObjectName?: InputMaybe<Scalars['String']['input']>;
  maxCreatedDate?: InputMaybe<Scalars['Date']['input']>;
  minCreatedDate?: InputMaybe<Scalars['Date']['input']>;
  ofErrorGroup?: InputMaybe<Failures_FailureErrorGroupModel>;
  ofErrorId?: InputMaybe<Scalars['UUID']['input']>;
  ofErrorStage?: InputMaybe<Failures_FailureErrorStageModel>;
  ofErrorType?: InputMaybe<Failures_FailureErrorTypeModel>;
  ofObjectId?: InputMaybe<Scalars['UUID']['input']>;
  ofObjectName?: InputMaybe<Scalars['String']['input']>;
  ofState?: InputMaybe<Failures_FailureStateModel>;
  ofType?: InputMaybe<Failures_FailureTypeModel>;
};

export type Failures_FailureHistoryItemModel = {
  __typename?: 'Failures_FailureHistoryItemModel';
  errorId?: Maybe<Scalars['UUID']['output']>;
  errorType?: Maybe<Failures_FailureErrorTypeModel>;
  id: Scalars['UUID']['output'];
  occuredAt: Scalars['DateTime']['output'];
  reporterId?: Maybe<Scalars['UUID']['output']>;
  type: Failures_FailureHistoryItemTypeModel;
};

export enum Failures_FailureHistoryItemTypeModel {
  ErrorDescribed = 'ERROR_DESCRIBED',
  ErrorRejected = 'ERROR_REJECTED',
  ErrorReported = 'ERROR_REPORTED',
  ErrorResolved = 'ERROR_RESOLVED',
  MaintenanceRequested = 'MAINTENANCE_REQUESTED',
  MaintenanceStarted = 'MAINTENANCE_STARTED'
}

export type Failures_FailureModel = {
  __typename?: 'Failures_FailureModel';
  active: Scalars['Boolean']['output'];
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  errors: Array<Failures_FailureErrorModel>;
  history: Array<Failures_FailureHistoryItemModel>;
  id: Scalars['UUID']['output'];
  objectId: Scalars['UUID']['output'];
  objectLocation: Failures_CoordinatesModel;
  objectName: Scalars['String']['output'];
  state: Failures_FailureStateModel;
  type: Failures_FailureTypeModel;
  version: Scalars['Int']['output'];
};

export type Failures_FailureRowModel = {
  __typename?: 'Failures_FailureRowModel';
  active: Scalars['Boolean']['output'];
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  objectId: Scalars['UUID']['output'];
  objectLocation: Failures_CoordinatesModel;
  objectName: Scalars['String']['output'];
  state: Failures_FailureStateModel;
  type: Failures_FailureTypeModel;
  version: Scalars['Int']['output'];
};

export enum Failures_FailureStateModel {
  Active = 'ACTIVE',
  InMaintenance = 'IN_MAINTENANCE',
  NeedsMaintenance = 'NEEDS_MAINTENANCE',
  Rejected = 'REJECTED',
  Resolved = 'RESOLVED'
}

export enum Failures_FailureTypeModel {
  Manual = 'MANUAL',
  System = 'SYSTEM'
}

export type Failures_GetFailureByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Failures_GetFailureByIdPayload = {
  __typename?: 'Failures_GetFailureByIdPayload';
  failure?: Maybe<Failures_FailureModel>;
};

export type Failures_GetFailuresInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Failures_FailureFiltersModelInput>;
};

export type Failures_GetFailuresPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Failures_FailureFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Failures_GetFailuresPagedPayload = {
  __typename?: 'Failures_GetFailuresPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Failures_FailureRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Failures_GetFailuresPayload = {
  __typename?: 'Failures_GetFailuresPayload';
  count: Scalars['Int']['output'];
  items: Array<Failures_FailureRowModel>;
  total: Scalars['Int']['output'];
};

export type Failures_GetObjectByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Failures_GetObjectByIdPayload = {
  __typename?: 'Failures_GetObjectByIdPayload';
  object?: Maybe<Failures_ObjectModel>;
};

export type Failures_GetObjectsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Failures_ObjectFiltersModelInput>;
};

export type Failures_GetObjectsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Failures_ObjectFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Failures_GetObjectsPagedPayload = {
  __typename?: 'Failures_GetObjectsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Failures_ObjectRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Failures_GetObjectsPayload = {
  __typename?: 'Failures_GetObjectsPayload';
  count: Scalars['Int']['output'];
  items: Array<Failures_ObjectRowModel>;
  total: Scalars['Int']['output'];
};

export type Failures_GetReporterByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Failures_GetReporterByIdPayload = {
  __typename?: 'Failures_GetReporterByIdPayload';
  reporter?: Maybe<Failures_ReporterModel>;
};

export type Failures_GetReportersInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Failures_ReporterFiltersModelInput>;
};

export type Failures_GetReportersPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Failures_ReporterFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Failures_GetReportersPagedPayload = {
  __typename?: 'Failures_GetReportersPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Failures_ReporterRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Failures_GetReportersPayload = {
  __typename?: 'Failures_GetReportersPayload';
  count: Scalars['Int']['output'];
  items: Array<Failures_ReporterRowModel>;
  total: Scalars['Int']['output'];
};

export type Failures_GetStatisticsInput = {
  areaId: Scalars['UUID']['input'];
};

export type Failures_GetStatisticsPayload = {
  __typename?: 'Failures_GetStatisticsPayload';
  statistics: Failures_StatisticsModel;
};

export type Failures_GraphQlError = {
  __typename?: 'Failures_GraphQLError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Array<Failures_KeyValuePairOfStringAndString>;
};

export type Failures_GraphQlResult = {
  __typename?: 'Failures_GraphQLResult';
  created: Scalars['DateTime']['output'];
  errors: Array<Failures_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Failures_GraphQlResultOfAddExternalUserReporterPayload = {
  __typename?: 'Failures_GraphQLResultOfAddExternalUserReporterPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Failures_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Failures_AddExternalUserReporterPayload>;
};

export type Failures_KeyValuePairOfStringAndString = {
  __typename?: 'Failures_KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Failures_ObjectFiltersModelInput = {
  matchesName?: InputMaybe<Scalars['String']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
  ofType?: InputMaybe<Failures_ObjectTypeModel>;
};

export type Failures_ObjectModel = {
  __typename?: 'Failures_ObjectModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  location: Failures_CoordinatesModel;
  name: Scalars['String']['output'];
  type: Failures_ObjectTypeModel;
  version: Scalars['Int']['output'];
};

export type Failures_ObjectRowModel = {
  __typename?: 'Failures_ObjectRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: Failures_CoordinatesModel;
  name: Scalars['String']['output'];
  type: Failures_ObjectTypeModel;
  version: Scalars['Int']['output'];
};

export enum Failures_ObjectTypeModel {
  Luminaire = 'LUMINAIRE'
}

export type Failures_RejectManualFailureErrorInput = {
  areaId: Scalars['UUID']['input'];
  errorId: Scalars['UUID']['input'];
  failureId: Scalars['UUID']['input'];
  failureVersion: Scalars['Int']['input'];
};

export type Failures_ReportFlagAsExternalUserInput = {
  areaId: Scalars['UUID']['input'];
  externalUserId: Scalars['UUID']['input'];
  flag: Failures_ExternalUserReportedFlagModel;
  objectId: Scalars['UUID']['input'];
};

export type Failures_ReporterFiltersModelInput = {
  matchesEmail?: InputMaybe<Scalars['String']['input']>;
  matchesObjectName?: InputMaybe<Scalars['String']['input']>;
  matchesRoutingKey?: InputMaybe<Scalars['String']['input']>;
  maxCreatedDate?: InputMaybe<Scalars['Date']['input']>;
  minCreatedDate?: InputMaybe<Scalars['Date']['input']>;
  ofEmail?: InputMaybe<Scalars['String']['input']>;
  ofObjectId?: InputMaybe<Scalars['UUID']['input']>;
  ofObjectName?: InputMaybe<Scalars['String']['input']>;
  ofRoutingKey?: InputMaybe<Scalars['String']['input']>;
  ofType?: InputMaybe<Failures_ReporterTypeModel>;
};

export type Failures_ReporterModel = {
  __typename?: 'Failures_ReporterModel';
  active: Scalars['Boolean']['output'];
  areaId: Scalars['UUID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  objectId?: Maybe<Scalars['UUID']['output']>;
  objectLocation?: Maybe<Failures_CoordinatesModel>;
  objectName?: Maybe<Scalars['String']['output']>;
  routingKey?: Maybe<Scalars['String']['output']>;
  type: Failures_ReporterTypeModel;
  version: Scalars['Int']['output'];
};

export type Failures_ReporterRowModel = {
  __typename?: 'Failures_ReporterRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  objectId?: Maybe<Scalars['UUID']['output']>;
  objectLocation?: Maybe<Failures_CoordinatesModel>;
  objectName?: Maybe<Scalars['String']['output']>;
  type: Failures_ReporterTypeModel;
  version: Scalars['Int']['output'];
};

export enum Failures_ReporterTypeModel {
  Device = 'DEVICE',
  ExternalUser = 'EXTERNAL_USER'
}

export type Failures_RequestFailureMaintenanceInput = {
  areaId: Scalars['UUID']['input'];
  failureId: Scalars['UUID']['input'];
  failureVersion: Scalars['Int']['input'];
};

export type Failures_ResolveManualFailureErrorInput = {
  areaId: Scalars['UUID']['input'];
  errorId: Scalars['UUID']['input'];
  errorReasonDescription?: InputMaybe<Scalars['String']['input']>;
  errorResolveDescription: Scalars['String']['input'];
  errorSparePartsDescription?: InputMaybe<Scalars['String']['input']>;
  failureId: Scalars['UUID']['input'];
  failureVersion: Scalars['Int']['input'];
};

export type Failures_StartFailureMaintenanceInput = {
  areaId: Scalars['UUID']['input'];
  failureId: Scalars['UUID']['input'];
  failureVersion: Scalars['Int']['input'];
};

export type Failures_StatisticsFailureCountModel = {
  __typename?: 'Failures_StatisticsFailureCountModel';
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  type: Failures_FailureTypeModel;
};

export type Failures_StatisticsModel = {
  __typename?: 'Failures_StatisticsModel';
  activeFailureCount: Scalars['Int']['output'];
  failureCountByDay: Array<Failures_StatisticsFailureCountModel>;
  manualFailureCount: Scalars['Int']['output'];
  resolvedFailureCount: Scalars['Int']['output'];
};

export type LightingIoT_AddControllerToLuminaireInput = {
  areaId: Scalars['UUID']['input'];
  controllerId: Scalars['String']['input'];
  luminaireId: Scalars['UUID']['input'];
  luminaireVersion: Scalars['Int']['input'];
};

export type LightingIoT_AddLuminaireToLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  luminaireId: Scalars['UUID']['input'];
  luminaireVersion: Scalars['Int']['input'];
  scheduleId: Scalars['UUID']['input'];
};

export type LightingIoT_AddLuminairesToLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  luminaires: Array<LightingIoT_AddLuminairesToLightingScheduleLuminaireModelInput>;
  scheduleId: Scalars['UUID']['input'];
};

export type LightingIoT_AddLuminairesToLightingScheduleLuminaireModelInput = {
  luminaireId: Scalars['UUID']['input'];
  luminaireVersion: Scalars['Int']['input'];
};

export type LightingIoT_Admin_DiscoverLuminaireJournalEntriesInput = {
  areaId: Scalars['UUID']['input'];
  entriesFrom: Scalars['DateTime']['input'];
  entriesTo: Scalars['DateTime']['input'];
  from: Scalars['Int']['input'];
  journalId: Scalars['UUID']['input'];
  minQuality: LightingIoT_LuminaireJournalEntryQualitySharedModel;
  ofType: LightingIoT_LuminaireJournalEntryTypeSharedModel;
  to: Scalars['Int']['input'];
};

export type LightingIoT_Admin_DiscoverLuminaireJournalEntriesPayload = {
  __typename?: 'LightingIoT_Admin_DiscoverLuminaireJournalEntriesPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireJournalEntrySharedModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_Admin_DiscoverLuminaireJournalInput = {
  areaId: Scalars['UUID']['input'];
  luminaireId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_DiscoverLuminaireJournalPayload = {
  __typename?: 'LightingIoT_Admin_DiscoverLuminaireJournalPayload';
  journal?: Maybe<LightingIoT_LuminaireJournalSharedModel>;
};

export type LightingIoT_Admin_DiscoverLuminaireJournalsInput = {
  areaId: Scalars['UUID']['input'];
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type LightingIoT_Admin_DiscoverLuminaireJournalsPayload = {
  __typename?: 'LightingIoT_Admin_DiscoverLuminaireJournalsPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireJournalSharedModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_Admin_DiscoverLuminairesInput = {
  areaId: Scalars['UUID']['input'];
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type LightingIoT_Admin_DiscoverLuminairesPayload = {
  __typename?: 'LightingIoT_Admin_DiscoverLuminairesPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireSharedModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_Admin_IndexLightingSchedulesInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_IndexLuminaireJobsInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_IndexLuminaireJournalsInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_IndexLuminairesInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_InitializeAllControllersInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_ValidateLightingSchedulesIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_ValidateLuminaireJobsIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_ValidateLuminaireJournalsIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_Admin_ValidateLuminairesIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export enum LightingIoT_ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type LightingIoT_ChangeOnTimeStepInput = {
  brightness: Scalars['Int']['input'];
  changeTime: Scalars['LightingIoT_TimeSpan']['input'];
};

export type LightingIoT_ChangeOnTimeStepPayload = {
  __typename?: 'LightingIoT_ChangeOnTimeStepPayload';
  brightness: Scalars['Int']['output'];
  changeTime: Scalars['LightingIoT_TimeSpan']['output'];
};

export type LightingIoT_CoordinatesModel = {
  __typename?: 'LightingIoT_CoordinatesModel';
  hash?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LightingIoT_CreateNewLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  changeSteps: Array<LightingIoT_LightingScheduleChangeStepInput>;
  name: Scalars['String']['input'];
  startStep: LightingIoT_LightingScheduleStartStepInput;
  stopStep: LightingIoT_LightingScheduleStopStepInput;
};

export type LightingIoT_CreateNewLightingSchedulePayload = {
  __typename?: 'LightingIoT_CreateNewLightingSchedulePayload';
  id: Scalars['UUID']['output'];
};

export type LightingIoT_DeleteLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  version: Scalars['Int']['input'];
};

export type LightingIoT_GetLightingScheduleByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type LightingIoT_GetLightingScheduleByIdPayload = {
  __typename?: 'LightingIoT_GetLightingScheduleByIdPayload';
  schedule?: Maybe<LightingIoT_LightingSchedulePayload>;
};

export type LightingIoT_GetLightingSchedulesInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LightingScheduleFiltersModelInput>;
};

export type LightingIoT_GetLightingSchedulesPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LightingScheduleFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type LightingIoT_GetLightingSchedulesPagedPayload = {
  __typename?: 'LightingIoT_GetLightingSchedulesPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LightingScheduleRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLightingSchedulesPayload = {
  __typename?: 'LightingIoT_GetLightingSchedulesPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LightingScheduleRowModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLuminaireByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type LightingIoT_GetLuminaireByIdPayload = {
  __typename?: 'LightingIoT_GetLuminaireByIdPayload';
  luminaire?: Maybe<LightingIoT_LuminaireModel>;
};

export type LightingIoT_GetLuminaireJobsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LuminaireJobFiltersModelInput>;
};

export type LightingIoT_GetLuminaireJobsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LuminaireJobFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type LightingIoT_GetLuminaireJobsPagedPayload = {
  __typename?: 'LightingIoT_GetLuminaireJobsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireJobRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLuminaireJobsPayload = {
  __typename?: 'LightingIoT_GetLuminaireJobsPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireJobRowModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLuminaireJournalByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type LightingIoT_GetLuminaireJournalByIdPayload = {
  __typename?: 'LightingIoT_GetLuminaireJournalByIdPayload';
  journal?: Maybe<LightingIoT_LuminaireJournalModel>;
};

export type LightingIoT_GetLuminaireJournalsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LuminaireJournalFiltersModelInput>;
};

export type LightingIoT_GetLuminaireJournalsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LuminaireJournalFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type LightingIoT_GetLuminaireJournalsPagedPayload = {
  __typename?: 'LightingIoT_GetLuminaireJournalsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireJournalRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLuminaireJournalsPayload = {
  __typename?: 'LightingIoT_GetLuminaireJournalsPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireJournalRowModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLuminairesInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LuminaireFiltersModelInput>;
};

export type LightingIoT_GetLuminairesPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<LightingIoT_LuminaireFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type LightingIoT_GetLuminairesPagedPayload = {
  __typename?: 'LightingIoT_GetLuminairesPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetLuminairesPayload = {
  __typename?: 'LightingIoT_GetLuminairesPayload';
  count: Scalars['Int']['output'];
  items: Array<LightingIoT_LuminaireRowModel>;
  total: Scalars['Int']['output'];
};

export type LightingIoT_GetStatisticsInput = {
  areaId: Scalars['UUID']['input'];
};

export type LightingIoT_GetStatisticsPayload = {
  __typename?: 'LightingIoT_GetStatisticsPayload';
  statistics: LightingIoT_StatisticsReadModel;
};

export type LightingIoT_GraphQlError = {
  __typename?: 'LightingIoT_GraphQLError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Array<LightingIoT_KeyValuePairOfStringAndString>;
};

export type LightingIoT_GraphQlResult = {
  __typename?: 'LightingIoT_GraphQLResult';
  created: Scalars['DateTime']['output'];
  errors: Array<LightingIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type LightingIoT_GraphQlResultOfCreateNewLightingSchedulePayload = {
  __typename?: 'LightingIoT_GraphQLResultOfCreateNewLightingSchedulePayload';
  created: Scalars['DateTime']['output'];
  errors: Array<LightingIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<LightingIoT_CreateNewLightingSchedulePayload>;
};

export type LightingIoT_GraphQlResultOfDiscoverLuminaireJournalEntriesPayload = {
  __typename?: 'LightingIoT_GraphQLResultOfDiscoverLuminaireJournalEntriesPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<LightingIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<LightingIoT_Admin_DiscoverLuminaireJournalEntriesPayload>;
};

export type LightingIoT_GraphQlResultOfDiscoverLuminaireJournalPayload = {
  __typename?: 'LightingIoT_GraphQLResultOfDiscoverLuminaireJournalPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<LightingIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<LightingIoT_Admin_DiscoverLuminaireJournalPayload>;
};

export type LightingIoT_GraphQlResultOfDiscoverLuminaireJournalsPayload = {
  __typename?: 'LightingIoT_GraphQLResultOfDiscoverLuminaireJournalsPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<LightingIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<LightingIoT_Admin_DiscoverLuminaireJournalsPayload>;
};

export type LightingIoT_GraphQlResultOfDiscoverLuminairesPayload = {
  __typename?: 'LightingIoT_GraphQLResultOfDiscoverLuminairesPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<LightingIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<LightingIoT_Admin_DiscoverLuminairesPayload>;
};

export type LightingIoT_KeyValuePairOfStringAndString = {
  __typename?: 'LightingIoT_KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LightingIoT_LightingScheduleChangeStep = LightingIoT_ChangeOnTimeStepPayload;

export type LightingIoT_LightingScheduleChangeStepInput = {
  changeOnTimeStep?: InputMaybe<LightingIoT_ChangeOnTimeStepInput>;
};

export type LightingIoT_LightingScheduleFiltersModelInput = {
  matchesName?: InputMaybe<Scalars['String']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
};

export type LightingIoT_LightingSchedulePayload = {
  __typename?: 'LightingIoT_LightingSchedulePayload';
  areaId: Scalars['UUID']['output'];
  changeSteps: Array<LightingIoT_LightingScheduleChangeStep>;
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  startStep: LightingIoT_LightingScheduleStartStep;
  stopStep: LightingIoT_LightingScheduleStopStep;
  version: Scalars['Int']['output'];
};

export type LightingIoT_LightingScheduleRowModel = {
  __typename?: 'LightingIoT_LightingScheduleRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LightingIoT_LightingScheduleStartStep = LightingIoT_StartOnSunsetStepPayload | LightingIoT_StartOnTimeStepPayload;

export type LightingIoT_LightingScheduleStartStepInput = {
  startOnSunsetStep?: InputMaybe<LightingIoT_StartOnSunsetStepInput>;
  startOnTimeStep?: InputMaybe<LightingIoT_StartOnTimeStepInput>;
};

export type LightingIoT_LightingScheduleStopStep = LightingIoT_StopOnSunriseStepPayload | LightingIoT_StopOnTimeStepPayload;

export type LightingIoT_LightingScheduleStopStepInput = {
  stopOnSunriseStep?: InputMaybe<LightingIoT_StopOnSunriseStepInput>;
  stopOnTimeStep?: InputMaybe<LightingIoT_StopOnTimeStepInput>;
};

export type LightingIoT_LuminaireControllerModel = {
  __typename?: 'LightingIoT_LuminaireControllerModel';
  id: Scalars['String']['output'];
};

export type LightingIoT_LuminaireControllerSharedModel = {
  __typename?: 'LightingIoT_LuminaireControllerSharedModel';
  id: Scalars['String']['output'];
};

export type LightingIoT_LuminaireFiltersModelInput = {
  matchesControllerId?: InputMaybe<Scalars['String']['input']>;
  matchesLightingScheduleName?: InputMaybe<Scalars['String']['input']>;
  matchesName?: InputMaybe<Scalars['String']['input']>;
  matchesTypeName?: InputMaybe<Scalars['String']['input']>;
  ofControllerId?: InputMaybe<Scalars['String']['input']>;
  ofLightingScheduleId?: InputMaybe<Scalars['UUID']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
  ofTypeId?: InputMaybe<Scalars['UUID']['input']>;
  usingJournal?: InputMaybe<LightingIoT_LuminaireJournalFiltersModelInput>;
  withController?: InputMaybe<Scalars['Boolean']['input']>;
  withLightingSchedule?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LightingIoT_LuminaireJobFiltersModelInput = {
  ofState?: InputMaybe<LightingIoT_LuminaireJobStateModel>;
  ofType?: InputMaybe<LightingIoT_LuminaireJobTypeModel>;
};

export type LightingIoT_LuminaireJobRowModel = {
  __typename?: 'LightingIoT_LuminaireJobRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  state: LightingIoT_LuminaireJobStateModel;
  type: LightingIoT_LuminaireJobTypeModel;
  version: Scalars['Int']['output'];
};

export enum LightingIoT_LuminaireJobStateModel {
  Failed = 'FAILED',
  Killed = 'KILLED',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Sent = 'SENT',
  Succeeded = 'SUCCEEDED'
}

export enum LightingIoT_LuminaireJobTypeModel {
  InitializeController = 'INITIALIZE_CONTROLLER',
  UpdateLightingSchedule = 'UPDATE_LIGHTING_SCHEDULE'
}

export type LightingIoT_LuminaireJournalEntryEnergyConsumptionSectionSharedModel = {
  __typename?: 'LightingIoT_LuminaireJournalEntryEnergyConsumptionSectionSharedModel';
  consumedEnergy: Scalars['Int']['output'];
  currentEnergy: Scalars['Int']['output'];
  powerFactor?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Int']['output']>;
};

export enum LightingIoT_LuminaireJournalEntryQualitySharedModel {
  High = 'HIGH',
  Low = 'LOW'
}

export type LightingIoT_LuminaireJournalEntrySharedModel = {
  __typename?: 'LightingIoT_LuminaireJournalEntrySharedModel';
  date: Scalars['DateTime']['output'];
  energyConsumption?: Maybe<LightingIoT_LuminaireJournalEntryEnergyConsumptionSectionSharedModel>;
  id: Scalars['UUID']['output'];
  quality: LightingIoT_LuminaireJournalEntryQualitySharedModel;
  status?: Maybe<LightingIoT_LuminaireJournalEntryStatusSectionSharedModel>;
  type: LightingIoT_LuminaireJournalEntryTypeSharedModel;
  workTime?: Maybe<LightingIoT_LuminaireJournalEntryWorkTimeSectionSharedModel>;
};

export type LightingIoT_LuminaireJournalEntryStatusSectionSharedModel = {
  __typename?: 'LightingIoT_LuminaireJournalEntryStatusSectionSharedModel';
  signalStrength?: Maybe<Scalars['Int']['output']>;
  signalToNoiseRatio?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Int']['output']>;
};

export enum LightingIoT_LuminaireJournalEntryTypeSharedModel {
  EnergyConsumption = 'ENERGY_CONSUMPTION',
  Status = 'STATUS',
  WorkTime = 'WORK_TIME'
}

export type LightingIoT_LuminaireJournalEntryWorkTimeSectionSharedModel = {
  __typename?: 'LightingIoT_LuminaireJournalEntryWorkTimeSectionSharedModel';
  operatingTime: Scalars['LightingIoT_TimeSpan']['output'];
};

export type LightingIoT_LuminaireJournalFiltersModelInput = {
  maxCommunicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  maxConsumption?: InputMaybe<Scalars['Int']['input']>;
  maxEnergyMeteringDate?: InputMaybe<Scalars['DateTime']['input']>;
  maxPowerFactor?: InputMaybe<Scalars['Float']['input']>;
  maxSignalStrength?: InputMaybe<Scalars['Int']['input']>;
  maxSignalToNoiseRatio?: InputMaybe<Scalars['Int']['input']>;
  maxTemperature?: InputMaybe<Scalars['Int']['input']>;
  maxVoltage?: InputMaybe<Scalars['Int']['input']>;
  minCommunicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  minConsumption?: InputMaybe<Scalars['Int']['input']>;
  minEnergyMeteringDate?: InputMaybe<Scalars['DateTime']['input']>;
  minPowerFactor?: InputMaybe<Scalars['Float']['input']>;
  minSignalStrength?: InputMaybe<Scalars['Int']['input']>;
  minSignalToNoiseRatio?: InputMaybe<Scalars['Int']['input']>;
  minTemperature?: InputMaybe<Scalars['Int']['input']>;
  minVoltage?: InputMaybe<Scalars['Int']['input']>;
  ofId?: InputMaybe<Scalars['UUID']['input']>;
  ofLuminaireId?: InputMaybe<Scalars['UUID']['input']>;
};

export type LightingIoT_LuminaireJournalModel = {
  __typename?: 'LightingIoT_LuminaireJournalModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  dailySummary: LightingIoT_LuminaireJournalSummaryModel;
  id: Scalars['UUID']['output'];
  luminaireId: Scalars['UUID']['output'];
  monthlySummary: LightingIoT_LuminaireJournalSummaryModel;
  status: LightingIoT_LuminaireJournalStatusModel;
  version: Scalars['Int']['output'];
};

export type LightingIoT_LuminaireJournalRowModel = {
  __typename?: 'LightingIoT_LuminaireJournalRowModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  luminaireId: Scalars['UUID']['output'];
  status: LightingIoT_LuminaireJournalStatusModel;
  version: Scalars['Int']['output'];
};

export type LightingIoT_LuminaireJournalSharedModel = {
  __typename?: 'LightingIoT_LuminaireJournalSharedModel';
  areaId: Scalars['UUID']['output'];
  entries: Array<LightingIoT_LuminaireJournalEntrySharedModel>;
  id: Scalars['UUID']['output'];
  luminaireId: Scalars['UUID']['output'];
  status?: Maybe<LightingIoT_LuminaireJournalStatusSharedModel>;
};

export type LightingIoT_LuminaireJournalStatusModel = {
  __typename?: 'LightingIoT_LuminaireJournalStatusModel';
  communicationDate?: Maybe<Scalars['DateTime']['output']>;
  consumption?: Maybe<Scalars['Int']['output']>;
  energyMeteringDate?: Maybe<Scalars['DateTime']['output']>;
  powerFactor?: Maybe<Scalars['Float']['output']>;
  signalStrength?: Maybe<Scalars['Int']['output']>;
  signalToNoiseRatio?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Int']['output']>;
  voltage?: Maybe<Scalars['Int']['output']>;
};

export type LightingIoT_LuminaireJournalStatusSharedModel = {
  __typename?: 'LightingIoT_LuminaireJournalStatusSharedModel';
  communicationDate?: Maybe<Scalars['DateTime']['output']>;
  consumption?: Maybe<Scalars['Int']['output']>;
  energyMeteringDate?: Maybe<Scalars['DateTime']['output']>;
  powerFactor?: Maybe<Scalars['Float']['output']>;
  signalStrength?: Maybe<Scalars['Int']['output']>;
  signalToNoiseRatio?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Int']['output']>;
  voltage?: Maybe<Scalars['Int']['output']>;
};

export type LightingIoT_LuminaireJournalSummaryItemEnergyConsumptionSectionModel = {
  __typename?: 'LightingIoT_LuminaireJournalSummaryItemEnergyConsumptionSectionModel';
  consumedEnergy: Scalars['Int']['output'];
  maxCurrentEnergy?: Maybe<Scalars['Int']['output']>;
  maxPowerFactor?: Maybe<Scalars['Float']['output']>;
  maxVoltage?: Maybe<Scalars['Int']['output']>;
  minCurrentEnergy?: Maybe<Scalars['Int']['output']>;
  minPowerFactor?: Maybe<Scalars['Float']['output']>;
  minVoltage?: Maybe<Scalars['Int']['output']>;
};

export type LightingIoT_LuminaireJournalSummaryItemModel = {
  __typename?: 'LightingIoT_LuminaireJournalSummaryItemModel';
  date: Scalars['DateTime']['output'];
  energyConsumption?: Maybe<LightingIoT_LuminaireJournalSummaryItemEnergyConsumptionSectionModel>;
  id: Scalars['UUID']['output'];
  type: LightingIoT_LuminaireJournalSummaryItemTypeModel;
  workTime?: Maybe<LightingIoT_LuminaireJournalSummaryItemWorkTimeSectionModel>;
};

export enum LightingIoT_LuminaireJournalSummaryItemTypeModel {
  EnergyConsumption = 'ENERGY_CONSUMPTION',
  WorkTime = 'WORK_TIME'
}

export type LightingIoT_LuminaireJournalSummaryItemWorkTimeSectionModel = {
  __typename?: 'LightingIoT_LuminaireJournalSummaryItemWorkTimeSectionModel';
  operatingTime: Scalars['LightingIoT_TimeSpan']['output'];
};

export type LightingIoT_LuminaireJournalSummaryModel = {
  __typename?: 'LightingIoT_LuminaireJournalSummaryModel';
  id: Scalars['UUID']['output'];
  items: Array<LightingIoT_LuminaireJournalSummaryItemModel>;
  range: LightingIoT_LuminaireJournalSummaryRangeModel;
};

export enum LightingIoT_LuminaireJournalSummaryRangeModel {
  Daily = 'DAILY',
  Monthly = 'MONTHLY'
}

export type LightingIoT_LuminaireModel = {
  __typename?: 'LightingIoT_LuminaireModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  controller?: Maybe<LightingIoT_LuminaireControllerModel>;
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  lightingScheduleId?: Maybe<Scalars['UUID']['output']>;
  lightingScheduleName?: Maybe<Scalars['String']['output']>;
  location: LightingIoT_CoordinatesModel;
  name: Scalars['String']['output'];
  typeId: Scalars['UUID']['output'];
  typeName: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LightingIoT_LuminaireRowModel = {
  __typename?: 'LightingIoT_LuminaireRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: LightingIoT_CoordinatesModel;
  name: Scalars['String']['output'];
  typeId: Scalars['UUID']['output'];
  typeName: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LightingIoT_LuminaireSharedModel = {
  __typename?: 'LightingIoT_LuminaireSharedModel';
  areaId: Scalars['UUID']['output'];
  controller?: Maybe<LightingIoT_LuminaireControllerSharedModel>;
  id: Scalars['UUID']['output'];
  latitude: Scalars['Float']['output'];
  lightingScheduleId?: Maybe<Scalars['UUID']['output']>;
  lightingScheduleName?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  typeId: Scalars['UUID']['output'];
  typeName: Scalars['String']['output'];
};

export type LightingIoT_RemoveControllerFromLuminaireInput = {
  areaId: Scalars['UUID']['input'];
  luminaireId: Scalars['UUID']['input'];
  luminaireVersion: Scalars['Int']['input'];
};

export type LightingIoT_RemoveLuminaireFromLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  luminaireId: Scalars['UUID']['input'];
  luminaireVersion: Scalars['Int']['input'];
};

export type LightingIoT_RemoveLuminairesFromLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  luminaires: Array<LightingIoT_RemoveLuminairesFromLightingScheduleLuminaireModelInput>;
};

export type LightingIoT_RemoveLuminairesFromLightingScheduleLuminaireModelInput = {
  luminaireId: Scalars['UUID']['input'];
  luminaireVersion: Scalars['Int']['input'];
};

export type LightingIoT_StartOnSunsetStepInput = {
  brightness: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type LightingIoT_StartOnSunsetStepPayload = {
  __typename?: 'LightingIoT_StartOnSunsetStepPayload';
  brightness: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type LightingIoT_StartOnTimeStepInput = {
  brightness: Scalars['Int']['input'];
  startTime: Scalars['LightingIoT_TimeSpan']['input'];
};

export type LightingIoT_StartOnTimeStepPayload = {
  __typename?: 'LightingIoT_StartOnTimeStepPayload';
  brightness: Scalars['Int']['output'];
  startTime: Scalars['LightingIoT_TimeSpan']['output'];
};

export type LightingIoT_StatisticsReadModel = {
  __typename?: 'LightingIoT_StatisticsReadModel';
  latestLuminaireCommunications: Array<Scalars['DateTime']['output']>;
  lightingScheduleCount: Scalars['Int']['output'];
  luminaireControllerCount: Scalars['Int']['output'];
  luminaireCount: Scalars['Int']['output'];
};

export type LightingIoT_StopOnSunriseStepInput = {
  brightness: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type LightingIoT_StopOnSunriseStepPayload = {
  __typename?: 'LightingIoT_StopOnSunriseStepPayload';
  brightness: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type LightingIoT_StopOnTimeStepInput = {
  brightness: Scalars['Int']['input'];
  stopTime: Scalars['LightingIoT_TimeSpan']['input'];
};

export type LightingIoT_StopOnTimeStepPayload = {
  __typename?: 'LightingIoT_StopOnTimeStepPayload';
  brightness: Scalars['Int']['output'];
  stopTime: Scalars['LightingIoT_TimeSpan']['output'];
};

export type LightingIoT_UpdateLightingScheduleInput = {
  areaId: Scalars['UUID']['input'];
  changeSteps: Array<LightingIoT_LightingScheduleChangeStepInput>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  startStep: LightingIoT_LightingScheduleStartStepInput;
  stopStep: LightingIoT_LightingScheduleStopStepInput;
  version: Scalars['Int']['input'];
};

export type Lighting_AddressModel = {
  __typename?: 'Lighting_AddressModel';
  number?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  town: Scalars['String']['output'];
};

export type Lighting_AddressModelInput = {
  number?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  town: Scalars['String']['input'];
};

export type Lighting_AddressSharedModel = {
  __typename?: 'Lighting_AddressSharedModel';
  number?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  town: Scalars['String']['output'];
};

export type Lighting_Admin_DiscoverLuminaireTypesInput = {
  areaId: Scalars['UUID']['input'];
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type Lighting_Admin_DiscoverLuminaireTypesPayload = {
  __typename?: 'Lighting_Admin_DiscoverLuminaireTypesPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_LuminaireTypeSharedModel>;
  total: Scalars['Int']['output'];
};

export type Lighting_Admin_DiscoverLuminairesInput = {
  areaId: Scalars['UUID']['input'];
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type Lighting_Admin_DiscoverLuminairesPayload = {
  __typename?: 'Lighting_Admin_DiscoverLuminairesPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_LuminaireSharedModel>;
  total: Scalars['Int']['output'];
};

export type Lighting_Admin_IndexLuminaireTypesInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_IndexLuminairesInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_IndexPolesInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_IndexPowerBoxesInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_ValidateLuminaireTypesIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_ValidateLuminairesIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_ValidatePolesIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_Admin_ValidatePowerBoxesIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export enum Lighting_ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Lighting_AreaModel = {
  __typename?: 'Lighting_AreaModel';
  id: Scalars['UUID']['output'];
  settings: Lighting_AreaSettingsModel;
};

export type Lighting_AreaSettingsModel = {
  __typename?: 'Lighting_AreaSettingsModel';
  requireMaintenanceDetails: Scalars['Boolean']['output'];
  requireRoadDetails: Scalars['Boolean']['output'];
};

export type Lighting_CoordinatesModel = {
  __typename?: 'Lighting_CoordinatesModel';
  hash?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type Lighting_CoordinatesModelInput = {
  hash?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type Lighting_CoordinatesSharedModel = {
  __typename?: 'Lighting_CoordinatesSharedModel';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type Lighting_CreateNewLuminaireInput = {
  areaId: Scalars['UUID']['input'];
  details: Lighting_LuminaireDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  maintenanceDetails?: InputMaybe<Lighting_LuminaireMaintenanceDetailsModelInput>;
  poleId: Scalars['UUID']['input'];
  typeId: Scalars['UUID']['input'];
};

export type Lighting_CreateNewLuminairePayload = {
  __typename?: 'Lighting_CreateNewLuminairePayload';
  id: Scalars['UUID']['output'];
};

export type Lighting_CreateNewLuminaireTypeInput = {
  areaId: Scalars['UUID']['input'];
  details: Lighting_LuminaireTypeDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  ledDetails?: InputMaybe<Lighting_LuminaireTypeLedDetailsModelInput>;
  manufacturer: Scalars['String']['input'];
  model: Scalars['String']['input'];
};

export type Lighting_CreateNewLuminaireTypePayload = {
  __typename?: 'Lighting_CreateNewLuminaireTypePayload';
  id: Scalars['UUID']['output'];
};

export type Lighting_CreateNewPoleInput = {
  address: Lighting_AddressModelInput;
  areaId: Scalars['UUID']['input'];
  bracketDetails?: InputMaybe<Lighting_PoleBracketDetailsModelInput>;
  details: Lighting_PoleDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  location: Lighting_CoordinatesModelInput;
  maintenanceDetails?: InputMaybe<Lighting_PoleMaintenanceDetailsModelInput>;
  name: Scalars['String']['input'];
  powerBoxId: Scalars['UUID']['input'];
  roadDetails?: InputMaybe<Lighting_PoleRoadDetailsModelInput>;
  sidewalkDetails?: InputMaybe<Lighting_PoleSidewalkDetailsModelInput>;
};

export type Lighting_CreateNewPolePayload = {
  __typename?: 'Lighting_CreateNewPolePayload';
  id: Scalars['UUID']['output'];
};

export type Lighting_CreateNewPowerBoxInput = {
  address: Lighting_AddressModelInput;
  areaId: Scalars['UUID']['input'];
  details: Lighting_PowerBoxDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  location: Lighting_CoordinatesModelInput;
  name: Scalars['String']['input'];
};

export type Lighting_CreateNewPowerBoxPayload = {
  __typename?: 'Lighting_CreateNewPowerBoxPayload';
  id: Scalars['UUID']['output'];
};

export type Lighting_DeleteLuminaireInput = {
  areaId: Scalars['UUID']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  version: Scalars['Int']['input'];
};

export type Lighting_DeleteLuminaireTypeInput = {
  areaId: Scalars['UUID']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  version: Scalars['Int']['input'];
};

export type Lighting_DeletePoleInput = {
  areaId: Scalars['UUID']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  version: Scalars['Int']['input'];
};

export type Lighting_DeletePowerBoxInput = {
  areaId: Scalars['UUID']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  version: Scalars['Int']['input'];
};

export enum Lighting_FasteningModel {
  OnPole = 'ON_POLE',
  OverLine = 'OVER_LINE',
  UnderLine = 'UNDER_LINE'
}

export type Lighting_GetAreaByIdInput = {
  id: Scalars['UUID']['input'];
};

export type Lighting_GetAreaByIdPayload = {
  __typename?: 'Lighting_GetAreaByIdPayload';
  area?: Maybe<Lighting_AreaModel>;
};

export type Lighting_GetLuminaireByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Lighting_GetLuminaireByIdPayload = {
  __typename?: 'Lighting_GetLuminaireByIdPayload';
  luminaire?: Maybe<Lighting_LuminaireModel>;
};

export type Lighting_GetLuminaireTypeByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Lighting_GetLuminaireTypeByIdPayload = {
  __typename?: 'Lighting_GetLuminaireTypeByIdPayload';
  luminaireType?: Maybe<Lighting_LuminaireTypeModel>;
};

export type Lighting_GetLuminaireTypesInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_LuminaireTypeFiltersModelInput>;
};

export type Lighting_GetLuminaireTypesPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_LuminaireTypeFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Lighting_GetLuminaireTypesPagedPayload = {
  __typename?: 'Lighting_GetLuminaireTypesPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_LuminaireTypeRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Lighting_GetLuminaireTypesPayload = {
  __typename?: 'Lighting_GetLuminaireTypesPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_LuminaireTypeRowModel>;
  total: Scalars['Int']['output'];
};

export type Lighting_GetLuminairesInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_LuminaireFiltersModelInput>;
};

export type Lighting_GetLuminairesPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_LuminaireFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Lighting_GetLuminairesPagedPayload = {
  __typename?: 'Lighting_GetLuminairesPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_LuminaireRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Lighting_GetLuminairesPayload = {
  __typename?: 'Lighting_GetLuminairesPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_LuminaireRowModel>;
  total: Scalars['Int']['output'];
};

export type Lighting_GetPoleByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Lighting_GetPoleByIdPayload = {
  __typename?: 'Lighting_GetPoleByIdPayload';
  pole?: Maybe<Lighting_PoleModel>;
};

export type Lighting_GetPolesInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_PoleFiltersModelInput>;
};

export type Lighting_GetPolesPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_PoleFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Lighting_GetPolesPagedPayload = {
  __typename?: 'Lighting_GetPolesPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_PoleRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Lighting_GetPolesPayload = {
  __typename?: 'Lighting_GetPolesPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_PoleRowModel>;
  total: Scalars['Int']['output'];
};

export type Lighting_GetPowerBoxByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type Lighting_GetPowerBoxByIdPayload = {
  __typename?: 'Lighting_GetPowerBoxByIdPayload';
  powerBox?: Maybe<Lighting_PowerBoxModel>;
};

export type Lighting_GetPowerBoxesInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_PowerBoxFiltersModelInput>;
};

export type Lighting_GetPowerBoxesPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Lighting_PowerBoxFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Lighting_GetPowerBoxesPagedPayload = {
  __typename?: 'Lighting_GetPowerBoxesPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_PowerBoxRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Lighting_GetPowerBoxesPayload = {
  __typename?: 'Lighting_GetPowerBoxesPayload';
  count: Scalars['Int']['output'];
  items: Array<Lighting_PowerBoxRowModel>;
  total: Scalars['Int']['output'];
};

export type Lighting_GetStatisticsInput = {
  areaId: Scalars['UUID']['input'];
};

export type Lighting_GetStatisticsPayload = {
  __typename?: 'Lighting_GetStatisticsPayload';
  statistics: Lighting_StatisticsReadModel;
};

export type Lighting_GraphQlError = {
  __typename?: 'Lighting_GraphQLError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Array<Lighting_KeyValuePairOfStringAndString>;
};

export type Lighting_GraphQlResult = {
  __typename?: 'Lighting_GraphQLResult';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Lighting_GraphQlResultOfCreateNewLuminairePayload = {
  __typename?: 'Lighting_GraphQLResultOfCreateNewLuminairePayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Lighting_CreateNewLuminairePayload>;
};

export type Lighting_GraphQlResultOfCreateNewLuminaireTypePayload = {
  __typename?: 'Lighting_GraphQLResultOfCreateNewLuminaireTypePayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Lighting_CreateNewLuminaireTypePayload>;
};

export type Lighting_GraphQlResultOfCreateNewPolePayload = {
  __typename?: 'Lighting_GraphQLResultOfCreateNewPolePayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Lighting_CreateNewPolePayload>;
};

export type Lighting_GraphQlResultOfCreateNewPowerBoxPayload = {
  __typename?: 'Lighting_GraphQLResultOfCreateNewPowerBoxPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Lighting_CreateNewPowerBoxPayload>;
};

export type Lighting_GraphQlResultOfDiscoverLuminaireTypesPayload = {
  __typename?: 'Lighting_GraphQLResultOfDiscoverLuminaireTypesPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Lighting_Admin_DiscoverLuminaireTypesPayload>;
};

export type Lighting_GraphQlResultOfDiscoverLuminairesPayload = {
  __typename?: 'Lighting_GraphQLResultOfDiscoverLuminairesPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<Lighting_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<Lighting_Admin_DiscoverLuminairesPayload>;
};

export enum Lighting_GridTypeModel {
  Cable = 'CABLE',
  Other = 'OTHER',
  Overhead = 'OVERHEAD'
}

export type Lighting_KeyValuePairOfStringAndString = {
  __typename?: 'Lighting_KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum Lighting_LineTypeModel {
  Al = 'AL',
  Asxsn = 'ASXSN',
  Other = 'OTHER',
  Yakxs = 'YAKXS',
  Yaky = 'YAKY',
  Ykxs = 'YKXS',
  Yky = 'YKY'
}

export type Lighting_LuminaireDefaultDetailsModel = {
  __typename?: 'Lighting_LuminaireDefaultDetailsModel';
  fastening: Lighting_FasteningModel;
  height: Scalars['Float']['output'];
};

export type Lighting_LuminaireDefaultDetailsModelInput = {
  fastening: Lighting_FasteningModel;
  height: Scalars['Float']['input'];
};

export type Lighting_LuminaireFiltersModelInput = {
  matchesPoleName?: InputMaybe<Scalars['String']['input']>;
  matchesTypeName?: InputMaybe<Scalars['String']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  minHeight?: InputMaybe<Scalars['Int']['input']>;
  ofFastening?: InputMaybe<Lighting_FasteningModel>;
  ofOrdinalNumber?: InputMaybe<Scalars['Int']['input']>;
  ofOwner?: InputMaybe<Lighting_OwnerModel>;
  ofPoleId?: InputMaybe<Scalars['UUID']['input']>;
  ofPoleName?: InputMaybe<Scalars['String']['input']>;
  ofTypeId?: InputMaybe<Scalars['UUID']['input']>;
  ofTypeName?: InputMaybe<Scalars['String']['input']>;
  qualifiedForReplacement?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Lighting_LuminaireMaintenanceDetailsModel = {
  __typename?: 'Lighting_LuminaireMaintenanceDetailsModel';
  owner: Lighting_OwnerModel;
  qualifiedForReplacement: Scalars['Boolean']['output'];
};

export type Lighting_LuminaireMaintenanceDetailsModelInput = {
  owner: Lighting_OwnerModel;
  qualifiedForReplacement: Scalars['Boolean']['input'];
};

export type Lighting_LuminaireModel = {
  __typename?: 'Lighting_LuminaireModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  details: Lighting_LuminaireDefaultDetailsModel;
  id: Scalars['UUID']['output'];
  maintenanceDetails?: Maybe<Lighting_LuminaireMaintenanceDetailsModel>;
  ordinalNumber: Scalars['Int']['output'];
  poleId: Scalars['UUID']['output'];
  poleName: Scalars['String']['output'];
  typeId: Scalars['UUID']['output'];
  typeName: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Lighting_LuminaireRowModel = {
  __typename?: 'Lighting_LuminaireRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  ordinalNumber: Scalars['Int']['output'];
  poleId: Scalars['UUID']['output'];
  poleName: Scalars['String']['output'];
  typeId: Scalars['UUID']['output'];
  typeName: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Lighting_LuminaireSharedModel = {
  __typename?: 'Lighting_LuminaireSharedModel';
  address: Lighting_AddressSharedModel;
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: Lighting_CoordinatesSharedModel;
  ordinalNumber: Scalars['Int']['output'];
  poleId: Scalars['UUID']['output'];
  poleName: Scalars['String']['output'];
  poleOrdinalNumber: Scalars['Int']['output'];
  typeId: Scalars['UUID']['output'];
  typeName: Scalars['String']['output'];
};

export type Lighting_LuminaireTypeDefaultDetailsModel = {
  __typename?: 'Lighting_LuminaireTypeDefaultDetailsModel';
  manufacturerCode?: Maybe<Scalars['String']['output']>;
  power: Scalars['Float']['output'];
  sourceOfLight: Lighting_SourceOfLightModel;
  sourcePower: Scalars['Float']['output'];
};

export type Lighting_LuminaireTypeDefaultDetailsModelInput = {
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  power: Scalars['Float']['input'];
  sourceOfLight: Lighting_SourceOfLightModel;
  sourcePower: Scalars['Float']['input'];
};

export type Lighting_LuminaireTypeFiltersModelInput = {
  hasControllerSocket?: InputMaybe<Scalars['Boolean']['input']>;
  matchesDriverType?: InputMaybe<Scalars['String']['input']>;
  matchesManufacturer?: InputMaybe<Scalars['String']['input']>;
  matchesManufacturerCode?: InputMaybe<Scalars['String']['input']>;
  matchesModel?: InputMaybe<Scalars['String']['input']>;
  matchesName?: InputMaybe<Scalars['String']['input']>;
  matchesOptics?: InputMaybe<Scalars['String']['input']>;
  maxAmountOfLed?: InputMaybe<Scalars['Int']['input']>;
  maxDriverCurrent?: InputMaybe<Scalars['Int']['input']>;
  maxPower?: InputMaybe<Scalars['Float']['input']>;
  maxSourcePower?: InputMaybe<Scalars['Float']['input']>;
  minAmountOfLed?: InputMaybe<Scalars['Int']['input']>;
  minDriverCurrent?: InputMaybe<Scalars['Int']['input']>;
  minPower?: InputMaybe<Scalars['Float']['input']>;
  minSourcePower?: InputMaybe<Scalars['Float']['input']>;
  ofManufacturer?: InputMaybe<Scalars['String']['input']>;
  ofModel?: InputMaybe<Scalars['String']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
  ofSourceOfLight?: InputMaybe<Lighting_SourceOfLightModel>;
};

export type Lighting_LuminaireTypeLedDetailsModel = {
  __typename?: 'Lighting_LuminaireTypeLedDetailsModel';
  amountOfLed?: Maybe<Scalars['Int']['output']>;
  driverCurrent?: Maybe<Scalars['Int']['output']>;
  driverType?: Maybe<Scalars['String']['output']>;
  hasControllerSocket: Scalars['Boolean']['output'];
  optics?: Maybe<Scalars['String']['output']>;
};

export type Lighting_LuminaireTypeLedDetailsModelInput = {
  amountOfLed?: InputMaybe<Scalars['Int']['input']>;
  driverCurrent?: InputMaybe<Scalars['Int']['input']>;
  driverType?: InputMaybe<Scalars['String']['input']>;
  hasControllerSocket: Scalars['Boolean']['input'];
  optics?: InputMaybe<Scalars['String']['input']>;
};

export type Lighting_LuminaireTypeModel = {
  __typename?: 'Lighting_LuminaireTypeModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  details: Lighting_LuminaireTypeDefaultDetailsModel;
  id: Scalars['UUID']['output'];
  ledDetails?: Maybe<Lighting_LuminaireTypeLedDetailsModel>;
  manufacturer: Scalars['String']['output'];
  model: Scalars['String']['output'];
  name: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Lighting_LuminaireTypeRowModel = {
  __typename?: 'Lighting_LuminaireTypeRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Lighting_LuminaireTypeSharedModel = {
  __typename?: 'Lighting_LuminaireTypeSharedModel';
  areaId: Scalars['UUID']['output'];
  hasControllerSocket: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  power: Scalars['Float']['output'];
  sourceOfLight: Lighting_SourceOfLightSharedModel;
  sourcePower: Scalars['Float']['output'];
};

export enum Lighting_OwnerModel {
  Commune = 'COMMUNE',
  Other = 'OTHER',
  PowerCompany = 'POWER_COMPANY'
}

export type Lighting_PoleBracketDetailsModel = {
  __typename?: 'Lighting_PoleBracketDetailsModel';
  bracketLength: Scalars['Float']['output'];
  bracketQualifiedForReplacement?: Maybe<Scalars['Boolean']['output']>;
  dateOfBracketPainting?: Maybe<Scalars['Date']['output']>;
  newBracketLengthAfterReplacement?: Maybe<Scalars['Float']['output']>;
  tiltAngle: Scalars['Float']['output'];
};

export type Lighting_PoleBracketDetailsModelInput = {
  bracketLength: Scalars['Float']['input'];
  bracketQualifiedForReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfBracketPainting?: InputMaybe<Scalars['Date']['input']>;
  newBracketLengthAfterReplacement?: InputMaybe<Scalars['Float']['input']>;
  tiltAngle: Scalars['Float']['input'];
};

export type Lighting_PoleDefaultDetailsModel = {
  __typename?: 'Lighting_PoleDefaultDetailsModel';
  gridType: Lighting_GridTypeModel;
  height: Scalars['Float']['output'];
  lineCrossSection?: Maybe<Scalars['String']['output']>;
  lineType: Lighting_LineTypeModel;
  type: Lighting_PoleTypeModel;
};

export type Lighting_PoleDefaultDetailsModelInput = {
  gridType: Lighting_GridTypeModel;
  height: Scalars['Float']['input'];
  lineCrossSection?: InputMaybe<Scalars['String']['input']>;
  lineType: Lighting_LineTypeModel;
  type: Lighting_PoleTypeModel;
};

export type Lighting_PoleFiltersModelInput = {
  bracketQualifiedForReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  matchesAddressNumber?: InputMaybe<Scalars['String']['input']>;
  matchesAddressStreet?: InputMaybe<Scalars['String']['input']>;
  matchesAddressTown?: InputMaybe<Scalars['String']['input']>;
  matchesLineCrossSection?: InputMaybe<Scalars['String']['input']>;
  matchesName?: InputMaybe<Scalars['String']['input']>;
  matchesPlotNumber?: InputMaybe<Scalars['String']['input']>;
  matchesPowerBoxName?: InputMaybe<Scalars['String']['input']>;
  maxBracketLength?: InputMaybe<Scalars['Float']['input']>;
  maxDateOfBracketPainting?: InputMaybe<Scalars['Date']['input']>;
  maxDateOfPainting?: InputMaybe<Scalars['Date']['input']>;
  maxDistanceFromRoadwayEdgeToPole?: InputMaybe<Scalars['Float']['input']>;
  maxDistanceToClosestPole?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Float']['input']>;
  maxNewBracketLengthAfterReplacement?: InputMaybe<Scalars['Float']['input']>;
  maxRoadWidth?: InputMaybe<Scalars['Float']['input']>;
  maxTiltAngle?: InputMaybe<Scalars['Float']['input']>;
  maxWidthOfSidewalk?: InputMaybe<Scalars['Float']['input']>;
  minBracketLength?: InputMaybe<Scalars['Float']['input']>;
  minDateOfBracketPainting?: InputMaybe<Scalars['Date']['input']>;
  minDateOfPainting?: InputMaybe<Scalars['Date']['input']>;
  minDistanceFromRoadwayEdgeToPole?: InputMaybe<Scalars['Float']['input']>;
  minDistanceToClosestPole?: InputMaybe<Scalars['Float']['input']>;
  minHeight?: InputMaybe<Scalars['Float']['input']>;
  minNewBracketLengthAfterReplacement?: InputMaybe<Scalars['Float']['input']>;
  minRoadWidth?: InputMaybe<Scalars['Float']['input']>;
  minTiltAngle?: InputMaybe<Scalars['Float']['input']>;
  minWidthOfSidewalk?: InputMaybe<Scalars['Float']['input']>;
  ofGridType?: InputMaybe<Lighting_GridTypeModel>;
  ofLineType?: InputMaybe<Lighting_LineTypeModel>;
  ofLocation?: InputMaybe<Lighting_PoleLocationModel>;
  ofName?: InputMaybe<Scalars['String']['input']>;
  ofOrdinalNumber?: InputMaybe<Scalars['Int']['input']>;
  ofOwner?: InputMaybe<Lighting_OwnerModel>;
  ofPowerBoxId?: InputMaybe<Scalars['UUID']['input']>;
  ofPowerBoxName?: InputMaybe<Scalars['String']['input']>;
  ofRoadLightingClass?: InputMaybe<Lighting_RoadLightingClassModel>;
  ofRoadSurface?: InputMaybe<Lighting_RoadSurfaceModel>;
  ofRoadType?: InputMaybe<Lighting_RoadTypeModel>;
  ofSidewalkSide?: InputMaybe<Lighting_SidewalkSideModel>;
  ofType?: InputMaybe<Lighting_PoleTypeModel>;
  qualifiedForReplacement?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Lighting_PoleLocationModel {
  LaneOfRoad = 'LANE_OF_ROAD',
  PrivatePlot = 'PRIVATE_PLOT'
}

export type Lighting_PoleMaintenanceDetailsModel = {
  __typename?: 'Lighting_PoleMaintenanceDetailsModel';
  dateOfPainting?: Maybe<Scalars['Date']['output']>;
  distanceToClosestPole?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Lighting_PoleLocationModel>;
  owner: Lighting_OwnerModel;
  plotNumber?: Maybe<Scalars['String']['output']>;
  qualifiedForReplacement: Scalars['Boolean']['output'];
};

export type Lighting_PoleMaintenanceDetailsModelInput = {
  dateOfPainting?: InputMaybe<Scalars['Date']['input']>;
  distanceToClosestPole?: InputMaybe<Scalars['Float']['input']>;
  location?: InputMaybe<Lighting_PoleLocationModel>;
  owner: Lighting_OwnerModel;
  plotNumber?: InputMaybe<Scalars['String']['input']>;
  qualifiedForReplacement: Scalars['Boolean']['input'];
};

export type Lighting_PoleModel = {
  __typename?: 'Lighting_PoleModel';
  address: Lighting_AddressModel;
  areaId: Scalars['UUID']['output'];
  bracketDetails?: Maybe<Lighting_PoleBracketDetailsModel>;
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  details: Lighting_PoleDefaultDetailsModel;
  id: Scalars['UUID']['output'];
  location: Lighting_CoordinatesModel;
  maintenanceDetails?: Maybe<Lighting_PoleMaintenanceDetailsModel>;
  name: Scalars['String']['output'];
  ordinalNumber: Scalars['Int']['output'];
  powerBoxId: Scalars['UUID']['output'];
  powerBoxName: Scalars['String']['output'];
  roadDetails?: Maybe<Lighting_PoleRoadDetailsModel>;
  sidewalkDetails?: Maybe<Lighting_PoleSidewalkDetailsModel>;
  version: Scalars['Int']['output'];
};

export type Lighting_PoleRoadDetailsModel = {
  __typename?: 'Lighting_PoleRoadDetailsModel';
  distanceFromRoadwayEdgeToPole: Scalars['Float']['output'];
  roadLightingClass: Lighting_RoadLightingClassModel;
  roadSurface: Lighting_RoadSurfaceModel;
  roadType: Lighting_RoadTypeModel;
  roadWidth: Scalars['Float']['output'];
};

export type Lighting_PoleRoadDetailsModelInput = {
  distanceFromRoadwayEdgeToPole: Scalars['Float']['input'];
  roadLightingClass: Lighting_RoadLightingClassModel;
  roadSurface: Lighting_RoadSurfaceModel;
  roadType: Lighting_RoadTypeModel;
  roadWidth: Scalars['Float']['input'];
};

export type Lighting_PoleRowModel = {
  __typename?: 'Lighting_PoleRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: Lighting_CoordinatesModel;
  name: Scalars['String']['output'];
  ordinalNumber: Scalars['Int']['output'];
  powerBoxId: Scalars['UUID']['output'];
  powerBoxName: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Lighting_PoleSidewalkDetailsModel = {
  __typename?: 'Lighting_PoleSidewalkDetailsModel';
  sidewalkSide: Lighting_SidewalkSideModel;
  widthOfSidewalk: Scalars['Float']['output'];
};

export type Lighting_PoleSidewalkDetailsModelInput = {
  sidewalkSide: Lighting_SidewalkSideModel;
  widthOfSidewalk: Scalars['Float']['input'];
};

export enum Lighting_PoleTypeModel {
  Aluminium = 'ALUMINIUM',
  ALetterRcc = 'A_LETTER_RCC',
  Composite = 'COMPOSITE',
  OrnamentalIron = 'ORNAMENTAL_IRON',
  OrnamentalSteel = 'ORNAMENTAL_STEEL',
  Park = 'PARK',
  Pcc = 'PCC',
  Rcc = 'RCC',
  Spun = 'SPUN',
  Steel = 'STEEL',
  Strong = 'STRONG',
  Synthetic = 'SYNTHETIC',
  Wood = 'WOOD'
}

export type Lighting_PowerBoxDefaultDetailsModel = {
  __typename?: 'Lighting_PowerBoxDefaultDetailsModel';
  contractedPower?: Maybe<Scalars['Float']['output']>;
  fuseAmpRating?: Maybe<Scalars['Int']['output']>;
  fuseType?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  power?: Maybe<Scalars['Float']['output']>;
  recipientCode?: Maybe<Scalars['String']['output']>;
};

export type Lighting_PowerBoxDefaultDetailsModelInput = {
  contractedPower?: InputMaybe<Scalars['Float']['input']>;
  fuseAmpRating?: InputMaybe<Scalars['Int']['input']>;
  fuseType?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  power?: InputMaybe<Scalars['Float']['input']>;
  recipientCode?: InputMaybe<Scalars['String']['input']>;
};

export type Lighting_PowerBoxFiltersModelInput = {
  matchesAddressNumber?: InputMaybe<Scalars['String']['input']>;
  matchesAddressStreet?: InputMaybe<Scalars['String']['input']>;
  matchesAddressTown?: InputMaybe<Scalars['String']['input']>;
  matchesFuseType?: InputMaybe<Scalars['String']['input']>;
  matchesName?: InputMaybe<Scalars['String']['input']>;
  matchesNotes?: InputMaybe<Scalars['String']['input']>;
  matchesRecipientCode?: InputMaybe<Scalars['String']['input']>;
  maxContractedPower?: InputMaybe<Scalars['Float']['input']>;
  maxFuseAmpRating?: InputMaybe<Scalars['Int']['input']>;
  maxPower?: InputMaybe<Scalars['Float']['input']>;
  minContractedPower?: InputMaybe<Scalars['Float']['input']>;
  minFuseAmpRating?: InputMaybe<Scalars['Int']['input']>;
  minPower?: InputMaybe<Scalars['Float']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
  ofOrdinalNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type Lighting_PowerBoxModel = {
  __typename?: 'Lighting_PowerBoxModel';
  address: Lighting_AddressModel;
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  details: Lighting_PowerBoxDefaultDetailsModel;
  id: Scalars['UUID']['output'];
  location: Lighting_CoordinatesModel;
  name: Scalars['String']['output'];
  ordinalNumber: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};

export type Lighting_PowerBoxRowModel = {
  __typename?: 'Lighting_PowerBoxRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: Lighting_CoordinatesModel;
  name: Scalars['String']['output'];
  ordinalNumber: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};

export enum Lighting_RoadLightingClassModel {
  C0 = 'C0',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  P7 = 'P7'
}

export enum Lighting_RoadSurfaceModel {
  Asphalt = 'ASPHALT',
  ConcreteSlab = 'CONCRETE_SLAB',
  Grass = 'GRASS',
  Ground = 'GROUND',
  Paving = 'PAVING'
}

export enum Lighting_RoadTypeModel {
  County = 'COUNTY',
  Field = 'FIELD',
  Highway = 'HIGHWAY',
  Internal = 'INTERNAL',
  Municipal = 'MUNICIPAL',
  National = 'NATIONAL',
  Provincial = 'PROVINCIAL'
}

export enum Lighting_SidewalkSideModel {
  BothSides = 'BOTH_SIDES',
  OppositeSideOfPole = 'OPPOSITE_SIDE_OF_POLE',
  SideOfPole = 'SIDE_OF_POLE'
}

export enum Lighting_SourceOfLightModel {
  Led = 'LED',
  Mercury = 'MERCURY',
  MetalHalide = 'METAL_HALIDE',
  Sodium = 'SODIUM'
}

export enum Lighting_SourceOfLightSharedModel {
  Led = 'LED',
  Mercury = 'MERCURY',
  MetalHalide = 'METAL_HALIDE',
  Sodium = 'SODIUM'
}

export type Lighting_StatisticsReadModel = {
  __typename?: 'Lighting_StatisticsReadModel';
  luminaireCount: Scalars['Int']['output'];
  poleCount: Scalars['Int']['output'];
  powerBoxCount: Scalars['Int']['output'];
};

export type Lighting_UpdateAreaSettingsInput = {
  areaId: Scalars['UUID']['input'];
  requireMaintenanceDetails: Scalars['Boolean']['input'];
  requireRoadDetails: Scalars['Boolean']['input'];
};

export type Lighting_UpdateLuminaireInput = {
  areaId: Scalars['UUID']['input'];
  details: Lighting_LuminaireDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  maintenanceDetails?: InputMaybe<Lighting_LuminaireMaintenanceDetailsModelInput>;
  version: Scalars['Int']['input'];
};

export type Lighting_UpdateLuminaireTypeInput = {
  areaId: Scalars['UUID']['input'];
  details: Lighting_LuminaireTypeDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  ledDetails?: InputMaybe<Lighting_LuminaireTypeLedDetailsModelInput>;
  version: Scalars['Int']['input'];
};

export type Lighting_UpdatePoleInput = {
  address: Lighting_AddressModelInput;
  areaId: Scalars['UUID']['input'];
  bracketDetails?: InputMaybe<Lighting_PoleBracketDetailsModelInput>;
  details: Lighting_PoleDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  location: Lighting_CoordinatesModelInput;
  maintenanceDetails?: InputMaybe<Lighting_PoleMaintenanceDetailsModelInput>;
  name: Scalars['String']['input'];
  powerBoxId: Scalars['UUID']['input'];
  roadDetails?: InputMaybe<Lighting_PoleRoadDetailsModelInput>;
  sidewalkDetails?: InputMaybe<Lighting_PoleSidewalkDetailsModelInput>;
  version: Scalars['Int']['input'];
};

export type Lighting_UpdatePowerBoxInput = {
  address: Lighting_AddressModelInput;
  areaId: Scalars['UUID']['input'];
  details: Lighting_PowerBoxDefaultDetailsModelInput;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  location: Lighting_CoordinatesModelInput;
  name: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  Failures_Admin_indexFailures: Failures_GraphQlResult;
  Failures_Admin_indexObjects: Failures_GraphQlResult;
  Failures_Admin_indexReporters: Failures_GraphQlResult;
  Failures_Admin_validateFailuresIndex: Failures_GraphQlResult;
  Failures_Admin_validateObjectsIndex: Failures_GraphQlResult;
  Failures_Admin_validateReportersIndex: Failures_GraphQlResult;
  Failures_activateExternalUserReporter: Failures_GraphQlResult;
  Failures_addExternalUserReporter: Failures_GraphQlResultOfAddExternalUserReporterPayload;
  Failures_deactivateExternalUserReporter: Failures_GraphQlResult;
  Failures_rejectManualFailureError: Failures_GraphQlResult;
  Failures_reportFlagAsExternalUser: Failures_GraphQlResult;
  Failures_requestFailureMaintenance: Failures_GraphQlResult;
  Failures_resolveManualFailureError: Failures_GraphQlResult;
  Failures_startFailureMaintenance: Failures_GraphQlResult;
  LightingIoT_Admin_indexLightingSchedules: LightingIoT_GraphQlResult;
  LightingIoT_Admin_indexLuminaireJobs: LightingIoT_GraphQlResult;
  LightingIoT_Admin_indexLuminaireJournals: LightingIoT_GraphQlResult;
  LightingIoT_Admin_indexLuminaires: LightingIoT_GraphQlResult;
  LightingIoT_Admin_initializeAllControllers: LightingIoT_GraphQlResult;
  LightingIoT_Admin_validateLightingSchedulesIndex: LightingIoT_GraphQlResult;
  LightingIoT_Admin_validateLuminaireJobsIndex: LightingIoT_GraphQlResult;
  LightingIoT_Admin_validateLuminaireJournalsIndex: LightingIoT_GraphQlResult;
  LightingIoT_Admin_validateLuminairesIndex: LightingIoT_GraphQlResult;
  LightingIoT_addControllerToLuminaire: LightingIoT_GraphQlResult;
  LightingIoT_addLuminaireToLightingSchedule: LightingIoT_GraphQlResult;
  LightingIoT_addLuminairesToLightingSchedule: LightingIoT_GraphQlResult;
  LightingIoT_createNewLightingSchedule: LightingIoT_GraphQlResultOfCreateNewLightingSchedulePayload;
  LightingIoT_deleteLightingSchedule: LightingIoT_GraphQlResult;
  LightingIoT_removeControllerFromLuminaire: LightingIoT_GraphQlResult;
  LightingIoT_removeLuminaireFromLightingSchedule: LightingIoT_GraphQlResult;
  LightingIoT_removeLuminairesFromLightingSchedule: LightingIoT_GraphQlResult;
  LightingIoT_updateLightingSchedule: LightingIoT_GraphQlResult;
  Lighting_Admin_indexAreas: Lighting_GraphQlResult;
  Lighting_Admin_indexLuminaireTypes: Lighting_GraphQlResult;
  Lighting_Admin_indexLuminaires: Lighting_GraphQlResult;
  Lighting_Admin_indexPoles: Lighting_GraphQlResult;
  Lighting_Admin_indexPowerBoxes: Lighting_GraphQlResult;
  Lighting_Admin_validateAreasIndex: Lighting_GraphQlResult;
  Lighting_Admin_validateLuminaireTypesIndex: Lighting_GraphQlResult;
  Lighting_Admin_validateLuminairesIndex: Lighting_GraphQlResult;
  Lighting_Admin_validatePolesIndex: Lighting_GraphQlResult;
  Lighting_Admin_validatePowerBoxesIndex: Lighting_GraphQlResult;
  Lighting_createNewLuminaire: Lighting_GraphQlResultOfCreateNewLuminairePayload;
  Lighting_createNewLuminaireType: Lighting_GraphQlResultOfCreateNewLuminaireTypePayload;
  Lighting_createNewPole: Lighting_GraphQlResultOfCreateNewPolePayload;
  Lighting_createNewPowerBox: Lighting_GraphQlResultOfCreateNewPowerBoxPayload;
  Lighting_deleteLuminaire: Lighting_GraphQlResult;
  Lighting_deleteLuminaireType: Lighting_GraphQlResult;
  Lighting_deletePole: Lighting_GraphQlResult;
  Lighting_deletePowerBox: Lighting_GraphQlResult;
  Lighting_updateAreaSettings: Lighting_GraphQlResult;
  Lighting_updateLuminaire: Lighting_GraphQlResult;
  Lighting_updateLuminaireType: Lighting_GraphQlResult;
  Lighting_updatePole: Lighting_GraphQlResult;
  Lighting_updatePowerBox: Lighting_GraphQlResult;
  Reports_generateEnergyConsumptionReport: Reports_GraphQlResult;
  Reports_generateWorkTimeReport: Reports_GraphQlResult;
  Security_changeCurrentUserPassword?: Maybe<Security_IResult>;
  Security_signInClient?: Maybe<Security_SignInClientResponse>;
  Security_signInUser?: Maybe<Security_SignInUserResponse>;
  TrafficIoT_Admin_indexCounterJournals: TrafficIoT_GraphQlResult;
  TrafficIoT_Admin_indexCounters: TrafficIoT_GraphQlResult;
  TrafficIoT_Admin_validateCounterJournalsIndex: TrafficIoT_GraphQlResult;
  TrafficIoT_Admin_validateCountersIndex: TrafficIoT_GraphQlResult;
  TrafficIoT_addCounter: TrafficIoT_GraphQlResultOfAddCounterPayload;
  TrafficIoT_removeCounter: TrafficIoT_GraphQlResult;
  WeatherIoT_Admin_indexStationJournals: WeatherIoT_GraphQlResult;
  WeatherIoT_Admin_indexStations: WeatherIoT_GraphQlResult;
  WeatherIoT_Admin_validateStationJournalsIndex: WeatherIoT_GraphQlResult;
  WeatherIoT_Admin_validateStationsIndex: WeatherIoT_GraphQlResult;
  WeatherIoT_addStation: WeatherIoT_GraphQlResultOfAddStationPayload;
  WeatherIoT_removeStation: WeatherIoT_GraphQlResult;
  signOut?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationFailures_Admin_IndexFailuresArgs = {
  input: Failures_Admin_IndexFailuresInput;
};


export type MutationFailures_Admin_IndexObjectsArgs = {
  input: Failures_Admin_IndexObjectsInput;
};


export type MutationFailures_Admin_IndexReportersArgs = {
  input: Failures_Admin_IndexReportersInput;
};


export type MutationFailures_Admin_ValidateFailuresIndexArgs = {
  input: Failures_Admin_ValidateFailuresIndexInput;
};


export type MutationFailures_Admin_ValidateObjectsIndexArgs = {
  input: Failures_Admin_ValidateObjectsIndexInput;
};


export type MutationFailures_Admin_ValidateReportersIndexArgs = {
  input: Failures_Admin_ValidateReportersIndexInput;
};


export type MutationFailures_ActivateExternalUserReporterArgs = {
  input: Failures_ActivateExternalUserReporterInput;
};


export type MutationFailures_AddExternalUserReporterArgs = {
  input: Failures_AddExternalUserReporterInput;
};


export type MutationFailures_DeactivateExternalUserReporterArgs = {
  input: Failures_DeactivateExternalUserReporterInput;
};


export type MutationFailures_RejectManualFailureErrorArgs = {
  input: Failures_RejectManualFailureErrorInput;
};


export type MutationFailures_ReportFlagAsExternalUserArgs = {
  input: Failures_ReportFlagAsExternalUserInput;
};


export type MutationFailures_RequestFailureMaintenanceArgs = {
  input: Failures_RequestFailureMaintenanceInput;
};


export type MutationFailures_ResolveManualFailureErrorArgs = {
  input: Failures_ResolveManualFailureErrorInput;
};


export type MutationFailures_StartFailureMaintenanceArgs = {
  input: Failures_StartFailureMaintenanceInput;
};


export type MutationLightingIoT_Admin_IndexLightingSchedulesArgs = {
  input: LightingIoT_Admin_IndexLightingSchedulesInput;
};


export type MutationLightingIoT_Admin_IndexLuminaireJobsArgs = {
  input: LightingIoT_Admin_IndexLuminaireJobsInput;
};


export type MutationLightingIoT_Admin_IndexLuminaireJournalsArgs = {
  input: LightingIoT_Admin_IndexLuminaireJournalsInput;
};


export type MutationLightingIoT_Admin_IndexLuminairesArgs = {
  input: LightingIoT_Admin_IndexLuminairesInput;
};


export type MutationLightingIoT_Admin_InitializeAllControllersArgs = {
  input: LightingIoT_Admin_InitializeAllControllersInput;
};


export type MutationLightingIoT_Admin_ValidateLightingSchedulesIndexArgs = {
  input: LightingIoT_Admin_ValidateLightingSchedulesIndexInput;
};


export type MutationLightingIoT_Admin_ValidateLuminaireJobsIndexArgs = {
  input: LightingIoT_Admin_ValidateLuminaireJobsIndexInput;
};


export type MutationLightingIoT_Admin_ValidateLuminaireJournalsIndexArgs = {
  input: LightingIoT_Admin_ValidateLuminaireJournalsIndexInput;
};


export type MutationLightingIoT_Admin_ValidateLuminairesIndexArgs = {
  input: LightingIoT_Admin_ValidateLuminairesIndexInput;
};


export type MutationLightingIoT_AddControllerToLuminaireArgs = {
  input: LightingIoT_AddControllerToLuminaireInput;
};


export type MutationLightingIoT_AddLuminaireToLightingScheduleArgs = {
  input: LightingIoT_AddLuminaireToLightingScheduleInput;
};


export type MutationLightingIoT_AddLuminairesToLightingScheduleArgs = {
  input: LightingIoT_AddLuminairesToLightingScheduleInput;
};


export type MutationLightingIoT_CreateNewLightingScheduleArgs = {
  input: LightingIoT_CreateNewLightingScheduleInput;
};


export type MutationLightingIoT_DeleteLightingScheduleArgs = {
  input: LightingIoT_DeleteLightingScheduleInput;
};


export type MutationLightingIoT_RemoveControllerFromLuminaireArgs = {
  input: LightingIoT_RemoveControllerFromLuminaireInput;
};


export type MutationLightingIoT_RemoveLuminaireFromLightingScheduleArgs = {
  input: LightingIoT_RemoveLuminaireFromLightingScheduleInput;
};


export type MutationLightingIoT_RemoveLuminairesFromLightingScheduleArgs = {
  input: LightingIoT_RemoveLuminairesFromLightingScheduleInput;
};


export type MutationLightingIoT_UpdateLightingScheduleArgs = {
  input: LightingIoT_UpdateLightingScheduleInput;
};


export type MutationLighting_Admin_IndexLuminaireTypesArgs = {
  input: Lighting_Admin_IndexLuminaireTypesInput;
};


export type MutationLighting_Admin_IndexLuminairesArgs = {
  input: Lighting_Admin_IndexLuminairesInput;
};


export type MutationLighting_Admin_IndexPolesArgs = {
  input: Lighting_Admin_IndexPolesInput;
};


export type MutationLighting_Admin_IndexPowerBoxesArgs = {
  input: Lighting_Admin_IndexPowerBoxesInput;
};


export type MutationLighting_Admin_ValidateLuminaireTypesIndexArgs = {
  input: Lighting_Admin_ValidateLuminaireTypesIndexInput;
};


export type MutationLighting_Admin_ValidateLuminairesIndexArgs = {
  input: Lighting_Admin_ValidateLuminairesIndexInput;
};


export type MutationLighting_Admin_ValidatePolesIndexArgs = {
  input: Lighting_Admin_ValidatePolesIndexInput;
};


export type MutationLighting_Admin_ValidatePowerBoxesIndexArgs = {
  input: Lighting_Admin_ValidatePowerBoxesIndexInput;
};


export type MutationLighting_CreateNewLuminaireArgs = {
  input: Lighting_CreateNewLuminaireInput;
};


export type MutationLighting_CreateNewLuminaireTypeArgs = {
  input: Lighting_CreateNewLuminaireTypeInput;
};


export type MutationLighting_CreateNewPoleArgs = {
  input: Lighting_CreateNewPoleInput;
};


export type MutationLighting_CreateNewPowerBoxArgs = {
  input: Lighting_CreateNewPowerBoxInput;
};


export type MutationLighting_DeleteLuminaireArgs = {
  input: Lighting_DeleteLuminaireInput;
};


export type MutationLighting_DeleteLuminaireTypeArgs = {
  input: Lighting_DeleteLuminaireTypeInput;
};


export type MutationLighting_DeletePoleArgs = {
  input: Lighting_DeletePoleInput;
};


export type MutationLighting_DeletePowerBoxArgs = {
  input: Lighting_DeletePowerBoxInput;
};


export type MutationLighting_UpdateAreaSettingsArgs = {
  input: Lighting_UpdateAreaSettingsInput;
};


export type MutationLighting_UpdateLuminaireArgs = {
  input: Lighting_UpdateLuminaireInput;
};


export type MutationLighting_UpdateLuminaireTypeArgs = {
  input: Lighting_UpdateLuminaireTypeInput;
};


export type MutationLighting_UpdatePoleArgs = {
  input: Lighting_UpdatePoleInput;
};


export type MutationLighting_UpdatePowerBoxArgs = {
  input: Lighting_UpdatePowerBoxInput;
};


export type MutationReports_GenerateEnergyConsumptionReportArgs = {
  input: Reports_GenerateEnergyConsumptionReportInput;
};


export type MutationReports_GenerateWorkTimeReportArgs = {
  input: Reports_GenerateWorkTimeReportInput;
};


export type MutationSecurity_ChangeCurrentUserPasswordArgs = {
  input?: InputMaybe<Security_ChangeCurrentUserPasswordRequest_Input>;
};


export type MutationSecurity_SignInClientArgs = {
  input?: InputMaybe<Security_SignInClientRequest_Input>;
};


export type MutationSecurity_SignInUserArgs = {
  input?: InputMaybe<Security_SignInUserRequest_Input>;
};


export type MutationTrafficIoT_Admin_IndexCounterJournalsArgs = {
  input: TrafficIoT_Admin_IndexCounterJournalsInput;
};


export type MutationTrafficIoT_Admin_IndexCountersArgs = {
  input: TrafficIoT_Admin_IndexCountersInput;
};


export type MutationTrafficIoT_Admin_ValidateCounterJournalsIndexArgs = {
  input: TrafficIoT_Admin_ValidateCounterJournalsIndexInput;
};


export type MutationTrafficIoT_Admin_ValidateCountersIndexArgs = {
  input: TrafficIoT_Admin_ValidateCountersIndexInput;
};


export type MutationTrafficIoT_AddCounterArgs = {
  input: TrafficIoT_AddCounterInput;
};


export type MutationTrafficIoT_RemoveCounterArgs = {
  input: TrafficIoT_RemoveCounterInput;
};


export type MutationWeatherIoT_Admin_IndexStationJournalsArgs = {
  input: WeatherIoT_Admin_IndexStationJournalsInput;
};


export type MutationWeatherIoT_Admin_IndexStationsArgs = {
  input: WeatherIoT_Admin_IndexStationsInput;
};


export type MutationWeatherIoT_Admin_ValidateStationJournalsIndexArgs = {
  input: WeatherIoT_Admin_ValidateStationJournalsIndexInput;
};


export type MutationWeatherIoT_Admin_ValidateStationsIndexArgs = {
  input: WeatherIoT_Admin_ValidateStationsIndexInput;
};


export type MutationWeatherIoT_AddStationArgs = {
  input: WeatherIoT_AddStationInput;
};


export type MutationWeatherIoT_RemoveStationArgs = {
  input: WeatherIoT_RemoveStationInput;
};

export type Query = {
  __typename?: 'Query';
  Failures_failureById: Failures_GetFailureByIdPayload;
  Failures_failures: Failures_GetFailuresPayload;
  Failures_failuresPaged: Failures_GetFailuresPagedPayload;
  Failures_objectById: Failures_GetObjectByIdPayload;
  Failures_objects: Failures_GetObjectsPayload;
  Failures_objectsPaged: Failures_GetObjectsPagedPayload;
  Failures_reporterById: Failures_GetReporterByIdPayload;
  Failures_reporters: Failures_GetReportersPayload;
  Failures_reportersPaged: Failures_GetReportersPagedPayload;
  Failures_statistics: Failures_GetStatisticsPayload;
  LightingIoT_Admin_discoverLuminaireJournal: LightingIoT_GraphQlResultOfDiscoverLuminaireJournalPayload;
  LightingIoT_Admin_discoverLuminaireJournalEntries: LightingIoT_GraphQlResultOfDiscoverLuminaireJournalEntriesPayload;
  LightingIoT_Admin_discoverLuminaireJournals: LightingIoT_GraphQlResultOfDiscoverLuminaireJournalsPayload;
  LightingIoT_Admin_discoverLuminaires: LightingIoT_GraphQlResultOfDiscoverLuminairesPayload;
  LightingIoT_lightingScheduleById: LightingIoT_GetLightingScheduleByIdPayload;
  LightingIoT_lightingSchedules: LightingIoT_GetLightingSchedulesPayload;
  LightingIoT_lightingSchedulesPaged: LightingIoT_GetLightingSchedulesPagedPayload;
  LightingIoT_luminaireById: LightingIoT_GetLuminaireByIdPayload;
  LightingIoT_luminaireJobs: LightingIoT_GetLuminaireJobsPayload;
  LightingIoT_luminaireJobsPaged: LightingIoT_GetLuminaireJobsPagedPayload;
  LightingIoT_luminaireJournalById: LightingIoT_GetLuminaireJournalByIdPayload;
  LightingIoT_luminaireJournals: LightingIoT_GetLuminaireJournalsPayload;
  LightingIoT_luminaireJournalsPaged: LightingIoT_GetLuminaireJournalsPagedPayload;
  LightingIoT_luminaires: LightingIoT_GetLuminairesPayload;
  LightingIoT_luminairesPaged: LightingIoT_GetLuminairesPagedPayload;
  LightingIoT_statistics: LightingIoT_GetStatisticsPayload;
  Lighting_Admin_discoverLuminaireTypes: Lighting_GraphQlResultOfDiscoverLuminaireTypesPayload;
  Lighting_Admin_discoverLuminaires: Lighting_GraphQlResultOfDiscoverLuminairesPayload;
  Lighting_areaById: Lighting_GetAreaByIdPayload;
  Lighting_luminaireById: Lighting_GetLuminaireByIdPayload;
  Lighting_luminaireTypeById: Lighting_GetLuminaireTypeByIdPayload;
  Lighting_luminaireTypes: Lighting_GetLuminaireTypesPayload;
  Lighting_luminaireTypesPaged: Lighting_GetLuminaireTypesPagedPayload;
  Lighting_luminaires: Lighting_GetLuminairesPayload;
  Lighting_luminairesPaged: Lighting_GetLuminairesPagedPayload;
  Lighting_poleById: Lighting_GetPoleByIdPayload;
  Lighting_poles: Lighting_GetPolesPayload;
  Lighting_polesPaged: Lighting_GetPolesPagedPayload;
  Lighting_powerBoxById: Lighting_GetPowerBoxByIdPayload;
  Lighting_powerBoxes: Lighting_GetPowerBoxesPayload;
  Lighting_powerBoxesPaged: Lighting_GetPowerBoxesPagedPayload;
  Lighting_statistics: Lighting_GetStatisticsPayload;
  Reports_objects: Reports_GetObjectsPayload;
  Reports_reports: Reports_GetReportsPayload;
  Reports_reportsPaged: Reports_GetReportsPagedPayload;
  Security_getCurrentClient?: Maybe<Security_GetCurrentClientResponse>;
  Security_getCurrentClientPermittedAreas?: Maybe<Security_GetCurrentClientPermittedAreasResponse>;
  Security_getCurrentUser?: Maybe<Security_GetCurrentUserResponse>;
  Security_getCurrentUserPermissions?: Maybe<Security_GetCurrentUserPermissionsResponse>;
  Security_getCurrentUserPermittedAreas?: Maybe<Security_GetCurrentUserPermittedAreasResponse>;
  TrafficIoT_Admin_discoverCounterJournal: TrafficIoT_GraphQlResultOfDiscoverCounterJournalPayload;
  TrafficIoT_Admin_discoverCounterJournalEntries: TrafficIoT_GraphQlResultOfDiscoverCounterJournalEntriesPayload;
  TrafficIoT_Admin_discoverCounterJournals: TrafficIoT_GraphQlResultOfDiscoverCounterJournalsPayload;
  TrafficIoT_Admin_discoverCounters: TrafficIoT_GraphQlResultOfDiscoverCountersPayload;
  TrafficIoT_counterById: TrafficIoT_GetCounterByIdPayload;
  TrafficIoT_counterJournalById: TrafficIoT_GetCounterJournalByIdPayload;
  TrafficIoT_counterJournals: TrafficIoT_GetCounterJournalsPayload;
  TrafficIoT_counterJournalsPaged: TrafficIoT_GetCounterJournalsPagedPayload;
  TrafficIoT_counters: TrafficIoT_GetCountersPayload;
  TrafficIoT_countersPaged: TrafficIoT_GetCountersPagedPayload;
  WeatherIoT_stationById: WeatherIoT_GetStationByIdPayload;
  WeatherIoT_stationJournalById: WeatherIoT_GetStationJournalByIdPayload;
  WeatherIoT_stationJournals: WeatherIoT_GetStationJournalsPayload;
  WeatherIoT_stationJournalsPaged: WeatherIoT_GetStationJournalsPagedPayload;
  WeatherIoT_stations: WeatherIoT_GetStationsPayload;
  WeatherIoT_stationsPaged: WeatherIoT_GetStationsPagedPayload;
  isSignedIn: Scalars['Boolean']['output'];
};


export type QueryFailures_FailureByIdArgs = {
  input: Failures_GetFailureByIdInput;
};


export type QueryFailures_FailuresArgs = {
  input: Failures_GetFailuresInput;
};


export type QueryFailures_FailuresPagedArgs = {
  input: Failures_GetFailuresPagedInput;
};


export type QueryFailures_ObjectByIdArgs = {
  input: Failures_GetObjectByIdInput;
};


export type QueryFailures_ObjectsArgs = {
  input: Failures_GetObjectsInput;
};


export type QueryFailures_ObjectsPagedArgs = {
  input: Failures_GetObjectsPagedInput;
};


export type QueryFailures_ReporterByIdArgs = {
  input: Failures_GetReporterByIdInput;
};


export type QueryFailures_ReportersArgs = {
  input: Failures_GetReportersInput;
};


export type QueryFailures_ReportersPagedArgs = {
  input: Failures_GetReportersPagedInput;
};


export type QueryFailures_StatisticsArgs = {
  input: Failures_GetStatisticsInput;
};


export type QueryLightingIoT_Admin_DiscoverLuminaireJournalArgs = {
  input: LightingIoT_Admin_DiscoverLuminaireJournalInput;
};


export type QueryLightingIoT_Admin_DiscoverLuminaireJournalEntriesArgs = {
  input: LightingIoT_Admin_DiscoverLuminaireJournalEntriesInput;
};


export type QueryLightingIoT_Admin_DiscoverLuminaireJournalsArgs = {
  input: LightingIoT_Admin_DiscoverLuminaireJournalsInput;
};


export type QueryLightingIoT_Admin_DiscoverLuminairesArgs = {
  input: LightingIoT_Admin_DiscoverLuminairesInput;
};


export type QueryLightingIoT_LightingScheduleByIdArgs = {
  input: LightingIoT_GetLightingScheduleByIdInput;
};


export type QueryLightingIoT_LightingSchedulesArgs = {
  input: LightingIoT_GetLightingSchedulesInput;
};


export type QueryLightingIoT_LightingSchedulesPagedArgs = {
  input: LightingIoT_GetLightingSchedulesPagedInput;
};


export type QueryLightingIoT_LuminaireByIdArgs = {
  input: LightingIoT_GetLuminaireByIdInput;
};


export type QueryLightingIoT_LuminaireJobsArgs = {
  input: LightingIoT_GetLuminaireJobsInput;
};


export type QueryLightingIoT_LuminaireJobsPagedArgs = {
  input: LightingIoT_GetLuminaireJobsPagedInput;
};


export type QueryLightingIoT_LuminaireJournalByIdArgs = {
  input: LightingIoT_GetLuminaireJournalByIdInput;
};


export type QueryLightingIoT_LuminaireJournalsArgs = {
  input: LightingIoT_GetLuminaireJournalsInput;
};


export type QueryLightingIoT_LuminaireJournalsPagedArgs = {
  input: LightingIoT_GetLuminaireJournalsPagedInput;
};


export type QueryLightingIoT_LuminairesArgs = {
  input: LightingIoT_GetLuminairesInput;
};


export type QueryLightingIoT_LuminairesPagedArgs = {
  input: LightingIoT_GetLuminairesPagedInput;
};


export type QueryLightingIoT_StatisticsArgs = {
  input: LightingIoT_GetStatisticsInput;
};


export type QueryLighting_Admin_DiscoverLuminaireTypesArgs = {
  input: Lighting_Admin_DiscoverLuminaireTypesInput;
};


export type QueryLighting_Admin_DiscoverLuminairesArgs = {
  input: Lighting_Admin_DiscoverLuminairesInput;
};


export type QueryLighting_AreaByIdArgs = {
  input: Lighting_GetAreaByIdInput;
};


export type QueryLighting_LuminaireByIdArgs = {
  input: Lighting_GetLuminaireByIdInput;
};


export type QueryLighting_LuminaireTypeByIdArgs = {
  input: Lighting_GetLuminaireTypeByIdInput;
};


export type QueryLighting_LuminaireTypesArgs = {
  input: Lighting_GetLuminaireTypesInput;
};


export type QueryLighting_LuminaireTypesPagedArgs = {
  input: Lighting_GetLuminaireTypesPagedInput;
};


export type QueryLighting_LuminairesArgs = {
  input: Lighting_GetLuminairesInput;
};


export type QueryLighting_LuminairesPagedArgs = {
  input: Lighting_GetLuminairesPagedInput;
};


export type QueryLighting_PoleByIdArgs = {
  input: Lighting_GetPoleByIdInput;
};


export type QueryLighting_PolesArgs = {
  input: Lighting_GetPolesInput;
};


export type QueryLighting_PolesPagedArgs = {
  input: Lighting_GetPolesPagedInput;
};


export type QueryLighting_PowerBoxByIdArgs = {
  input: Lighting_GetPowerBoxByIdInput;
};


export type QueryLighting_PowerBoxesArgs = {
  input: Lighting_GetPowerBoxesInput;
};


export type QueryLighting_PowerBoxesPagedArgs = {
  input: Lighting_GetPowerBoxesPagedInput;
};


export type QueryLighting_StatisticsArgs = {
  input: Lighting_GetStatisticsInput;
};


export type QueryReports_ObjectsArgs = {
  input: Reports_GetObjectsInput;
};


export type QueryReports_ReportsArgs = {
  input: Reports_GetReportsInput;
};


export type QueryReports_ReportsPagedArgs = {
  input: Reports_GetReportsPagedInput;
};


export type QuerySecurity_GetCurrentUserPermissionsArgs = {
  areaId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTrafficIoT_Admin_DiscoverCounterJournalArgs = {
  input: TrafficIoT_Admin_DiscoverCounterJournalInput;
};


export type QueryTrafficIoT_Admin_DiscoverCounterJournalEntriesArgs = {
  input: TrafficIoT_Admin_DiscoverCounterJournalEntriesInput;
};


export type QueryTrafficIoT_Admin_DiscoverCounterJournalsArgs = {
  input: TrafficIoT_Admin_DiscoverCounterJournalsInput;
};


export type QueryTrafficIoT_Admin_DiscoverCountersArgs = {
  input: TrafficIoT_Admin_DiscoverCountersInput;
};


export type QueryTrafficIoT_CounterByIdArgs = {
  input: TrafficIoT_GetCounterByIdInput;
};


export type QueryTrafficIoT_CounterJournalByIdArgs = {
  input: TrafficIoT_GetCounterJournalByIdInput;
};


export type QueryTrafficIoT_CounterJournalsArgs = {
  input: TrafficIoT_GetCounterJournalsInput;
};


export type QueryTrafficIoT_CounterJournalsPagedArgs = {
  input: TrafficIoT_GetCounterJournalsPagedInput;
};


export type QueryTrafficIoT_CountersArgs = {
  input: TrafficIoT_GetCountersInput;
};


export type QueryTrafficIoT_CountersPagedArgs = {
  input: TrafficIoT_GetCountersPagedInput;
};


export type QueryWeatherIoT_StationByIdArgs = {
  input: WeatherIoT_GetStationByIdInput;
};


export type QueryWeatherIoT_StationJournalByIdArgs = {
  input: WeatherIoT_GetStationJournalByIdInput;
};


export type QueryWeatherIoT_StationJournalsArgs = {
  input: WeatherIoT_GetStationJournalsInput;
};


export type QueryWeatherIoT_StationJournalsPagedArgs = {
  input: WeatherIoT_GetStationJournalsPagedInput;
};


export type QueryWeatherIoT_StationsArgs = {
  input: WeatherIoT_GetStationsInput;
};


export type QueryWeatherIoT_StationsPagedArgs = {
  input: WeatherIoT_GetStationsPagedInput;
};

export enum Reports_ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Reports_GenerateEnergyConsumptionReportInput = {
  areaId: Scalars['UUID']['input'];
  language: Reports_LanguageModel;
  recordsDateEnd: Scalars['Date']['input'];
  recordsDateStart: Scalars['Date']['input'];
  recordsScope: Reports_ReportRecordScopeModel;
  selectedObjectIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type Reports_GenerateWorkTimeReportInput = {
  areaId: Scalars['UUID']['input'];
  language: Reports_LanguageModel;
  recordsDateEnd: Scalars['Date']['input'];
  recordsDateStart: Scalars['Date']['input'];
  recordsScope: Reports_ReportRecordScopeModel;
  selectedObjectIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type Reports_GetObjectsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Reports_ObjectFiltersModelInput>;
};

export type Reports_GetObjectsPayload = {
  __typename?: 'Reports_GetObjectsPayload';
  count: Scalars['Int']['output'];
  items: Array<Reports_ObjectRowModel>;
  total: Scalars['Int']['output'];
};

export type Reports_GetReportsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Reports_ReportFiltersModelInput>;
};

export type Reports_GetReportsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<Reports_ReportFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type Reports_GetReportsPagedPayload = {
  __typename?: 'Reports_GetReportsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<Reports_ReportRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Reports_GetReportsPayload = {
  __typename?: 'Reports_GetReportsPayload';
  count: Scalars['Int']['output'];
  items: Array<Reports_ReportRowModel>;
  total: Scalars['Int']['output'];
};

export type Reports_GraphQlError = {
  __typename?: 'Reports_GraphQLError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Array<Reports_KeyValuePairOfStringAndString>;
};

export type Reports_GraphQlResult = {
  __typename?: 'Reports_GraphQLResult';
  created: Scalars['DateTime']['output'];
  errors: Array<Reports_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Reports_KeyValuePairOfStringAndString = {
  __typename?: 'Reports_KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum Reports_LanguageModel {
  English = 'ENGLISH',
  Polish = 'POLISH'
}

export type Reports_ObjectFiltersModelInput = {
  matchesName?: InputMaybe<Scalars['String']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
  ofType?: InputMaybe<Reports_ObjectTypeModel>;
};

export type Reports_ObjectRowModel = {
  __typename?: 'Reports_ObjectRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: Reports_ObjectTypeModel;
  version: Scalars['Int']['output'];
};

export enum Reports_ObjectTypeModel {
  Luminaire = 'LUMINAIRE'
}

export type Reports_ReportFiltersModelInput = {
  maxRecordsDateEnd?: InputMaybe<Scalars['Date']['input']>;
  maxRecordsDateStart?: InputMaybe<Scalars['Date']['input']>;
  minRecordsDateEnd?: InputMaybe<Scalars['Date']['input']>;
  minRecordsDateStart?: InputMaybe<Scalars['Date']['input']>;
  ofId?: InputMaybe<Scalars['UUID']['input']>;
  ofLanguage?: InputMaybe<Reports_LanguageModel>;
  ofState?: InputMaybe<Reports_ReportStateModel>;
  ofType?: InputMaybe<Reports_ReportTypeModel>;
};

export enum Reports_ReportRecordScope {
  Daily = 'DAILY',
  Total = 'TOTAL'
}

export enum Reports_ReportRecordScopeModel {
  Daily = 'DAILY',
  Total = 'TOTAL'
}

export type Reports_ReportRowModel = {
  __typename?: 'Reports_ReportRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  language: Reports_LanguageModel;
  recordsDateEnd?: Maybe<Scalars['DateTime']['output']>;
  recordsDateStart?: Maybe<Scalars['DateTime']['output']>;
  recordsScope?: Maybe<Reports_ReportRecordScope>;
  state: Reports_ReportStateModel;
  type: Reports_ReportTypeModel;
  version: Scalars['Int']['output'];
};

export enum Reports_ReportStateModel {
  Draft = 'DRAFT',
  Fulfilled = 'FULFILLED',
  Issued = 'ISSUED',
  Ready = 'READY'
}

export enum Reports_ReportTypeModel {
  EnergyConsumption = 'ENERGY_CONSUMPTION',
  WorkTime = 'WORK_TIME'
}

export type Security_AreaReadModel = {
  __typename?: 'Security_AreaReadModel';
  center: Security_CoordinatesModel;
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  enabledApis: Array<Maybe<Scalars['String']['output']>>;
  hasSummerTime: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  timeZone: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};

export type Security_ChangeCurrentUserPasswordRequest_Input = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type Security_ClientAreaRoleReadModel = {
  __typename?: 'Security_ClientAreaRoleReadModel';
  areaId: Scalars['UUID']['output'];
  roleId: Scalars['UUID']['output'];
};

export type Security_ClientKeyReadModel = {
  __typename?: 'Security_ClientKeyReadModel';
  id: Scalars['UUID']['output'];
  invalidated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type Security_ClientReadModel = {
  __typename?: 'Security_ClientReadModel';
  areaRoles: Array<Maybe<Security_ClientAreaRoleReadModel>>;
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  globalRole?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  keys: Array<Maybe<Security_ClientKeyReadModel>>;
  name: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type Security_CoordinatesModel = {
  __typename?: 'Security_CoordinatesModel';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type Security_GetCurrentClientPermittedAreasResponse = {
  __typename?: 'Security_GetCurrentClientPermittedAreasResponse';
  areas: Array<Maybe<Security_AreaReadModel>>;
};

export type Security_GetCurrentClientResponse = {
  __typename?: 'Security_GetCurrentClientResponse';
  client?: Maybe<Security_ClientReadModel>;
};

export type Security_GetCurrentUserPermissionsResponse = {
  __typename?: 'Security_GetCurrentUserPermissionsResponse';
  permissions: Array<Maybe<Scalars['String']['output']>>;
};

export type Security_GetCurrentUserPermittedAreasResponse = {
  __typename?: 'Security_GetCurrentUserPermittedAreasResponse';
  areas: Array<Maybe<Security_AreaReadModel>>;
};

export type Security_GetCurrentUserResponse = {
  __typename?: 'Security_GetCurrentUserResponse';
  user?: Maybe<Security_UserReadModel>;
};

export enum Security_HttpMethod {
  Connect = 'CONNECT',
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
  Trace = 'TRACE'
}

export type Security_IError = {
  __typename?: 'Security_IError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Scalars['JSON']['output'];
};

export type Security_IResult = {
  __typename?: 'Security_IResult';
  created: Scalars['DateTime']['output'];
  errors: Array<Maybe<Security_IError>>;
  isFailed: Scalars['Boolean']['output'];
  isSuccess: Scalars['Boolean']['output'];
};

export type Security_SignInClientRequest_Input = {
  clientId: Scalars['UUID']['input'];
  clientSecret: Scalars['String']['input'];
};

export type Security_SignInClientResponse = {
  __typename?: 'Security_SignInClientResponse';
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type Security_SignInUserRequest_Input = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Security_SignInUserResponse = {
  __typename?: 'Security_SignInUserResponse';
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type Security_UserAreaRoleReadModel = {
  __typename?: 'Security_UserAreaRoleReadModel';
  area: Scalars['UUID']['output'];
  role: Scalars['UUID']['output'];
  user: Scalars['UUID']['output'];
};

export type Security_UserReadModel = {
  __typename?: 'Security_UserReadModel';
  areaRoles: Array<Maybe<Security_UserAreaRoleReadModel>>;
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  globalRole?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  version: Scalars['Int']['output'];
};

export type TrafficIoT_AddCounterInput = {
  areaId: Scalars['UUID']['input'];
  location: TrafficIoT_CoordinatesModelInput;
  name: Scalars['String']['input'];
  smartlyId: Scalars['String']['input'];
};

export type TrafficIoT_AddCounterPayload = {
  __typename?: 'TrafficIoT_AddCounterPayload';
  id: Scalars['UUID']['output'];
};

export type TrafficIoT_Admin_DiscoverCounterJournalEntriesInput = {
  areaId: Scalars['UUID']['input'];
  entriesFrom: Scalars['DateTime']['input'];
  entriesTo: Scalars['DateTime']['input'];
  from: Scalars['Int']['input'];
  journalId: Scalars['UUID']['input'];
  minQuality: TrafficIoT_CounterJournalEntryQualitySharedModel;
  ofType: TrafficIoT_CounterJournalEntryTypeSharedModel;
  to: Scalars['Int']['input'];
};

export type TrafficIoT_Admin_DiscoverCounterJournalEntriesPayload = {
  __typename?: 'TrafficIoT_Admin_DiscoverCounterJournalEntriesPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterJournalEntrySharedModel>;
  total: Scalars['Int']['output'];
};

export type TrafficIoT_Admin_DiscoverCounterJournalInput = {
  areaId: Scalars['UUID']['input'];
  counterId: Scalars['UUID']['input'];
};

export type TrafficIoT_Admin_DiscoverCounterJournalPayload = {
  __typename?: 'TrafficIoT_Admin_DiscoverCounterJournalPayload';
  journal?: Maybe<TrafficIoT_CounterJournalSharedModel>;
};

export type TrafficIoT_Admin_DiscoverCounterJournalsInput = {
  areaId: Scalars['UUID']['input'];
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type TrafficIoT_Admin_DiscoverCounterJournalsPayload = {
  __typename?: 'TrafficIoT_Admin_DiscoverCounterJournalsPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterJournalSharedModel>;
  total: Scalars['Int']['output'];
};

export type TrafficIoT_Admin_DiscoverCountersInput = {
  areaId: Scalars['UUID']['input'];
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type TrafficIoT_Admin_DiscoverCountersPayload = {
  __typename?: 'TrafficIoT_Admin_DiscoverCountersPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterSharedModel>;
  total: Scalars['Int']['output'];
};

export type TrafficIoT_Admin_IndexCounterJournalsInput = {
  areaId: Scalars['UUID']['input'];
};

export type TrafficIoT_Admin_IndexCountersInput = {
  areaId: Scalars['UUID']['input'];
};

export type TrafficIoT_Admin_ValidateCounterJournalsIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type TrafficIoT_Admin_ValidateCountersIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export enum TrafficIoT_ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type TrafficIoT_CoordinatesModel = {
  __typename?: 'TrafficIoT_CoordinatesModel';
  hash?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type TrafficIoT_CoordinatesModelInput = {
  hash?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type TrafficIoT_CounterFiltersModelInput = {
  matchesName?: InputMaybe<Scalars['String']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
};

export type TrafficIoT_CounterJournalEntryBikeCountSectionSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalEntryBikeCountSectionSharedModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalEntryCarCountSectionSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalEntryCarCountSectionSharedModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalEntryPeopleCountSectionSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalEntryPeopleCountSectionSharedModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
};

export enum TrafficIoT_CounterJournalEntryQualitySharedModel {
  High = 'HIGH',
  Low = 'LOW'
}

export type TrafficIoT_CounterJournalEntrySharedModel = {
  __typename?: 'TrafficIoT_CounterJournalEntrySharedModel';
  bikeCount?: Maybe<TrafficIoT_CounterJournalEntryBikeCountSectionSharedModel>;
  carCount?: Maybe<TrafficIoT_CounterJournalEntryCarCountSectionSharedModel>;
  date: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  peopleCount?: Maybe<TrafficIoT_CounterJournalEntryPeopleCountSectionSharedModel>;
  quality: TrafficIoT_CounterJournalEntryQualitySharedModel;
  status?: Maybe<TrafficIoT_CounterJournalEntryStatusSectionSharedModel>;
  truckCount?: Maybe<TrafficIoT_CounterJournalEntryTruckCountSectionSharedModel>;
  type: TrafficIoT_CounterJournalEntryTypeSharedModel;
};

export type TrafficIoT_CounterJournalEntryStatusSectionSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalEntryStatusSectionSharedModel';
  voltage?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalEntryTruckCountSectionSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalEntryTruckCountSectionSharedModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
};

export enum TrafficIoT_CounterJournalEntryTypeSharedModel {
  BikeCount = 'BIKE_COUNT',
  CarCount = 'CAR_COUNT',
  PeopleCount = 'PEOPLE_COUNT',
  Status = 'STATUS',
  TruckCount = 'TRUCK_COUNT'
}

export type TrafficIoT_CounterJournalFiltersModelInput = {
  ofCounterId?: InputMaybe<Scalars['UUID']['input']>;
};

export type TrafficIoT_CounterJournalModel = {
  __typename?: 'TrafficIoT_CounterJournalModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  counterId: Scalars['UUID']['output'];
  created: Scalars['DateTime']['output'];
  dailySummary: TrafficIoT_CounterJournalSummaryModel;
  id: Scalars['UUID']['output'];
  monthlySummary: TrafficIoT_CounterJournalSummaryModel;
  status: TrafficIoT_CounterJournalStatusModel;
  version: Scalars['Int']['output'];
};

export type TrafficIoT_CounterJournalRowModel = {
  __typename?: 'TrafficIoT_CounterJournalRowModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  counterId: Scalars['UUID']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  status: TrafficIoT_CounterJournalStatusModel;
  version: Scalars['Int']['output'];
};

export type TrafficIoT_CounterJournalSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalSharedModel';
  areaId: Scalars['UUID']['output'];
  counterId: Scalars['UUID']['output'];
  entries: Array<TrafficIoT_CounterJournalEntrySharedModel>;
  id: Scalars['UUID']['output'];
  status?: Maybe<TrafficIoT_CounterJournalStatusSharedModel>;
};

export type TrafficIoT_CounterJournalStatusModel = {
  __typename?: 'TrafficIoT_CounterJournalStatusModel';
  bikeCount?: Maybe<Scalars['Int']['output']>;
  bikeCountDate?: Maybe<Scalars['DateTime']['output']>;
  carCount?: Maybe<Scalars['Int']['output']>;
  carCountDate?: Maybe<Scalars['DateTime']['output']>;
  communicationDate?: Maybe<Scalars['DateTime']['output']>;
  peopleCount?: Maybe<Scalars['Int']['output']>;
  peopleCountDate?: Maybe<Scalars['DateTime']['output']>;
  truckCount?: Maybe<Scalars['Int']['output']>;
  truckCountDate?: Maybe<Scalars['DateTime']['output']>;
  voltage?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalStatusSharedModel = {
  __typename?: 'TrafficIoT_CounterJournalStatusSharedModel';
  bikeCount?: Maybe<Scalars['Int']['output']>;
  bikeCountDate?: Maybe<Scalars['DateTime']['output']>;
  carCount?: Maybe<Scalars['Int']['output']>;
  carCountDate?: Maybe<Scalars['DateTime']['output']>;
  communicationDate?: Maybe<Scalars['DateTime']['output']>;
  peopleCount?: Maybe<Scalars['Int']['output']>;
  peopleCountDate?: Maybe<Scalars['DateTime']['output']>;
  truckCount?: Maybe<Scalars['Int']['output']>;
  truckCountDate?: Maybe<Scalars['DateTime']['output']>;
  voltage?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalSummaryItemBikeCountSectionModel = {
  __typename?: 'TrafficIoT_CounterJournalSummaryItemBikeCountSectionModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  leftToRightMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
  maxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  minAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalSummaryItemCarCountSectionModel = {
  __typename?: 'TrafficIoT_CounterJournalSummaryItemCarCountSectionModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  leftToRightMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
  maxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  minAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalSummaryItemModel = {
  __typename?: 'TrafficIoT_CounterJournalSummaryItemModel';
  bikeCount?: Maybe<TrafficIoT_CounterJournalSummaryItemBikeCountSectionModel>;
  carCount?: Maybe<TrafficIoT_CounterJournalSummaryItemCarCountSectionModel>;
  date: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  peopleCount?: Maybe<TrafficIoT_CounterJournalSummaryItemPeopleCountSectionModel>;
  truckCount?: Maybe<TrafficIoT_CounterJournalSummaryItemTruckCountSectionModel>;
  type: TrafficIoT_CounterJournalSummaryItemTypeModel;
};

export type TrafficIoT_CounterJournalSummaryItemPeopleCountSectionModel = {
  __typename?: 'TrafficIoT_CounterJournalSummaryItemPeopleCountSectionModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  leftToRightMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
  maxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  minAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
};

export type TrafficIoT_CounterJournalSummaryItemTruckCountSectionModel = {
  __typename?: 'TrafficIoT_CounterJournalSummaryItemTruckCountSectionModel';
  averageSpeed?: Maybe<Scalars['Int']['output']>;
  count: Scalars['Int']['output'];
  leftToRightAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightCount?: Maybe<Scalars['Int']['output']>;
  leftToRightMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  leftToRightMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
  maxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  minAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftCount?: Maybe<Scalars['Int']['output']>;
  rightToLeftMaxAverageSpeed?: Maybe<Scalars['Int']['output']>;
  rightToLeftMinAverageSpeed?: Maybe<Scalars['Int']['output']>;
};

export enum TrafficIoT_CounterJournalSummaryItemTypeModel {
  BikeCount = 'BIKE_COUNT',
  CarCount = 'CAR_COUNT',
  PeopleCount = 'PEOPLE_COUNT',
  TruckCount = 'TRUCK_COUNT'
}

export type TrafficIoT_CounterJournalSummaryModel = {
  __typename?: 'TrafficIoT_CounterJournalSummaryModel';
  id: Scalars['UUID']['output'];
  items: Array<TrafficIoT_CounterJournalSummaryItemModel>;
  range: TrafficIoT_CounterJournalSummaryRangeModel;
};

export enum TrafficIoT_CounterJournalSummaryRangeModel {
  Daily = 'DAILY',
  Monthly = 'MONTHLY'
}

export type TrafficIoT_CounterModel = {
  __typename?: 'TrafficIoT_CounterModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  location: TrafficIoT_CoordinatesModel;
  name: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  smartlyId: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type TrafficIoT_CounterRowModel = {
  __typename?: 'TrafficIoT_CounterRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: TrafficIoT_CoordinatesModel;
  name: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type TrafficIoT_CounterSharedModel = {
  __typename?: 'TrafficIoT_CounterSharedModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  smartlyId: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type TrafficIoT_GetCounterByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type TrafficIoT_GetCounterByIdPayload = {
  __typename?: 'TrafficIoT_GetCounterByIdPayload';
  counter?: Maybe<TrafficIoT_CounterModel>;
};

export type TrafficIoT_GetCounterJournalByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type TrafficIoT_GetCounterJournalByIdPayload = {
  __typename?: 'TrafficIoT_GetCounterJournalByIdPayload';
  journal?: Maybe<TrafficIoT_CounterJournalModel>;
};

export type TrafficIoT_GetCounterJournalsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<TrafficIoT_CounterJournalFiltersModelInput>;
};

export type TrafficIoT_GetCounterJournalsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<TrafficIoT_CounterJournalFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type TrafficIoT_GetCounterJournalsPagedPayload = {
  __typename?: 'TrafficIoT_GetCounterJournalsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterJournalRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TrafficIoT_GetCounterJournalsPayload = {
  __typename?: 'TrafficIoT_GetCounterJournalsPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterJournalRowModel>;
  total: Scalars['Int']['output'];
};

export type TrafficIoT_GetCountersInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<TrafficIoT_CounterFiltersModelInput>;
};

export type TrafficIoT_GetCountersPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<TrafficIoT_CounterFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type TrafficIoT_GetCountersPagedPayload = {
  __typename?: 'TrafficIoT_GetCountersPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TrafficIoT_GetCountersPayload = {
  __typename?: 'TrafficIoT_GetCountersPayload';
  count: Scalars['Int']['output'];
  items: Array<TrafficIoT_CounterRowModel>;
  total: Scalars['Int']['output'];
};

export type TrafficIoT_GraphQlError = {
  __typename?: 'TrafficIoT_GraphQLError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Array<TrafficIoT_KeyValuePairOfStringAndString>;
};

export type TrafficIoT_GraphQlResult = {
  __typename?: 'TrafficIoT_GraphQLResult';
  created: Scalars['DateTime']['output'];
  errors: Array<TrafficIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type TrafficIoT_GraphQlResultOfAddCounterPayload = {
  __typename?: 'TrafficIoT_GraphQLResultOfAddCounterPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<TrafficIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<TrafficIoT_AddCounterPayload>;
};

export type TrafficIoT_GraphQlResultOfDiscoverCounterJournalEntriesPayload = {
  __typename?: 'TrafficIoT_GraphQLResultOfDiscoverCounterJournalEntriesPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<TrafficIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<TrafficIoT_Admin_DiscoverCounterJournalEntriesPayload>;
};

export type TrafficIoT_GraphQlResultOfDiscoverCounterJournalPayload = {
  __typename?: 'TrafficIoT_GraphQLResultOfDiscoverCounterJournalPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<TrafficIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<TrafficIoT_Admin_DiscoverCounterJournalPayload>;
};

export type TrafficIoT_GraphQlResultOfDiscoverCounterJournalsPayload = {
  __typename?: 'TrafficIoT_GraphQLResultOfDiscoverCounterJournalsPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<TrafficIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<TrafficIoT_Admin_DiscoverCounterJournalsPayload>;
};

export type TrafficIoT_GraphQlResultOfDiscoverCountersPayload = {
  __typename?: 'TrafficIoT_GraphQLResultOfDiscoverCountersPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<TrafficIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<TrafficIoT_Admin_DiscoverCountersPayload>;
};

export type TrafficIoT_KeyValuePairOfStringAndString = {
  __typename?: 'TrafficIoT_KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TrafficIoT_RemoveCounterInput = {
  areaId: Scalars['UUID']['input'];
  counterId: Scalars['UUID']['input'];
  counterVersion: Scalars['Int']['input'];
};

export type WeatherIoT_AddStationInput = {
  areaId: Scalars['UUID']['input'];
  location: WeatherIoT_CoordinatesModelInput;
  name: Scalars['String']['input'];
  smartlyId: Scalars['String']['input'];
};

export type WeatherIoT_AddStationPayload = {
  __typename?: 'WeatherIoT_AddStationPayload';
  id: Scalars['UUID']['output'];
};

export type WeatherIoT_Admin_IndexStationJournalsInput = {
  areaId: Scalars['UUID']['input'];
};

export type WeatherIoT_Admin_IndexStationsInput = {
  areaId: Scalars['UUID']['input'];
};

export type WeatherIoT_Admin_ValidateStationJournalsIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export type WeatherIoT_Admin_ValidateStationsIndexInput = {
  areaId: Scalars['UUID']['input'];
};

export enum WeatherIoT_ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type WeatherIoT_CoordinatesModel = {
  __typename?: 'WeatherIoT_CoordinatesModel';
  hash?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type WeatherIoT_CoordinatesModelInput = {
  hash?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type WeatherIoT_GetStationByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type WeatherIoT_GetStationByIdPayload = {
  __typename?: 'WeatherIoT_GetStationByIdPayload';
  station?: Maybe<WeatherIoT_StationModel>;
};

export type WeatherIoT_GetStationJournalByIdInput = {
  areaId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type WeatherIoT_GetStationJournalByIdPayload = {
  __typename?: 'WeatherIoT_GetStationJournalByIdPayload';
  journal?: Maybe<WeatherIoT_StationJournalModel>;
};

export type WeatherIoT_GetStationJournalsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<WeatherIoT_StationJournalFiltersModelInput>;
};

export type WeatherIoT_GetStationJournalsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<WeatherIoT_StationJournalFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type WeatherIoT_GetStationJournalsPagedPayload = {
  __typename?: 'WeatherIoT_GetStationJournalsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<WeatherIoT_StationJournalRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WeatherIoT_GetStationJournalsPayload = {
  __typename?: 'WeatherIoT_GetStationJournalsPayload';
  count: Scalars['Int']['output'];
  items: Array<WeatherIoT_StationJournalRowModel>;
  total: Scalars['Int']['output'];
};

export type WeatherIoT_GetStationsInput = {
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<WeatherIoT_StationFiltersModelInput>;
};

export type WeatherIoT_GetStationsPagedInput = {
  amount: Scalars['Int']['input'];
  areaId: Scalars['UUID']['input'];
  filters?: InputMaybe<WeatherIoT_StationFiltersModelInput>;
  page: Scalars['Int']['input'];
};

export type WeatherIoT_GetStationsPagedPayload = {
  __typename?: 'WeatherIoT_GetStationsPagedPayload';
  count: Scalars['Int']['output'];
  items: Array<WeatherIoT_StationRowModel>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WeatherIoT_GetStationsPayload = {
  __typename?: 'WeatherIoT_GetStationsPayload';
  count: Scalars['Int']['output'];
  items: Array<WeatherIoT_StationRowModel>;
  total: Scalars['Int']['output'];
};

export type WeatherIoT_GraphQlError = {
  __typename?: 'WeatherIoT_GraphQLError';
  key: Scalars['String']['output'];
  message: Scalars['String']['output'];
  variables: Array<WeatherIoT_KeyValuePairOfStringAndString>;
};

export type WeatherIoT_GraphQlResult = {
  __typename?: 'WeatherIoT_GraphQLResult';
  created: Scalars['DateTime']['output'];
  errors: Array<WeatherIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type WeatherIoT_GraphQlResultOfAddStationPayload = {
  __typename?: 'WeatherIoT_GraphQLResultOfAddStationPayload';
  created: Scalars['DateTime']['output'];
  errors: Array<WeatherIoT_GraphQlError>;
  isSuccess: Scalars['Boolean']['output'];
  value?: Maybe<WeatherIoT_AddStationPayload>;
};

export type WeatherIoT_KeyValuePairOfStringAndString = {
  __typename?: 'WeatherIoT_KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type WeatherIoT_RemoveStationInput = {
  areaId: Scalars['UUID']['input'];
  stationId: Scalars['UUID']['input'];
  stationVersion: Scalars['Int']['input'];
};

export type WeatherIoT_StationFiltersModelInput = {
  matchesName?: InputMaybe<Scalars['String']['input']>;
  ofName?: InputMaybe<Scalars['String']['input']>;
};

export type WeatherIoT_StationJournalFiltersModelInput = {
  ofStationId?: InputMaybe<Scalars['UUID']['input']>;
};

export type WeatherIoT_StationJournalModel = {
  __typename?: 'WeatherIoT_StationJournalModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  readings: WeatherIoT_StationJournalReadingsModel;
  stationId: Scalars['UUID']['output'];
  status: WeatherIoT_StationJournalStatusModel;
  version: Scalars['Int']['output'];
};

export type WeatherIoT_StationJournalReadingsModel = {
  __typename?: 'WeatherIoT_StationJournalReadingsModel';
  airQualityCarbonDioxide?: Maybe<Scalars['Int']['output']>;
  airQualityCarbonMonoxide?: Maybe<Scalars['Int']['output']>;
  airQualityDate?: Maybe<Scalars['DateTime']['output']>;
  airQualityHydrogenSulphide?: Maybe<Scalars['Float']['output']>;
  airQualityIndex?: Maybe<Scalars['Int']['output']>;
  airQualityNitricOxide?: Maybe<Scalars['Float']['output']>;
  airQualityNitrogenDioxide?: Maybe<Scalars['Float']['output']>;
  airQualityOxygen?: Maybe<Scalars['Int']['output']>;
  airQualityOzone?: Maybe<Scalars['Float']['output']>;
  airQualityPM01Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM03Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM05Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM1Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM2Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM4Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM5Concentration?: Maybe<Scalars['Float']['output']>;
  airQualityPM10Concentration?: Maybe<Scalars['Float']['output']>;
  airQualitySulphurDioxide?: Maybe<Scalars['Float']['output']>;
  airQualityVolatileOrganicCompounds?: Maybe<Scalars['Int']['output']>;
  ambientLightDate?: Maybe<Scalars['DateTime']['output']>;
  ambientLightValue?: Maybe<Scalars['Float']['output']>;
  humidityAbsolute?: Maybe<Scalars['Float']['output']>;
  humidityDate?: Maybe<Scalars['DateTime']['output']>;
  humidityRelative?: Maybe<Scalars['Float']['output']>;
  pressureDate?: Maybe<Scalars['DateTime']['output']>;
  pressureValue?: Maybe<Scalars['Float']['output']>;
  temperatureDate?: Maybe<Scalars['DateTime']['output']>;
  temperatureValue?: Maybe<Scalars['Int']['output']>;
};

export type WeatherIoT_StationJournalRowModel = {
  __typename?: 'WeatherIoT_StationJournalRowModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  stationId: Scalars['UUID']['output'];
  status: WeatherIoT_StationJournalStatusModel;
  version: Scalars['Int']['output'];
};

export type WeatherIoT_StationJournalStatusModel = {
  __typename?: 'WeatherIoT_StationJournalStatusModel';
  airQualityIndex?: Maybe<Scalars['Int']['output']>;
  communicationDate?: Maybe<Scalars['DateTime']['output']>;
  current?: Maybe<Scalars['Int']['output']>;
  measurementsDate?: Maybe<Scalars['DateTime']['output']>;
  signalStrength?: Maybe<Scalars['Int']['output']>;
  signalToNoiseRatio?: Maybe<Scalars['Int']['output']>;
  voltage?: Maybe<Scalars['Int']['output']>;
};

export type WeatherIoT_StationModel = {
  __typename?: 'WeatherIoT_StationModel';
  areaId: Scalars['UUID']['output'];
  changed: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  location: WeatherIoT_CoordinatesModel;
  name: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  smartlyId: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type WeatherIoT_StationRowModel = {
  __typename?: 'WeatherIoT_StationRowModel';
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  location: WeatherIoT_CoordinatesModel;
  name: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};
