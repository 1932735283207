import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAreaSettingsFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_UpdateAreaSettingsInput;
}>;


export type UpdateAreaSettingsFromLightingMutation = { __typename?: 'Mutation', Lighting_updateAreaSettings: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const UpdateAreaSettingsFromLightingDocument = gql`
    mutation updateAreaSettingsFromLighting($input: Lighting_UpdateAreaSettingsInput!) {
  Lighting_updateAreaSettings(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type UpdateAreaSettingsFromLightingMutationFn = Apollo.MutationFunction<UpdateAreaSettingsFromLightingMutation, UpdateAreaSettingsFromLightingMutationVariables>;

/**
 * __useUpdateAreaSettingsFromLightingMutation__
 *
 * To run a mutation, you first call `useUpdateAreaSettingsFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaSettingsFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaSettingsFromLightingMutation, { data, loading, error }] = useUpdateAreaSettingsFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAreaSettingsFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaSettingsFromLightingMutation, UpdateAreaSettingsFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaSettingsFromLightingMutation, UpdateAreaSettingsFromLightingMutationVariables>(UpdateAreaSettingsFromLightingDocument, options);
      }
export type UpdateAreaSettingsFromLightingMutationHookResult = ReturnType<typeof useUpdateAreaSettingsFromLightingMutation>;
export type UpdateAreaSettingsFromLightingMutationResult = Apollo.MutationResult<UpdateAreaSettingsFromLightingMutation>;
export type UpdateAreaSettingsFromLightingMutationOptions = Apollo.BaseMutationOptions<UpdateAreaSettingsFromLightingMutation, UpdateAreaSettingsFromLightingMutationVariables>;