import type { ApolloCache } from "@apollo/client";

export const clearFailuresCache = (cache: ApolloCache<any>): void => {
  cache.evict({
    fieldName: "Failures_failures",
  });
  cache.evict({
    fieldName: "Failures_failuresPaged",
  });
  cache.evict({
    fieldName: "Failures_failureById",
  });
  cache.gc();
};

export const clearObjectsCache = (cache: ApolloCache<any>): void => {
  cache.evict({
    fieldName: "Failures_objects",
  });
  cache.evict({
    fieldName: "Failures_objectsPaged",
  });
  cache.evict({
    fieldName: "Failures_objectById",
  });
  cache.gc();
};

export const clearReportersCache = (cache: ApolloCache<any>): void => {
  cache.evict({
    fieldName: "Failures_reporters",
  });
  cache.evict({
    fieldName: "Failures_reportersPaged",
  });
  cache.evict({
    fieldName: "Failures_reporterById",
  });
  cache.gc();
};
