import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { useLightingIoTTranslation } from "../../utils/translation";
import { useNavigate } from "react-router-dom";
import {
  LuminaireList,
  LuminaireListSkeleton,
} from "../../containers/LuminaireList";

const LuminaireCollection: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const navigate = useNavigate();

  return (
    <Page permission="lighting_iot.luminaire.get">
      <StyledHeader title={t("page_luminaire_collection.title")} />
      <React.Suspense fallback={<LuminaireListSkeleton />}>
        <LuminaireList
          paged
          filtered
          onClick={(luminaire) => navigate(luminaire.id)}
        />
      </React.Suspense>
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default LuminaireCollection;
