import React from "react";
import Compo from "@smartly-city/compo";
import { toast } from "react-toastify";
import { useLightingTranslation } from "../../utils/translation";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useValidatePowerBoxesIndexFromLightingMutation } from "src/graphql/__generated__/LightingValidatePowerBoxesIndex";
import { useValidatePolesIndexFromLightingMutation } from "src/graphql/__generated__/LightingValidatePolesIndex";
import { useValidateLuminaireTypesIndexFromLightingMutation } from "src/graphql/__generated__/LightingValidateLuminaireTypesIndex";
import { useValidateLuminairesIndexFromLightingMutation } from "src/graphql/__generated__/LightingValidateLuminairesIndex";
import { useIndexPowerBoxesFromLightingMutation } from "src/graphql/__generated__/LightingIndexPowerBoxes";
import { useIndexPolesFromLightingMutation } from "src/graphql/__generated__/LightingIndexPoles";
import { useIndexLuminaireTypesFromLightingMutation } from "src/graphql/__generated__/LightingIndexLuminaireTypes";
import { useIndexLuminairesFromLightingMutation } from "src/graphql/__generated__/LightingIndexLuminaires";
import styled from "styled-components";
import Page from "src/core/components/Page";

const Administration: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const [tab, setTab] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  const hasValidatePowerBoxesIndexPermission = usePermissionMatch(
    "lighting.admin.validate_power_boxes_index"
  );
  const [validatePowerBoxes] = useValidatePowerBoxesIndexFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_powerBoxes",
      });
      cache.evict({
        fieldName: "Lighting_powerBoxesPaged",
      });
      cache.gc();
    },
  });
  const handleValidatePowerBoxes = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validatePowerBoxes();
    if (result.data?.Lighting_Admin_validatePowerBoxesIndex.isSuccess) {
      toast.success(t(`notification.power_boxes_index_validated`));
    } else {
      result.data?.Lighting_Admin_validatePowerBoxesIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidatePolesIndexPermission = usePermissionMatch(
    "lighting.admin.validate_poles_index"
  );
  const [validatePoles] = useValidatePolesIndexFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_poles",
      });
      cache.evict({
        fieldName: "Lighting_polesPaged",
      });
      cache.gc();
    },
  });
  const handleValidatePoles = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validatePoles();
    if (result.data?.Lighting_Admin_validatePolesIndex.isSuccess) {
      toast.success(t(`notification.poles_index_validated`));
    } else {
      result.data?.Lighting_Admin_validatePolesIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateLuminaireTypesIndexPermission = usePermissionMatch(
    "lighting.admin.validate_luminaire_types_index"
  );
  const [validateLuminaireTypes] =
    useValidateLuminaireTypesIndexFromLightingMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "Lighting_luminaireTypes",
        });
        cache.gc();
      },
    });
  const handleValidateLuminaireTypes = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateLuminaireTypes();
    if (result.data?.Lighting_Admin_validateLuminaireTypesIndex.isSuccess) {
      toast.success(t(`notification.luminaire_types_index_validated`));
    } else {
      result.data?.Lighting_Admin_validateLuminaireTypesIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateLuminairesIndexPermission = usePermissionMatch(
    "lighting.admin.validate_luminaires_index"
  );
  const [validateLuminaires] = useValidateLuminairesIndexFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_luminaires",
      });
      cache.gc();
    },
  });
  const handleValidateLuminaires = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateLuminaires();
    if (result.data?.Lighting_Admin_validateLuminairesIndex.isSuccess) {
      toast.success(t(`notification.luminaires_index_validated`));
    } else {
      result.data?.Lighting_Admin_validateLuminairesIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexPowerBoxesPermission = usePermissionMatch(
    "lighting.admin.index_power_boxes"
  );
  const [indexPowerBoxes] = useIndexPowerBoxesFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_powerBoxes",
      });
      cache.evict({
        fieldName: "Lighting_powerBoxesPaged",
      });
      cache.gc();
    },
  });
  const handleIndexPowerBoxes = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexPowerBoxes();
    if (result.data?.Lighting_Admin_indexPowerBoxes.isSuccess) {
      toast.success(t(`notification.power_boxes_indexed`));
    } else {
      result.data?.Lighting_Admin_indexPowerBoxes.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexPolesPermission = usePermissionMatch(
    "lighting.admin.index_poles"
  );
  const [indexPoles] = useIndexPolesFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_poles",
      });
      cache.gc();
    },
  });
  const handleIndexPoles = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexPoles();
    if (result.data?.Lighting_Admin_indexPoles.isSuccess) {
      toast.success(t(`notification.poles_indexed`));
    } else {
      result.data?.Lighting_Admin_indexPoles.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexLuminaireTypesPermission = usePermissionMatch(
    "lighting.admin.index_luminaire_types"
  );
  const [indexLuminaireTypes] = useIndexLuminaireTypesFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_luminaireTypes",
      });
      cache.gc();
    },
  });
  const handleIndexLuminaireTypes = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexLuminaireTypes();
    if (result.data?.Lighting_Admin_indexLuminaireTypes.isSuccess) {
      toast.success(t(`notification.luminaire_types_indexed`));
    } else {
      result.data?.Lighting_Admin_indexLuminaireTypes.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexLuminairesPermission = usePermissionMatch(
    "lighting.admin.index_luminaires"
  );
  const [indexLuminaires] = useIndexLuminairesFromLightingMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "Lighting_luminaires",
      });
      cache.gc();
    },
  });
  const handleIndexLuminaires = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexLuminaires();
    if (result.data?.Lighting_Admin_indexLuminaires.isSuccess) {
      toast.success(t(`notification.luminaires_indexed`));
    } else {
      result.data?.Lighting_Admin_indexLuminaires.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  return (
    <Page wide permission="lighting.admin.get">
      <Compo.Tabs
        wide
        current={tab}
        onChange={(index) => setTab(index)}
        tabs={[
          {
            icon: Compo.SearchIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidatePowerBoxesIndexPermission}
                  onClick={handleValidatePowerBoxes}
                >
                  {t("page_administration.validate_power_boxes_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidatePolesIndexPermission}
                  onClick={handleValidatePoles}
                >
                  {t("page_administration.validate_poles_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateLuminaireTypesIndexPermission
                  }
                  onClick={handleValidateLuminaireTypes}
                >
                  {t("page_administration.validate_luminaire_types_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidateLuminairesIndexPermission}
                  onClick={handleValidateLuminaires}
                >
                  {t("page_administration.validate_luminaires_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexPowerBoxesPermission}
                  onClick={handleIndexPowerBoxes}
                >
                  {t("page_administration.index_power_boxes")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexPolesPermission}
                  onClick={handleIndexPoles}
                >
                  {t("page_administration.index_poles")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexLuminaireTypesPermission}
                  onClick={handleIndexLuminaireTypes}
                >
                  {t("page_administration.index_luminaire_types")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexLuminairesPermission}
                  onClick={handleIndexLuminaires}
                >
                  {t("page_administration.index_luminaires")}
                </Compo.Button>
              </Wrapper>
            ),
          },
        ]}
      />
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem;
`;

export default Administration;
