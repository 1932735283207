import React from "react";
import styled from "styled-components";
import Compo, { type Types } from "@smartly-city/compo";
import Page from "src/core/components/Page";
import PoleViewSkeleton from "./PoleView.skeleton";
import { Navigate, useParams } from "react-router-dom";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { usePoleByIdFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPoleById";
import { PoleDetailsTab } from "./elements/PoleDetailsTab";
import { PoleMaintenanceDetailsTab } from "./elements/PoleMaintenanceDetailsTab";
import type { Lighting_PoleModel } from "src/graphql/types";
import { PoleRoadDetailsTab } from "./elements/PoleRoadDetailsTab";
import { PoleBracketDetailsTab } from "./elements/PoleBracketDetailsTab";
import { PoleSidewalkDetailsTab } from "./elements/PoleSidewalkDetailsTab";

const PoleViewContent: React.FC = () => {
  const area = useCurrentArea();
  const params = useParams<{ id: string }>();
  const editPermitted = usePermissionMatch("lighting.pole.update");
  const deletePermitted = usePermissionMatch("lighting.pole.delete");
  const [current, setCurrent] = React.useState(0);

  const { data } = usePoleByIdFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id, id: params.id as string } },
  });
  const pole = data.Lighting_poleById.pole as Lighting_PoleModel;

  if (!pole) {
    return <Navigate replace to="/404" />;
  }

  const tabs: Record<string, Types.TabProps> = {
    details: {
      icon: Compo.InformationCircleIcon,
      content: (
        <PoleDetailsTab
          pole={pole}
          editPermitted={editPermitted}
          deletePermitted={deletePermitted}
        />
      ),
    },
  };

  if (pole.maintenanceDetails) {
    tabs.maintenanceDetails = {
      icon: Compo.HandymanIcon,
      content: <PoleMaintenanceDetailsTab pole={pole} />,
    };
  }

  if (pole.roadDetails) {
    tabs.roadDetails = {
      icon: Compo.RoadIcon,
      content: <PoleRoadDetailsTab pole={pole} />,
    };
  }

  if (pole.bracketDetails) {
    tabs.bracketDetails = {
      icon: Compo.LuminaireIcon,
      content: <PoleBracketDetailsTab pole={pole} />,
    };
  }

  if (pole.sidewalkDetails) {
    tabs.sidewalkDetails = {
      icon: Compo.PersonWalkIcon,
      content: <PoleSidewalkDetailsTab pole={pole} />,
    };
  }

  return (
    <Wrapper>
      <Compo.Tabs
        current={current}
        onChange={setCurrent}
        wide
        tabs={Object.values(tabs)}
      >
        {(content) => <ContentWrapper>{content}</ContentWrapper>}
      </Compo.Tabs>
    </Wrapper>
  );
};

const PoleView: React.FC = () => (
  <Page wide permission="lighting.pole.get">
    <React.Suspense fallback={<PoleViewSkeleton />}>
      <PoleViewContent />
    </React.Suspense>
  </Page>
);

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

export default PoleView;
