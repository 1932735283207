import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import { useLightingSchedulesPagedFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLightingSchedulesPaged";
import { usePagination } from "src/core/utils/pagination";

const LightingScheduleList: React.FC = () => {
  const ITEMS_AMOUNT = 10;

  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const { page, setPage } = usePagination();

  const { data } = useLightingSchedulesPagedFromLightingIoTSuspenseQuery({
    variables: { input: { areaId: area.id, page, amount: ITEMS_AMOUNT } },
  });
  const lightingSchedules = data.LightingIoT_lightingSchedulesPaged.items;

  if (lightingSchedules.length === 0) {
    return (
      <Compo.ListRowEmpty
        wide
        text={t("page_lighting_schedule_collection.no_schedules_message")}
      />
    );
  }

  const rows = lightingSchedules.map((lightingSchedule) => (
    <Compo.ListRow
      wide
      key={lightingSchedule.id}
      onClick={() => navigate(lightingSchedule.id)}
    >
      {lightingSchedule.name}
    </Compo.ListRow>
  ));

  return (
    <Wrapper>
      <RowsWrapper>{rows}</RowsWrapper>
      <PaginationWrapper>
        <Compo.Pagination
          wide
          page={page}
          itemCount={ITEMS_AMOUNT}
          totalCount={data.LightingIoT_lightingSchedulesPaged.total}
          onNext={() => setPage(page + 1)}
          onPrev={() => setPage(page - 1)}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const RowsWrapper = styled.div`
  overflow-y: auto;
`;

const PaginationWrapper = styled.div`
  margin-top: auto;
`;

export default LightingScheduleList;
