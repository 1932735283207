import React from "react";
import Compo from "@smartly-city/compo";
import List from "../../../../core/components/List";
import OperationsHeader from "src/core/components/OperationsHeader";
import CounterListRows from "./CounterList.rows";
import CounterListRowsSkeleton from "./CounterList.rows.skeleton";
import { usePagination } from "src/core/utils/pagination";
import { useTrafficIoTTranslation } from "../../utils/translation";
import type { TrafficIoT_CounterRowModel } from "src/graphql/types";

export interface CounterListProps {
  checked?: string[];
  className?: string;
  paged?: boolean;
  filtered?: boolean;
  paginationKey?: string;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (luminaire: TrafficIoT_CounterRowModel) => React.ReactNode;
  onClick?: (luminaire: TrafficIoT_CounterRowModel) => void;
  onCheck?: (luminaire: TrafficIoT_CounterRowModel, value: boolean) => void;
}

const CounterList: React.FC<CounterListProps> = (props) => {
  const ITEMS_AMOUNT = 10;

  const { t } = useTrafficIoTTranslation();
  const pagination = usePagination(props.paginationKey);
  const [rows, setRows] = React.useState<TrafficIoT_CounterRowModel[]>([]);

  const sections = {} as any;

  if (props.withCheckbox) {
    sections.operations = {
      key: "operations",
      title: t("operations"),
      icon: Compo.CogIcon,
      content: (
        <OperationsHeader
          onSelectAll={
            props.onCheck
              ? () => rows.forEach((row) => props.onCheck?.(row, true))
              : undefined
          }
          onUnselectAll={
            props.onCheck
              ? () => rows.forEach((row) => props.onCheck?.(row, false))
              : undefined
          }
        />
      ),
    };
  }

  return (
    <List
      className={props.className}
      header={<Compo.ListHeader thin wide sections={Object.values(sections)} />}
      pagination={props.paged ? pagination : undefined}
    >
      <React.Suspense fallback={<CounterListRowsSkeleton />}>
        <CounterListRows
          rows={rows}
          setRows={setRows}
          checked={props.checked}
          page={props.paged ? pagination.page : undefined}
          pagedAmount={ITEMS_AMOUNT}
          withCheckbox={props.withCheckbox}
          withRadio={props.withRadio}
          actions={props.actions}
          onClick={props.onClick}
          onCheck={props.onCheck}
        />
      </React.Suspense>
    </List>
  );
};

export default CounterList;
