import type { DeepPartial } from "src/core/utils/types";
import type { Lighting_LuminaireTypeModel } from "src/graphql/types";

export const defaultValues: DeepPartial<Lighting_LuminaireTypeModel> = {
  model: null as any,
  manufacturer: null as any,
  details: {
    sourceOfLight: null as any,
    power: null as any,
    sourcePower: null as any,
    manufacturerCode: null as any,
  },
  ledDetails: {
    hasControllerSocket: true,
    optics: null as any,
    driverType: null as any,
    driverCurrent: null as any,
    amountOfLed: null as any,
  },
};
