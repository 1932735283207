import React from "react";
import { useCurrentArea } from "src/core/contexts";
import { usePolesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPoles";
import { usePowerBoxesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPowerBoxes";
import { useNavigate } from "react-router-dom";
import MapProxy from "src/core/proxies/MapProxy";
import type { MapItem } from "@smartly-city/compo-map";
import { usePoleLayerBuilder, usePowerBoxLayerBuilder } from "../utils/layers";

const LightingMapProxy: React.FC = () => {
  const area = useCurrentArea();
  const navigate = useNavigate();
  const poleBuilder = usePoleLayerBuilder();
  const powerBoxBuilder = usePowerBoxLayerBuilder();

  const { data: powerBoxData } = usePowerBoxesFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const powerBoxes = powerBoxData.Lighting_powerBoxes.items;

  const { data: poleData } = usePolesFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const poles = poleData.Lighting_poles.items;

  const powerBoxLayer = React.useMemo(
    () => powerBoxBuilder(powerBoxes),
    [powerBoxes, powerBoxBuilder]
  );
  const poleLayer = React.useMemo(
    () => poleBuilder(poles),
    [poles, poleBuilder]
  );

  return (
    <MapProxy
      layers={[powerBoxLayer, poleLayer]}
      onItemClick={(item, layer) => {
        const redirectUrl = getActiveItemRedirectUrl(item, layer);
        if (redirectUrl === window.location.pathname) return;
        navigate(redirectUrl);
      }}
    />
  );
};

const getActiveItemRedirectUrl = (active: MapItem, layer: string): string => {
  switch (layer) {
    case "power_box":
      return `/lighting/power-box/${active.id}`;
    case "pole":
      return `/lighting/pole/${active.id}`;
    default:
      return ".";
  }
};

export default LightingMapProxy;
