import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FailuresFromFailuresQueryVariables = Types.Exact<{
  input: Types.Failures_GetFailuresInput;
}>;


export type FailuresFromFailuresQuery = { __typename?: 'Query', Failures_failures: { __typename?: 'Failures_GetFailuresPayload', count: number, total: number, items: Array<{ __typename?: 'Failures_FailureRowModel', id: any, version: number, areaId: any, type: Types.Failures_FailureTypeModel, state: Types.Failures_FailureStateModel, active: boolean, objectId: any, objectName: string, objectLocation: { __typename?: 'Failures_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const FailuresFromFailuresDocument = gql`
    query failuresFromFailures($input: Failures_GetFailuresInput!) {
  Failures_failures(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      type
      state
      active
      objectId
      objectName
      objectLocation {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useFailuresFromFailuresQuery__
 *
 * To run a query within a React component, call `useFailuresFromFailuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailuresFromFailuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailuresFromFailuresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFailuresFromFailuresQuery(baseOptions: Apollo.QueryHookOptions<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables> & ({ variables: FailuresFromFailuresQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables>(FailuresFromFailuresDocument, options);
      }
export function useFailuresFromFailuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables>(FailuresFromFailuresDocument, options);
        }
export function useFailuresFromFailuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables>(FailuresFromFailuresDocument, options);
        }
export type FailuresFromFailuresQueryHookResult = ReturnType<typeof useFailuresFromFailuresQuery>;
export type FailuresFromFailuresLazyQueryHookResult = ReturnType<typeof useFailuresFromFailuresLazyQuery>;
export type FailuresFromFailuresSuspenseQueryHookResult = ReturnType<typeof useFailuresFromFailuresSuspenseQuery>;
export type FailuresFromFailuresQueryResult = Apollo.QueryResult<FailuresFromFailuresQuery, FailuresFromFailuresQueryVariables>;