import React from "react";
import Compo, { usePopover } from "@smartly-city/compo";
import * as yup from "yup";
import { useFailuresTranslation } from "../../utils/translation";
import { useFormik } from "formik";
import {
  type FailureFilters,
  useFailureFilters,
} from "../../contexts/FailureFilters";
import {
  setBooleanFieldValue,
  setDateFieldValue,
  setTextFieldValue,
  toDateValue,
  toStringValue,
} from "src/core/utils/formik";
import FiltersHeader, { type Filter } from "src/core/components/FiltersHeader";
import { toSnakeCase } from "src/core/utils/string";
import styled from "styled-components";
import {
  Failures_FailureErrorGroupModel,
  Failures_FailureErrorStageModel,
  Failures_FailureErrorTypeModel,
  Failures_FailureStateModel,
  Failures_FailureTypeModel,
} from "src/graphql/types";

export interface FailureFiltersFormProps {
  onSubmit?: () => void;
}

export interface FailureFiltersFormContext {
  active: boolean | null;
  objectName: string | null;
  minCreatedDate: Date | null;
  maxCreatedDate: Date | null;
  errorGroup: Failures_FailureErrorGroupModel | null;
  errorStage: Failures_FailureErrorStageModel | null;
  errorType: Failures_FailureErrorTypeModel | null;
  state: Failures_FailureStateModel | null;
  type: Failures_FailureTypeModel | null;
}

const FailureFiltersForm: React.FC<FailureFiltersFormProps> = (props) => {
  const { t } = useFailuresTranslation();
  const popover = usePopover();
  const filters = useFailureFilters();
  const ref = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    popover.buttonRef(ref.current);
  }, [popover, ref]);

  const headerFilterKeys = Object.keys(filters.status) as Array<
    keyof FailureFilters
  >;

  const headerFilters: Filter[] = headerFilterKeys.map((key) => ({
    id: key,
    name: t("form_failure_filters." + toSnakeCase(key)),
    active: filters.status[key] ?? false,
    onAdd: () => filters.activateFilter(key),
  }));

  const formik = useFormik<FailureFiltersFormContext>({
    initialValues: {
      active: filters.filters.active ?? null,
      objectName: filters.filters.objectName ?? null,
      minCreatedDate: filters.filters.createdDate?.min ?? null,
      maxCreatedDate: filters.filters.createdDate?.max ?? null,
      errorGroup: filters.filters.errorGroup ?? null,
      errorStage: filters.filters.errorStage ?? null,
      errorType: filters.filters.errorType ?? null,
      state: filters.filters.state ?? null,
      type: filters.filters.type ?? null,
    },
    validationSchema: yup.object({
      active: yup.bool().nullable().fromFormik(),
      objectName: yup.string().nullable().fromFormik(),
      minCreatedDate: yup.date().nullable().fromFormik(),
      maxCreatedDate: yup.date().nullable().fromFormik(),
      errorGroup: yup
        .string()
        .oneOf(Object.values(Failures_FailureErrorGroupModel))
        .nullable()
        .fromFormik(),
      errorStage: yup
        .string()
        .oneOf(Object.values(Failures_FailureErrorStageModel))
        .nullable()
        .fromFormik(),
      errorType: yup
        .string()
        .oneOf(Object.values(Failures_FailureErrorTypeModel))
        .nullable()
        .fromFormik(),
      state: yup
        .string()
        .oneOf(Object.values(Failures_FailureStateModel))
        .nullable()
        .fromFormik(),
      type: yup
        .string()
        .oneOf(Object.values(Failures_FailureTypeModel))
        .nullable()
        .fromFormik(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      filters.setFilters({
        active: values.active ?? undefined,
        objectName: values.objectName ?? undefined,
        createdDate: {
          min: values.minCreatedDate
            ? new Date(values.minCreatedDate)
            : undefined,
          max: values.maxCreatedDate
            ? new Date(values.maxCreatedDate)
            : undefined,
        },
        errorGroup: values.errorGroup ?? undefined,
        errorStage: values.errorStage ?? undefined,
        errorType: values.errorType ?? undefined,
        state: values.state ?? undefined,
        type: values.type ?? undefined,
      });
      props.onSubmit?.();
      setSubmitting(false);
    },
    onReset: () => {
      filters.clearFilters();
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FiltersHeader
        filters={headerFilters}
        onSave={formik.submitForm}
        onClear={formik.resetForm}
      />
      {filters.status.active && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("active")}>
          <Compo.Toggle
            wide
            label={t("form_failure_filters.active") ?? ""}
            checked={formik.values.active ?? false}
            onChange={setBooleanFieldValue(formik, "active")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.objectName && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("objectName")}>
          <Compo.TextInput
            wide
            label={t("form_failure_filters.object_name")}
            value={toStringValue(formik.values.objectName)}
            onChange={setTextFieldValue(formik, "objectName")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.createdDate && (
        <Compo.FilterBox
          vertical
          onClose={() => filters.deactivateFilter("createdDate")}
        >
          <Compo.DateInput
            wide
            label={t("form_failure_filters.min_created_date")}
            value={toDateValue(formik.values.minCreatedDate)}
            onChange={setDateFieldValue(formik, "minCreatedDate")}
          />
          <Compo.DateInput
            wide
            label={t("form_failure_filters.max_created_date")}
            value={toDateValue(formik.values.maxCreatedDate)}
            onChange={setDateFieldValue(formik, "maxCreatedDate")}
          />
        </Compo.FilterBox>
      )}
      {filters.status.errorGroup && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("errorGroup")}>
          <Compo.SelectInput
            wide
            label={t("form_failure_filters.error_group")}
            value={toStringValue(formik.values.errorGroup)}
            onChange={setTextFieldValue(formik, "errorGroup")}
          >
            <option />
            {Object.values(Failures_FailureErrorGroupModel).map((type) => (
              <option key={type} value={type}>
                {t(`failure_error_group.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.errorStage && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("errorStage")}>
          <Compo.SelectInput
            wide
            label={t("form_failure_filters.error_stage")}
            value={toStringValue(formik.values.errorStage)}
            onChange={setTextFieldValue(formik, "errorStage")}
          >
            <option />
            {Object.values(Failures_FailureErrorStageModel).map((type) => (
              <option key={type} value={type}>
                {t(`failure_error_stage.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.errorType && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("errorType")}>
          <Compo.SelectInput
            wide
            label={t("form_failure_filters.error_type")}
            value={toStringValue(formik.values.errorType)}
            onChange={setTextFieldValue(formik, "errorType")}
          >
            <option />
            {Object.values(Failures_FailureErrorTypeModel).map((type) => (
              <option key={type} value={type}>
                {t(`failure_error_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.state && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("state")}>
          <Compo.SelectInput
            wide
            label={t("form_failure_filters.state")}
            value={toStringValue(formik.values.state)}
            onChange={setTextFieldValue(formik, "state")}
          >
            <option />
            {Object.values(Failures_FailureStateModel).map((type) => (
              <option key={type} value={type}>
                {t(`failure_state.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
      {filters.status.type && (
        <Compo.FilterBox onClose={() => filters.deactivateFilter("type")}>
          <Compo.SelectInput
            wide
            label={t("form_failure_filters.type")}
            value={toStringValue(formik.values.type)}
            onChange={setTextFieldValue(formik, "type")}
          >
            <option />
            {Object.values(Failures_FailureTypeModel).map((type) => (
              <option key={type} value={type}>
                {t(`failure_type.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
        </Compo.FilterBox>
      )}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0.25rem;
`;

export default FailureFiltersForm;
