import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useLightingIoTTranslation } from "../../utils/translation";
import { useRemoveControllerFromLuminaireFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTRemoveControllerFromLuminaire";
import type {
  LightingIoT_LuminaireControllerModel,
  LightingIoT_LuminaireModel,
} from "src/graphql/types";

export interface LuminaireControllerDeleteFormProps {
  onClose: () => void;
  luminaire: LightingIoT_LuminaireModel;
  controller: LightingIoT_LuminaireControllerModel;
}

const LuminaireControllerDeleteForm: React.FC<
  LuminaireControllerDeleteFormProps
> = (props) => {
  const { t } = useLightingIoTTranslation();

  const luminaire = props.luminaire;
  const controller = props.controller;

  const [deleteController] =
    useRemoveControllerFromLuminaireFromLightingIoTMutation({
      variables: {
        input: {
          areaId: luminaire.areaId,
          luminaireId: luminaire.id,
          luminaireVersion: luminaire.version,
        },
      },
      update: (cache, result) => {
        if (
          result.errors ||
          !result.data?.LightingIoT_removeControllerFromLuminaire.isSuccess
        )
          return;
        cache.evict({
          fieldName: "LightingIoT_luminaireById",
        });
        cache.gc();
      },
    });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const { data } = await deleteController();
      const result = data?.LightingIoT_removeControllerFromLuminaire;

      if (result?.isSuccess) {
        toast.success(t(`notification.controller_removed`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={<Compo.Header title={t("form_remove_controller.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={formik.isSubmitting}
              type="submit"
              variant="alert"
            >
              {t("delete")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <Text>
          {t("form_remove_controller.text", {
            controller,
          })}
        </Text>
      </Compo.ModalBox>
    </form>
  );
};

const Text = styled.span`
  margin: 1rem;
  display: inline-block;
`;

export default LuminaireControllerDeleteForm;
