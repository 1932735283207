import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CounterByIdFromTrafficIoTQueryVariables = Types.Exact<{
  input: Types.TrafficIoT_GetCounterByIdInput;
}>;


export type CounterByIdFromTrafficIoTQuery = { __typename?: 'Query', TrafficIoT_counterById: { __typename?: 'TrafficIoT_GetCounterByIdPayload', counter?: { __typename?: 'TrafficIoT_CounterModel', id: any, version: number, created: any, changed: any, published: boolean, areaId: any, name: string, smartlyId: string, location: { __typename?: 'TrafficIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } } | null } };


export const CounterByIdFromTrafficIoTDocument = gql`
    query counterByIdFromTrafficIoT($input: TrafficIoT_GetCounterByIdInput!) {
  TrafficIoT_counterById(input: $input) {
    counter {
      id
      version
      created
      changed
      published
      areaId
      name
      location {
        latitude
        longitude
        hash
      }
      smartlyId
    }
  }
}
    `;

/**
 * __useCounterByIdFromTrafficIoTQuery__
 *
 * To run a query within a React component, call `useCounterByIdFromTrafficIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterByIdFromTrafficIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterByIdFromTrafficIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCounterByIdFromTrafficIoTQuery(baseOptions: Apollo.QueryHookOptions<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables> & ({ variables: CounterByIdFromTrafficIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables>(CounterByIdFromTrafficIoTDocument, options);
      }
export function useCounterByIdFromTrafficIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables>(CounterByIdFromTrafficIoTDocument, options);
        }
export function useCounterByIdFromTrafficIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables>(CounterByIdFromTrafficIoTDocument, options);
        }
export type CounterByIdFromTrafficIoTQueryHookResult = ReturnType<typeof useCounterByIdFromTrafficIoTQuery>;
export type CounterByIdFromTrafficIoTLazyQueryHookResult = ReturnType<typeof useCounterByIdFromTrafficIoTLazyQuery>;
export type CounterByIdFromTrafficIoTSuspenseQueryHookResult = ReturnType<typeof useCounterByIdFromTrafficIoTSuspenseQuery>;
export type CounterByIdFromTrafficIoTQueryResult = Apollo.QueryResult<CounterByIdFromTrafficIoTQuery, CounterByIdFromTrafficIoTQueryVariables>;