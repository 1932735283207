import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useIsSignedInSuspenseQuery } from "src/graphql/__generated__/IsSignedIn";

const AuthProxy: React.FC = () => {
  const location = useLocation();
  const isSignedInResult = useIsSignedInSuspenseQuery();

  if (!isSignedInResult.data?.isSignedIn) {
    return <Navigate to="/login" state={{ location }} />;
  }

  return <Outlet />;
};

export default AuthProxy;
