import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireTypeByIdFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetLuminaireTypeByIdInput;
}>;


export type LuminaireTypeByIdFromLightingQuery = { __typename?: 'Query', Lighting_luminaireTypeById: { __typename?: 'Lighting_GetLuminaireTypeByIdPayload', luminaireType?: { __typename?: 'Lighting_LuminaireTypeModel', id: any, version: number, areaId: any, name: string, model: string, manufacturer: string, details: { __typename?: 'Lighting_LuminaireTypeDefaultDetailsModel', sourceOfLight: Types.Lighting_SourceOfLightModel, power: number, sourcePower: number, manufacturerCode?: string | null }, ledDetails?: { __typename?: 'Lighting_LuminaireTypeLedDetailsModel', hasControllerSocket: boolean, optics?: string | null, driverType?: string | null, driverCurrent?: number | null, amountOfLed?: number | null } | null } | null } };


export const LuminaireTypeByIdFromLightingDocument = gql`
    query luminaireTypeByIdFromLighting($input: Lighting_GetLuminaireTypeByIdInput!) {
  Lighting_luminaireTypeById(input: $input) {
    luminaireType {
      id
      version
      areaId
      name
      model
      manufacturer
      details {
        sourceOfLight
        power
        sourcePower
        manufacturerCode
      }
      ledDetails {
        hasControllerSocket
        optics
        driverType
        driverCurrent
        amountOfLed
      }
    }
  }
}
    `;

/**
 * __useLuminaireTypeByIdFromLightingQuery__
 *
 * To run a query within a React component, call `useLuminaireTypeByIdFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireTypeByIdFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireTypeByIdFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireTypeByIdFromLightingQuery(baseOptions: Apollo.QueryHookOptions<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables> & ({ variables: LuminaireTypeByIdFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables>(LuminaireTypeByIdFromLightingDocument, options);
      }
export function useLuminaireTypeByIdFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables>(LuminaireTypeByIdFromLightingDocument, options);
        }
export function useLuminaireTypeByIdFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables>(LuminaireTypeByIdFromLightingDocument, options);
        }
export type LuminaireTypeByIdFromLightingQueryHookResult = ReturnType<typeof useLuminaireTypeByIdFromLightingQuery>;
export type LuminaireTypeByIdFromLightingLazyQueryHookResult = ReturnType<typeof useLuminaireTypeByIdFromLightingLazyQuery>;
export type LuminaireTypeByIdFromLightingSuspenseQueryHookResult = ReturnType<typeof useLuminaireTypeByIdFromLightingSuspenseQuery>;
export type LuminaireTypeByIdFromLightingQueryResult = Apollo.QueryResult<LuminaireTypeByIdFromLightingQuery, LuminaireTypeByIdFromLightingQueryVariables>;