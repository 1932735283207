import * as yup from "yup";
import {
  Lighting_RoadLightingClassModel,
  Lighting_RoadSurfaceModel,
  Lighting_RoadTypeModel,
} from "src/graphql/types";

export const getRoadDetailsValidationSchema = (required: boolean): yup.Schema =>
  yup.object().shape({
    roadDetails: yup
      .object()
      .when({
        is: required,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })
      .shape({
        roadType: yup
          .string()
          .fromFormik()
          .oneOf(Object.values(Lighting_RoadTypeModel))
          .required(),
        roadSurface: yup
          .string()
          .fromFormik()
          .oneOf(Object.values(Lighting_RoadSurfaceModel))
          .required(),
        roadLightingClass: yup
          .string()
          .fromFormik()
          .oneOf(Object.values(Lighting_RoadLightingClassModel))
          .required(),
        roadWidth: yup.number().fromFormik().min(0).required(),
        distanceFromRoadwayEdgeToPole: yup
          .number()
          .fromFormik()
          .min(0)
          .required(),
      }),
  });
