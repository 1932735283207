import * as yup from "yup";
import {
  Lighting_OwnerModel,
  Lighting_PoleLocationModel,
} from "src/graphql/types";

export const getMaintenanceDetailsValidationSchema = (
  required: boolean
): yup.Schema =>
  yup.object().shape({
    maintenanceDetails: yup
      .object()
      .when({
        is: required,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })
      .shape({
        qualifiedForReplacement: yup.boolean().fromFormik().required(),
        owner: yup
          .string()
          .fromFormik()
          .oneOf(Object.values(Lighting_OwnerModel))
          .required(),
        dateOfPainting: yup
          .date()
          .fromFormik()
          .nullable()
          .default(undefined)
          .max(new Date()),
        plotNumber: yup.string().fromFormik().optional(),
        location: yup
          .string()
          .fromFormik()
          .optional()
          .oneOf(Object.values(Lighting_PoleLocationModel)),
        distanceToClosestPole: yup.number().fromFormik().optional().min(0),
      }),
  });
