import { useTranslation } from "react-i18next";
import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export const useWeatherIoTTranslation = (
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<"weather_iot"> => {
  return useTranslation("weather_iot", options);
};
