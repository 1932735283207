import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import CounterCarJournalTabDailyCountChartSkeleton from "./charts/CounterCarJournalTabDailyCountChart.skeleton";
import CounterCarJournalTabDailySpeedChartSkeleton from "./charts/CounterCarJournalTabDailySpeedChart.skeleton";
import CounterCarJournalTabMonthlyCountChartSkeleton from "./charts/CounterCarJournalTabMonthlyCountChart.skeleton";
import CounterCarJournalTabMonthlySpeedChartSkeleton from "./charts/CounterCarJournalTabMonthlySpeedChart.skeleton";

const CounterCarJournalTabSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <CounterCarJournalTabDailyCountChartSkeleton />
      <CounterCarJournalTabDailySpeedChartSkeleton />
      <CounterCarJournalTabMonthlyCountChartSkeleton />
      <CounterCarJournalTabMonthlySpeedChartSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CounterCarJournalTabSkeleton;
