import * as yup from "yup";
import {
  Lighting_GridTypeModel,
  Lighting_LineTypeModel,
  Lighting_PoleTypeModel,
} from "src/graphql/types";

export const defaultDetailsValidationSchema = yup.object().shape({
  details: yup
    .object()
    .fromFormik()
    .shape({
      type: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_PoleTypeModel))
        .required(),
      gridType: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_GridTypeModel))
        .required(),
      lineType: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_LineTypeModel))
        .required(),
      height: yup.number().fromFormik().min(0).required(),
      lineCrossSection: yup.string().fromFormik().optional(),
    }),
});
