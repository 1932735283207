import React from "react";
import styled from "styled-components";
import { useTrafficIoTTranslation } from "src/modules/traffic_iot/utils/translation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  TrafficIoT_CounterJournalSummaryItemTypeModel,
  type TrafficIoT_CounterJournalModel,
} from "src/graphql/types";

export interface CounterBikeJournalTabMonthlySpeedChartProps {
  journal: TrafficIoT_CounterJournalModel;
}

const CounterBikeJournalTabMonthlySpeedChart: React.FC<
  CounterBikeJournalTabMonthlySpeedChartProps
> = (props) => {
  const { t, i18n } = useTrafficIoTTranslation();
  const isEnglish = i18n.language.startsWith("en");

  const startDate = new Date();
  startDate.setUTCMilliseconds(0);
  startDate.setUTCSeconds(0);
  startDate.setUTCMinutes(0);
  startDate.setUTCHours(0);
  startDate.setUTCDate(startDate.getUTCDate() - 30 + 1);

  const endDate = new Date();
  endDate.setUTCMilliseconds(0);
  endDate.setUTCSeconds(0);
  endDate.setUTCMinutes(0);
  endDate.setUTCHours(0);

  const dates: Date[] = [];
  // eslint-disable-next-line no-unmodified-loop-condition
  for (let d = startDate; d <= endDate; d.setUTCDate(d.getUTCDate() + 1)) {
    dates.push(new Date(d));
  }

  const data = dates.map((date) =>
    props.journal.monthlySummary.items
      .filter(
        (item) =>
          item.type === TrafficIoT_CounterJournalSummaryItemTypeModel.BikeCount
      )
      .find((item) => new Date(item.date).getTime() === date.getTime())
  );

  const speedData = dates.map((date, index) => ({
    x: date,
    y: data[index]?.bikeCount?.averageSpeed,
  }));

  const ltrSpeedData = dates.map((date, index) => ({
    x: date,
    y: data[index]?.bikeCount?.leftToRightAverageSpeed,
  }));

  const rtlSpeedData = dates.map((date, index) => ({
    x: date,
    y: data[index]?.bikeCount?.rightToLeftAverageSpeed,
  }));

  console.log(speedData);

  ChartJS.register(CategoryScale, TimeScale, LinearScale, BarElement);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>{t("page_counter_view.monthly_bike_speed_chart_title")}</Title>
      </HeaderWrapper>
      <StyledBar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: "index",
              displayColors: false,
              callbacks: {
                label: (item) =>
                  `${item.dataset.label ?? ""}: ${item.formattedValue} km/h`,
              },
              filter: (item) => item.formattedValue !== "0",
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: isEnglish ? "MMMM DD" : "DD MMMM",
                displayFormats: {
                  day: isEnglish ? "MMM DD" : "DD MMM",
                },
              },
              grid: { display: false },
              stacked: true,
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
              },
            },
          },
        }}
        data={{
          labels: dates,
          datasets: [
            {
              label: t("page_counter_view.average"),
              data: speedData,
              backgroundColor: "#2463EB",
              order: 1,
            },
            {
              label: t("page_counter_view.left_to_right"),
              data: ltrSpeedData,
              barThickness: 0,
            },
            {
              label: t("page_counter_view.right_to_left"),
              data: rtlSpeedData,
              barThickness: 0,
            },
          ],
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border: 2.5px solid #2463eb;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 0.5rem;
`;

const Title = styled.h3`
  color: #767676;
  margin: 0;
`;

const StyledBar = styled(Bar)`
  max-height: 12.5rem;
  width: 100%;
`;

export default CounterBikeJournalTabMonthlySpeedChart;
