import React from "react";

export interface StationFilters {
  name?: string;
}

export type StationFiltersStatus = Record<keyof StationFilters, boolean>;

export interface StationFiltersContextState {
  filters: StationFilters;
  status: StationFiltersStatus;
  setFilters: (filters: StationFilters) => void;
  deactivateFilter: (name: keyof StationFilters) => void;
  activateFilter: (name: keyof StationFilters) => void;
  clearFilters: () => void;
}

export const StationFiltersContext = React.createContext<
  StationFiltersContextState | undefined
>(undefined);

export const useStationFilters = (): StationFiltersContextState => {
  const context = React.useContext(StationFiltersContext);
  return context as StationFiltersContextState;
};
