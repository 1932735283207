import React from "react";
import { PowerBoxFiltersContext } from "./PowerBoxFiltersContext";
import type {
  PowerBoxFiltersStatus,
  PowerBoxFilters,
} from "./PowerBoxFiltersContext";
import { useFiltersUtils } from "src/core/utils/filters";

export interface PowerBoxFiltersProviderProps {
  children?: React.ReactNode;
}

const PowerBoxFiltersProvider: React.FC<PowerBoxFiltersProviderProps> = (
  props
) => {
  const initStatus: PowerBoxFiltersStatus = {
    name: false,
    ordinalNumber: false,
    addressNumber: false,
    addressStreet: false,
    addressTown: false,
    power: false,
    contractedPower: false,
    fuseAmpRating: false,
    fuseType: false,
    recipientCode: false,
    notes: false,
  };

  const utils = useFiltersUtils<PowerBoxFilters>({}, initStatus);

  return (
    <PowerBoxFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </PowerBoxFiltersContext.Provider>
  );
};

export default PowerBoxFiltersProvider;
