import Compo from "@smartly-city/compo";
import React from "react";

const LuminaireLightingScheduleTabSkeleton: React.FC = () => {
  return (
    <React.Fragment>
      <Compo.HeaderSkeleton />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
      <Compo.ListRowSkeleton wide />
    </React.Fragment>
  );
};

export default LuminaireLightingScheduleTabSkeleton;
