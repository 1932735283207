import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LightingScheduleByIdFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLightingScheduleByIdInput;
}>;


export type LightingScheduleByIdFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_lightingScheduleById: { __typename?: 'LightingIoT_GetLightingScheduleByIdPayload', schedule?: { __typename?: 'LightingIoT_LightingSchedulePayload', id: any, version: number, created: any, changed: any, areaId: any, name: string, startStep: { __typename?: 'LightingIoT_StartOnSunsetStepPayload', offset: number, brightness: number } | { __typename?: 'LightingIoT_StartOnTimeStepPayload', startTime: any, brightness: number }, changeSteps: Array<{ __typename?: 'LightingIoT_ChangeOnTimeStepPayload', changeTime: any, brightness: number }>, stopStep: { __typename?: 'LightingIoT_StopOnSunriseStepPayload', offset: number, brightness: number } | { __typename?: 'LightingIoT_StopOnTimeStepPayload', stopTime: any, brightness: number } } | null } };


export const LightingScheduleByIdFromLightingIoTDocument = gql`
    query lightingScheduleByIdFromLightingIoT($input: LightingIoT_GetLightingScheduleByIdInput!) {
  LightingIoT_lightingScheduleById(input: $input) {
    schedule {
      id
      version
      created
      changed
      areaId
      name
      startStep {
        ... on LightingIoT_StartOnTimeStepPayload {
          startTime
          brightness
        }
        ... on LightingIoT_StartOnSunsetStepPayload {
          offset
          brightness
        }
      }
      changeSteps {
        ... on LightingIoT_ChangeOnTimeStepPayload {
          changeTime
          brightness
        }
      }
      stopStep {
        ... on LightingIoT_StopOnTimeStepPayload {
          stopTime
          brightness
        }
        ... on LightingIoT_StopOnSunriseStepPayload {
          offset
          brightness
        }
      }
    }
  }
}
    `;

/**
 * __useLightingScheduleByIdFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLightingScheduleByIdFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLightingScheduleByIdFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLightingScheduleByIdFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLightingScheduleByIdFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables> & ({ variables: LightingScheduleByIdFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables>(LightingScheduleByIdFromLightingIoTDocument, options);
      }
export function useLightingScheduleByIdFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables>(LightingScheduleByIdFromLightingIoTDocument, options);
        }
export function useLightingScheduleByIdFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables>(LightingScheduleByIdFromLightingIoTDocument, options);
        }
export type LightingScheduleByIdFromLightingIoTQueryHookResult = ReturnType<typeof useLightingScheduleByIdFromLightingIoTQuery>;
export type LightingScheduleByIdFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLightingScheduleByIdFromLightingIoTLazyQuery>;
export type LightingScheduleByIdFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLightingScheduleByIdFromLightingIoTSuspenseQuery>;
export type LightingScheduleByIdFromLightingIoTQueryResult = Apollo.QueryResult<LightingScheduleByIdFromLightingIoTQuery, LightingScheduleByIdFromLightingIoTQueryVariables>;