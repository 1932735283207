import React from "react";
import { PageContext } from "./PageContext";

export interface PageProviderProps {
  children?: React.ReactNode;
}

const PageProvider: React.FC<PageProviderProps> = (props) => {
  const [title, setTitle] = React.useState("");
  const [apiTitle, setApiTitle] = React.useState("");
  const [sidebarVisible, setSidebarVisible] = React.useState(true);

  React.useEffect(() => {
    document.title = (apiTitle ? apiTitle + " - " : "") + "Smartly City";
  }, [apiTitle]);

  const value = {
    title,
    setTitle,
    apiTitle,
    setApiTitle,
    sidebarVisible,
    setSidebarVisible,
  };

  return (
    <PageContext.Provider value={value}>{props.children}</PageContext.Provider>
  );
};

export default PageProvider;
