import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminairesFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLuminairesInput;
}>;


export type LuminairesFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_luminaires: { __typename?: 'LightingIoT_GetLuminairesPayload', count: number, total: number, items: Array<{ __typename?: 'LightingIoT_LuminaireRowModel', id: any, version: number, areaId: any, typeId: any, typeName: string, name: string, location: { __typename?: 'LightingIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const LuminairesFromLightingIoTDocument = gql`
    query luminairesFromLightingIoT($input: LightingIoT_GetLuminairesInput!) {
  LightingIoT_luminaires(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      typeId
      typeName
      name
      location {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useLuminairesFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLuminairesFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminairesFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminairesFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminairesFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables> & ({ variables: LuminairesFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables>(LuminairesFromLightingIoTDocument, options);
      }
export function useLuminairesFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables>(LuminairesFromLightingIoTDocument, options);
        }
export function useLuminairesFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables>(LuminairesFromLightingIoTDocument, options);
        }
export type LuminairesFromLightingIoTQueryHookResult = ReturnType<typeof useLuminairesFromLightingIoTQuery>;
export type LuminairesFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLuminairesFromLightingIoTLazyQuery>;
export type LuminairesFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLuminairesFromLightingIoTSuspenseQuery>;
export type LuminairesFromLightingIoTQueryResult = Apollo.QueryResult<LuminairesFromLightingIoTQuery, LuminairesFromLightingIoTQueryVariables>;