import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexPowerBoxesFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_Admin_IndexPowerBoxesInput;
}>;


export type IndexPowerBoxesFromLightingMutation = { __typename?: 'Mutation', Lighting_Admin_indexPowerBoxes: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const IndexPowerBoxesFromLightingDocument = gql`
    mutation indexPowerBoxesFromLighting($input: Lighting_Admin_IndexPowerBoxesInput!) {
  Lighting_Admin_indexPowerBoxes(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type IndexPowerBoxesFromLightingMutationFn = Apollo.MutationFunction<IndexPowerBoxesFromLightingMutation, IndexPowerBoxesFromLightingMutationVariables>;

/**
 * __useIndexPowerBoxesFromLightingMutation__
 *
 * To run a mutation, you first call `useIndexPowerBoxesFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexPowerBoxesFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexPowerBoxesFromLightingMutation, { data, loading, error }] = useIndexPowerBoxesFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexPowerBoxesFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<IndexPowerBoxesFromLightingMutation, IndexPowerBoxesFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexPowerBoxesFromLightingMutation, IndexPowerBoxesFromLightingMutationVariables>(IndexPowerBoxesFromLightingDocument, options);
      }
export type IndexPowerBoxesFromLightingMutationHookResult = ReturnType<typeof useIndexPowerBoxesFromLightingMutation>;
export type IndexPowerBoxesFromLightingMutationResult = Apollo.MutationResult<IndexPowerBoxesFromLightingMutation>;
export type IndexPowerBoxesFromLightingMutationOptions = Apollo.BaseMutationOptions<IndexPowerBoxesFromLightingMutation, IndexPowerBoxesFromLightingMutationVariables>;