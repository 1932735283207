import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveCounterFromTrafficIoTMutationVariables = Types.Exact<{
  input: Types.TrafficIoT_RemoveCounterInput;
}>;


export type RemoveCounterFromTrafficIoTMutation = { __typename?: 'Mutation', TrafficIoT_removeCounter: { __typename?: 'TrafficIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'TrafficIoT_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'TrafficIoT_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const RemoveCounterFromTrafficIoTDocument = gql`
    mutation removeCounterFromTrafficIoT($input: TrafficIoT_RemoveCounterInput!) {
  TrafficIoT_removeCounter(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type RemoveCounterFromTrafficIoTMutationFn = Apollo.MutationFunction<RemoveCounterFromTrafficIoTMutation, RemoveCounterFromTrafficIoTMutationVariables>;

/**
 * __useRemoveCounterFromTrafficIoTMutation__
 *
 * To run a mutation, you first call `useRemoveCounterFromTrafficIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCounterFromTrafficIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCounterFromTrafficIoTMutation, { data, loading, error }] = useRemoveCounterFromTrafficIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCounterFromTrafficIoTMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCounterFromTrafficIoTMutation, RemoveCounterFromTrafficIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCounterFromTrafficIoTMutation, RemoveCounterFromTrafficIoTMutationVariables>(RemoveCounterFromTrafficIoTDocument, options);
      }
export type RemoveCounterFromTrafficIoTMutationHookResult = ReturnType<typeof useRemoveCounterFromTrafficIoTMutation>;
export type RemoveCounterFromTrafficIoTMutationResult = Apollo.MutationResult<RemoveCounterFromTrafficIoTMutation>;
export type RemoveCounterFromTrafficIoTMutationOptions = Apollo.BaseMutationOptions<RemoveCounterFromTrafficIoTMutation, RemoveCounterFromTrafficIoTMutationVariables>;