import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface OperationsHeaderProps {
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
}

const OperationsHeader: React.FC<OperationsHeaderProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {props.onSelectAll && (
        <Compo.Button icon={Compo.CheckIcon} onClick={props.onSelectAll}>
          {t("operations_header.select_all")}
        </Compo.Button>
      )}
      {props.onUnselectAll && (
        <Compo.Button
          variant="alert"
          icon={Compo.CloseIcon}
          onClick={props.onUnselectAll}
        >
          {t("operations_header.unselect_all")}
        </Compo.Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

export default OperationsHeader;
