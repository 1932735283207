import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StatisticsFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetStatisticsInput;
}>;


export type StatisticsFromLightingQuery = { __typename?: 'Query', Lighting_statistics: { __typename?: 'Lighting_GetStatisticsPayload', statistics: { __typename?: 'Lighting_StatisticsReadModel', powerBoxCount: number, poleCount: number, luminaireCount: number } } };


export const StatisticsFromLightingDocument = gql`
    query statisticsFromLighting($input: Lighting_GetStatisticsInput!) {
  Lighting_statistics(input: $input) {
    statistics {
      powerBoxCount
      poleCount
      luminaireCount
    }
  }
}
    `;

/**
 * __useStatisticsFromLightingQuery__
 *
 * To run a query within a React component, call `useStatisticsFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStatisticsFromLightingQuery(baseOptions: Apollo.QueryHookOptions<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables> & ({ variables: StatisticsFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables>(StatisticsFromLightingDocument, options);
      }
export function useStatisticsFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables>(StatisticsFromLightingDocument, options);
        }
export function useStatisticsFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables>(StatisticsFromLightingDocument, options);
        }
export type StatisticsFromLightingQueryHookResult = ReturnType<typeof useStatisticsFromLightingQuery>;
export type StatisticsFromLightingLazyQueryHookResult = ReturnType<typeof useStatisticsFromLightingLazyQuery>;
export type StatisticsFromLightingSuspenseQueryHookResult = ReturnType<typeof useStatisticsFromLightingSuspenseQuery>;
export type StatisticsFromLightingQueryResult = Apollo.QueryResult<StatisticsFromLightingQuery, StatisticsFromLightingQueryVariables>;