import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { usePage, useEnabledApiMatch } from "../contexts";

export interface ApiProxyProps {
  api: string;
  public?: boolean;
}

const ApiProxy: React.FC<ApiProxyProps> = (props) => {
  const { t } = useTranslation();
  const page = usePage();
  const navigate = useNavigate();
  const enabled = useEnabledApiMatch(props.api);

  React.useEffect(() => {
    page.setApiTitle(t(`api_${props.api}`));
  }, [t, page, props.api]);

  React.useEffect(() => {
    if (!props.public && enabled === false) {
      navigate("/404", { replace: true });
    }
  }, [props.public, enabled, navigate]);

  if (props.public || enabled) {
    return <Outlet />;
  }

  return <React.Fragment />;
};

export default ApiProxy;
