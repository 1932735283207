import React from "react";
import { Outlet } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import {
  LayerFocusProxy,
  LayerOverrideProxy,
  type MapLayer,
} from "@smartly-city/compo-map";
import { usePolesFromLightingLazyQuery } from "src/graphql/__generated__/LightingPoles";
import { PoleFiltersProvider, usePoleFilters } from "../contexts/PoleFilters";
import { POLE_LAYER_ID, usePoleLayerBuilder } from "../utils/layers";
import { toPoleQueryFilters } from "../utils/filters";

const LightingPolesProxy: React.FC = () => {
  return (
    <PoleFiltersProvider>
      <LightingPolesFiltersProxy>
        <LayerFocusProxy layerId={POLE_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </LightingPolesFiltersProxy>
    </PoleFiltersProvider>
  );
};

const LightingPolesFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = usePoleLayerBuilder();
  const { filters } = usePoleFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getPoles] = usePolesFromLightingLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data } = await getPoles({
      variables: {
        input: { areaId: area.id, filters: toPoleQueryFilters(filters) },
      },
    });
    const items = data?.Lighting_poles.items ?? [];
    setData(builder(items));
  }, [area.id, filters, getPoles, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={POLE_LAYER_ID} data={data}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default LightingPolesProxy;
