import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { usePermissionMatch } from "src/core/contexts";
import { useFailuresTranslation } from "../../utils/translation";
import { useNavigate } from "react-router-dom";
import { FailureList, FailureListSkeleton } from "../../containers/FailureList";

const FailureCollection: React.FC = () => {
  const { t } = useFailuresTranslation();
  const navigate = useNavigate();
  const administrationPermitted = usePermissionMatch("failures.admin.get");

  return (
    <Page permission="failures.failure.get">
      <StyledHeader title={t("page_failure_collection.title")}>
        <Compo.ButtonList>
          {administrationPermitted && (
            <Compo.Button
              minimize
              icon={Compo.CogIcon}
              onClick={() => navigate("admin")}
            >
              {t("page_failure_collection.admin")}
            </Compo.Button>
          )}
        </Compo.ButtonList>
      </StyledHeader>
      <React.Suspense fallback={<FailureListSkeleton />}>
        <FailureList
          paged
          filtered
          onClick={(failure) => navigate("failure/" + failure.id)}
        />
      </React.Suspense>
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default FailureCollection;
