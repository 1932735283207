import React from "react";
import styled from "styled-components";
import Page from "src/core/components/Page";
import Compo, { useDialog, useModal } from "@smartly-city/compo";
import { Navigate, useParams } from "react-router-dom";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useLuminaireTypeByIdFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingLuminaireTypeById";
import { LuminaireTypeForm } from "../../containers/LuminaireTypeForm";
import LuminaireTypeViewSkeleton from "./LuminaireTypeView.skeleton";
import { LuminaireTypeDeleteForm } from "../../containers/LuminaireTypeDeleteForm";
import { useLightingTranslation } from "../../utils/translation";
import type { Lighting_LuminaireTypeModel } from "src/graphql/types";

const LuminaireTypeView: React.FC = () => (
  <Page permission="lighting.luminaire_type.get">
    <React.Suspense fallback={<LuminaireTypeViewSkeleton />}>
      <LuminaireTypeViewContent />
    </React.Suspense>
  </Page>
);

const LuminaireTypeViewContent: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const editModal = useModal();
  const deleteDialog = useDialog();
  const params = useParams<{ id: string }>();
  const editPermitted = usePermissionMatch("lighting.luminaire_type.update");
  const deletePermitted = usePermissionMatch("lighting.luminaire_type.delete");

  const { data } = useLuminaireTypeByIdFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id, id: params.id as string } },
  });

  const luminaireType = data.Lighting_luminaireTypeById
    .luminaireType as Lighting_LuminaireTypeModel;

  if (!luminaireType) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Wrapper>
      <Compo.Header title={luminaireType.name}>
        {editPermitted && (
          <Compo.Button
            filled
            icon={Compo.PencilIcon}
            onClick={() => editModal.setIsOpen(true)}
          />
        )}
        {deletePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => deleteDialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={
          t(
            `source_of_light.${luminaireType.details.sourceOfLight.toLowerCase()}`
          ) ?? undefined
        }
      >
        {t("page_luminaire_type_view.source_of_light")}
      </Compo.ListRow>
      <Compo.ListRow wide description={String(luminaireType.details.power)}>
        {t("page_luminaire_type_view.power")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={String(luminaireType.details.sourcePower)}
      >
        {t("page_luminaire_type_view.source_power")}
      </Compo.ListRow>
      {luminaireType.details.manufacturerCode && (
        <Compo.ListRow
          wide
          description={luminaireType.details.manufacturerCode}
        >
          {t("page_luminaire_type_view.manufacturer_code")}
        </Compo.ListRow>
      )}
      {luminaireType.ledDetails && (
        <React.Fragment>
          <HeaderWithDistance outline type="h2" title={t("led_details")} />
          <Compo.ListRow
            wide
            description={
              (luminaireType.ledDetails.hasControllerSocket
                ? t("yes")
                : t("no")) ?? undefined
            }
          >
            {t("page_luminaire_type_view.has_controller_socket")}
          </Compo.ListRow>
          {luminaireType.ledDetails.optics && (
            <Compo.ListRow wide description={luminaireType.ledDetails.optics}>
              {t("page_luminaire_type_view.optics")}
            </Compo.ListRow>
          )}
          {luminaireType.ledDetails.driverType && (
            <Compo.ListRow
              wide
              description={luminaireType.ledDetails.driverType}
            >
              {t("page_luminaire_type_view.driver_type")}
            </Compo.ListRow>
          )}
          {luminaireType.ledDetails.driverCurrent && (
            <Compo.ListRow
              wide
              description={
                String(luminaireType.ledDetails.driverCurrent) + " mA"
              }
            >
              {t("page_luminaire_type_view.driver_current")}
            </Compo.ListRow>
          )}
          {luminaireType.ledDetails.amountOfLed && (
            <Compo.ListRow
              wide
              description={String(luminaireType.ledDetails.amountOfLed)}
            >
              {t("page_luminaire_type_view.amount_of_led")}
            </Compo.ListRow>
          )}
        </React.Fragment>
      )}
      {editModal.isOpen &&
        editModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireTypeForm
              luminaireType={luminaireType}
              onClose={() => editModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {deleteDialog.isOpen &&
        deleteDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireTypeDeleteForm
              luminaireType={luminaireType}
              onClose={() => deleteDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default LuminaireTypeView;
