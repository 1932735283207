import React from "react";
import Page from "src/core/components/Page";
import AreaSettingsSkeleton from "./AreaSettings.skeleton";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useAreaByIdFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingAreaById";
import Compo, { useModal } from "@smartly-city/compo";
import styled from "styled-components";
import { AreaSettingsForm } from "./elements/AreaSettingsForm";
import type { Lighting_AreaModel } from "src/graphql/types";
import { useLightingTranslation } from "../../utils/translation";

const AreaSettings: React.FC = () => {
  return (
    <Page permission="lighting.area.update_settings">
      <React.Suspense fallback={<AreaSettingsSkeleton />}>
        <AreaSettingsContent />
      </React.Suspense>
    </Page>
  );
};

const AreaSettingsContent: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const modal = useModal();
  const editPermitted = usePermissionMatch("lighting.area.update_settings");

  const { data } = useAreaByIdFromLightingSuspenseQuery({
    variables: { input: { id: area.id } },
  });

  const areaSettings = data.Lighting_areaById.area as Lighting_AreaModel;

  return (
    <Wrapper>
      <Compo.Header title={area.name}>
        {editPermitted && (
          <Compo.Button
            filled
            icon={Compo.PencilIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <HeaderWithDistance
        outline
        type="h2"
        title={t("page_area_settings.title")}
      />
      <Compo.ListRow
        wide
        description={
          (areaSettings?.settings.requireMaintenanceDetails
            ? t("yes")
            : t("no")) ?? undefined
        }
      >
        {t("page_area_settings.require_maintenance_details")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={
          (areaSettings?.settings.requireRoadDetails ? t("yes") : t("no")) ??
          undefined
        }
      >
        {t("page_area_settings.require_road_details")}
      </Compo.ListRow>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <AreaSettingsForm
              areaSettings={areaSettings}
              onClose={() => modal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default AreaSettings;
