import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PowerBoxByIdFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetPowerBoxByIdInput;
}>;


export type PowerBoxByIdFromLightingQuery = { __typename?: 'Query', Lighting_powerBoxById: { __typename?: 'Lighting_GetPowerBoxByIdPayload', powerBox?: { __typename?: 'Lighting_PowerBoxModel', id: any, version: number, ordinalNumber: number, areaId: any, name: string, location: { __typename?: 'Lighting_CoordinatesModel', latitude: number, longitude: number }, address: { __typename?: 'Lighting_AddressModel', number?: string | null, town: string, street?: string | null }, details: { __typename?: 'Lighting_PowerBoxDefaultDetailsModel', power?: number | null, contractedPower?: number | null, fuseType?: string | null, fuseAmpRating?: number | null, recipientCode?: string | null, notes?: string | null } } | null } };


export const PowerBoxByIdFromLightingDocument = gql`
    query powerBoxByIdFromLighting($input: Lighting_GetPowerBoxByIdInput!) {
  Lighting_powerBoxById(input: $input) {
    powerBox {
      id
      version
      ordinalNumber
      areaId
      name
      location {
        latitude
        longitude
      }
      address {
        number
        town
        street
      }
      details {
        power
        contractedPower
        fuseType
        fuseAmpRating
        recipientCode
        notes
      }
    }
  }
}
    `;

/**
 * __usePowerBoxByIdFromLightingQuery__
 *
 * To run a query within a React component, call `usePowerBoxByIdFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerBoxByIdFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerBoxByIdFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePowerBoxByIdFromLightingQuery(baseOptions: Apollo.QueryHookOptions<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables> & ({ variables: PowerBoxByIdFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables>(PowerBoxByIdFromLightingDocument, options);
      }
export function usePowerBoxByIdFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables>(PowerBoxByIdFromLightingDocument, options);
        }
export function usePowerBoxByIdFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables>(PowerBoxByIdFromLightingDocument, options);
        }
export type PowerBoxByIdFromLightingQueryHookResult = ReturnType<typeof usePowerBoxByIdFromLightingQuery>;
export type PowerBoxByIdFromLightingLazyQueryHookResult = ReturnType<typeof usePowerBoxByIdFromLightingLazyQuery>;
export type PowerBoxByIdFromLightingSuspenseQueryHookResult = ReturnType<typeof usePowerBoxByIdFromLightingSuspenseQuery>;
export type PowerBoxByIdFromLightingQueryResult = Apollo.QueryResult<PowerBoxByIdFromLightingQuery, PowerBoxByIdFromLightingQueryVariables>;