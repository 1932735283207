import { useTranslation } from "react-i18next";
import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export const useReportsTranslation = (
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<"reports"> => {
  return useTranslation("reports", options);
};
