import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StationJournalByIdFromWeatherIoTQueryVariables = Types.Exact<{
  input: Types.WeatherIoT_GetStationJournalByIdInput;
}>;


export type StationJournalByIdFromWeatherIoTQuery = { __typename?: 'Query', WeatherIoT_stationJournalById: { __typename?: 'WeatherIoT_GetStationJournalByIdPayload', journal?: { __typename?: 'WeatherIoT_StationJournalModel', id: any, version: number, created: any, changed: any, areaId: any, stationId: any, status: { __typename?: 'WeatherIoT_StationJournalStatusModel', communicationDate?: any | null, measurementsDate?: any | null, signalStrength?: number | null, signalToNoiseRatio?: number | null, voltage?: number | null, current?: number | null, airQualityIndex?: number | null }, readings: { __typename?: 'WeatherIoT_StationJournalReadingsModel', airQualityDate?: any | null, airQualityIndex?: number | null, airQualityVolatileOrganicCompounds?: number | null, airQualityCarbonMonoxide?: number | null, airQualityCarbonDioxide?: number | null, airQualityOxygen?: number | null, airQualityOzone?: number | null, airQualityHydrogenSulphide?: number | null, airQualityNitricOxide?: number | null, airQualityNitrogenDioxide?: number | null, airQualitySulphurDioxide?: number | null, airQualityPM01Concentration?: number | null, airQualityPM03Concentration?: number | null, airQualityPM05Concentration?: number | null, airQualityPM1Concentration?: number | null, airQualityPM2Concentration?: number | null, airQualityPM4Concentration?: number | null, airQualityPM5Concentration?: number | null, airQualityPM10Concentration?: number | null, ambientLightDate?: any | null, ambientLightValue?: number | null, humidityDate?: any | null, humidityAbsolute?: number | null, humidityRelative?: number | null, pressureDate?: any | null, pressureValue?: number | null, temperatureDate?: any | null, temperatureValue?: number | null } } | null } };


export const StationJournalByIdFromWeatherIoTDocument = gql`
    query stationJournalByIdFromWeatherIoT($input: WeatherIoT_GetStationJournalByIdInput!) {
  WeatherIoT_stationJournalById(input: $input) {
    journal {
      id
      version
      created
      changed
      areaId
      stationId
      status {
        communicationDate
        measurementsDate
        signalStrength
        signalToNoiseRatio
        voltage
        current
        airQualityIndex
      }
      readings {
        airQualityDate
        airQualityIndex
        airQualityVolatileOrganicCompounds
        airQualityCarbonMonoxide
        airQualityCarbonDioxide
        airQualityOxygen
        airQualityOzone
        airQualityHydrogenSulphide
        airQualityNitricOxide
        airQualityNitrogenDioxide
        airQualitySulphurDioxide
        airQualityPM01Concentration
        airQualityPM03Concentration
        airQualityPM05Concentration
        airQualityPM1Concentration
        airQualityPM2Concentration
        airQualityPM4Concentration
        airQualityPM5Concentration
        airQualityPM10Concentration
        ambientLightDate
        ambientLightValue
        humidityDate
        humidityAbsolute
        humidityRelative
        pressureDate
        pressureValue
        temperatureDate
        temperatureValue
      }
    }
  }
}
    `;

/**
 * __useStationJournalByIdFromWeatherIoTQuery__
 *
 * To run a query within a React component, call `useStationJournalByIdFromWeatherIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationJournalByIdFromWeatherIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationJournalByIdFromWeatherIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStationJournalByIdFromWeatherIoTQuery(baseOptions: Apollo.QueryHookOptions<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables> & ({ variables: StationJournalByIdFromWeatherIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables>(StationJournalByIdFromWeatherIoTDocument, options);
      }
export function useStationJournalByIdFromWeatherIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables>(StationJournalByIdFromWeatherIoTDocument, options);
        }
export function useStationJournalByIdFromWeatherIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables>(StationJournalByIdFromWeatherIoTDocument, options);
        }
export type StationJournalByIdFromWeatherIoTQueryHookResult = ReturnType<typeof useStationJournalByIdFromWeatherIoTQuery>;
export type StationJournalByIdFromWeatherIoTLazyQueryHookResult = ReturnType<typeof useStationJournalByIdFromWeatherIoTLazyQuery>;
export type StationJournalByIdFromWeatherIoTSuspenseQueryHookResult = ReturnType<typeof useStationJournalByIdFromWeatherIoTSuspenseQuery>;
export type StationJournalByIdFromWeatherIoTQueryResult = Apollo.QueryResult<StationJournalByIdFromWeatherIoTQuery, StationJournalByIdFromWeatherIoTQueryVariables>;