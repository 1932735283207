import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LightingSchedulesPagedFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLightingSchedulesPagedInput;
}>;


export type LightingSchedulesPagedFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_lightingSchedulesPaged: { __typename?: 'LightingIoT_GetLightingSchedulesPagedPayload', count: number, total: number, items: Array<{ __typename?: 'LightingIoT_LightingScheduleRowModel', id: any, version: number, name: string, areaId: any }> } };


export const LightingSchedulesPagedFromLightingIoTDocument = gql`
    query lightingSchedulesPagedFromLightingIoT($input: LightingIoT_GetLightingSchedulesPagedInput!) {
  LightingIoT_lightingSchedulesPaged(input: $input) {
    count
    total
    items {
      id
      version
      name
      areaId
    }
  }
}
    `;

/**
 * __useLightingSchedulesPagedFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLightingSchedulesPagedFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLightingSchedulesPagedFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLightingSchedulesPagedFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLightingSchedulesPagedFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables> & ({ variables: LightingSchedulesPagedFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables>(LightingSchedulesPagedFromLightingIoTDocument, options);
      }
export function useLightingSchedulesPagedFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables>(LightingSchedulesPagedFromLightingIoTDocument, options);
        }
export function useLightingSchedulesPagedFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables>(LightingSchedulesPagedFromLightingIoTDocument, options);
        }
export type LightingSchedulesPagedFromLightingIoTQueryHookResult = ReturnType<typeof useLightingSchedulesPagedFromLightingIoTQuery>;
export type LightingSchedulesPagedFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLightingSchedulesPagedFromLightingIoTLazyQuery>;
export type LightingSchedulesPagedFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLightingSchedulesPagedFromLightingIoTSuspenseQuery>;
export type LightingSchedulesPagedFromLightingIoTQueryResult = Apollo.QueryResult<LightingSchedulesPagedFromLightingIoTQuery, LightingSchedulesPagedFromLightingIoTQueryVariables>;