import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexPolesFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_Admin_IndexPolesInput;
}>;


export type IndexPolesFromLightingMutation = { __typename?: 'Mutation', Lighting_Admin_indexPoles: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const IndexPolesFromLightingDocument = gql`
    mutation indexPolesFromLighting($input: Lighting_Admin_IndexPolesInput!) {
  Lighting_Admin_indexPoles(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type IndexPolesFromLightingMutationFn = Apollo.MutationFunction<IndexPolesFromLightingMutation, IndexPolesFromLightingMutationVariables>;

/**
 * __useIndexPolesFromLightingMutation__
 *
 * To run a mutation, you first call `useIndexPolesFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexPolesFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexPolesFromLightingMutation, { data, loading, error }] = useIndexPolesFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexPolesFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<IndexPolesFromLightingMutation, IndexPolesFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexPolesFromLightingMutation, IndexPolesFromLightingMutationVariables>(IndexPolesFromLightingDocument, options);
      }
export type IndexPolesFromLightingMutationHookResult = ReturnType<typeof useIndexPolesFromLightingMutation>;
export type IndexPolesFromLightingMutationResult = Apollo.MutationResult<IndexPolesFromLightingMutation>;
export type IndexPolesFromLightingMutationOptions = Apollo.BaseMutationOptions<IndexPolesFromLightingMutation, IndexPolesFromLightingMutationVariables>;