import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexStationJournalsFromWeatherIoTMutationVariables = Types.Exact<{
  input: Types.WeatherIoT_Admin_IndexStationJournalsInput;
}>;


export type IndexStationJournalsFromWeatherIoTMutation = { __typename?: 'Mutation', WeatherIoT_Admin_indexStationJournals: { __typename?: 'WeatherIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'WeatherIoT_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'WeatherIoT_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const IndexStationJournalsFromWeatherIoTDocument = gql`
    mutation indexStationJournalsFromWeatherIoT($input: WeatherIoT_Admin_IndexStationJournalsInput!) {
  WeatherIoT_Admin_indexStationJournals(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type IndexStationJournalsFromWeatherIoTMutationFn = Apollo.MutationFunction<IndexStationJournalsFromWeatherIoTMutation, IndexStationJournalsFromWeatherIoTMutationVariables>;

/**
 * __useIndexStationJournalsFromWeatherIoTMutation__
 *
 * To run a mutation, you first call `useIndexStationJournalsFromWeatherIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexStationJournalsFromWeatherIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexStationJournalsFromWeatherIoTMutation, { data, loading, error }] = useIndexStationJournalsFromWeatherIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexStationJournalsFromWeatherIoTMutation(baseOptions?: Apollo.MutationHookOptions<IndexStationJournalsFromWeatherIoTMutation, IndexStationJournalsFromWeatherIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexStationJournalsFromWeatherIoTMutation, IndexStationJournalsFromWeatherIoTMutationVariables>(IndexStationJournalsFromWeatherIoTDocument, options);
      }
export type IndexStationJournalsFromWeatherIoTMutationHookResult = ReturnType<typeof useIndexStationJournalsFromWeatherIoTMutation>;
export type IndexStationJournalsFromWeatherIoTMutationResult = Apollo.MutationResult<IndexStationJournalsFromWeatherIoTMutation>;
export type IndexStationJournalsFromWeatherIoTMutationOptions = Apollo.BaseMutationOptions<IndexStationJournalsFromWeatherIoTMutation, IndexStationJournalsFromWeatherIoTMutationVariables>;