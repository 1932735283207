import type { DeepPartial } from "src/core/utils/types";
import type { LightingIoT_CreateNewLightingScheduleInput } from "src/graphql/types";

export const defaultValues: DeepPartial<LightingIoT_CreateNewLightingScheduleInput> =
  {
    name: null as any,
    startStep: null as any,
    changeSteps: [],
    stopStep: null as any,
  };
