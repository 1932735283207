import React from "react";

export interface CounterFilters {
  name?: string;
}

export type CounterFiltersStatus = Record<keyof CounterFilters, boolean>;

export interface CounterFiltersContextState {
  filters: CounterFilters;
  status: CounterFiltersStatus;
  setFilters: (filters: CounterFilters) => void;
  deactivateFilter: (name: keyof CounterFilters) => void;
  activateFilter: (name: keyof CounterFilters) => void;
  clearFilters: () => void;
}

export const CounterFiltersContext = React.createContext<
  CounterFiltersContextState | undefined
>(undefined);

export const useCounterFilters = (): CounterFiltersContextState => {
  const context = React.useContext(CounterFiltersContext);
  return context as CounterFiltersContextState;
};
