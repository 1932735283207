import React from "react";
import Compo from "@smartly-city/compo";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { Lighting_FasteningModel } from "src/graphql/types";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { useFormikContext } from "formik";
import type { LuminaireFormContext } from "../../LuminaireForm";

const LuminaireFormDefaultDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<LuminaireFormContext>();

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="fastening"
        name="fastening"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.fastening")}
        label={t("form_luminaire.fastening")}
        value={toStringValue(formik.values.details?.fastening)}
        onChange={setTextFieldValue(formik, "details.fastening")}
      >
        <option value="" />
        {Object.values(Lighting_FasteningModel).map((type) => (
          <option key={type} value={type}>
            {t(`fastening.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.TextInput
        wide
        id="height"
        name="height"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "details.height")}
        label={t("form_luminaire.height")}
        value={toStringValue(formik.values.details?.height)}
        onChange={setFloatFieldValue(formik, "details.height")}
      />
    </React.Fragment>
  );
};

export default LuminaireFormDefaultDetailsInputs;
