import React from "react";
import { Outlet } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import {
  LayerFocusProxy,
  LayerOverrideProxy,
  type MapLayer,
} from "@smartly-city/compo-map";
import { usePowerBoxesFromLightingLazyQuery } from "src/graphql/__generated__/LightingPowerBoxes";
import {
  PowerBoxFiltersProvider,
  usePowerBoxFilters,
} from "../contexts/PowerBoxFilters";
import { POWER_BOX_LAYER_ID, usePowerBoxLayerBuilder } from "../utils/layers";
import { toPowerBoxQueryFilters } from "../utils/filters";

const LightingPowerBoxesProxy: React.FC = () => {
  return (
    <PowerBoxFiltersProvider>
      <LightingPowerBoxesFiltersProxy>
        <LayerFocusProxy layerId={POWER_BOX_LAYER_ID}>
          <Outlet />
        </LayerFocusProxy>
      </LightingPowerBoxesFiltersProxy>
    </PowerBoxFiltersProvider>
  );
};

const LightingPowerBoxesFiltersProxy: React.FC<React.PropsWithChildren> = (
  props
) => {
  const area = useCurrentArea();
  const builder = usePowerBoxLayerBuilder();
  const { filters } = usePowerBoxFilters();
  const [data, setData] = React.useState<MapLayer>();
  const [getPowerBoxes] = usePowerBoxesFromLightingLazyQuery();

  const updateOverride = React.useCallback(async (): Promise<void> => {
    const { data } = await getPowerBoxes({
      variables: {
        input: { areaId: area.id, filters: toPowerBoxQueryFilters(filters) },
      },
    });
    const items = data?.Lighting_powerBoxes.items ?? [];
    setData(builder(items));
  }, [area.id, filters, getPowerBoxes, builder]);

  React.useEffect(() => {
    if (!filters || Object.keys(filters).length === 0) setData(undefined);
    else void updateOverride();
  }, [filters, updateOverride]);

  return (
    <LayerOverrideProxy layerId={POWER_BOX_LAYER_ID} data={data}>
      {props.children}
    </LayerOverrideProxy>
  );
};

export default LightingPowerBoxesProxy;
