import type { MapLayer } from "@smartly-city/compo-map";
import React from "react";
import type {
  Lighting_PoleRowModel,
  Lighting_PowerBoxRowModel,
} from "src/graphql/types";

export const POWER_BOX_LAYER_ID = "power_box";
export const POLE_LAYER_ID = "pole";

export const usePowerBoxLayerBuilder = (): ((
  powerBoxes: Lighting_PowerBoxRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (powerBoxes) => ({
      id: POWER_BOX_LAYER_ID,
      color: "#EB7A24",
      items: powerBoxes.map((powerBox) => ({
        id: powerBox.id,
        coordinates: powerBox.location,
        name: powerBox.name,
        color: "#EB7A24",
      })),
    }),
    []
  );
};

export const usePoleLayerBuilder = (): ((
  poles: Lighting_PoleRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (poles) => ({
      id: POLE_LAYER_ID,
      color: "#2463EB",
      items: poles.map((pole) => ({
        id: pole.id,
        coordinates: pole.location,
        name: pole.name,
        color: "#2463EB",
      })),
    }),
    []
  );
};
