import React from "react";
import { FailureFiltersContext } from "./FailureFiltersContext";
import type {
  FailureFiltersStatus,
  FailureFilters,
} from "./FailureFiltersContext";
import { useFiltersUtils } from "src/core/utils/filters";

export interface FailureFiltersProviderProps {
  children?: React.ReactNode;
}

const FailureFiltersProvider: React.FC<FailureFiltersProviderProps> = (
  props
) => {
  const initStatus: FailureFiltersStatus = {
    active: false,
    objectName: false,
    createdDate: false,
    errorGroup: false,
    errorStage: false,
    errorType: false,
    state: false,
    type: false,
  };

  const utils = useFiltersUtils<FailureFilters>({}, initStatus);

  return (
    <FailureFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </FailureFiltersContext.Provider>
  );
};

export default FailureFiltersProvider;
