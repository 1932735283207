import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toSnakeCase } from "src/core/utils/string";
import { useCurrentArea } from "src/core/contexts";
import { useUpdateLuminaireFromLightingMutation } from "src/graphql/__generated__/LightingUpdateLuminaire";
import { useCreateNewLuminaireFromLightingMutation } from "src/graphql/__generated__/LightingCreateNewLuminaire";
import type { TFunction } from "i18next";
import type { DeepPartial } from "src/core/utils/types";
import type {
  Lighting_LuminaireModel,
  Lighting_CreateNewLuminaireInput,
  Lighting_UpdateLuminaireInput,
} from "src/graphql/types";
import {
  LuminaireFormDataInputs,
  dataDefaultValues,
  dataValidationSchedma,
} from "./elements/LuminaireFormData";
import {
  LuminaireFormDefaultDetailsInputs,
  defaultDetailsDefaultValues,
  defaultDetailsValidationSchema,
} from "./elements/LuminaireFormDefaultDetails";
import {
  LuminaireFormMaintenanceDetailsInputs,
  maintenanceDetailsDefaultValues,
  maintenanceDetailsValidationSchema,
} from "./elements/LuminaireFormMaintenanceDetails";
import { useAreaSettings } from "../../contexts/AreaSettings";
import { useLightingTranslation } from "../../utils/translation";

export interface LuminaireFormProps {
  onClose: () => void;
  poleId: string;
  luminaire?: Lighting_LuminaireModel;
}

export interface LuminaireFormContext
  extends DeepPartial<Lighting_CreateNewLuminaireInput> {}

const LuminaireForm: React.FC<LuminaireFormProps> = (props) => {
  const { t } = useLightingTranslation();
  const navigate = useNavigate();
  const area = useCurrentArea();
  const settings = useAreaSettings();
  const [step, setStep] = React.useState(0);

  const [create] = useCreateNewLuminaireFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_createNewLuminaire.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_luminaires",
      });
      cache.gc();
    },
  });

  const [update] = useUpdateLuminaireFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_updateLuminaire.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_luminaireById",
      });
      cache.evict({
        fieldName: "Lighting_luminaires",
      });
      cache.gc();
    },
  });

  const steps = {
    data: {
      render: React.useCallback(
        () => <LuminaireFormDataInputs isNew={!props.luminaire} />,
        [props.luminaire]
      ),
      validationSchema: dataValidationSchedma,
      defaultValues: dataDefaultValues,
      canBeSkipped: false,
      isEmpty: () => !formik.values,
    },
    details: {
      render: LuminaireFormDefaultDetailsInputs,
      validationSchema: defaultDetailsValidationSchema,
      defaultValues: defaultDetailsDefaultValues,
      canBeSkipped: false,
      isEmpty: () => !formik.values.details,
    },
    maintenanceDetails: {
      render: LuminaireFormMaintenanceDetailsInputs,
      validationSchema: maintenanceDetailsValidationSchema,
      defaultValues: maintenanceDetailsDefaultValues,
      canBeSkipped: !settings.requireMaintenanceDetails,
      isEmpty: () => !formik.values.maintenanceDetails,
    },
  } as const;

  const currentStep = Object.values(steps)[step];
  const currentStepName = Object.keys(steps)[step];

  React.useEffect(() => {
    if (currentStep.isEmpty()) {
      void formik.setFieldValue(currentStepName, currentStep.defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepName]);

  const isLastStep = (): boolean => {
    return step === Object.keys(steps).length - 1;
  };

  const handleNext = (): void => {
    const prevStep = step;
    setStep(prevStep + 1);
  };

  const handlePrev = (): void => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  const handleClear = async (): Promise<void> => {
    if (!currentStep.canBeSkipped) return;
    await formik.setFieldValue(currentStepName, null);
    await formik.validateForm();
  };

  const handleSubmit = async (values: any): Promise<void> => {
    if (!isLastStep()) {
      formik.setSubmitting(false);
      handleNext();
      return;
    }

    if (props.luminaire) {
      const newLuminaire = {
        id: props.luminaire.id,
        version: props.luminaire.version,
        areaId: props.luminaire.areaId,
        details: values.details,
        maintenanceDetails: values.maintenanceDetails,
      };

      const { data } = await update({
        variables: {
          input: newLuminaire as Lighting_UpdateLuminaireInput,
        },
      });

      const result = data?.Lighting_updateLuminaire;
      if (result?.isSuccess) {
        toast.success(t(`notification.luminaire_updated`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    } else {
      const { data } = await create({
        variables: {
          input: {
            areaId: area.id,
            poleId: props.poleId,
            typeId: values.typeId,
            details: values.details,
            maintenanceDetails: values.maintenanceDetails,
          },
        },
      });

      const result = data?.Lighting_createNewLuminaire;
      if (result?.isSuccess) {
        toast.success(t(`notification.luminaire_created`));
        navigate(`luminaires/${result.value?.id as string}`);
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    }
  };

  const formik = useFormik<LuminaireFormContext>({
    initialValues: props.luminaire ?? {
      ...steps.data.defaultValues,
      details: steps.details.defaultValues,
      maintenanceDetails: steps.maintenanceDetails.defaultValues,
    },
    validationSchema: currentStep.validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={
          <HeaderWrapper>
            <Header
              title={t(
                "form_luminaire." +
                  (props.luminaire ? "edit_title" : "add_title")
              )}
            />
            <Compo.Stepper>
              {Object.keys(steps).map((key, index) =>
                getStep(t, index + 1, toSnakeCase(key), step + 1)
              )}
            </Compo.Stepper>
          </HeaderWrapper>
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {isLastStep()
                ? t("submit")
                : currentStep.canBeSkipped && currentStep.isEmpty()
                ? t("skip")
                : t("next")}
            </Compo.Button>
            <Compo.Button onClick={handlePrev} disabled={step === 0}>
              {t("previous")}
            </Compo.Button>
            <Compo.Button
              disabled={!(currentStep.canBeSkipped && !currentStep.isEmpty())}
              onClick={handleClear}
            >
              {t("clear_to_skip")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <FormikProvider value={formik}>
            <currentStep.render />
          </FormikProvider>
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const getStep = (
  t: TFunction,
  order: number,
  name: string,
  currentStep: number
): React.ReactElement => (
  <Compo.StepperStep
    key={`step-${order}`}
    label={t(name)}
    order={order}
    disabled={currentStep < order}
    finished={currentStep > order}
  />
);

const Header = styled(Compo.Header)`
  width: max-content;
  padding: 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LuminaireForm;
