import React from "react";
import Compo from "@smartly-city/compo";
import { useSignOutMutation } from "src/graphql/__generated__/SignOut";

const LogoutPage: React.FC = () => {
  const [signOut] = useSignOutMutation();
  const [processing, setProcessing] = React.useState(true);

  React.useEffect(() => {
    void signOut({ onCompleted: () => setProcessing(false) });
  }, [signOut]);

  React.useEffect(() => {
    if (!processing) {
      setTimeout(() => {
        window.location.href = "/login";
      }, 100);
    }
  }, [processing]);

  return <Compo.Spinner />;
};

export default LogoutPage;
