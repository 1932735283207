import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import CounterPeopleJournalTabDailyCountChartSkeleton from "./charts/CounterPeopleJournalTabDailyCountChart.skeleton";
import CounterPeopleJournalTabDailySpeedChartSkeleton from "./charts/CounterPeopleJournalTabDailySpeedChart.skeleton";
import CounterPeopleJournalTabMonthlyCountChartSkeleton from "./charts/CounterPeopleJournalTabMonthlyCountChart.skeleton";
import CounterPeopleJournalTabMonthlySpeedChartSkeleton from "./charts/CounterPeopleJournalTabMonthlySpeedChart.skeleton";

const CounterPeopleJournalTabSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Compo.HeaderSkeleton />
      <CounterPeopleJournalTabDailyCountChartSkeleton />
      <CounterPeopleJournalTabDailySpeedChartSkeleton />
      <CounterPeopleJournalTabMonthlyCountChartSkeleton />
      <CounterPeopleJournalTabMonthlySpeedChartSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CounterPeopleJournalTabSkeleton;
