import type { StationFilters } from "../contexts/StationFilters";
import type { WeatherIoT_StationFiltersModelInput } from "src/graphql/types";

export const toStationQueryFilters = (
  filters?: StationFilters
): WeatherIoT_StationFiltersModelInput | null => {
  if (!filters) return null;

  return {
    matchesName: filters.name ?? null,
  };
};
