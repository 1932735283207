import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

const LuminaireJournalTabMonthlyEnergyConsumptionChartSkeleton: React.FC =
  () => {
    return <StyledSkeleton />;
  };

const StyledSkeleton = styled(Skeleton)`
  display: block;
  height: 20rem;
  width: 100%;
  max-width: 100%;
  border-radius: 1rem;
`;

export default LuminaireJournalTabMonthlyEnergyConsumptionChartSkeleton;
