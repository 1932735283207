import React from "react";
import styled from "styled-components";
import Page from "src/core/components/Page";
import Compo, { useDialog, useModal } from "@smartly-city/compo";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useLuminaireByIdFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingLuminaireById";
import { LuminaireForm } from "../../containers/LuminaireForm";
import LuminaireViewSkeleton from "./LuminaireView.skeleton";
import type { Lighting_LuminaireModel } from "src/graphql/types";
import { LuminaireDeleteForm } from "../../containers/LuminaireDeleteForm";
import { useLightingTranslation } from "../../utils/translation";

const LuminaireView: React.FC = () => (
  <Page permission="lighting.luminaire.get">
    <React.Suspense fallback={<LuminaireViewSkeleton />}>
      <LuminaireViewContent />
    </React.Suspense>
  </Page>
);

const LuminaireViewContent: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const editModal = useModal();
  const deleteDialog = useDialog();
  const { id: poleId, luminaireId } = useParams<{
    id: string;
    luminaireId: string;
  }>();
  const navigate = useNavigate();
  const editPermitted = usePermissionMatch("lighting.luminaire.update");
  const deletePermitted = usePermissionMatch("lighting.luminaire.delete");

  const { data } = useLuminaireByIdFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id, id: luminaireId as string } },
  });

  const luminaire = data.Lighting_luminaireById
    .luminaire as Lighting_LuminaireModel;

  if (!luminaire || luminaire.poleId !== poleId) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Wrapper>
      <Compo.Header title={luminaire.ordinalNumber.toString()}>
        {editPermitted && (
          <Compo.Button
            filled
            icon={Compo.PencilIcon}
            onClick={() => editModal.setIsOpen(true)}
          />
        )}
        {deletePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => deleteDialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.Header type="h2" title={t("luminaire_type")} />
      <Compo.LinkTile
        wide
        text={luminaire.typeName}
        icon={Compo.ExternalLinkIcon}
        onClick={() => navigate(`/lighting/luminaire-type/${luminaire.typeId}`)}
      />
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow
        wide
        description={
          t(`fastening.${luminaire.details.fastening.toLowerCase()}`) ??
          undefined
        }
      >
        {t("page_luminaire_view.fastening")}
      </Compo.ListRow>
      <Compo.ListRow wide description={`${luminaire.details.height} m`}>
        {t("page_luminaire_view.height")}
      </Compo.ListRow>
      {luminaire.maintenanceDetails && (
        <React.Fragment>
          <HeaderWithDistance
            outline
            type="h2"
            title={t("maintenance_details")}
          />
          <Compo.ListRow
            wide
            description={
              t(`owner.${luminaire.maintenanceDetails.owner.toLowerCase()}`) ??
              undefined
            }
          >
            {t("page_luminaire_view.owner")}
          </Compo.ListRow>
          <Compo.ListRow
            wide
            description={
              (luminaire.maintenanceDetails.qualifiedForReplacement
                ? t("yes")
                : t("no")) ?? undefined
            }
          >
            {t("page_luminaire_view.qualified_for_replacement")}
          </Compo.ListRow>
        </React.Fragment>
      )}
      {editModal.isOpen &&
        editModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireForm
              luminaire={luminaire}
              poleId={luminaire.poleId}
              onClose={() => editModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {deleteDialog.isOpen &&
        deleteDialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LuminaireDeleteForm
              luminaire={luminaire}
              onClose={() => deleteDialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default LuminaireView;
