import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminairesPagedFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLuminairesPagedInput;
}>;


export type LuminairesPagedFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_luminairesPaged: { __typename?: 'LightingIoT_GetLuminairesPagedPayload', count: number, total: number, items: Array<{ __typename?: 'LightingIoT_LuminaireRowModel', id: any, version: number, areaId: any, typeId: any, typeName: string, name: string, location: { __typename?: 'LightingIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const LuminairesPagedFromLightingIoTDocument = gql`
    query luminairesPagedFromLightingIoT($input: LightingIoT_GetLuminairesPagedInput!) {
  LightingIoT_luminairesPaged(input: $input) {
    count
    total
    items {
      id
      version
      areaId
      typeId
      typeName
      name
      location {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useLuminairesPagedFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLuminairesPagedFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminairesPagedFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminairesPagedFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminairesPagedFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables> & ({ variables: LuminairesPagedFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables>(LuminairesPagedFromLightingIoTDocument, options);
      }
export function useLuminairesPagedFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables>(LuminairesPagedFromLightingIoTDocument, options);
        }
export function useLuminairesPagedFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables>(LuminairesPagedFromLightingIoTDocument, options);
        }
export type LuminairesPagedFromLightingIoTQueryHookResult = ReturnType<typeof useLuminairesPagedFromLightingIoTQuery>;
export type LuminairesPagedFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLuminairesPagedFromLightingIoTLazyQuery>;
export type LuminairesPagedFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLuminairesPagedFromLightingIoTSuspenseQuery>;
export type LuminairesPagedFromLightingIoTQueryResult = Apollo.QueryResult<LuminairesPagedFromLightingIoTQuery, LuminairesPagedFromLightingIoTQueryVariables>;