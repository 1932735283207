import { useTranslation } from "react-i18next";
import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export const useTrafficIoTTranslation = (
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<"traffic_iot"> => {
  return useTranslation("traffic_iot", options);
};
