import React from "react";
import { useFiltersUtils } from "src/core/utils/filters";
import { CounterFiltersContext } from "./CounterFiltersContext";
import type {
  CounterFiltersStatus,
  CounterFilters,
} from "./CounterFiltersContext";

export interface CounterFiltersProviderProps {
  children?: React.ReactNode;
}

const CounterFiltersProvider: React.FC<CounterFiltersProviderProps> = (
  props
) => {
  const initStatus: CounterFiltersStatus = {
    name: false,
  };

  const utils = useFiltersUtils<CounterFilters>({}, initStatus);

  return (
    <CounterFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </CounterFiltersContext.Provider>
  );
};

export default CounterFiltersProvider;
