import React from "react";
import Compo from "@smartly-city/compo";
import AddressInput from "src/core/components/AddressInput";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { useFormikContext } from "formik";
import { useCurrentArea } from "src/core/contexts";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { usePowerBoxesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPowerBoxes";
import type { PoleFormContext } from "../../PoleForm";

const PoleFormDataInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const formik = useFormikContext<PoleFormContext>();

  const { data: powerBoxesData } = usePowerBoxesFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const powerBoxes = powerBoxesData.Lighting_powerBoxes.items;

  return (
    <React.Fragment>
      <Compo.TextInput
        wide
        id="name"
        name="name"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "name")}
        label={t("form_pole.name")}
        value={formik.values.name}
        onChange={setTextFieldValue(formik, "name")}
      />
      <Compo.SelectInput
        wide
        id="power-box"
        name="power-box-id"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "powerBoxId")}
        label={t("form_pole.power_box")}
        value={formik.values.powerBoxId}
        onChange={setTextFieldValue(formik, "powerBoxId")}
      >
        <option value="" />
        {powerBoxes.map((powerBox) => (
          <option key={powerBox.id} value={powerBox.id}>
            {powerBox.name}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.Header outline type="h2" title={t("location")} />
      <AddressInput
        disabled={formik.isSubmitting}
        latitude={{
          value: toStringValue(formik.values.location?.latitude),
          error: getFieldError(formik, "location.latitude"),
          onChange: setFloatFieldValue(formik, "location.latitude"),
        }}
        longitude={{
          value: toStringValue(formik.values.location?.longitude),
          error: getFieldError(formik, "location.longitude"),
          onChange: setFloatFieldValue(formik, "location.longitude"),
        }}
        number={{
          value: formik.values.address?.number ?? "",
          error: getFieldError(formik, "address.number"),
          onChange: setTextFieldValue(formik, "address.number"),
        }}
        city={{
          value: formik.values.address?.town ?? "",
          error: getFieldError(formik, "address.town"),
          onChange: setTextFieldValue(formik, "address.town"),
        }}
        street={{
          value: formik.values.address?.street ?? "",
          error: getFieldError(formik, "address.street"),
          onChange: setTextFieldValue(formik, "address.street"),
        }}
      />
    </React.Fragment>
  );
};

export default PoleFormDataInputs;
