import React from "react";
import Compo from "@smartly-city/compo";
import { useFormikContext } from "formik";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import AddressInput from "src/core/components/AddressInput";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import type { PowerBoxFormContext } from "../../PowerBoxForm";

const PowerBoxFormDataInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PowerBoxFormContext>();

  return (
    <React.Fragment>
      <Compo.TextInput
        wide
        disabled={formik.isSubmitting}
        error={formik.touched.name && formik.errors.name}
        label={t("form_power_box.name")}
        value={formik.values.name}
        onChange={setTextFieldValue(formik, "name")}
      />
      <Compo.Header outline type="h2" title={t("location")} />
      <AddressInput
        disabled={formik.isSubmitting}
        latitude={{
          value: toStringValue(formik.values.location?.latitude),
          error: getFieldError(formik, "location.latitude"),
          onChange: setFloatFieldValue(formik, "location.latitude"),
        }}
        longitude={{
          value: toStringValue(formik.values.location?.longitude),
          error: getFieldError(formik, "location.longitude"),
          onChange: setFloatFieldValue(formik, "location.longitude"),
        }}
        number={{
          value: formik.values.address?.number ?? "",
          error: getFieldError(formik, "address.number"),
          onChange: setTextFieldValue(formik, "address.number"),
        }}
        city={{
          value: formik.values.address?.town ?? "",
          error: getFieldError(formik, "address.town"),
          onChange: setTextFieldValue(formik, "address.town"),
        }}
        street={{
          value: formik.values.address?.street ?? "",
          error: getFieldError(formik, "address.street"),
          onChange: setTextFieldValue(formik, "address.street"),
        }}
      />
    </React.Fragment>
  );
};

export default PowerBoxFormDataInputs;
