export const isEmpty = (value: any): value is null | undefined => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

export const utcDateFromString = (value: string): Date => {
  const date = new Date(value);
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};
