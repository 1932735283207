import React from "react";
import Compo from "@smartly-city/compo";
import {
  getFieldError,
  setBooleanFieldValue,
  setDateFieldValue,
  setFloatFieldValue,
  toDateValue,
  toStringValue,
} from "src/core/utils/formik";
import { useFormikContext } from "formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import type { PoleFormContext } from "../../PoleForm";

const PoleFormBracketDetailsInputs: React.FC = (): React.ReactElement => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PoleFormContext>();

  return (
    <React.Fragment>
      <Compo.TextInput
        wide
        id="bracket-length"
        name="bracket-length"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "bracketDetails.bracketLength")}
        label={t("form_pole.bracket_length")}
        value={toStringValue(formik.values.bracketDetails?.bracketLength)}
        onChange={setFloatFieldValue(formik, "bracketDetails.bracketLength")}
      />
      <Compo.TextInput
        wide
        id="tilt-angle"
        name="tilt-angle"
        type="number"
        unit="°"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "bracketDetails.tiltAngle")}
        label={t("form_pole.tilt_angle")}
        value={toStringValue(formik.values.bracketDetails?.tiltAngle)}
        onChange={setFloatFieldValue(formik, "bracketDetails.tiltAngle")}
      />
      <Compo.DateInput
        wide
        id="date-of-bracket-painting"
        name="date-of-bracket-painting"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "bracketDetails.dateOfBracketPainting")}
        label={t("form_pole.date_of_bracket_painting")}
        value={toDateValue(formik.values.bracketDetails?.dateOfBracketPainting)}
        onChange={setDateFieldValue(
          formik,
          "bracketDetails.dateOfBracketPainting"
        )}
      />
      <Compo.Toggle
        wide
        checked={
          formik.values.bracketDetails?.bracketQualifiedForReplacement ?? false
        }
        onChange={async (v) => {
          await setBooleanFieldValue(
            formik,
            "bracketDetails.bracketQualifiedForReplacement"
          )(v);
          !v &&
            (await setFloatFieldValue(
              formik,
              "bracketDetails.newBracketLengthAfterReplacement"
            )(undefined));
        }}
        label={t("form_pole.bracket_qualified_for_replacement") ?? undefined}
      />
      {formik.values.bracketDetails?.bracketQualifiedForReplacement && (
        <Compo.TextInput
          wide
          id="new-bracket-length-after-replacement"
          name="new-bracket-length-after-replacement"
          type="number"
          unit="m"
          disabled={formik.isSubmitting}
          error={getFieldError(
            formik,
            "bracketDetails.newBracketLengthAfterReplacement"
          )}
          label={t("form_pole.new_bracket_length_after_replacement")}
          value={toStringValue(
            formik.values.bracketDetails?.newBracketLengthAfterReplacement
          )}
          onChange={setFloatFieldValue(
            formik,
            "bracketDetails.newBracketLengthAfterReplacement"
          )}
        />
      )}
    </React.Fragment>
  );
};

export default PoleFormBracketDetailsInputs;
