import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { getValidationSchema } from "./LightingScheduleForm.validation";
import { useUpdateLightingScheduleFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTUpdateLightingSchedule";
import { useCreateNewLightingScheduleFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTCreateNewLightingSchedule";
import { setTextFieldValue } from "src/core/utils/formik";
import type { DeepPartial } from "src/core/utils/types";
import type {
  LightingIoT_CreateNewLightingScheduleInput,
  LightingIoT_LightingSchedulePayload,
  LightingIoT_UpdateLightingScheduleInput,
} from "src/graphql/types";
import { defaultValues } from "./LightingScheduleForm.defaults";
import {
  getStartStepInputFromPayload,
  getChangeStepInputFromPayload,
  getStopStepInputFromPayload,
} from "./LightingScheduleForm.utils";
import StartStepInputs from "./elements/StartStepInputs";
import StopStepInputs from "./elements/StopStepInputs";
import ChangeStepInputs from "./elements/ChangeStepInputs";
import { useLightingIoTTranslation } from "../../utils/translation";
import { convertTimeSpanToIso } from "src/core/utils/timespan";

export interface LightingScheduleFormProps {
  onClose: () => void;
  schedule?: LightingIoT_LightingSchedulePayload;
}

export interface LightingScheduleFormContext
  extends DeepPartial<LightingIoT_CreateNewLightingScheduleInput> {}

const LightingScheduleForm: React.FC<LightingScheduleFormProps> = (props) => {
  const { t } = useLightingIoTTranslation();
  const navigate = useNavigate();
  const area = useCurrentArea();
  const validationSchema = getValidationSchema(t);

  const [create] = useCreateNewLightingScheduleFromLightingIoTMutation({
    update: (cache, result) => {
      if (
        result.errors ||
        !result.data?.LightingIoT_createNewLightingSchedule.isSuccess
      )
        return;
      cache.evict({
        fieldName: "LightingIoT_lightingSchedules",
      });
      cache.evict({
        fieldName: "LightingIoT_lightingSchedulesPaged",
      });
      cache.gc();
    },
  });

  const [update] = useUpdateLightingScheduleFromLightingIoTMutation({
    update: (cache, result) => {
      if (
        result.errors ||
        !result.data?.LightingIoT_updateLightingSchedule.isSuccess
      )
        return;
      cache.evict({
        fieldName: "LightingIoT_lightingScheduleById",
      });
      cache.evict({
        fieldName: "LightingIoT_lightingSchedules",
      });
      cache.evict({
        fieldName: "LightingIoT_lightingSchedulesPaged",
      });
      cache.gc();
    },
  });

  const handleSubmit = async (
    values: LightingScheduleFormContext
  ): Promise<void> => {
    if (props.schedule) {
      const newLightingSchedule: LightingIoT_UpdateLightingScheduleInput = {
        id: props.schedule.id,
        version: props.schedule.version,
        areaId: props.schedule.areaId,
        name: values.name as string,
        ...toStepsInput(values),
      };

      const { data } = await update({
        variables: {
          input: newLightingSchedule,
        },
      });

      const result = data?.LightingIoT_updateLightingSchedule;
      if (result?.isSuccess) {
        toast.success(t(`notification.lighting_schedule_updated`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    } else {
      const { data } = await create({
        variables: {
          input: {
            ...(values as LightingIoT_CreateNewLightingScheduleInput),
            areaId: area.id,
            ...toStepsInput(values),
          },
        },
      });

      const result = data?.LightingIoT_createNewLightingSchedule;
      if (result?.isSuccess) {
        toast.success(t(`notification.lighting_schedule_created`));
        navigate(result.value?.id);
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    }
  };

  const formik = useFormik<
    DeepPartial<LightingIoT_CreateNewLightingScheduleInput>
  >({
    initialValues: props.schedule
      ? {
          name: props.schedule.name,
          startStep: getStartStepInputFromPayload(props.schedule.startStep),
          changeSteps: props.schedule.changeSteps.map(
            getChangeStepInputFromPayload
          ),
          stopStep: getStopStepInputFromPayload(props.schedule.stopStep),
        }
      : defaultValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={
          <React.Fragment>
            <Compo.Header
              title={t(
                "form_lighting_schedule." +
                  (props.schedule ? "edit_title" : "add_title")
              )}
            />
          </React.Fragment>
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <FormikProvider value={formik}>
            <Compo.TextInput
              wide
              id="name"
              name="name"
              disabled={formik.isSubmitting}
              error={formik.touched.name && formik.errors.name}
              label={t("form_lighting_schedule.name")}
              value={formik.values.name}
              onChange={setTextFieldValue(formik, "name")}
            />
            <StartStepInputs />
            <ChangeStepInputs />
            <StopStepInputs />
          </FormikProvider>
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const toStepsInput = (values: any): any => {
  return {
    startStep: {
      startOnTimeStep: values.startStep?.startOnTimeStep
        ? {
            startTime: convertTimeSpanToIso(
              values.startStep.startOnTimeStep.startTime as string
            ),
            brightness: values.startStep.startOnTimeStep.brightness as number,
          }
        : undefined,
      startOnSunsetStep: values.startStep?.startOnSunsetStep
        ? {
            offset: values.startStep.startOnSunsetStep.offset as number,
            brightness: values.startStep.startOnSunsetStep.brightness as number,
          }
        : undefined,
    },
    changeSteps:
      values.changeSteps?.map((step: any) => ({
        changeOnTimeStep: step?.changeOnTimeStep
          ? {
              changeTime: convertTimeSpanToIso(
                step.changeOnTimeStep.changeTime as string
              ),
              brightness: step.changeOnTimeStep.brightness as number,
            }
          : undefined,
      })) ?? [],
    stopStep: {
      stopOnTimeStep: values.stopStep?.stopOnTimeStep
        ? {
            stopTime: convertTimeSpanToIso(
              values.stopStep.stopOnTimeStep.stopTime as string
            ),
            brightness: 0,
          }
        : undefined,
      stopOnSunriseStep: values.stopStep?.stopOnSunriseStep
        ? {
            offset: values.stopStep.stopOnSunriseStep.offset as number,
            brightness: 0,
          }
        : undefined,
    },
  };
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LightingScheduleForm;
