import React from "react";
import Compo from "@smartly-city/compo";
import { Lighting_SidewalkSideModel } from "src/graphql/types";
import {
  getFieldError,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { useFormikContext } from "formik";
import type { PoleFormContext } from "../../PoleForm";

export const PoleFormSidewalkDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PoleFormContext>();

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="sidewalk-side"
        name="sidewalk-side"
        disabled={formik.isSubmitting}
        label={t("form_pole.sidewalk_side")}
        error={getFieldError(formik, "sidewalkDetails.sidewalkSide")}
        value={toStringValue(formik.values.sidewalkDetails?.sidewalkSide)}
        onChange={setTextFieldValue(formik, "sidewalkDetails.sidewalkSide")}
      >
        <option value="" />
        {Object.values(Lighting_SidewalkSideModel).map((type) => (
          <option key={type} value={type}>
            {t(`sidewalk_side.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.TextInput
        wide
        id="width-of-sidewalk"
        name="width-of-sidewalk"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        label={t("form_pole.width_of_sidewalk")}
        error={getFieldError(formik, "sidewalkDetails.widthOfSidewalk")}
        value={toStringValue(formik.values.sidewalkDetails?.widthOfSidewalk)}
        onChange={setFloatFieldValue(formik, "sidewalkDetails.widthOfSidewalk")}
      />
    </React.Fragment>
  );
};
