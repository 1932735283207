import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useWeatherIoTTranslation } from "../../utils/translation";
import { useRemoveStationFromWeatherIoTMutation } from "src/graphql/__generated__/WeatherIoTRemoveStation";
import type { WeatherIoT_StationModel } from "src/graphql/types";

export interface StationDeleteFormProps {
  onClose: () => void;
  station: WeatherIoT_StationModel;
}

const StationDeleteForm: React.FC<StationDeleteFormProps> = (props) => {
  const { t } = useWeatherIoTTranslation();
  const navigate = useNavigate();

  const [deleteStation] = useRemoveStationFromWeatherIoTMutation({
    variables: {
      input: {
        stationId: props.station.id,
        stationVersion: props.station.version,
        areaId: props.station.areaId,
      },
    },
    update: (cache, result) => {
      if (result.errors || !result.data?.WeatherIoT_removeStation.isSuccess)
        return;
      cache.evict({
        fieldName: "WeatherIoT_stationById",
      });
      cache.evict({
        fieldName: "WeatherIoT_stationsPaged",
      });
      cache.evict({
        fieldName: "WeatherIoT_stations",
      });
      cache.gc();
    },
  });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const { data } = await deleteStation();
      const result = data?.WeatherIoT_removeStation;

      if (result?.isSuccess) {
        toast.success(t(`notification.station_removed`));
        props.onClose();
        navigate("..", { replace: true });
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={<Compo.Header title={t("form_station_delete.title")} />}
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={formik.isSubmitting}
              type="submit"
              variant="alert"
            >
              {t("delete")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <Text>
          {t("form_station_delete.text", {
            station: props.station,
          })}
        </Text>
      </Compo.ModalBox>
    </form>
  );
};

const Text = styled.span`
  margin: 1rem;
  display: inline-block;
`;

export default StationDeleteForm;
