import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteLuminaireTypeFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_DeleteLuminaireTypeInput;
}>;


export type DeleteLuminaireTypeFromLightingMutation = { __typename?: 'Mutation', Lighting_deleteLuminaireType: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const DeleteLuminaireTypeFromLightingDocument = gql`
    mutation deleteLuminaireTypeFromLighting($input: Lighting_DeleteLuminaireTypeInput!) {
  Lighting_deleteLuminaireType(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type DeleteLuminaireTypeFromLightingMutationFn = Apollo.MutationFunction<DeleteLuminaireTypeFromLightingMutation, DeleteLuminaireTypeFromLightingMutationVariables>;

/**
 * __useDeleteLuminaireTypeFromLightingMutation__
 *
 * To run a mutation, you first call `useDeleteLuminaireTypeFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLuminaireTypeFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLuminaireTypeFromLightingMutation, { data, loading, error }] = useDeleteLuminaireTypeFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLuminaireTypeFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLuminaireTypeFromLightingMutation, DeleteLuminaireTypeFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLuminaireTypeFromLightingMutation, DeleteLuminaireTypeFromLightingMutationVariables>(DeleteLuminaireTypeFromLightingDocument, options);
      }
export type DeleteLuminaireTypeFromLightingMutationHookResult = ReturnType<typeof useDeleteLuminaireTypeFromLightingMutation>;
export type DeleteLuminaireTypeFromLightingMutationResult = Apollo.MutationResult<DeleteLuminaireTypeFromLightingMutation>;
export type DeleteLuminaireTypeFromLightingMutationOptions = Apollo.BaseMutationOptions<DeleteLuminaireTypeFromLightingMutation, DeleteLuminaireTypeFromLightingMutationVariables>;