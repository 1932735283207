import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePoleFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_UpdatePoleInput;
}>;


export type UpdatePoleFromLightingMutation = { __typename?: 'Mutation', Lighting_updatePole: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const UpdatePoleFromLightingDocument = gql`
    mutation updatePoleFromLighting($input: Lighting_UpdatePoleInput!) {
  Lighting_updatePole(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type UpdatePoleFromLightingMutationFn = Apollo.MutationFunction<UpdatePoleFromLightingMutation, UpdatePoleFromLightingMutationVariables>;

/**
 * __useUpdatePoleFromLightingMutation__
 *
 * To run a mutation, you first call `useUpdatePoleFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoleFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoleFromLightingMutation, { data, loading, error }] = useUpdatePoleFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePoleFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePoleFromLightingMutation, UpdatePoleFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePoleFromLightingMutation, UpdatePoleFromLightingMutationVariables>(UpdatePoleFromLightingDocument, options);
      }
export type UpdatePoleFromLightingMutationHookResult = ReturnType<typeof useUpdatePoleFromLightingMutation>;
export type UpdatePoleFromLightingMutationResult = Apollo.MutationResult<UpdatePoleFromLightingMutation>;
export type UpdatePoleFromLightingMutationOptions = Apollo.BaseMutationOptions<UpdatePoleFromLightingMutation, UpdatePoleFromLightingMutationVariables>;