import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireTypesFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetLuminaireTypesInput;
}>;


export type LuminaireTypesFromLightingQuery = { __typename?: 'Query', Lighting_luminaireTypes: { __typename?: 'Lighting_GetLuminaireTypesPayload', count: number, items: Array<{ __typename?: 'Lighting_LuminaireTypeRowModel', id: any, version: number, areaId: any, name: string }> } };


export const LuminaireTypesFromLightingDocument = gql`
    query luminaireTypesFromLighting($input: Lighting_GetLuminaireTypesInput!) {
  Lighting_luminaireTypes(input: $input) {
    count
    items {
      id
      version
      areaId
      name
    }
  }
}
    `;

/**
 * __useLuminaireTypesFromLightingQuery__
 *
 * To run a query within a React component, call `useLuminaireTypesFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireTypesFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireTypesFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireTypesFromLightingQuery(baseOptions: Apollo.QueryHookOptions<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables> & ({ variables: LuminaireTypesFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables>(LuminaireTypesFromLightingDocument, options);
      }
export function useLuminaireTypesFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables>(LuminaireTypesFromLightingDocument, options);
        }
export function useLuminaireTypesFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables>(LuminaireTypesFromLightingDocument, options);
        }
export type LuminaireTypesFromLightingQueryHookResult = ReturnType<typeof useLuminaireTypesFromLightingQuery>;
export type LuminaireTypesFromLightingLazyQueryHookResult = ReturnType<typeof useLuminaireTypesFromLightingLazyQuery>;
export type LuminaireTypesFromLightingSuspenseQueryHookResult = ReturnType<typeof useLuminaireTypesFromLightingSuspenseQuery>;
export type LuminaireTypesFromLightingQueryResult = Apollo.QueryResult<LuminaireTypesFromLightingQuery, LuminaireTypesFromLightingQueryVariables>;