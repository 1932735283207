import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import i18next from "i18next";
import { toast } from "react-toastify";
import fragments from "src/graphql/fragments.json";

const possibleTypes = {
  Lighting_Pole: ["Lighting_PoleModel", "Lighting_PoleRowModel"],
  Lighting_PowerBox: ["Lighting_PowerBoxModel", "Lighting_PowerBoxRowModel"],
  Lighting_Luminaire: ["Lighting_LuminaireModel", "Lighting_LuminaireRowModel"],
  Lighting_LuminaireType: [
    "Lighting_LuminaireTypeModel",
    "Lighting_LuminaireTypeRowModel",
  ],
};

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL as string}/api/graphql`,
  credentials: "include",
});

const omitTypename = (key: string, value: any): any => {
  return key === "__typename" ? undefined : value;
};

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});

const errorLink = onError((response) => {
  const skippedOperations = ["signInUserFromSecurity"];
  if (response.graphQLErrors) {
    if (!skippedOperations.includes(response.operation.operationName)) {
      toast.error(i18next.t("error.unknown"), { toastId: "error.unknown" });
    }
  }
  if (response.networkError) {
    toast.error(i18next.t("error.network"), { toastId: "error.network" });
  }
  response = { operation: response.operation, forward: response.forward };
});

export const client = new ApolloClient({
  link: from([errorLink, omitTypenameLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: { ...fragments.possibleTypes, ...possibleTypes },
  }),
  defaultOptions: {
    watchQuery: {
      initialFetchPolicy: "cache-first",
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
  },
});
