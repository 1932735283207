import type { PoleFilters } from "../contexts/PoleFilters";
import type { PowerBoxFilters } from "../contexts/PowerBoxFilters";
import type {
  Lighting_PoleFiltersModelInput,
  Lighting_PowerBoxFiltersModelInput,
} from "src/graphql/types";

export const toPowerBoxQueryFilters = (
  filters?: PowerBoxFilters
): Lighting_PowerBoxFiltersModelInput | null => {
  if (!filters) return null;
  const res = {
    matchesAddressNumber: filters.addressNumber ?? null,
    matchesAddressStreet: filters.addressStreet ?? null,
    matchesAddressTown: filters.addressTown ?? null,
    matchesFuseType: filters.fuseType ?? null,
    matchesName: filters.name ?? null,
    matchesNotes: filters.notes ?? null,
    matchesRecipientCode: filters.recipientCode ?? null,
    maxContractedPower: filters.contractedPower?.max ?? null,
    maxFuseAmpRating: filters.fuseAmpRating?.max ?? null,
    maxPower: filters.power?.max ?? null,
    minContractedPower: filters.contractedPower?.min ?? null,
    minFuseAmpRating: filters.fuseAmpRating?.min ?? null,
    minPower: filters.power?.min ?? null,
    ofName: null,
    ofOrdinalNumber: filters.ordinalNumber ?? null,
  };
  return res;
};

export const toPoleQueryFilters = (
  filters?: PoleFilters
): Lighting_PoleFiltersModelInput | null => {
  if (!filters) return null;
  const res = {
    qualifiedForReplacement: filters.qualifiedForReplacement ?? null,
    matchesAddressNumber: filters.addressNumber ?? null,
    matchesAddressStreet: filters.addressStreet ?? null,
    matchesAddressTown: filters.addressTown ?? null,
    matchesLineCrossSection: filters.lineCrossSection ?? null,
    matchesName: filters.name ?? null,
    matchesPlotNumber: filters.plotNumber ?? null,
    matchesPowerBoxName: filters.powerBoxName ?? null,
    maxDateOfPainting:
      filters.dateOfPainting?.max?.toLocaleDateString() ?? null,
    maxDistanceFromRoadwayEdgeToPole:
      filters.distanceFromRoadwayEdgeToPole?.max ?? null,
    maxDistanceToClosestPole: filters.distanceToClosestPole?.max ?? null,
    maxHeight: filters.height?.max ?? null,
    maxRoadWidth: filters.roadWidth?.max ?? null,
    maxWidthOfSidewalk: filters.sidewalkWidth?.max ?? null,
    minDateOfPainting:
      filters.dateOfPainting?.min?.toLocaleDateString() ?? null,
    minDistanceFromRoadwayEdgeToPole:
      filters.distanceFromRoadwayEdgeToPole?.min ?? null,
    minDistanceToClosestPole: filters.distanceToClosestPole?.min ?? null,
    minHeight: filters.height?.min ?? null,
    minRoadWidth: filters.roadWidth?.min ?? null,
    minWidthOfSidewalk: filters.sidewalkWidth?.min ?? null,
    ofGridType: filters.gridType ?? null,
    ofLineType: filters.lineType ?? null,
    ofLocation: filters.location ?? null,
    ofOrdinalNumber: filters.ordinalNumber ?? null,
    ofOwner: filters.owner ?? null,
    ofRoadLightingClass: filters.roadLightingClass ?? null,
    ofRoadSurface: filters.roadSurface ?? null,
    ofRoadType: filters.roadType ?? null,
    ofSidewalkSide: filters.sidewalkSide ?? null,
    ofType: filters.type ?? null,
    minBracketLength: filters.bracketLength?.min ?? null,
    maxBracketLength: filters.bracketLength?.max ?? null,
    minTiltAngle: filters.tiltAngle?.min ?? null,
    maxTiltAngle: filters.tiltAngle?.max ?? null,
    bracketQualifiedForReplacement:
      filters.bracketQualifiedForReplacement ?? null,
    minNewBracketLengthAfterReplacement:
      filters.newBracketLengthAfterReplacement?.min ?? null,
    maxNewBracketLengthAfterReplacement:
      filters.newBracketLengthAfterReplacement?.max ?? null,
    minDateOfBracketPainting:
      filters.dateOfBracketPainting?.min?.toLocaleDateString() ?? null,
    maxDateOfBracketPainting:
      filters.dateOfBracketPainting?.max?.toLocaleDateString() ?? null,
  };
  return res;
};
