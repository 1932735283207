import React from "react";
import styled from "styled-components";
import Compo, { useDialog } from "@smartly-city/compo";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTrafficIoTTranslation } from "../../../../utils/translation";
import { useCounterJournalsFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounterJournals";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import type { TrafficIoT_CounterModel } from "src/graphql/types";
import { CounterDeleteForm } from "src/modules/traffic_iot/containers/CounterDeleteForm";
import { isEmpty } from "src/core/utils/common";

export interface CounterDetailsTabProps {
  counter: TrafficIoT_CounterModel;
}

const CounterDetailsTab: React.FC<CounterDetailsTabProps> = (props) => {
  const { t } = useTrafficIoTTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const dialog = useDialog();
  const removePermitted = usePermissionMatch("traffic_iot.counter.remove");

  const counter = props.counter;
  const coordinatesString = `${counter.location.latitude}, ${counter.location.longitude}`;

  const { data: journalData } = useCounterJournalsFromTrafficIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: {
          ofCounterId: counter.id,
        },
      },
    },
  });
  const journal = journalData.TrafficIoT_counterJournals.items[0];

  if (!journal) {
    navigate("/404");
    return <div />;
  }

  const toLocaleDate = (date: string): string => {
    return new Date(date).toLocaleString();
  };

  return (
    <React.Fragment>
      <Compo.Header title={counter.name}>
        {removePermitted && (
          <Compo.Button
            outline
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={() => dialog.setIsOpen(true)}
          />
        )}
      </Compo.Header>
      <Compo.Header type="h2" title={t("location")} />
      <Compo.LinkTile
        wide
        text={coordinatesString}
        icon={Compo.DocumentDuplicateIcon}
        onClick={async () => {
          await navigator.clipboard.writeText(coordinatesString);
          toast.success(t("notification.coordinates_copied"));
        }}
      />
      <HeaderWithDistance outline type="h2" title={t("details")} />
      <Compo.ListRow wide description={counter.smartlyId}>
        {t("page_counter_view.smartly_id")}
      </Compo.ListRow>
      <Separator />
      {!isEmpty(journal.status.peopleCountDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_counter_view.people_count")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.status.peopleCountDate)}
          >
            {t("page_counter_view.people_count_date")}
          </Compo.ListRow>
          <Compo.ListRow
            wide
            description={journal.status.peopleCount?.toString()}
          >
            {t("page_counter_view.people_count_total")}
          </Compo.ListRow>
        </React.Fragment>
      )}
      {!isEmpty(journal.status.bikeCountDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_counter_view.bike_count")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.status.bikeCountDate)}
          >
            {t("page_counter_view.bike_count_date")}
          </Compo.ListRow>
          <Compo.ListRow
            wide
            description={journal.status.bikeCount?.toString()}
          >
            {t("page_counter_view.bike_count_total")}
          </Compo.ListRow>
        </React.Fragment>
      )}
      {!isEmpty(journal.status.carCountDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_counter_view.car_count")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.status.carCountDate)}
          >
            {t("page_counter_view.car_count_date")}
          </Compo.ListRow>
          <Compo.ListRow wide description={journal.status.carCount?.toString()}>
            {t("page_counter_view.car_count_total")}
          </Compo.ListRow>
        </React.Fragment>
      )}
      {!isEmpty(journal.status.truckCountDate) && (
        <React.Fragment>
          <Compo.Header
            outline
            type="h2"
            title={t("page_counter_view.truck_count")}
          />
          <Compo.ListRow
            wide
            description={toLocaleDate(journal.status.truckCountDate)}
          >
            {t("page_counter_view.truck_count_date")}
          </Compo.ListRow>
          <Compo.ListRow
            wide
            description={journal.status.truckCount?.toString()}
          >
            {t("page_counter_view.truck_count_total")}
          </Compo.ListRow>
        </React.Fragment>
      )}
      {dialog.isOpen &&
        dialog.withDialogWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <CounterDeleteForm
              counter={counter}
              onClose={() => dialog.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

const Separator = styled.div`
  width: 100%;
  height: 1rem;
`;

export default CounterDetailsTab;
