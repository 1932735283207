import React from "react";
import Page from "src/core/components/Page";
import styled from "styled-components";
import Compo, { useModal } from "@smartly-city/compo";
import { usePermissionMatch } from "src/core/contexts";
import {
  LightingScheduleList,
  LightingScheduleListSkeleton,
} from "./elements/LightingScheduleList";
import LightingScheduleForm from "../../containers/LightingScheduleForm/LightingScheduleForm";
import { useLightingIoTTranslation } from "../../utils/translation";

const LightingScheduleCollection: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const modal = useModal();
  const createPermitted = usePermissionMatch(
    "lighting_iot.lighting_schedule.create"
  );

  return (
    <Page permission="lighting_iot.lighting_schedule.get">
      <StyledHeader title={t("page_lighting_schedule_collection.title")}>
        {createPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </StyledHeader>
      <React.Suspense fallback={<LightingScheduleListSkeleton />}>
        <LightingScheduleList />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <LightingScheduleForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default LightingScheduleCollection;
