import Compo from "@smartly-city/compo";
import React from "react";
import styled from "styled-components";

const LightingScheduleViewSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <Wrapper>
        <Compo.HeaderSkeleton />
        <Compo.HeaderSkeleton type="h2" />
        <Compo.LinkTileSkeleton wide />
        <Compo.HeaderSkeleton type="h2" />
        <Compo.ListRowSkeleton wide />
        <Compo.ListRowSkeleton wide />
        <Compo.HeaderSkeleton type="h2" />
        <Compo.ListRowSkeleton wide />
        <Compo.ListRowSkeleton wide />
        <Compo.HeaderSkeleton type="h2" />
        <Compo.ListRowSkeleton wide />
        <Compo.ListRowSkeleton wide />
      </Wrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

export default LightingScheduleViewSkeleton;
