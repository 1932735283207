import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLuminaireFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_UpdateLuminaireInput;
}>;


export type UpdateLuminaireFromLightingMutation = { __typename?: 'Mutation', Lighting_updateLuminaire: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const UpdateLuminaireFromLightingDocument = gql`
    mutation updateLuminaireFromLighting($input: Lighting_UpdateLuminaireInput!) {
  Lighting_updateLuminaire(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type UpdateLuminaireFromLightingMutationFn = Apollo.MutationFunction<UpdateLuminaireFromLightingMutation, UpdateLuminaireFromLightingMutationVariables>;

/**
 * __useUpdateLuminaireFromLightingMutation__
 *
 * To run a mutation, you first call `useUpdateLuminaireFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLuminaireFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLuminaireFromLightingMutation, { data, loading, error }] = useUpdateLuminaireFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLuminaireFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLuminaireFromLightingMutation, UpdateLuminaireFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLuminaireFromLightingMutation, UpdateLuminaireFromLightingMutationVariables>(UpdateLuminaireFromLightingDocument, options);
      }
export type UpdateLuminaireFromLightingMutationHookResult = ReturnType<typeof useUpdateLuminaireFromLightingMutation>;
export type UpdateLuminaireFromLightingMutationResult = Apollo.MutationResult<UpdateLuminaireFromLightingMutation>;
export type UpdateLuminaireFromLightingMutationOptions = Apollo.BaseMutationOptions<UpdateLuminaireFromLightingMutation, UpdateLuminaireFromLightingMutationVariables>;