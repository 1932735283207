import React from "react";
import Page from "src/core/components/Page";
import styled from "styled-components";
import Compo, { useModal } from "@smartly-city/compo";
import { PoleForm } from "../../containers/PoleForm";
import { usePermissionMatch } from "src/core/contexts";
import { useLightingTranslation } from "../../utils/translation";
import { PoleListSkeleton, PoleList } from "../../containers/PoleList";
import { useNavigate } from "react-router-dom";

const PoleCollection: React.FC = () => {
  const { t } = useLightingTranslation();
  const modal = useModal();
  const navigate = useNavigate();
  const createPermitted = usePermissionMatch("lighting.pole.create");

  return (
    <Page permission="lighting.pole.get">
      <StyledHeader title={t("page_pole_collection.title")}>
        {createPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </StyledHeader>
      <React.Suspense fallback={<PoleListSkeleton />}>
        <PoleList
          paged
          filtered
          onClick={(powerBox) => navigate(powerBox.id)}
        />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <PoleForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default PoleCollection;
