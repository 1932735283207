import React from "react";
import styled from "styled-components";
import Compo, { useModal } from "@smartly-city/compo";
import { useReportsTranslation } from "../../utils/translation";
import { usePermissionMatch } from "src/core/contexts";
import Page from "src/core/components/Page";
import { ReportTable } from "./elements";
import { WorkTimeReportForm } from "../../containers/WorkTimeReportForm";
import { EnergyConsumptionReportForm } from "../../containers/EnergyConsumptionReportForm";

const ReportCollection: React.FC = () => {
  const { t } = useReportsTranslation();
  const generatePermitted = usePermissionMatch("reports.report.generate");
  const energyConsumptionModal = useModal();
  const workTimeModal = useModal();

  return (
    <Page permission="reports.report.get">
      <StyledHeader title={t("page_report_collection.title")}>
        {generatePermitted && (
          <Compo.ButtonList>
            <Compo.Button
              minimize
              icon={Compo.PlusIcon}
              onClick={() => energyConsumptionModal.setIsOpen(true)}
            >
              {t("page_report_collection.energy_consumption_select")}
            </Compo.Button>
            <Compo.Button
              minimize
              icon={Compo.PlusIcon}
              onClick={() => workTimeModal.setIsOpen(true)}
            >
              {t("page_report_collection.work_time_select")}
            </Compo.Button>
          </Compo.ButtonList>
        )}
      </StyledHeader>
      <React.Suspense fallback={<ReportTable />}>
        <ReportTable />
      </React.Suspense>
      {energyConsumptionModal.isOpen &&
        energyConsumptionModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <EnergyConsumptionReportForm
              onClose={() => energyConsumptionModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
      {workTimeModal.isOpen &&
        workTimeModal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <WorkTimeReportForm
              onClose={() => workTimeModal.setIsOpen(false)}
            />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default ReportCollection;
