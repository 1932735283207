import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useCurrentArea } from "src/core/contexts";
import {
  setDateFieldValue,
  setTextFieldValue,
  toDateValue,
  toStringValue,
} from "src/core/utils/formik";
import { useReportsTranslation } from "../../utils/translation";
import {
  Reports_LanguageModel,
  Reports_ReportRecordScopeModel,
} from "src/graphql/types";
import { useGenerateEnergyConsumptionReportFromReportsMutation } from "src/graphql/__generated__/ReportsGenerateEnergyConsumptionReport";
import { useObjectsFromReportsSuspenseQuery } from "src/graphql/__generated__/ReportsObjects";

export interface EnergyConsumptionReportFormProps {
  onClose: () => void;
}

interface EnergyConsumptionReportFormContext {
  language: Reports_LanguageModel;
  startDate: Date;
  endDate: Date;
  selectedObject: string | null;
}

const EnergyConsumptionReportForm: React.FC<
  EnergyConsumptionReportFormProps
> = (props) => {
  const { t } = useReportsTranslation();
  const area = useCurrentArea();

  const { data: objectData } = useObjectsFromReportsSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const objects = objectData?.Reports_objects.items;

  const [generateEnergyConsumption] =
    useGenerateEnergyConsumptionReportFromReportsMutation({
      update: (cache, result) => {
        if (
          result.errors ||
          !result.data?.Reports_generateEnergyConsumptionReport.isSuccess
        )
          return;
        cache.evict({
          fieldName: "Reports_reports",
        });
        cache.evict({
          fieldName: "Reports_reportsPaged",
        });
        cache.gc();
      },
    });

  const handleSubmit = async (values: any): Promise<void> => {
    const scope = formik.values.selectedObject
      ? Reports_ReportRecordScopeModel.Daily
      : Reports_ReportRecordScopeModel.Total;

    const startDate = new Date(formik.values.startDate).toLocaleDateString(
      "en-US"
    );
    const endDate = new Date(formik.values.endDate).toLocaleDateString("en-US");

    const { data } = await generateEnergyConsumption({
      variables: {
        input: {
          areaId: area.id,
          language: formik.values.language,
          recordsDateEnd: endDate,
          recordsDateStart: startDate,
          recordsScope: scope,
          selectedObjectIds: formik.values.selectedObject
            ? [formik.values.selectedObject]
            : null,
        },
      },
    });
    const res = data?.Reports_generateEnergyConsumptionReport;

    if (res?.isSuccess) {
      toast.success(t("notification.report_created"));
      props.onClose();
    } else {
      res?.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
  };

  const formik = useFormik<EnergyConsumptionReportFormContext>({
    initialValues: {
      startDate: null as any,
      endDate: null as any,
      language: Reports_LanguageModel.English,
      selectedObject: null,
    },
    validationSchema: yup.object().shape({
      startDate: yup
        .date()
        .fromFormik()
        .required()
        .nullable()
        .default(undefined)
        .max(new Date()),
      endDate: yup
        .date()
        .fromFormik()
        .required()
        .nullable()
        .default(undefined)
        .min(yup.ref("startDate"))
        .max(new Date()),
      language: yup
        .string()
        .fromFormik()
        .required()
        .oneOf(Object.values(Reports_LanguageModel)),
      selectedObject: yup
        .string()
        .nullable()
        .fromFormik()
        .oneOf([...objects?.map((s) => s.id)]),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={
          <Compo.Header title={t("form_energy_consumption_report.title")} />
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.DateInput
            wide
            disabled={formik.isSubmitting}
            error={
              formik.touched.startDate
                ? (formik.errors.startDate as string)
                : undefined
            }
            label={t("form_energy_consumption_report.start_date")}
            value={toDateValue(formik.values.startDate)}
            onChange={setDateFieldValue(formik, "startDate")}
          />
          <Compo.DateInput
            wide
            disabled={formik.isSubmitting}
            error={
              formik.touched.endDate
                ? (formik.errors.endDate as string)
                : undefined
            }
            label={t("form_energy_consumption_report.end_date")}
            value={toDateValue(formik.values.endDate)}
            onChange={setDateFieldValue(formik, "endDate")}
          />
          <Compo.SelectInput
            wide
            disabled={formik.isSubmitting}
            error={formik.touched.language && formik.errors.language}
            label={t("form_energy_consumption_report.language")}
            value={toStringValue(formik.values.language)}
            onChange={setTextFieldValue(formik, "language")}
          >
            {Object.values(Reports_LanguageModel).map((type) => (
              <option key={type} value={type}>
                {t(`language.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
          <Compo.SelectInput
            wide
            disabled={formik.isSubmitting}
            error={
              formik.touched.selectedObject && formik.errors.selectedObject
            }
            label={t("form_energy_consumption_report.selected_object")}
            value={toStringValue(formik.values.selectedObject)}
            onChange={setTextFieldValue(formik, "selectedObject")}
          >
            <option value="">
              {t("form_energy_consumption_report.whole_area")}
            </option>
            {objects?.map((object) => (
              <option key={object.id} value={object.id}>
                {object.name}
              </option>
            ))}
          </Compo.SelectInput>
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default EnergyConsumptionReportForm;
