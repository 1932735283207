import React from "react";
import Compo from "@smartly-city/compo";
import { useList } from "src/core/components/List";
import { useCurrentArea } from "src/core/contexts";
import { usePowerBoxFilters } from "../../contexts/PowerBoxFilters";
import { useLightingTranslation } from "../../utils/translation";
import { toPowerBoxQueryFilters } from "../../utils/filters";
import { usePowerBoxesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPowerBoxes";
import { usePowerBoxesPagedFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPowerBoxesPaged";
import type { Lighting_PowerBoxRowModel } from "src/graphql/types";

export interface PowerBoxListRowsProps {
  rows: Lighting_PowerBoxRowModel[];
  setRows: (rows: Lighting_PowerBoxRowModel[]) => void;
  checked?: string[];
  page?: number;
  pagedAmount?: number;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (powerBox: Lighting_PowerBoxRowModel) => React.ReactNode;
  onClick?: (powerBox: Lighting_PowerBoxRowModel) => void;
  onCheck?: (powerBox: Lighting_PowerBoxRowModel, value: boolean) => void;
}

const PowerBoxListRows: React.FC<PowerBoxListRowsProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 10;
  const { setRows } = props;

  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const { setCurrentPage } = useList();
  const filters = usePowerBoxFilters();
  const queryFilters = toPowerBoxQueryFilters(filters.filters);

  const checkType = props.withCheckbox
    ? "checkbox"
    : props.withRadio
    ? "radio"
    : undefined;

  const { data } = usePowerBoxesFromLightingSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: queryFilters,
      },
    },
    skip: props.page !== undefined,
  });
  const { data: pagedData } = usePowerBoxesPagedFromLightingSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        page: props.page ?? 1,
        amount: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
        filters: queryFilters,
      },
    },
    skip: props.page === undefined,
  });

  const powerBoxes =
    data?.Lighting_powerBoxes.items ??
    pagedData?.Lighting_powerBoxesPaged.items;
  const powerBoxesTotal =
    pagedData?.Lighting_powerBoxesPaged.total ?? powerBoxes?.length ?? 0;

  React.useEffect(() => {
    setRows(powerBoxes);
  }, [setRows, powerBoxes]);

  React.useEffect(() => {
    setCurrentPage({
      pageSize: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
      pageTotal: powerBoxesTotal,
    });
  }, [setCurrentPage, powerBoxesTotal, props.pagedAmount]);

  return (
    <React.Fragment>
      {props.rows.length === 0 && (
        <Compo.ListRowEmpty wide text={t("message.no_power_boxes")} />
      )}
      {props.rows.map((powerBox) => (
        <Compo.ListRow
          wide
          key={powerBox.id}
          checkType={checkType}
          checked={props.checked?.includes(powerBox.id)}
          onCheck={
            props.onCheck
              ? (value) => props.onCheck?.(powerBox, value)
              : undefined
          }
          onClick={props.onClick ? () => props.onClick?.(powerBox) : undefined}
        >
          {powerBox.name}
        </Compo.ListRow>
      ))}
    </React.Fragment>
  );
};

export default PowerBoxListRows;
