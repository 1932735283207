export const getReportFile = async (
  areaId: string,
  reportId: string
): Promise<Blob | null> => {
  const res = await fetch(getReportFileUrl(areaId, reportId), {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/pdf",
    },
  });

  if (!res.ok) return null;
  return await res.blob();
};

const getReportFileUrl = (areaId: string, reportId: string): string => {
  return `${
    process.env.REACT_APP_BACKEND_URL as string
  }/api/reports/get-report/${areaId}?reportId=${reportId}`;
};
