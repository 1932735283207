import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useCurrentArea } from "src/core/contexts";
import {
  type Reports_ReportRowModel,
  Reports_ReportStateModel,
} from "src/graphql/types";
import { getReportFile } from "src/modules/reports/services/report_file";
import { useReportsTranslation } from "src/modules/reports/utils/translation";
import { usePagination } from "src/core/utils/pagination";
import { useReportsPagedFromReportsSuspenseQuery } from "src/graphql/__generated__/ReportsReportsPaged";

const ReportTable: React.FC = () => {
  const ITEMS_AMOUNT = 10;

  const { t } = useReportsTranslation();
  const area = useCurrentArea();
  const { page, setPage } = usePagination();
  const [rendering, setRendering] = React.useState(false);

  const { data } = useReportsPagedFromReportsSuspenseQuery({
    variables: { input: { areaId: area.id, page, amount: ITEMS_AMOUNT } },
  });

  const reports = data.Reports_reportsPaged.items;

  if (reports.length === 0) {
    return <Compo.ListRowEmpty wide text={t("banner.no_reports")} />;
  }

  const handleDownloadReport = async (
    report: Reports_ReportRowModel
  ): Promise<void> => {
    setRendering(true);
    try {
      const pdf = await getReportFile(area.id, report.id);
      if (!pdf) {
        toast.error(t("error.unknown"));
        setRendering(false);
        return;
      }
      const file = new Blob([pdf], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.target = "_blank";
      fileLink.download = `report-${report.id}`;
      fileLink.click();
    } catch {}
    setRendering(false);
  };

  const rows = reports.map((report) => (
    <tr key={report.id}>
      <td>{t(`report_type.${report.type.toLowerCase()}`)}</td>
      <td>
        {report.recordsDateStart &&
          new Date(report.recordsDateStart).toLocaleDateString()}
      </td>
      <td>
        {report.recordsDateEnd &&
          new Date(report.recordsDateEnd).toLocaleDateString()}
      </td>
      <td>{t(`language.${report.language.toLowerCase()}`)}</td>
      <td>
        <Compo.Chip
          small
          variant={
            report.state === Reports_ReportStateModel.Ready
              ? "success"
              : "default"
          }
          text={t(`report_state.${report.state.toLowerCase()}`)}
        />
      </td>
      <td>
        {report.state === Reports_ReportStateModel.Ready ? (
          <Compo.Button
            small
            outline
            disabled={rendering}
            icon={Compo.ExternalLinkIcon}
            onClick={async () => await handleDownloadReport(report)}
          />
        ) : undefined}
      </td>
    </tr>
  ));

  return (
    <Wrapper>
      <TableWrapper>
        <Compo.Table>
          <tr>
            <th>{t("page_report_collection.report_type")}</th>
            <th>{t("page_report_collection.start_date")}</th>
            <th>{t("page_report_collection.end_date")}</th>
            <th>{t("page_report_collection.language")}</th>
            <th>{t("page_report_collection.report_state")}</th>
            <th>{t("page_report_collection.download")}</th>
          </tr>
          {rows}
        </Compo.Table>
      </TableWrapper>
      <PaginationWrapper>
        <Compo.Pagination
          wide
          page={page}
          itemCount={ITEMS_AMOUNT}
          totalCount={data.Reports_reportsPaged.total}
          onNext={() => setPage(page + 1)}
          onPrev={() => setPage(page - 1)}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  padding-bottom: 0.75rem;
`;

const PaginationWrapper = styled.div`
  margin-top: auto;
`;

export default ReportTable;
