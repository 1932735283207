import * as yup from "yup";
import moment from "moment";
import type { TFunction } from "i18next";

export const getValidationSchema = (t: TFunction): yup.Schema =>
  yup.object().shape({
    name: yup.string().fromFormik().required(),
    startStep: yup
      .object()
      .fromFormik()
      .shape({
        startOnTimeStep: yup
          .object()
          .default(undefined)
          .shape({
            startTime: yup
              .string()
              .fromFormik()
              .required()
              .test({
                name: "invalid",
                message: t("input_error.invalid") ?? undefined,
                test: (value) => moment(value, "HH:mm").isValid(),
              })
              .test({
                name: "min",
                message: t("input_error.too_low") ?? undefined,
                test: (value) => {
                  return moment(value, "HH:mm").isSameOrAfter(
                    moment("00:00", "HH:mm")
                  );
                },
              })
              .test({
                name: "max",
                message: t("input_error.too_high") ?? undefined,
                test: (value) => {
                  return moment(value, "HH:mm").isSameOrBefore(
                    moment("23:59", "HH:mm")
                  );
                },
              }),
            brightness: yup.number().fromFormik().required().min(0).max(100),
          }),
        startOnSunsetStep: yup
          .object()
          .default(undefined)
          .shape({
            offset: yup.number().fromFormik().required().min(-120).max(120),
            brightness: yup.number().fromFormik().required().min(0).max(100),
          }),
      })
      .required()
      .test({
        name: "required",
        message: t("input_error.required") ?? undefined,
        test: (value) => !!(value.startOnTimeStep || value.startOnSunsetStep),
      }),
    changeSteps: yup
      .array(
        yup
          .object()
          .fromFormik()
          .shape({
            changeOnTimeStep: yup
              .object()
              .default(undefined)
              .shape({
                changeTime: yup
                  .string()
                  .fromFormik()
                  .required()
                  .test({
                    name: "invalid",
                    message: t("input_error.invalid") ?? undefined,
                    test: (value) => moment(value, "HH:mm").isValid(),
                  })
                  .test({
                    name: "min",
                    message: t("input_error.too_low") ?? undefined,
                    test: (value) => {
                      return moment(value, "HH:mm").isSameOrAfter(
                        moment("00:00", "HH:mm")
                      );
                    },
                  })
                  .test({
                    name: "max",
                    message: t("input_error.too_high") ?? undefined,
                    test: (value) => {
                      return moment(value, "HH:mm").isSameOrBefore(
                        moment("23:59", "HH:mm")
                      );
                    },
                  }),
                brightness: yup
                  .number()
                  .fromFormik()
                  .required()
                  .min(0)
                  .max(100),
              }),
          })
          .required()
          .test({
            name: "required",
            message: t("input_error.required") ?? undefined,
            test: (value) => !!value.changeOnTimeStep,
          })
      )
      .max(5),
    stopStep: yup
      .object()
      .fromFormik()
      .shape({
        stopOnTimeStep: yup
          .object()
          .default(undefined)
          .shape({
            stopTime: yup
              .string()
              .fromFormik()
              .required()
              .test({
                name: "invalid",
                message: t("input_error.invalid") ?? undefined,
                test: (value) => moment(value, "HH:mm").isValid(),
              })
              .test({
                name: "min",
                message: t("input_error.too_low") ?? undefined,
                test: (value) => {
                  return moment(value, "HH:mm").isSameOrAfter(
                    moment("00:00", "HH:mm")
                  );
                },
              })
              .test({
                name: "max",
                message: t("input_error.too_high") ?? undefined,
                test: (value) => {
                  return moment(value, "HH:mm").isSameOrBefore(
                    moment("23:59", "HH:mm")
                  );
                },
              }),
          }),
        stopOnSunriseStep: yup
          .object()
          .default(undefined)
          .shape({
            offset: yup.number().fromFormik().required().min(-120).max(120),
          }),
      })
      .required()
      .test({
        name: "required",
        message: t("input_error.required") ?? undefined,
        test: (value) => !!(value.stopOnTimeStep || value.stopOnSunriseStep),
      }),
  });
