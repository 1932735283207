import React from "react";
import { Outlet, Route } from "react-router-dom";
import WeatherIoTMapProxy from "../proxies/WeatherIoTMapProxy";
import WeatherIoTStationsProxy from "../proxies/WeatherIoTStationsProxy";
import WeatherIoTStationsItemProxy from "../proxies/WeatherIoTStationsItemProxy";
import { Home } from "../pages/Home";
import { Administration } from "../pages/Administration";
import { StationCollection } from "../pages/StationCollection";
import { StationView } from "../pages/StationView";

const routes = (
  <Route element={<WeatherIoTMapProxy />}>
    <Route path="weather-iot" element={<Outlet />}>
      <Route index element={<Home />} />
      <Route path="admin" element={<Administration />} />
      <Route path="station" element={<Outlet />}>
        <Route element={<WeatherIoTStationsProxy />}>
          <Route index element={<StationCollection />} />
          <Route element={<WeatherIoTStationsItemProxy />}>
            <Route path=":id" element={<StationView />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

export default routes;
