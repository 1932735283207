import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useTrafficIoTTranslation } from "src/modules/traffic_iot/utils/translation";
import CounterCarJournalTabDailyCountChart from "./charts/CounterCarJournalTabDailyCountChart";
import CounterCarJournalTabDailySpeedChart from "./charts/CounterCarJournalTabDailySpeedChart";
import CounterCarJournalTabMonthlyCountChart from "./charts/CounterCarJournalTabMonthlyCountChart";
import CounterCarJournalTabMonthlySpeedChart from "./charts/CounterCarJournalTabMonthlySpeedChart";
import type { TrafficIoT_CounterJournalModel } from "src/graphql/types";

export interface CounterCarJournalTabProps {
  journal: TrafficIoT_CounterJournalModel;
}

const CounterCarJournalTab: React.FC<CounterCarJournalTabProps> = (props) => {
  const { t } = useTrafficIoTTranslation();

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("page_counter_view.car_journal")} />
      <CounterCarJournalTabDailyCountChart journal={props.journal} />
      <CounterCarJournalTabDailySpeedChart journal={props.journal} />
      <CounterCarJournalTabMonthlyCountChart journal={props.journal} />
      <CounterCarJournalTabMonthlySpeedChart journal={props.journal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default CounterCarJournalTab;
