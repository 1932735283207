import React from "react";
import styled from "styled-components";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  LightingIoT_LuminaireJournalSummaryItemTypeModel,
  type LightingIoT_LuminaireJournalModel,
} from "src/graphql/types";

export interface LuminaireJournalTabMonthlyEnergyConsumptionChartProps {
  journal: LightingIoT_LuminaireJournalModel;
}

const LuminaireJournalTabMonthlyEnergyConsumptionChart: React.FC<
  LuminaireJournalTabMonthlyEnergyConsumptionChartProps
> = (props) => {
  const { t, i18n } = useLightingIoTTranslation();
  const isEnglish = i18n.language.startsWith("en");

  const startDate = new Date();
  startDate.setUTCMilliseconds(0);
  startDate.setUTCSeconds(0);
  startDate.setUTCMinutes(0);
  startDate.setUTCHours(0);
  startDate.setUTCDate(startDate.getUTCDate() - 30 + 1);

  const endDate = new Date();
  endDate.setUTCMilliseconds(0);
  endDate.setUTCSeconds(0);
  endDate.setUTCMinutes(0);
  endDate.setUTCHours(0);

  const dates: Date[] = [];
  // eslint-disable-next-line no-unmodified-loop-condition
  for (let d = startDate; d <= endDate; d.setUTCDate(d.getUTCDate() + 1)) {
    dates.push(new Date(d));
  }

  const data = dates.map((date) =>
    props.journal.monthlySummary.items
      .filter(
        (item) =>
          item.type ===
          LightingIoT_LuminaireJournalSummaryItemTypeModel.EnergyConsumption
      )
      .find((item) => new Date(item.date).getTime() === date.getTime())
  );

  const chartData = dates.map((date, index) => {
    const energy = data[index]?.energyConsumption?.consumedEnergy;
    return {
      x: date,
      y: energy ? energy / 1000 : energy,
    };
  });

  ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>
          {t("page_luminaire_view.monthly_energy_consumption_chart_title")}
        </Title>
      </HeaderWrapper>
      <StyledLine
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            filler: {
              propagate: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (item) => `${item.formattedValue} kWh`,
              },
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: isEnglish ? "MMMM DD" : "DD MMMM",
                displayFormats: {
                  day: isEnglish ? "MMM DD" : "DD MMM",
                },
              },
              border: { display: false },
              grid: {
                color: "#eef6ff",
              },
            },
            y: {
              border: { display: false },
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
              grid: {
                color: "#eef6ff",
              },
            },
          },
        }}
        data={{
          labels: dates,
          datasets: [
            {
              data: chartData,
              fill: "start",
              borderColor: "#2463EB",
              backgroundColor: "#2463EB",
              pointStyle: false,
              showLine: false,
            },
          ],
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border: 2.5px solid #2463eb;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 0.5rem;
`;

const Title = styled.h3`
  color: #767676;
  margin: 0;
`;

const StyledLine = styled(Line)`
  max-height: 12.5rem;
  width: 100%;
`;

export default LuminaireJournalTabMonthlyEnergyConsumptionChart;
