import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useLightingIoTTranslation } from "../../utils/translation";
import { useDeleteLightingScheduleFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTDeleteLightingSchedule";
import type { LightingIoT_LightingSchedulePayload } from "src/graphql/types";

export interface LightingScheduleConfirmDeleteProps {
  onClose: () => void;
  lightingSchedule: LightingIoT_LightingSchedulePayload;
}

const LightingScheduleDeleteForm: React.FC<
  LightingScheduleConfirmDeleteProps
> = (props) => {
  const { t } = useLightingIoTTranslation();
  const navigate = useNavigate();

  const lightingSchedule = props.lightingSchedule;

  const [deleteLightingSchedule] =
    useDeleteLightingScheduleFromLightingIoTMutation({
      variables: {
        input: {
          id: lightingSchedule.id,
          version: lightingSchedule.version,
          areaId: lightingSchedule.areaId,
        },
      },
      update: (cache, result) => {
        if (
          result.errors ||
          !result.data?.LightingIoT_deleteLightingSchedule.isSuccess
        )
          return;
        cache.evict({
          fieldName: "LightingIoT_lightingScheduleById",
        });
        cache.evict({
          fieldName: "LightingIoT_lightingSchedules",
        });
        cache.gc();
      },
    });

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const { data } = await deleteLightingSchedule();
      const result = data?.LightingIoT_deleteLightingSchedule;

      if (result?.isSuccess) {
        toast.success(t(`notification.lighting_schedule_deleted`));
        props.onClose();
        navigate("..", { replace: true });
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={
          <Compo.Header title={t("form_lighting_schedule_delete.title")} />
        }
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={formik.isSubmitting}
              type="submit"
              variant="alert"
            >
              {t("delete")}
            </Compo.Button>
            <Compo.Button
              disabled={formik.isSubmitting}
              onClick={props.onClose}
            >
              {t("cancel")}
            </Compo.Button>
          </React.Fragment>
        }
      >
        <Text>
          {t("form_lighting_schedule_delete.text", {
            lightingSchedule,
          })}
        </Text>
      </Compo.ModalBox>
    </form>
  );
};

const Text = styled.span`
  margin: 1rem;
  display: inline-block;
`;

export default LightingScheduleDeleteForm;
