import React from "react";
import { useCurrentArea } from "src/core/contexts";
import { useFailuresFromFailuresSuspenseQuery } from "src/graphql/__generated__/FailuresFailures";
import { useNavigate } from "react-router-dom";
import MapProxy from "src/core/proxies/MapProxy";
import type { MapItem } from "@smartly-city/compo-map";
import { useFailureLayerBuilder } from "../utils/layers";

const FailuresMapProxy: React.FC = () => {
  const area = useCurrentArea();
  const navigate = useNavigate();
  const failureBuilder = useFailureLayerBuilder();

  const { data: failureData } = useFailuresFromFailuresSuspenseQuery({
    variables: { input: { areaId: area.id, filters: { active: true } } },
  });
  const failures = failureData.Failures_failures.items;

  const failureLayer = React.useMemo(
    () => failureBuilder(failures),
    [failures, failureBuilder]
  );

  return (
    <MapProxy
      layers={[failureLayer]}
      onItemClick={(item, layer) => {
        const redirectUrl = getActiveItemRedirectUrl(item, layer);
        if (redirectUrl === window.location.pathname) return;
        navigate(redirectUrl);
      }}
    />
  );
};

const getActiveItemRedirectUrl = (active: MapItem, layer: string): string => {
  switch (layer) {
    case "failure":
      return `/failures/failure/${active.id}`;
    default:
      return ".";
  }
};

export default FailuresMapProxy;
