import React from "react";
import Compo, { type Types } from "@smartly-city/compo";
import styled from "styled-components";
import { useCurrentArea } from "src/core/contexts";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import { LightingIoT_LuminaireJobStateModel } from "src/graphql/types";
import { usePagination } from "src/core/utils/pagination";
import { useLuminaireJobsPagedFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaireJobsPaged";
import type { LightingIoT_LuminaireJobRowModel } from "src/graphql/types";

const LuminaireJobList: React.FC = () => {
  const ITEMS_AMOUNT = 10;

  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const { page, setPage } = usePagination();

  const { data } = useLuminaireJobsPagedFromLightingIoTSuspenseQuery({
    variables: { input: { areaId: area.id, page, amount: ITEMS_AMOUNT } },
  });
  const jobs = data.LightingIoT_luminaireJobsPaged.items;

  if (jobs.length === 0) {
    return (
      <Compo.ListRowEmpty
        wide
        text={t("page_luminaire_job_collection.no_jobs_message")}
      />
    );
  }

  const getChipVariant = (
    job: LightingIoT_LuminaireJobRowModel
  ): keyof Types.ChipTheme => {
    switch (job.state) {
      case LightingIoT_LuminaireJobStateModel.Failed:
      case LightingIoT_LuminaireJobStateModel.Killed:
        return "alert";

      case LightingIoT_LuminaireJobStateModel.Pending:
      case LightingIoT_LuminaireJobStateModel.Queued:
      case LightingIoT_LuminaireJobStateModel.Sent:
        return "default";

      case LightingIoT_LuminaireJobStateModel.Succeeded:
        return "success";
    }
  };

  const rows = jobs.map((job) => (
    <Compo.ListRow
      wide
      key={job.id}
      buttons={
        <Compo.Chip
          small
          variant={getChipVariant(job)}
          text={t(`luminaire_job_state.${job.state.toLowerCase()}`)}
        />
      }
    >
      {t(`luminaire_job_type.${job.type.toLowerCase()}`)}
    </Compo.ListRow>
  ));

  return (
    <Wrapper>
      <RowsWrapper>{rows}</RowsWrapper>
      <PaginationWrapper>
        <Compo.Pagination
          wide
          page={page}
          itemCount={data.LightingIoT_luminaireJobsPaged.count}
          totalCount={data.LightingIoT_luminaireJobsPaged.total}
          onNext={() => setPage(page + 1)}
          onPrev={() => setPage(page - 1)}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const RowsWrapper = styled.div`
  overflow-y: auto;
`;

const PaginationWrapper = styled.div`
  margin-top: auto;
`;

export default LuminaireJobList;
