import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { usePermissionMatch } from "src/core/contexts";
import { useTrafficIoTTranslation } from "../../utils/translation";

const Home: React.FC = () => {
  const { t } = useTrafficIoTTranslation();
  const navigate = useNavigate();
  const administrationPermitted = usePermissionMatch("traffic_iot.admin.get");
  const counterPermitted = usePermissionMatch("traffic_iot.counter.get");

  return (
    <Page>
      <Wrapper>
        <Compo.ModuleTile
          wide
          text={t("counters")}
          icon={Compo.MeterIcon}
          disabled={!counterPermitted}
          onClick={() => navigate("/traffic-iot/counter")}
        />
        {administrationPermitted && (
          <Compo.ModuleTile
            wide
            text={t("administration")}
            icon={Compo.CogIcon}
            onClick={() => navigate("/traffic-iot/admin")}
          />
        )}
      </Wrapper>
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default Home;
