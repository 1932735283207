import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PoleByIdFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetPoleByIdInput;
}>;


export type PoleByIdFromLightingQuery = { __typename?: 'Query', Lighting_poleById: { __typename?: 'Lighting_GetPoleByIdPayload', pole?: { __typename?: 'Lighting_PoleModel', id: any, version: number, ordinalNumber: number, areaId: any, powerBoxId: any, powerBoxName: string, name: string, location: { __typename?: 'Lighting_CoordinatesModel', latitude: number, longitude: number }, address: { __typename?: 'Lighting_AddressModel', number?: string | null, town: string, street?: string | null }, details: { __typename?: 'Lighting_PoleDefaultDetailsModel', type: Types.Lighting_PoleTypeModel, gridType: Types.Lighting_GridTypeModel, lineType: Types.Lighting_LineTypeModel, height: number, lineCrossSection?: string | null }, maintenanceDetails?: { __typename?: 'Lighting_PoleMaintenanceDetailsModel', qualifiedForReplacement: boolean, owner: Types.Lighting_OwnerModel, dateOfPainting?: any | null, plotNumber?: string | null, location?: Types.Lighting_PoleLocationModel | null, distanceToClosestPole?: number | null } | null, roadDetails?: { __typename?: 'Lighting_PoleRoadDetailsModel', roadType: Types.Lighting_RoadTypeModel, roadSurface: Types.Lighting_RoadSurfaceModel, roadLightingClass: Types.Lighting_RoadLightingClassModel, roadWidth: number, distanceFromRoadwayEdgeToPole: number } | null, bracketDetails?: { __typename?: 'Lighting_PoleBracketDetailsModel', bracketLength: number, tiltAngle: number, newBracketLengthAfterReplacement?: number | null, bracketQualifiedForReplacement?: boolean | null, dateOfBracketPainting?: any | null } | null, sidewalkDetails?: { __typename?: 'Lighting_PoleSidewalkDetailsModel', sidewalkSide: Types.Lighting_SidewalkSideModel, widthOfSidewalk: number } | null } | null } };


export const PoleByIdFromLightingDocument = gql`
    query poleByIdFromLighting($input: Lighting_GetPoleByIdInput!) {
  Lighting_poleById(input: $input) {
    pole {
      id
      version
      ordinalNumber
      areaId
      powerBoxId
      powerBoxName
      name
      location {
        latitude
        longitude
      }
      address {
        number
        town
        street
      }
      details {
        type
        gridType
        lineType
        height
        lineCrossSection
      }
      maintenanceDetails {
        qualifiedForReplacement
        owner
        dateOfPainting
        plotNumber
        location
        distanceToClosestPole
      }
      roadDetails {
        roadType
        roadSurface
        roadLightingClass
        roadWidth
        distanceFromRoadwayEdgeToPole
      }
      bracketDetails {
        bracketLength
        tiltAngle
        newBracketLengthAfterReplacement
        bracketQualifiedForReplacement
        dateOfBracketPainting
      }
      sidewalkDetails {
        sidewalkSide
        widthOfSidewalk
      }
    }
  }
}
    `;

/**
 * __usePoleByIdFromLightingQuery__
 *
 * To run a query within a React component, call `usePoleByIdFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoleByIdFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoleByIdFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePoleByIdFromLightingQuery(baseOptions: Apollo.QueryHookOptions<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables> & ({ variables: PoleByIdFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables>(PoleByIdFromLightingDocument, options);
      }
export function usePoleByIdFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables>(PoleByIdFromLightingDocument, options);
        }
export function usePoleByIdFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables>(PoleByIdFromLightingDocument, options);
        }
export type PoleByIdFromLightingQueryHookResult = ReturnType<typeof usePoleByIdFromLightingQuery>;
export type PoleByIdFromLightingLazyQueryHookResult = ReturnType<typeof usePoleByIdFromLightingLazyQuery>;
export type PoleByIdFromLightingSuspenseQueryHookResult = ReturnType<typeof usePoleByIdFromLightingSuspenseQuery>;
export type PoleByIdFromLightingQueryResult = Apollo.QueryResult<PoleByIdFromLightingQuery, PoleByIdFromLightingQueryVariables>;