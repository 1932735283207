import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireByIdFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLuminaireByIdInput;
}>;


export type LuminaireByIdFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_luminaireById: { __typename?: 'LightingIoT_GetLuminaireByIdPayload', luminaire?: { __typename?: 'LightingIoT_LuminaireModel', id: any, version: number, created: any, changed: any, areaId: any, typeId: any, typeName: string, name: string, lightingScheduleId?: any | null, lightingScheduleName?: string | null, location: { __typename?: 'LightingIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null }, controller?: { __typename?: 'LightingIoT_LuminaireControllerModel', id: string } | null } | null } };


export const LuminaireByIdFromLightingIoTDocument = gql`
    query luminaireByIdFromLightingIoT($input: LightingIoT_GetLuminaireByIdInput!) {
  LightingIoT_luminaireById(input: $input) {
    luminaire {
      id
      version
      created
      changed
      areaId
      typeId
      typeName
      name
      location {
        latitude
        longitude
        hash
      }
      controller {
        id
      }
      lightingScheduleId
      lightingScheduleName
    }
  }
}
    `;

/**
 * __useLuminaireByIdFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLuminaireByIdFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireByIdFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireByIdFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireByIdFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables> & ({ variables: LuminaireByIdFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables>(LuminaireByIdFromLightingIoTDocument, options);
      }
export function useLuminaireByIdFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables>(LuminaireByIdFromLightingIoTDocument, options);
        }
export function useLuminaireByIdFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables>(LuminaireByIdFromLightingIoTDocument, options);
        }
export type LuminaireByIdFromLightingIoTQueryHookResult = ReturnType<typeof useLuminaireByIdFromLightingIoTQuery>;
export type LuminaireByIdFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLuminaireByIdFromLightingIoTLazyQuery>;
export type LuminaireByIdFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLuminaireByIdFromLightingIoTSuspenseQuery>;
export type LuminaireByIdFromLightingIoTQueryResult = Apollo.QueryResult<LuminaireByIdFromLightingIoTQuery, LuminaireByIdFromLightingIoTQueryVariables>;