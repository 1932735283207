import type { FailureFilters } from "../contexts/FailureFilters";
import type { Failures_FailureFiltersModelInput } from "src/graphql/types";

export const toFailureQueryFilters = (
  filters?: FailureFilters
): Failures_FailureFiltersModelInput | null => {
  if (!filters) return null;
  const res = {
    active: filters.active ?? null,
    matchesObjectName: filters.objectName ?? null,
    maxCreatedDate: filters.createdDate?.max?.toLocaleDateString() ?? null,
    minCreatedDate: filters.createdDate?.min?.toLocaleDateString() ?? null,
    ofErrorGroup: filters.errorGroup ?? null,
    ofErrorStage: filters.errorStage ?? null,
    ofErrorType: filters.errorType ?? null,
    ofState: filters.state ?? null,
    ofType: filters.type ?? null,
  };
  return res;
};
