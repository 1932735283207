import type { Lighting_AddressModel } from "src/graphql/types";

export const buildAddressString = (address: Lighting_AddressModel): string => {
  let result = "";
  if (address.number) {
    result += `${address.number}`;
  }
  if (address.street) {
    result += `${address.number ? " " : ""}${address.street}`;
  }
  if (result.length > 0) {
    result += `, ${address.town}`;
  } else {
    result += address.town;
  }
  return result;
};
