import React from "react";
import Page from "src/core/components/Page";
import styled from "styled-components";
import Compo, { useModal } from "@smartly-city/compo";
import { PowerBoxForm } from "../../containers/PowerBoxForm";
import { usePermissionMatch } from "src/core/contexts";
import { useLightingTranslation } from "../../utils/translation";
import {
  PowerBoxListSkeleton,
  PowerBoxList,
} from "../../containers/PowerBoxList";
import { useNavigate } from "react-router-dom";

const PowerBoxCollection: React.FC = () => {
  const { t } = useLightingTranslation();
  const modal = useModal();
  const navigate = useNavigate();
  const createPermitted = usePermissionMatch("lighting.power_box.create");

  return (
    <Page permission="lighting.power_box.get">
      <StyledHeader title={t("page_power_box_collection.title")}>
        {createPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </StyledHeader>
      <React.Suspense fallback={<PowerBoxListSkeleton />}>
        <PowerBoxList
          paged
          filtered
          onClick={(powerBox) => navigate(powerBox.id)}
        />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <PowerBoxForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default PowerBoxCollection;
