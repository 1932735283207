import React from "react";
import styled from "styled-components";
import Compo from "@smartly-city/compo";
import type {
  Lighting_PoleModel,
  Lighting_PoleMaintenanceDetailsModel,
} from "src/graphql/types";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";

export interface PoleMaintenanceDetailsTabProps {
  pole: Lighting_PoleModel;
}

const PoleMaintenanceDetailsTab: React.FC<PoleMaintenanceDetailsTabProps> = (
  props
) => {
  const { t } = useLightingTranslation();
  const pole = props.pole;
  const maintenanceDetails =
    pole.maintenanceDetails as Lighting_PoleMaintenanceDetailsModel;

  return (
    <React.Fragment>
      <HeaderWithDistance outline type="h2" title={t("maintenance_details")} />
      <Compo.ListRow
        wide
        description={
          t(`owner.${maintenanceDetails.owner.toLowerCase()}`) ?? undefined
        }
      >
        {t("page_pole_view.owner")}
      </Compo.ListRow>
      <Compo.ListRow
        wide
        description={
          (maintenanceDetails.qualifiedForReplacement ? t("yes") : t("no")) ??
          undefined
        }
      >
        {t("page_pole_view.qualified_for_replacement")}
      </Compo.ListRow>
      {maintenanceDetails.dateOfPainting && (
        <Compo.ListRow
          wide
          description={new Date(
            maintenanceDetails.dateOfPainting
          ).toLocaleDateString()}
        >
          {t("page_pole_view.date_of_painting")}
        </Compo.ListRow>
      )}
      {maintenanceDetails.plotNumber && (
        <Compo.ListRow wide description={maintenanceDetails.plotNumber}>
          {t("page_pole_view.plot_number")}
        </Compo.ListRow>
      )}
      {maintenanceDetails.location && (
        <Compo.ListRow
          wide
          description={
            t(`pole_location.${maintenanceDetails.location.toLowerCase()}`) ??
            undefined
          }
        >
          {t("page_pole_view.location")}
        </Compo.ListRow>
      )}
      {maintenanceDetails.distanceToClosestPole && (
        <Compo.ListRow
          wide
          description={`${maintenanceDetails.distanceToClosestPole} m`}
        >
          {t("page_pole_view.distance_to_closest_pole")}
        </Compo.ListRow>
      )}
    </React.Fragment>
  );
};

const HeaderWithDistance = styled(Compo.Header)`
  margin-top: 1rem;
`;

export default PoleMaintenanceDetailsTab;
