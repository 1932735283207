import React from "react";
import MapProxy from "src/core/proxies/MapProxy";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useCounterLayerBuilder } from "../utils/layers";
import { useCountersFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounters";
import { useCounterJournalsFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounterJournals";
import type { MapItem } from "@smartly-city/compo-map";

const TrafficIoTMapProxy: React.FC = () => {
  const area = useCurrentArea();
  const navigate = useNavigate();
  const counterBuilder = useCounterLayerBuilder();

  const { data: counterData } = useCountersFromTrafficIoTSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const counters = counterData.TrafficIoT_counters.items;

  const { data: journalData } = useCounterJournalsFromTrafficIoTSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const journals = journalData.TrafficIoT_counterJournals.items;

  const counterLayer = React.useMemo(
    () => counterBuilder(counters, journals),
    [counters, journals, counterBuilder]
  );

  return (
    <MapProxy
      layers={[counterLayer]}
      onItemClick={(item, layer) => {
        const redirectUrl = getActiveItemRedirectUrl(item, layer);
        if (redirectUrl === window.location.pathname) return;
        navigate(redirectUrl);
      }}
    />
  );
};

const getActiveItemRedirectUrl = (active: MapItem, layer: string): string => {
  switch (layer) {
    case "counter":
      return `/traffic-iot/counter/${active.id}`;
    default:
      return ".";
  }
};

export default TrafficIoTMapProxy;
