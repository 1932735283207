import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateStationJournalsIndexFromWeatherIoTMutationVariables = Types.Exact<{
  input: Types.WeatherIoT_Admin_ValidateStationJournalsIndexInput;
}>;


export type ValidateStationJournalsIndexFromWeatherIoTMutation = { __typename?: 'Mutation', WeatherIoT_Admin_validateStationJournalsIndex: { __typename?: 'WeatherIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'WeatherIoT_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'WeatherIoT_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const ValidateStationJournalsIndexFromWeatherIoTDocument = gql`
    mutation validateStationJournalsIndexFromWeatherIoT($input: WeatherIoT_Admin_ValidateStationJournalsIndexInput!) {
  WeatherIoT_Admin_validateStationJournalsIndex(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type ValidateStationJournalsIndexFromWeatherIoTMutationFn = Apollo.MutationFunction<ValidateStationJournalsIndexFromWeatherIoTMutation, ValidateStationJournalsIndexFromWeatherIoTMutationVariables>;

/**
 * __useValidateStationJournalsIndexFromWeatherIoTMutation__
 *
 * To run a mutation, you first call `useValidateStationJournalsIndexFromWeatherIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateStationJournalsIndexFromWeatherIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateStationJournalsIndexFromWeatherIoTMutation, { data, loading, error }] = useValidateStationJournalsIndexFromWeatherIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateStationJournalsIndexFromWeatherIoTMutation(baseOptions?: Apollo.MutationHookOptions<ValidateStationJournalsIndexFromWeatherIoTMutation, ValidateStationJournalsIndexFromWeatherIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateStationJournalsIndexFromWeatherIoTMutation, ValidateStationJournalsIndexFromWeatherIoTMutationVariables>(ValidateStationJournalsIndexFromWeatherIoTDocument, options);
      }
export type ValidateStationJournalsIndexFromWeatherIoTMutationHookResult = ReturnType<typeof useValidateStationJournalsIndexFromWeatherIoTMutation>;
export type ValidateStationJournalsIndexFromWeatherIoTMutationResult = Apollo.MutationResult<ValidateStationJournalsIndexFromWeatherIoTMutation>;
export type ValidateStationJournalsIndexFromWeatherIoTMutationOptions = Apollo.BaseMutationOptions<ValidateStationJournalsIndexFromWeatherIoTMutation, ValidateStationJournalsIndexFromWeatherIoTMutationVariables>;