import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexLuminairesFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_Admin_IndexLuminairesInput;
}>;


export type IndexLuminairesFromLightingMutation = { __typename?: 'Mutation', Lighting_Admin_indexLuminaires: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const IndexLuminairesFromLightingDocument = gql`
    mutation indexLuminairesFromLighting($input: Lighting_Admin_IndexLuminairesInput!) {
  Lighting_Admin_indexLuminaires(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type IndexLuminairesFromLightingMutationFn = Apollo.MutationFunction<IndexLuminairesFromLightingMutation, IndexLuminairesFromLightingMutationVariables>;

/**
 * __useIndexLuminairesFromLightingMutation__
 *
 * To run a mutation, you first call `useIndexLuminairesFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexLuminairesFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexLuminairesFromLightingMutation, { data, loading, error }] = useIndexLuminairesFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexLuminairesFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<IndexLuminairesFromLightingMutation, IndexLuminairesFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexLuminairesFromLightingMutation, IndexLuminairesFromLightingMutationVariables>(IndexLuminairesFromLightingDocument, options);
      }
export type IndexLuminairesFromLightingMutationHookResult = ReturnType<typeof useIndexLuminairesFromLightingMutation>;
export type IndexLuminairesFromLightingMutationResult = Apollo.MutationResult<IndexLuminairesFromLightingMutation>;
export type IndexLuminairesFromLightingMutationOptions = Apollo.BaseMutationOptions<IndexLuminairesFromLightingMutation, IndexLuminairesFromLightingMutationVariables>;