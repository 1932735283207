import React from "react";
import { PoleFiltersContext } from "./PoleFiltersContext";
import type { PoleFiltersStatus, PoleFilters } from "./PoleFiltersContext";
import { useFiltersUtils } from "src/core/utils/filters";

export interface PoleFiltersProviderProps {
  children?: React.ReactNode;
}

const PoleFiltersProvider: React.FC<PoleFiltersProviderProps> = (props) => {
  const initStatus: PoleFiltersStatus = {
    name: false,
    powerBoxName: false,
    ordinalNumber: false,
    addressNumber: false,
    addressStreet: false,
    addressTown: false,
    type: false,
    height: false,
    lineType: false,
    gridType: false,
    lineCrossSection: false,
    owner: false,
    dateOfPainting: false,
    plotNumber: false,
    location: false,
    distanceToClosestPole: false,
    qualifiedForReplacement: false,
    roadType: false,
    roadSurface: false,
    roadLightingClass: false,
    roadWidth: false,
    distanceFromRoadwayEdgeToPole: false,
    bracketLength: false,
    tiltAngle: false,
    bracketQualifiedForReplacement: false,
    newBracketLengthAfterReplacement: false,
    dateOfBracketPainting: false,
    sidewalkSide: false,
    sidewalkWidth: false,
  };

  const utils = useFiltersUtils<PoleFilters>({}, initStatus);

  return (
    <PoleFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </PoleFiltersContext.Provider>
  );
};

export default PoleFiltersProvider;
