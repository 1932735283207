import React from "react";
import Compo from "@smartly-city/compo";
import { useList } from "src/core/components/List";
import { useCurrentArea } from "src/core/contexts";
import { usePoleFilters } from "../../contexts/PoleFilters";
import { useLightingTranslation } from "../../utils/translation";
import { toPoleQueryFilters } from "../../utils/filters";
import { usePolesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPoles";
import { usePolesPagedFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingPolesPaged";
import type { Lighting_PoleRowModel } from "src/graphql/types";

export interface PoleListRowsProps {
  rows: Lighting_PoleRowModel[];
  setRows: (rows: Lighting_PoleRowModel[]) => void;
  checked?: string[];
  page?: number;
  pagedAmount?: number;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (pole: Lighting_PoleRowModel) => React.ReactNode;
  onClick?: (pole: Lighting_PoleRowModel) => void;
  onCheck?: (pole: Lighting_PoleRowModel, value: boolean) => void;
}

const PoleListRows: React.FC<PoleListRowsProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 10;
  const { setRows } = props;

  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const { setCurrentPage } = useList();
  const filters = usePoleFilters();
  const queryFilters = toPoleQueryFilters(filters.filters);

  const checkType = props.withCheckbox
    ? "checkbox"
    : props.withRadio
    ? "radio"
    : undefined;

  const { data } = usePolesFromLightingSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: queryFilters,
      },
    },
    skip: props.page !== undefined,
  });
  const { data: pagedData } = usePolesPagedFromLightingSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        page: props.page ?? 1,
        amount: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
        filters: queryFilters,
      },
    },
    skip: props.page === undefined,
  });

  const poles =
    data?.Lighting_poles.items ?? pagedData?.Lighting_polesPaged.items;
  const polesTotal = pagedData?.Lighting_polesPaged.total ?? poles?.length ?? 0;

  React.useEffect(() => {
    setRows(poles);
  }, [setRows, poles]);

  React.useEffect(() => {
    setCurrentPage({
      pageSize: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
      pageTotal: polesTotal,
    });
  }, [setCurrentPage, polesTotal, props.pagedAmount]);

  return (
    <React.Fragment>
      {props.rows.length === 0 && (
        <Compo.ListRowEmpty wide text={t("message.no_poles")} />
      )}
      {props.rows.map((pole) => (
        <Compo.ListRow
          wide
          key={pole.id}
          checkType={checkType}
          checked={props.checked?.includes(pole.id)}
          onCheck={
            props.onCheck ? (value) => props.onCheck?.(pole, value) : undefined
          }
          onClick={props.onClick ? () => props.onClick?.(pole) : undefined}
        >
          {pole.name}
        </Compo.ListRow>
      ))}
    </React.Fragment>
  );
};

export default PoleListRows;
