import React from "react";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useStatisticsFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTStatistics";
import { useLightingIoTTranslation } from "../../utils/translation";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Compo, { media } from "@smartly-city/compo";
import { LuminaireCommunicationChart } from "./elements/LuminaireCommunicationChart";

const LightingIoTStatistics: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const hasStatisticsPermission = usePermissionMatch(
    "lighting_iot.statistics.get"
  );
  const hasLuminairesPermission = usePermissionMatch(
    "lighting_iot.luminaire.get"
  );
  const hasLightingSchedulesPermission = usePermissionMatch(
    "lighting_iot.lighting_schedule.get"
  );

  const { data } = useStatisticsFromLightingIoTSuspenseQuery({
    variables: { input: { areaId: area?.id } },
  });
  const statistics = data?.LightingIoT_statistics.statistics;

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("api_lighting_iot")}>
        {hasStatisticsPermission && (
          <Compo.Button
            small
            filled
            icon={Compo.ExternalLinkIcon}
            onClick={() => navigate("/lighting-iot")}
          />
        )}
      </Compo.Header>
      {hasStatisticsPermission && data ? (
        <ContentWrapper>
          <TilesWrapper>
            <Compo.DashboardTile
              wide
              title={t("luminaires")}
              onClick={
                hasLuminairesPermission
                  ? () => navigate("/lighting-iot/luminaire")
                  : undefined
              }
            >
              {statistics.luminaireCount.toString()}
            </Compo.DashboardTile>
            <Compo.DashboardTile
              wide
              title={t("lighting_schedules")}
              onClick={
                hasLightingSchedulesPermission
                  ? () => navigate("/lighting-iot/lighting-schedule")
                  : undefined
              }
            >
              {statistics.lightingScheduleCount.toString()}
            </Compo.DashboardTile>
            <Compo.DashboardTile wide title={t("controllers")}>
              {statistics.luminaireControllerCount.toString()}
            </Compo.DashboardTile>
          </TilesWrapper>
          <ChartWrapper>
            <LuminaireCommunicationChart statistics={statistics} />
          </ChartWrapper>
        </ContentWrapper>
      ) : (
        <StyledActionTile text={t("banner.no_statistics_access")} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem 1rem;
  background-color: #fff;
  border-radius: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${media.max.sm} {
    flex-direction: column;
  }
`;

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3%;
  gap: 1rem;

  @media ${media.max.sm} {
    width: 100%;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  width: 66.6%;
  margin-left: 1rem;

  @media ${media.max.sm} {
    margin-top: 1rem;
    margin-left: 0;
    width: 100%;
  }
`;

const StyledActionTile = styled(Compo.ActionTile)`
  width: 100%;
  padding: 7.5rem 7.5rem;
`;

export default LightingIoTStatistics;
