import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import {
  LuminaireJobList,
  LuminaireJobListSkeleton,
} from "./elements/LuminaireJobList";
import { useLightingIoTTranslation } from "../../utils/translation";

const LuminaireJobCollection: React.FC = () => {
  const { t } = useLightingIoTTranslation();

  return (
    <Page permission="lighting_iot.luminaire_job.get">
      <StyledHeader title={t("page_luminaire_job_collection.title")} />
      <React.Suspense fallback={<LuminaireJobListSkeleton />}>
        <LuminaireJobList />
      </React.Suspense>
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1.5rem;
`;

export default LuminaireJobCollection;
