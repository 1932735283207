import React from "react";
import { useCurrentArea } from "src/core/contexts";
import { useLuminairesFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaires";
import { useNavigate } from "react-router-dom";
import MapProxy from "src/core/proxies/MapProxy";
import type { MapItem } from "@smartly-city/compo-map";
import { useLuminaireLayerBuilder } from "../utils/layers";
import { useLuminaireJournalsFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaireJournals";

const LightingIoTMapProxy: React.FC = () => {
  const area = useCurrentArea();
  const navigate = useNavigate();
  const luminaireBuilder = useLuminaireLayerBuilder();

  const { data: luminaireData } = useLuminairesFromLightingIoTSuspenseQuery({
    variables: { input: { areaId: area.id } },
  });
  const luminaires = luminaireData.LightingIoT_luminaires.items;

  const { data: journalData } =
    useLuminaireJournalsFromLightingIoTSuspenseQuery({
      variables: { input: { areaId: area.id } },
    });
  const journals = journalData.LightingIoT_luminaireJournals.items;

  const luminaireLayer = React.useMemo(
    () => luminaireBuilder(luminaires, journals),
    [luminaires, journals, luminaireBuilder]
  );

  return (
    <MapProxy
      layers={[luminaireLayer]}
      onItemClick={(item, layer) => {
        const redirectUrl = getActiveItemRedirectUrl(item, layer);
        if (redirectUrl === window.location.pathname) return;
        navigate(redirectUrl);
      }}
    />
  );
};

const getActiveItemRedirectUrl = (active: MapItem, layer: string): string => {
  switch (layer) {
    case "luminaire":
      return `/lighting-iot/luminaire/${active.id}`;
    default:
      return ".";
  }
};

export default LightingIoTMapProxy;
