import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StationsPagedFromWeatherIoTQueryVariables = Types.Exact<{
  input: Types.WeatherIoT_GetStationsPagedInput;
}>;


export type StationsPagedFromWeatherIoTQuery = { __typename?: 'Query', WeatherIoT_stationsPaged: { __typename?: 'WeatherIoT_GetStationsPagedPayload', page: number, count: number, total: number, items: Array<{ __typename?: 'WeatherIoT_StationRowModel', id: any, version: number, areaId: any, name: string, location: { __typename?: 'WeatherIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } }> } };


export const StationsPagedFromWeatherIoTDocument = gql`
    query stationsPagedFromWeatherIoT($input: WeatherIoT_GetStationsPagedInput!) {
  WeatherIoT_stationsPaged(input: $input) {
    page
    count
    total
    items {
      id
      version
      areaId
      name
      location {
        latitude
        longitude
        hash
      }
    }
  }
}
    `;

/**
 * __useStationsPagedFromWeatherIoTQuery__
 *
 * To run a query within a React component, call `useStationsPagedFromWeatherIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationsPagedFromWeatherIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationsPagedFromWeatherIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStationsPagedFromWeatherIoTQuery(baseOptions: Apollo.QueryHookOptions<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables> & ({ variables: StationsPagedFromWeatherIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables>(StationsPagedFromWeatherIoTDocument, options);
      }
export function useStationsPagedFromWeatherIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables>(StationsPagedFromWeatherIoTDocument, options);
        }
export function useStationsPagedFromWeatherIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables>(StationsPagedFromWeatherIoTDocument, options);
        }
export type StationsPagedFromWeatherIoTQueryHookResult = ReturnType<typeof useStationsPagedFromWeatherIoTQuery>;
export type StationsPagedFromWeatherIoTLazyQueryHookResult = ReturnType<typeof useStationsPagedFromWeatherIoTLazyQuery>;
export type StationsPagedFromWeatherIoTSuspenseQueryHookResult = ReturnType<typeof useStationsPagedFromWeatherIoTSuspenseQuery>;
export type StationsPagedFromWeatherIoTQueryResult = Apollo.QueryResult<StationsPagedFromWeatherIoTQuery, StationsPagedFromWeatherIoTQueryVariables>;