import { parseTimeSpan } from "src/core/utils/timespan";
import type {
  LightingIoT_LightingScheduleStartStep,
  LightingIoT_LightingScheduleStartStepInput,
  LightingIoT_LightingScheduleChangeStep,
  LightingIoT_LightingScheduleChangeStepInput,
  LightingIoT_LightingScheduleStopStep,
  LightingIoT_LightingScheduleStopStepInput,
} from "src/graphql/types";

export const getStartStepInputFromPayload = (
  startStep: LightingIoT_LightingScheduleStartStep
): LightingIoT_LightingScheduleStartStepInput => {
  switch (startStep.__typename) {
    case "LightingIoT_StartOnSunsetStepPayload":
      return {
        startOnSunsetStep: {
          offset: startStep.offset,
          brightness: startStep.brightness,
        },
      };
    case "LightingIoT_StartOnTimeStepPayload":
      return {
        startOnTimeStep: {
          startTime: parseTimeSpan(startStep.startTime),
          brightness: startStep.brightness,
        },
      };
  }

  throw new Error("Invalid Start Step");
};

export const getChangeStepInputFromPayload = (
  changeStep: LightingIoT_LightingScheduleChangeStep
): LightingIoT_LightingScheduleChangeStepInput => {
  switch (changeStep.__typename) {
    case "LightingIoT_ChangeOnTimeStepPayload":
      return {
        changeOnTimeStep: {
          changeTime: parseTimeSpan(changeStep.changeTime),
          brightness: changeStep.brightness,
        },
      };
  }

  throw new Error("Invalid Change Step");
};

export const getStopStepInputFromPayload = (
  stopStep: LightingIoT_LightingScheduleStopStep
): LightingIoT_LightingScheduleStopStepInput => {
  switch (stopStep.__typename) {
    case "LightingIoT_StopOnSunriseStepPayload":
      return {
        stopOnSunriseStep: {
          offset: stopStep.offset,
          brightness: stopStep.brightness,
        },
      };
    case "LightingIoT_StopOnTimeStepPayload":
      return {
        stopOnTimeStep: {
          stopTime: parseTimeSpan(stopStep.stopTime),
          brightness: stopStep.brightness,
        },
      };
  }

  throw new Error("Invalid Stop Step");
};
