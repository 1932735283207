import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StatisticsFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetStatisticsInput;
}>;


export type StatisticsFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_statistics: { __typename?: 'LightingIoT_GetStatisticsPayload', statistics: { __typename?: 'LightingIoT_StatisticsReadModel', luminaireCount: number, luminaireControllerCount: number, lightingScheduleCount: number, latestLuminaireCommunications: Array<any> } } };


export const StatisticsFromLightingIoTDocument = gql`
    query statisticsFromLightingIoT($input: LightingIoT_GetStatisticsInput!) {
  LightingIoT_statistics(input: $input) {
    statistics {
      luminaireCount
      luminaireControllerCount
      lightingScheduleCount
      latestLuminaireCommunications
    }
  }
}
    `;

/**
 * __useStatisticsFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useStatisticsFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStatisticsFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables> & ({ variables: StatisticsFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables>(StatisticsFromLightingIoTDocument, options);
      }
export function useStatisticsFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables>(StatisticsFromLightingIoTDocument, options);
        }
export function useStatisticsFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables>(StatisticsFromLightingIoTDocument, options);
        }
export type StatisticsFromLightingIoTQueryHookResult = ReturnType<typeof useStatisticsFromLightingIoTQuery>;
export type StatisticsFromLightingIoTLazyQueryHookResult = ReturnType<typeof useStatisticsFromLightingIoTLazyQuery>;
export type StatisticsFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useStatisticsFromLightingIoTSuspenseQuery>;
export type StatisticsFromLightingIoTQueryResult = Apollo.QueryResult<StatisticsFromLightingIoTQuery, StatisticsFromLightingIoTQueryVariables>;