import React from "react";
import Compo from "@smartly-city/compo";
import { getFieldError, setTextFieldValue } from "src/core/utils/formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { useLuminaireTypesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingLuminaireTypes";
import { useCurrentArea } from "src/core/contexts";
import { useFormikContext } from "formik";
import type { LuminaireFormContext } from "../../LuminaireForm";

interface LuminaireFormDataInputsProps {
  isNew: boolean;
}

const LuminaireFormDataInputs: React.FC<LuminaireFormDataInputsProps> = (
  props
) => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const formik = useFormikContext<LuminaireFormContext>();

  const { data: luminaireTypesData } =
    useLuminaireTypesFromLightingSuspenseQuery({
      variables: { input: { areaId: area.id } },
    });
  const luminaireTypes = luminaireTypesData.Lighting_luminaireTypes.items;

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="type"
        name="type-id"
        disabled={formik.isSubmitting || !props.isNew}
        error={getFieldError(formik, "typeId")}
        label={t("form_luminaire.type")}
        value={formik.values.typeId}
        onChange={setTextFieldValue(formik, "typeId")}
      >
        <option value="" />
        {luminaireTypes.map((type) => (
          <option key={type.id} value={type.id}>
            {type.name}
          </option>
        ))}
      </Compo.SelectInput>
    </React.Fragment>
  );
};

export default LuminaireFormDataInputs;
