import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { LayerActiveItemProxy } from "@smartly-city/compo-map";
import { LUMINAIRE_LAYER_ID } from "../utils/layers";

const LightingIoTLuminairesItemProxy: React.FC = () => {
  const params = useParams<{ id: string }>();

  return (
    <LayerActiveItemProxy id={params.id as string} layerId={LUMINAIRE_LAYER_ID}>
      <Outlet />
    </LayerActiveItemProxy>
  );
};

export default LightingIoTLuminairesItemProxy;
