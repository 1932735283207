import { useTranslation } from "react-i18next";
import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

export const useLightingIoTTranslation = (
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<"lighting_iot"> => {
  return useTranslation("lighting_iot", options);
};
