import React from "react";
import { AreaSettingsProvider } from "../contexts/AreaSettings";
import { Outlet } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useAreaByIdFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingAreaById";

const LightingAreaSettingsProxy: React.FC = () => {
  const area = useCurrentArea();
  const { data } = useAreaByIdFromLightingSuspenseQuery({
    variables: { input: { id: area.id } },
  });

  return (
    <AreaSettingsProvider settings={data.Lighting_areaById.area?.settings}>
      <Outlet />
    </AreaSettingsProvider>
  );
};

export default LightingAreaSettingsProxy;
