import { getIn } from "formik";
import type { FieldMetaProps, FormikErrors, FormikTouched } from "formik";

interface FormikType {
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  getFieldMeta: (field: string) => FieldMetaProps<any>;
  setFieldValue: (field: string, v: any) => any;
}

export const toStringValue = (value: any): string | undefined =>
  value !== null && value !== undefined ? String(value) : undefined;

export const toDateValue = (value: any): Date | undefined =>
  value !== null && value !== undefined ? new Date(value) : undefined;

export const setTextFieldValue =
  (formik: FormikType, field: string): ((v: any) => Promise<void>) =>
  (v) =>
    formik.setFieldValue(field, v ?? null);

export const setIntFieldValue =
  (formik: FormikType, field: string): ((v: any) => Promise<void>) =>
  (v) =>
    formik.setFieldValue(field, v ? parseInt(v) : null);

export const setFloatFieldValue =
  (formik: FormikType, field: string): ((v: any) => Promise<void>) =>
  (v) =>
    formik.setFieldValue(field, v ? parseFloat(v) : null);

export const setBooleanFieldValue =
  (formik: FormikType, field: string): ((v: any) => Promise<void>) =>
  (v) =>
    formik.setFieldValue(field, v ?? false);

export const setDateFieldValue =
  (formik: FormikType, field: string): ((v: any) => Promise<void>) =>
  (v) =>
    formik.setFieldValue(
      field,
      v ? (v as Date).toLocaleDateString("en-US") : null
    );

export const getFieldError = (
  formik: FormikType,
  field: string
): string | undefined => {
  const touched = getIn(formik.touched, field);
  const error = getIn(formik.errors, field);
  return touched && error ? error : undefined;
};
