import React from "react";
import Compo, { usePopover } from "@smartly-city/compo";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ButtonWithList from "src/core/components/ButtonWithList";

export interface Filter {
  id: string;
  name: string;
  active: boolean;
  onAdd: () => void;
}

export interface FiltersHeaderProps {
  filters: Filter[];
  onSave?: () => void;
  onClear?: () => void;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = (props) => {
  const { t } = useTranslation();
  const popover = usePopover();

  return (
    <Wrapper>
      <LeftWrapper>
        {props.onClear && (
          <Compo.Button
            variant="alert"
            icon={Compo.TrashIcon}
            onClick={props.onClear}
            disabled={!props.filters.some((filter) => filter.active)}
          >
            {t("filters_header.clear")}
          </Compo.Button>
        )}
      </LeftWrapper>
      <RightWrapper>
        <ButtonWithList
          popover={popover}
          icon={Compo.PlusIcon}
          onClick={() => popover.setIsOpen(true)}
          disabled={!props.filters.some((filter) => !filter.active)}
          content={props.filters
            .filter((filter) => !filter.active)
            .map((filter) => (
              <Compo.Button
                key={filter.id}
                variant="simple"
                align="left"
                onClick={() => {
                  filter.onAdd();
                  popover.setIsOpen(false);
                }}
              >
                {filter.name}
              </Compo.Button>
            ))}
        >
          {t("filters_header.add")}
        </ButtonWithList>
        {props.onSave && (
          <Compo.Button icon={Compo.CheckIcon} onClick={props.onSave}>
            {t("filters_header.save")}
          </Compo.Button>
        )}
      </RightWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const LeftWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const RightWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export default FiltersHeader;
