import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { defaultValues } from "./LuminaireTypeForm.defaults";
import { useCurrentArea } from "src/core/contexts";
import { validationSchema } from "./LuminaireTypeForm.validation";
import { Lighting_SourceOfLightModel } from "src/graphql/types";
import { useUpdateLuminaireTypeFromLightingMutation } from "src/graphql/__generated__/LightingUpdateLuminaireType";
import { useCreateNewLuminaireTypeFromLightingMutation } from "src/graphql/__generated__/LightingCreateNewLuminaireType";
import {
  getFieldError,
  setBooleanFieldValue,
  setFloatFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import type { DeepPartial } from "src/core/utils/types";
import type {
  Lighting_LuminaireTypeModel,
  Lighting_CreateNewLuminaireTypeInput,
  Lighting_UpdateLuminaireTypeInput,
} from "src/graphql/types";
import { useLightingTranslation } from "../../utils/translation";

export interface LuminaireTypeFormProps {
  onClose: () => void;
  luminaireType?: Lighting_LuminaireTypeModel;
}

export interface LuminaireTypeFormContext
  extends DeepPartial<Lighting_CreateNewLuminaireTypeInput> {}

const LuminaireTypeForm: React.FC<LuminaireTypeFormProps> = (props) => {
  const { t } = useLightingTranslation();
  const navigate = useNavigate();
  const area = useCurrentArea();

  const [create] = useCreateNewLuminaireTypeFromLightingMutation({
    update: (cache, result) => {
      if (
        result.errors ||
        !result.data?.Lighting_createNewLuminaireType.isSuccess
      )
        return;
      cache.evict({
        fieldName: "Lighting_luminaireTypes",
      });
      cache.gc();
    },
  });

  const [update] = useUpdateLuminaireTypeFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_updateLuminaireType.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_luminaireTypeById",
      });
      cache.evict({
        fieldName: "Lighting_luminaireTypes",
      });
      cache.gc();
    },
  });

  const handleSubmit = async (values: any): Promise<void> => {
    if (props.luminaireType) {
      const newLuminaireType = {
        id: props.luminaireType.id,
        version: props.luminaireType.version,
        areaId: props.luminaireType.areaId,
        details: values.details,
        ledDetails:
          values.details.sourceOfLight === Lighting_SourceOfLightModel.Led
            ? values.ledDetails
            : null,
      };

      const { data } = await update({
        variables: {
          input: newLuminaireType as Lighting_UpdateLuminaireTypeInput,
        },
      });

      const result = data?.Lighting_updateLuminaireType;
      if (result?.isSuccess) {
        toast.success(t("notification.luminaire_type_updated"));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    } else {
      const { data } = await create({
        variables: {
          input: {
            areaId: area.id,
            model: values.model,
            manufacturer: values.manufacturer,
            details: values.details,
            ledDetails:
              values.details.sourceOfLight === Lighting_SourceOfLightModel.Led
                ? values.ledDetails
                : null,
          },
        },
      });

      const result = data?.Lighting_createNewLuminaireType;
      if (result?.isSuccess) {
        toast.success(t("notification.luminaire_type_created"));
        navigate(result.value?.id);
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    }
  };

  const formik = useFormik<LuminaireTypeFormContext>({
    initialValues: props.luminaireType ?? defaultValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={
          <Compo.Header
            title={t(
              "form_luminaire_type." +
                (props.luminaireType ? "edit_title" : "add_title")
            )}
          />
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.TextInput
            wide
            id="model"
            name="model"
            disabled={formik.isSubmitting}
            error={formik.touched.model && formik.errors.model}
            label={t("form_luminaire_type.model")}
            value={formik.values.model}
            onChange={setTextFieldValue(formik, "model")}
          />
          <Compo.TextInput
            wide
            id="manufacturer"
            name="manufacturer"
            disabled={formik.isSubmitting}
            error={formik.touched.manufacturer && formik.errors.manufacturer}
            label={t("form_luminaire_type.manufacturer")}
            value={formik.values.manufacturer}
            onChange={setTextFieldValue(formik, "manufacturer")}
          />
          <Compo.Header outline type="h2" title={t("details")} />
          <Compo.SelectInput
            wide
            id="source-of-light"
            name="source-of-light"
            disabled={formik.isSubmitting || !!props.luminaireType}
            error={getFieldError(formik, "details.sourceOfLight")}
            label={t("form_luminaire_type.source_of_light")}
            value={toStringValue(formik.values.details?.sourceOfLight)}
            onChange={async (value) => {
              await setTextFieldValue(formik, "details.sourceOfLight")(value);
              if (value !== Lighting_SourceOfLightModel.Led) {
                await formik.setFieldValue(
                  "ledDetails",
                  defaultValues.ledDetails
                );
              }
            }}
          >
            <option value="" />
            {Object.values(Lighting_SourceOfLightModel).map((type) => (
              <option key={type} value={type}>
                {t(`source_of_light.${type.toLowerCase()}`)}
              </option>
            ))}
          </Compo.SelectInput>
          <Compo.TextInput
            wide
            id="power"
            name="power"
            type="number"
            unit="W"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "details.power")}
            label={t("form_luminaire_type.power")}
            value={toStringValue(formik.values.details?.power)}
            onChange={setFloatFieldValue(formik, "details.power")}
          />
          <Compo.TextInput
            wide
            id="source-power"
            name="source-power"
            type="number"
            unit="W"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "details.sourcePower")}
            label={t("form_luminaire_type.source_power")}
            value={toStringValue(formik.values.details?.sourcePower)}
            onChange={setFloatFieldValue(formik, "details.sourcePower")}
          />
          <Compo.TextInput
            wide
            id="manufacturer-code"
            name="manufacturer-code"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "details.manufacturerCode")}
            label={t("form_luminaire_type.manufacturer_code")}
            value={toStringValue(formik.values.details?.manufacturerCode)}
            onChange={setTextFieldValue(formik, "details.manufacturerCode")}
          />
          {formik.values.details?.sourceOfLight ===
            Lighting_SourceOfLightModel.Led && (
            <React.Fragment>
              <Compo.Toggle
                wide
                checked={formik.values.ledDetails?.hasControllerSocket ?? false}
                disabled={formik.isSubmitting || !!props.luminaireType}
                onChange={setBooleanFieldValue(
                  formik,
                  "ledDetails.hasControllerSocket"
                )}
                label={
                  t("form_luminaire_type.has_controller_socket") ?? undefined
                }
              />
              <Compo.TextInput
                wide
                id="optics"
                name="optics"
                disabled={formik.isSubmitting}
                error={getFieldError(formik, "ledDetails.optics")}
                label={t("form_luminaire_type.optics")}
                value={toStringValue(formik.values.ledDetails?.optics)}
                onChange={setTextFieldValue(formik, "ledDetails.optics")}
              />
              <Compo.TextInput
                wide
                id="driver-type"
                name="driver-type"
                disabled={formik.isSubmitting}
                error={getFieldError(formik, "ledDetails.driverType")}
                label={t("form_luminaire_type.driver_type")}
                value={toStringValue(formik.values.ledDetails?.driverType)}
                onChange={setTextFieldValue(formik, "ledDetails.driverType")}
              />
              <Compo.TextInput
                wide
                id="driver-current"
                name="driver-current"
                type="number"
                unit="mA"
                disabled={formik.isSubmitting}
                error={getFieldError(formik, "ledDetails.driverCurrent")}
                label={t("form_luminaire_type.driver_current")}
                value={toStringValue(formik.values.ledDetails?.driverCurrent)}
                onChange={setFloatFieldValue(
                  formik,
                  "ledDetails.driverCurrent"
                )}
              />
              <Compo.TextInput
                wide
                id="amount-of-led"
                name="amount-of-led"
                type="number"
                disabled={formik.isSubmitting}
                error={getFieldError(formik, "ledDetails.amountOfLed")}
                label={t("form_luminaire_type.amount_of_led")}
                value={toStringValue(formik.values.ledDetails?.amountOfLed)}
                onChange={setFloatFieldValue(formik, "ledDetails.amountOfLed")}
              />
            </React.Fragment>
          )}
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LuminaireTypeForm;
