import React from "react";
import { Outlet, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import Administration from "../pages/Administration";
import LightingMapProxy from "../proxies/LightingMapProxy";
import { PoleCollection } from "../pages/PoleCollection";
import { PowerBoxCollection } from "../pages/PowerBoxCollection";
import { PowerBoxView } from "../pages/PowerBoxView";
import { PoleView } from "../pages/PoleView";
import { LuminaireView } from "../pages/LuminaireView";
import { LuminaireTypeCollection } from "../pages/LuminaireTypeCollection";
import { LuminaireTypeView } from "../pages/LuminaireTypeView";
import { AreaSettings } from "../pages/AreaSettings";
import LightingAreaSettingsProxy from "../proxies/LightingAreaSettingsProxy";
import LightingPowerBoxesProxy from "../proxies/LightingPowerBoxesProxy";
import LightingPowerBoxesFocusProxy from "../proxies/LightingPowerBoxesItemProxy";
import LightingPolesProxy from "../proxies/LightingPolesProxy";
import LightingPolesItemProxy from "../proxies/LightingPolesItemProxy";

const routes = (
  <Route element={<LightingAreaSettingsProxy />}>
    <Route element={<LightingMapProxy />}>
      <Route path="lighting" element={<Outlet />}>
        <Route index element={<HomePage />} />
        <Route path="area-settings" element={<AreaSettings />} />
        <Route path="admin" element={<Administration />} />
        <Route path="power-box" element={<Outlet />}>
          <Route element={<LightingPowerBoxesProxy />}>
            <Route index element={<PowerBoxCollection />} />
            <Route element={<LightingPowerBoxesFocusProxy />}>
              <Route path=":id" element={<PowerBoxView />} />
            </Route>
          </Route>
        </Route>
        <Route path="pole" element={<Outlet />}>
          <Route element={<LightingPolesProxy />}>
            <Route index element={<PoleCollection />} />
            <Route element={<LightingPolesItemProxy />}>
              <Route path=":id" element={<Outlet />}>
                <Route index element={<PoleView />} />
                <Route
                  path="luminaires/:luminaireId"
                  element={<LuminaireView />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="luminaire-type" element={<Outlet />}>
          <Route index element={<LuminaireTypeCollection />} />
          <Route path=":id" element={<LuminaireTypeView />} />
        </Route>
      </Route>
    </Route>
  </Route>
);

export default routes;
