import {
  Failures_FailureStateModel,
  Failures_FailureErrorStageModel,
} from "src/graphql/types";
import type {
  Failures_FailureModel,
  Failures_FailureRowModel,
  Failures_FailureErrorModel,
} from "src/graphql/types";

export const isFailureResolved = (
  failure: Failures_FailureModel | Failures_FailureRowModel
): boolean => {
  return failure.state === Failures_FailureStateModel.Resolved;
};

export const isFailureNew = (
  failure: Failures_FailureModel | Failures_FailureRowModel
): boolean => {
  return failure.state === Failures_FailureStateModel.Active;
};

export const isFailureErrorResolved = (
  error: Failures_FailureErrorModel
): boolean => {
  return error.stage === Failures_FailureErrorStageModel.Resolved;
};

export const isFailureErrorNew = (
  error: Failures_FailureErrorModel
): boolean => {
  return error.stage === Failures_FailureErrorStageModel.Active;
};
