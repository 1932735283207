import React from "react";
import Compo, { type Types } from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import CounterViewSkeleton from "./CounterView.skeleton";
import { useCurrentArea } from "src/core/contexts";
import { Navigate, useParams } from "react-router-dom";
import { CounterDetailsTab } from "./elements/CounterDetailsTab";
import { useCounterByIdFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounterById";
import { useCounterJournalsFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounterJournals";
import { useCounterJournalByIdFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounterJournalById";
import {
  CounterPeopleJournalTab,
  CounterPeopleJournalTabSkeleton,
} from "./elements/CounterPeopleJournalTab";
import {
  CounterBikeJournalTab,
  CounterBikeJournalTabSkeleton,
} from "./elements/CounterBikeJournalTab";
import {
  CounterCarJournalTab,
  CounterCarJournalTabSkeleton,
} from "./elements/CounterCarJournalTab";
import {
  CounterTruckJournalTab,
  CounterTruckJournalTabSkeleton,
} from "./elements/CounterTruckJournalTab";
import type {
  TrafficIoT_CounterJournalModel,
  TrafficIoT_CounterModel,
} from "src/graphql/types";
const CounterView: React.FC = () => (
  <Page wide permission="traffic_iot.counter.get">
    <React.Suspense fallback={<CounterViewSkeleton />}>
      <CounterViewContent />
    </React.Suspense>
  </Page>
);

const CounterViewContent: React.FC = () => {
  const area = useCurrentArea();
  const params = useParams<{ id: string }>();
  const [current, setCurrent] = React.useState(0);

  const { data: counterData } = useCounterByIdFromTrafficIoTSuspenseQuery({
    variables: { input: { areaId: area.id, id: params.id as string } },
  });
  const counter = counterData.TrafficIoT_counterById
    .counter as TrafficIoT_CounterModel;

  const { data: journalListData } =
    useCounterJournalsFromTrafficIoTSuspenseQuery({
      variables: {
        input: {
          areaId: area.id,
          filters: { ofCounterId: counter.id },
        },
      },
    });

  const { data: journalData } =
    useCounterJournalByIdFromTrafficIoTSuspenseQuery({
      variables: {
        input: {
          areaId: area.id,
          id: journalListData.TrafficIoT_counterJournals.items[0]?.id,
        },
      },
      skip: journalListData.TrafficIoT_counterJournals.count === 0,
    });

  const journal = journalData?.TrafficIoT_counterJournalById
    ?.journal as TrafficIoT_CounterJournalModel;

  if (!counter) {
    return <Navigate replace to="/404" />;
  }

  const tabs: Record<string, Types.TabProps> = {
    details: {
      icon: Compo.InformationCircleIcon,
      content: <CounterDetailsTab counter={counter} />,
    },
  };

  if (journal.status.peopleCountDate) {
    tabs.personJournal = {
      icon: Compo.PersonWalkIcon,
      content: (
        <React.Suspense fallback={<CounterPeopleJournalTabSkeleton />}>
          <CounterPeopleJournalTab journal={journal} />
        </React.Suspense>
      ),
    };
  }

  if (journal.status.bikeCountDate) {
    tabs.bikeJournal = {
      icon: Compo.BicycleIcon,
      content: (
        <React.Suspense fallback={<CounterBikeJournalTabSkeleton />}>
          <CounterBikeJournalTab journal={journal} />
        </React.Suspense>
      ),
    };
  }

  if (journal.status.carCountDate) {
    tabs.carJournal = {
      icon: Compo.CarIcon,
      content: (
        <React.Suspense fallback={<CounterCarJournalTabSkeleton />}>
          <CounterCarJournalTab journal={journal} />
        </React.Suspense>
      ),
    };
  }

  if (journal.status.truckCountDate) {
    tabs.truckJournal = {
      icon: Compo.TruckIcon,
      content: (
        <React.Suspense fallback={<CounterTruckJournalTabSkeleton />}>
          <CounterTruckJournalTab journal={journal} />
        </React.Suspense>
      ),
    };
  }

  return (
    <Wrapper>
      <Compo.Tabs
        current={current}
        onChange={setCurrent}
        wide
        tabs={Object.values(tabs)}
      >
        {(content) => <ContentWrapper>{content}</ContentWrapper>}
      </Compo.Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

export default CounterView;
