import React from "react";
import { useCurrentArea, usePage } from "../contexts";
import { Outlet } from "react-router-dom";
import Compo from "@smartly-city/compo";
import {
  CircleLayer,
  OverridesControl,
  CompassControl,
  LocateItemControl,
  LocateUserControl,
  Map,
  MapDataLayers,
  MapDataProvider,
  MapModeControl,
  MapProvider,
  SidebarLayout,
  useMapRef,
} from "@smartly-city/compo-map";
import type { MapItem, MapLayer } from "@smartly-city/compo-map";

export interface MapProxyProps {
  layers?: MapLayer[];
  onItemClick?: (item: MapItem, layer: string) => void;
}

const MapProxy: React.FC<MapProxyProps> = (props) => {
  const page = usePage();
  const area = useCurrentArea();
  const mapRef = useMapRef();

  return (
    <MapProvider
      storeStyleInLocalStorage
      center={[area.center.longitude, area.center.latitude]}
      zoom={10}
      mapRef={mapRef}
    >
      <MapDataProvider layers={props.layers}>
        <SidebarLayout open={page.sidebarVisible} panelChildren={<Outlet />}>
          <Map
            ref={mapRef}
            controls={
              <Compo.MapMenu>
                <CompassControl />
                <MapModeControl />
                <LocateUserControl />
                <LocateItemControl />
                <OverridesControl />
              </Compo.MapMenu>
            }
          >
            <MapDataLayers
              render={(layer, active) => (
                <CircleLayer
                  contextOnClick
                  contextOnContextMenu
                  key={layer.id}
                  id={layer.id}
                  style={layer.style}
                  items={layer.items}
                  activeId={active?.layer === layer.id ? active.id : undefined}
                  onItemClick={props.onItemClick}
                />
              )}
            />
          </Map>
        </SidebarLayout>
      </MapDataProvider>
    </MapProvider>
  );
};

export default MapProxy;
