import React from "react";
import Compo from "@smartly-city/compo";
import { toast } from "react-toastify";
import { useLightingIoTTranslation } from "../../utils/translation";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import styled from "styled-components";
import Page from "src/core/components/Page";
import { useValidateLuminairesIndexFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTValidateLuminairesIndex";
import { useIndexLuminairesFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTIndexLuminaires";
import { useValidateLightingSchedulesIndexFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTValidateLightingSchedulesIndex";
import { useIndexLightingSchedulesFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTIndexLightingSchedules";
import { useValidateLuminaireJobsIndexFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTValidateLuminaireJobsIndex";
import { useIndexLuminaireJobsFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTIndexLuminaireJobs";
import { useInitializeAllControllersFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTInitializeAllControllers";
import { useIndexLuminaireJournalsFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTIndexLuminaireJournals";
import { useValidateLuminaireJournalsIndexFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTValidateLuminaireJournalsIndex";

const Administration: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();
  const [tab, setTab] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  const hasValidateLuminairesIndexPermission = usePermissionMatch(
    "lighting_iot.admin.validate_luminaires_index"
  );
  const [validateLuminaires] =
    useValidateLuminairesIndexFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_luminaires",
        });
        cache.gc();
      },
    });
  const handleValidateLuminaires = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateLuminaires();
    if (result.data?.LightingIoT_Admin_validateLuminairesIndex.isSuccess) {
      toast.success(t(`notification.luminaires_index_validated`));
    } else {
      result.data?.LightingIoT_Admin_validateLuminairesIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateLightingSchedulesIndexPermission = usePermissionMatch(
    "lighting_iot.admin.validate_lighting_schedules_index"
  );
  const [validateLightingSchedules] =
    useValidateLightingSchedulesIndexFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_lightingSchedules",
        });
        cache.gc();
      },
    });
  const handleValidateLightingSchedules = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateLightingSchedules();
    if (
      result.data?.LightingIoT_Admin_validateLightingSchedulesIndex.isSuccess
    ) {
      toast.success(t(`notification.lighting_schedules_index_validated`));
    } else {
      result.data?.LightingIoT_Admin_validateLightingSchedulesIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateLuminaireJobsIndexPermission = usePermissionMatch(
    "lighting_iot.admin.validate_luminaire_jobs_index"
  );
  const [validateLuminaireJobs] =
    useValidateLuminaireJobsIndexFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_luminaireJobs",
        });
        cache.gc();
      },
    });
  const handleValidateLuminaireJobs = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateLuminaireJobs();
    if (result.data?.LightingIoT_Admin_validateLuminaireJobsIndex.isSuccess) {
      toast.success(t(`notification.luminaire_jobs_index_validated`));
    } else {
      result.data?.LightingIoT_Admin_validateLuminaireJobsIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateLuminaireJournalsIndexPermission = usePermissionMatch(
    "lighting_iot.admin.validate_luminaire_journals_index"
  );
  const [validateLuminaireJournals] =
    useValidateLuminaireJournalsIndexFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_luminaireJournals",
        });
        cache.gc();
      },
    });
  const handleValidateLuminaireJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateLuminaireJournals();
    if (
      result.data?.LightingIoT_Admin_validateLuminaireJournalsIndex.isSuccess
    ) {
      toast.success(t(`notification.luminaire_journals_index_validated`));
    } else {
      result.data?.LightingIoT_Admin_validateLuminaireJournalsIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexLuminairesPermission = usePermissionMatch(
    "lighting_iot.admin.index_luminaires"
  );
  const [indexLuminaires] = useIndexLuminairesFromLightingIoTMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "LightingIoT_luminaires",
      });
      cache.gc();
    },
  });
  const handleIndexLuminaires = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexLuminaires();
    if (result.data?.LightingIoT_Admin_indexLuminaires.isSuccess) {
      toast.success(t(`notification.luminaires_indexed`));
    } else {
      result.data?.LightingIoT_Admin_indexLuminaires.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexLightingSchedulesPermission = usePermissionMatch(
    "lighting_iot.admin.index_lighting_schedules"
  );
  const [indexLightingSchedules] =
    useIndexLightingSchedulesFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_lightingSchedules",
        });
        cache.gc();
      },
    });
  const handleIndexLightingSchedules = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexLightingSchedules();
    if (result.data?.LightingIoT_Admin_indexLightingSchedules.isSuccess) {
      toast.success(t(`notification.lighting_schedules_indexed`));
    } else {
      result.data?.LightingIoT_Admin_indexLightingSchedules.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexLuminaireJobsPermission = usePermissionMatch(
    "lighting_iot.admin.index_luminaire_jobs"
  );
  const [indexLuminaireJobs] = useIndexLuminaireJobsFromLightingIoTMutation({
    variables: { input: { areaId: area.id } },
    update: (cache) => {
      cache.evict({
        fieldName: "LightingIoT_luminaireJobs",
      });
      cache.gc();
    },
  });
  const handleIndexLuminaireJobs = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexLuminaireJobs();
    if (result.data?.LightingIoT_Admin_indexLuminaireJobs.isSuccess) {
      toast.success(t(`notification.luminaire_jobs_indexed`));
    } else {
      result.data?.LightingIoT_Admin_indexLuminaireJobs.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexLuminaireJournalsPermission = usePermissionMatch(
    "lighting_iot.admin.index_luminaire_journals"
  );
  const [indexLuminaireJournals] =
    useIndexLuminaireJournalsFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_luminaireJournals",
        });
        cache.gc();
      },
    });
  const handleIndexLuminaireJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexLuminaireJournals();
    if (result.data?.LightingIoT_Admin_indexLuminaireJournals.isSuccess) {
      toast.success(t(`notification.luminaire_journals_indexed`));
    } else {
      result.data?.LightingIoT_Admin_indexLuminaireJournals.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasInitializeAllControllersPermission = usePermissionMatch(
    "lighting_iot.admin.initialize_all_controllers"
  );
  const [initializeAllControllers] =
    useInitializeAllControllersFromLightingIoTMutation({
      variables: { input: { areaId: area.id } },
      update: (cache) => {
        cache.evict({
          fieldName: "LightingIoT_luminaireJobs",
        });
        cache.gc();
      },
    });
  const handleInitializeAllControllers = async (): Promise<void> => {
    setSubmitting(true);
    const result = await initializeAllControllers();
    if (result.data?.LightingIoT_Admin_initializeAllControllers.isSuccess) {
      toast.success(t(`notification.started_controllers_initialization`));
    } else {
      result.data?.LightingIoT_Admin_initializeAllControllers.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  return (
    <Page wide permission="lighting_iot.admin.get">
      <Compo.Tabs
        wide
        current={tab}
        onChange={(index) => setTab(index)}
        tabs={[
          {
            icon: Compo.SearchIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidateLuminairesIndexPermission}
                  onClick={handleValidateLuminaires}
                >
                  {t("page_administration.validate_luminaires_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateLightingSchedulesIndexPermission
                  }
                  onClick={handleValidateLightingSchedules}
                >
                  {t("page_administration.validate_lighting_schedules_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateLuminaireJobsIndexPermission
                  }
                  onClick={handleValidateLuminaireJobs}
                >
                  {t("page_administration.validate_luminaire_jobs_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateLuminaireJournalsIndexPermission
                  }
                  onClick={handleValidateLuminaireJournals}
                >
                  {t("page_administration.validate_luminaire_journals_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexLuminairesPermission}
                  onClick={handleIndexLuminaires}
                >
                  {t("page_administration.index_luminaires")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexLightingSchedulesPermission}
                  onClick={handleIndexLightingSchedules}
                >
                  {t("page_administration.index_lighting_schedules")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexLuminaireJobsPermission}
                  onClick={handleIndexLuminaireJobs}
                >
                  {t("page_administration.index_luminaire_jobs")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexLuminaireJournalsPermission}
                  onClick={handleIndexLuminaireJournals}
                >
                  {t("page_administration.index_luminaire_journals")}
                </Compo.Button>
              </Wrapper>
            ),
          },
          {
            icon: Compo.LuminaireIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={
                    submitting || !hasInitializeAllControllersPermission
                  }
                  onClick={handleInitializeAllControllers}
                >
                  {t("page_administration.initialize_all_controllers")}
                </Compo.Button>
              </Wrapper>
            ),
          },
        ]}
      />
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem;
`;

export default Administration;
