import Compo, { media, useMediaQuery } from "@smartly-city/compo";
import React from "react";
import { useTranslation } from "react-i18next";
import Page from "src/core/components/Page";
import { useEnabledApiMatch } from "src/core/contexts";
import { FailuresStatistics } from "src/modules/failures/containers/FailuresStatistics";
import { LightingStatistics } from "src/modules/lighting/containers/LightingStatistics";
import { LightingIoTStatistics } from "src/modules/lighting_iot/containers/LightingIoTStatistics";
import styled from "styled-components";

const DashboardPage: React.FC = (props) => {
  const { t } = useTranslation();
  const isLightingEnabled = useEnabledApiMatch("lighting");
  const isLightingIoTEnabled = useEnabledApiMatch("lighting_iot");
  const isFailuresEnabled = useEnabledApiMatch("failures");
  const isMobile = useMediaQuery(media.max.md);

  const items = [
    isLightingEnabled && <LightingStatistics key={1} />,
    isLightingIoTEnabled && <LightingIoTStatistics key={2} />,
    isFailuresEnabled && <FailuresStatistics key={3} />,
  ].filter((item) => item);

  return (
    <StyledPage>
      <StyledHeader title={t("hello")} />
      {isMobile ? (
        <MobileWrapper>{items}</MobileWrapper>
      ) : (
        <Wrapper>
          <ColumnWrapper>
            {items.filter((_, index) => index % 2 === 0)}
          </ColumnWrapper>
          <ColumnWrapper>
            {items.filter((_, index) => index % 2 === 1)}
          </ColumnWrapper>
        </Wrapper>
      )}
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  background-color: #f3f5f7;
`;

const StyledHeader = styled(Compo.Header)`
  margin-bottom: 1rem;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

export default DashboardPage;
