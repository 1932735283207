import React from "react";
import type {
  Failures_FailureErrorGroupModel,
  Failures_FailureErrorStageModel,
  Failures_FailureErrorTypeModel,
  Failures_FailureStateModel,
  Failures_FailureTypeModel,
} from "src/graphql/types";

export interface FailureFilters {
  active?: boolean;
  objectName?: string;
  createdDate?: {
    min?: Date;
    max?: Date;
  };
  errorGroup?: Failures_FailureErrorGroupModel;
  errorStage?: Failures_FailureErrorStageModel;
  errorType?: Failures_FailureErrorTypeModel;
  state?: Failures_FailureStateModel;
  type?: Failures_FailureTypeModel;
}

export type FailureFiltersStatus = Record<keyof FailureFilters, boolean>;

export interface FailureFiltersContextState {
  filters: FailureFilters;
  status: FailureFiltersStatus;
  setFilters: (filters: FailureFilters) => void;
  deactivateFilter: (name: keyof FailureFilters) => void;
  activateFilter: (name: keyof FailureFilters) => void;
  clearFilters: () => void;
}

export const FailureFiltersContext = React.createContext<
  FailureFiltersContextState | undefined
>(undefined);

export const useFailureFilters = (): FailureFiltersContextState => {
  const context = React.useContext(FailureFiltersContext);
  return context as FailureFiltersContextState;
};
