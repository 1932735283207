import i18next from "i18next";
import * as yup from "yup";

yup.addMethod<yup.Schema>(yup.mixed, "fromFormik", function () {
  return this.transform((v) => (!v && v !== 0 ? undefined : v));
});

yup.addMethod<yup.Schema>(yup.string, "fromFormik", function () {
  return this.transform((v) => (!v ? undefined : v));
});

yup.addMethod<yup.Schema>(yup.number, "fromFormik", function () {
  return this.transform((v) => (!v && v !== 0 ? undefined : v));
});

yup.addMethod<yup.Schema>(yup.object, "fromFormik", function () {
  return this.transform((v) => (!v ? undefined : v));
});

yup.addMethod<yup.Schema>(yup.date, "fromFormik", function () {
  return this.transform((v) => (!v ? undefined : v));
});

yup.addMethod<yup.Schema>(yup.array, "fromFormik", function () {
  return this.transform((v) => (!v ? undefined : v));
});

yup.addMethod<yup.Schema>(yup.boolean, "fromFormik", function () {
  return this.transform((v) => (!v && v !== false ? undefined : v));
});

declare module "yup" {
  interface Schema<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TType = any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TContext = any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TDefault = any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TFlags extends yup.Flags = ""
  > {
    fromFormik: () => this;
  }
}

yup.setLocale({
  mixed: {
    default: () => i18next.t("input_error.invalid"),
    required: () => i18next.t("input_error.required"),
    oneOf: () => i18next.t("input_error.invalid"),
    notType: () => i18next.t("input_error.invalid"),
  },
  string: {
    min: () => i18next.t("input_error.short"),
    max: () => i18next.t("input_error.long"),
  },
  number: {
    min: () => i18next.t("input_error.min"),
    max: () => i18next.t("input_error.max"),
    integer: () => i18next.t("input_error.integer"),
  },
  date: {
    min: (date) =>
      i18next
        .t("input_error.min_date")
        .replaceAll("{date}", (date.min as Date).toLocaleDateString()),
    max: (date) =>
      i18next
        .t("input_error.max_date")
        .replaceAll("{date}", (date.max as Date).toLocaleDateString()),
  },
});
