import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteLuminaireFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_DeleteLuminaireInput;
}>;


export type DeleteLuminaireFromLightingMutation = { __typename?: 'Mutation', Lighting_deleteLuminaire: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const DeleteLuminaireFromLightingDocument = gql`
    mutation deleteLuminaireFromLighting($input: Lighting_DeleteLuminaireInput!) {
  Lighting_deleteLuminaire(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type DeleteLuminaireFromLightingMutationFn = Apollo.MutationFunction<DeleteLuminaireFromLightingMutation, DeleteLuminaireFromLightingMutationVariables>;

/**
 * __useDeleteLuminaireFromLightingMutation__
 *
 * To run a mutation, you first call `useDeleteLuminaireFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLuminaireFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLuminaireFromLightingMutation, { data, loading, error }] = useDeleteLuminaireFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLuminaireFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLuminaireFromLightingMutation, DeleteLuminaireFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLuminaireFromLightingMutation, DeleteLuminaireFromLightingMutationVariables>(DeleteLuminaireFromLightingDocument, options);
      }
export type DeleteLuminaireFromLightingMutationHookResult = ReturnType<typeof useDeleteLuminaireFromLightingMutation>;
export type DeleteLuminaireFromLightingMutationResult = Apollo.MutationResult<DeleteLuminaireFromLightingMutation>;
export type DeleteLuminaireFromLightingMutationOptions = Apollo.BaseMutationOptions<DeleteLuminaireFromLightingMutation, DeleteLuminaireFromLightingMutationVariables>;