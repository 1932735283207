import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CounterJournalsFromTrafficIoTQueryVariables = Types.Exact<{
  input: Types.TrafficIoT_GetCounterJournalsInput;
}>;


export type CounterJournalsFromTrafficIoTQuery = { __typename?: 'Query', TrafficIoT_counterJournals: { __typename?: 'TrafficIoT_GetCounterJournalsPayload', count: number, total: number, items: Array<{ __typename?: 'TrafficIoT_CounterJournalRowModel', id: any, version: number, created: any, changed: any, areaId: any, counterId: any, status: { __typename?: 'TrafficIoT_CounterJournalStatusModel', communicationDate?: any | null, peopleCountDate?: any | null, bikeCountDate?: any | null, carCountDate?: any | null, truckCountDate?: any | null, voltage?: number | null, peopleCount?: number | null, bikeCount?: number | null, carCount?: number | null, truckCount?: number | null } }> } };


export const CounterJournalsFromTrafficIoTDocument = gql`
    query counterJournalsFromTrafficIoT($input: TrafficIoT_GetCounterJournalsInput!) {
  TrafficIoT_counterJournals(input: $input) {
    count
    total
    items {
      id
      version
      created
      changed
      areaId
      counterId
      status {
        communicationDate
        peopleCountDate
        bikeCountDate
        carCountDate
        truckCountDate
        voltage
        peopleCount
        bikeCount
        carCount
        truckCount
      }
    }
  }
}
    `;

/**
 * __useCounterJournalsFromTrafficIoTQuery__
 *
 * To run a query within a React component, call `useCounterJournalsFromTrafficIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterJournalsFromTrafficIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterJournalsFromTrafficIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCounterJournalsFromTrafficIoTQuery(baseOptions: Apollo.QueryHookOptions<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables> & ({ variables: CounterJournalsFromTrafficIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables>(CounterJournalsFromTrafficIoTDocument, options);
      }
export function useCounterJournalsFromTrafficIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables>(CounterJournalsFromTrafficIoTDocument, options);
        }
export function useCounterJournalsFromTrafficIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables>(CounterJournalsFromTrafficIoTDocument, options);
        }
export type CounterJournalsFromTrafficIoTQueryHookResult = ReturnType<typeof useCounterJournalsFromTrafficIoTQuery>;
export type CounterJournalsFromTrafficIoTLazyQueryHookResult = ReturnType<typeof useCounterJournalsFromTrafficIoTLazyQuery>;
export type CounterJournalsFromTrafficIoTSuspenseQueryHookResult = ReturnType<typeof useCounterJournalsFromTrafficIoTSuspenseQuery>;
export type CounterJournalsFromTrafficIoTQueryResult = Apollo.QueryResult<CounterJournalsFromTrafficIoTQuery, CounterJournalsFromTrafficIoTQueryVariables>;