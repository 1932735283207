import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddLuminaireToLightingScheduleFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_AddLuminaireToLightingScheduleInput;
}>;


export type AddLuminaireToLightingScheduleFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_addLuminaireToLightingSchedule: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const AddLuminaireToLightingScheduleFromLightingIoTDocument = gql`
    mutation addLuminaireToLightingScheduleFromLightingIoT($input: LightingIoT_AddLuminaireToLightingScheduleInput!) {
  LightingIoT_addLuminaireToLightingSchedule(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type AddLuminaireToLightingScheduleFromLightingIoTMutationFn = Apollo.MutationFunction<AddLuminaireToLightingScheduleFromLightingIoTMutation, AddLuminaireToLightingScheduleFromLightingIoTMutationVariables>;

/**
 * __useAddLuminaireToLightingScheduleFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useAddLuminaireToLightingScheduleFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLuminaireToLightingScheduleFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLuminaireToLightingScheduleFromLightingIoTMutation, { data, loading, error }] = useAddLuminaireToLightingScheduleFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLuminaireToLightingScheduleFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<AddLuminaireToLightingScheduleFromLightingIoTMutation, AddLuminaireToLightingScheduleFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLuminaireToLightingScheduleFromLightingIoTMutation, AddLuminaireToLightingScheduleFromLightingIoTMutationVariables>(AddLuminaireToLightingScheduleFromLightingIoTDocument, options);
      }
export type AddLuminaireToLightingScheduleFromLightingIoTMutationHookResult = ReturnType<typeof useAddLuminaireToLightingScheduleFromLightingIoTMutation>;
export type AddLuminaireToLightingScheduleFromLightingIoTMutationResult = Apollo.MutationResult<AddLuminaireToLightingScheduleFromLightingIoTMutation>;
export type AddLuminaireToLightingScheduleFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<AddLuminaireToLightingScheduleFromLightingIoTMutation, AddLuminaireToLightingScheduleFromLightingIoTMutationVariables>;