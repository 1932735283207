import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import type { Pagination } from "src/core/utils/pagination";

interface ListContextCurrentPage {
  pageSize: number;
  pageTotal: number;
}

export interface ListContextState {
  currentPage: ListContextCurrentPage | null;
  setCurrentPage: (current: ListContextCurrentPage | null) => void;
}

export interface ListProps {
  className?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
  pagination?: Pagination;
}

const ListContext = React.createContext<ListContextState | undefined>(
  undefined
);

const List: React.FC<ListProps> = (props) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] =
    React.useState<ListContextCurrentPage | null>(null);

  return (
    <ListContext.Provider value={{ currentPage, setCurrentPage }}>
      <Wrapper className={props.className}>
        <ContentWrapper>
          {props.header && <HeaderWrapper>{props.header}</HeaderWrapper>}
          {props.children}
        </ContentWrapper>
        {props.pagination && (
          <PaginationWrapper>
            {currentPage ? (
              <Compo.Pagination
                wide
                ofText={t("list_pagination.of")?.toLowerCase() ?? undefined}
                page={props.pagination.page}
                itemCount={currentPage.pageSize}
                totalCount={currentPage.pageTotal}
                onNext={() =>
                  props.pagination?.setPage(props.pagination.page + 1)
                }
                onPrev={() =>
                  props.pagination?.setPage(props.pagination.page - 1)
                }
              />
            ) : (
              <Compo.PaginationSkeleton />
            )}
          </PaginationWrapper>
        )}
      </Wrapper>
    </ListContext.Provider>
  );
};

export const useList = (): ListContextState => {
  return React.useContext(ListContext) as ListContextState;
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const ContentWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const PaginationWrapper = styled.div`
  margin-top: auto;
`;

export default List;
