import React from "react";
import styled from "styled-components";
import Compo from "@smartly-city/compo";
import { toast } from "react-toastify";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toSnakeCase } from "src/core/utils/string";
import { useCurrentArea } from "src/core/contexts";
import { useUpdatePoleFromLightingMutation } from "src/graphql/__generated__/LightingUpdatePole";
import { useCreateNewPoleFromLightingMutation } from "src/graphql/__generated__/LightingCreateNewPole";
import type { TFunction } from "i18next";
import type { DeepPartial } from "src/core/utils/types";
import type {
  Lighting_PoleModel,
  Lighting_CreateNewPoleInput,
  Lighting_UpdatePoleInput,
} from "src/graphql/types";
import {
  PoleFormDataInputs,
  dataDefaultValues,
  dataValidationSchema,
} from "./elements/PoleFormData";
import {
  PoleFormDefaultDetailsInputs,
  defaultDetailsDefaultValues,
  defaultDetailsValidationSchema,
} from "./elements/PoleFormDefaultDetails";
import {
  PoleFormMaintenanceDetailsInputs,
  maintenanceDetailsDefaultValues,
  getMaintenanceDetailsValidationSchema,
} from "./elements/PoleFormMaintenanceDetails";
import {
  PoleFormRoadDetailsInputs,
  roadDetailsDefaultValues,
  getRoadDetailsValidationSchema,
} from "./elements/PoleFormRoadDetails";
import {
  PoleFormBracketDetailsInputs,
  bracketDetailsDefaultValues,
  bracketDetailsValidationSchema,
} from "./elements/PoleFormBracketDetails";
import {
  PoleFormSidewalkDetailsInputs,
  sidewalkDetailsDefaultValues,
  sidewalkDetailsValidationSchema,
} from "./elements/PoleFormSidewalkDetails";
import { useAreaSettings } from "../../contexts/AreaSettings";
import { useLightingTranslation } from "../../utils/translation";

export interface PoleFormProps {
  onClose: () => void;
  pole?: Lighting_PoleModel;
}

export interface PoleFormContext
  extends DeepPartial<Lighting_CreateNewPoleInput> {}

const PoleForm: React.FC<PoleFormProps> = (props) => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const settings = useAreaSettings();
  const [step, setStep] = React.useState(0);

  const [create] = useCreateNewPoleFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_createNewPole.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_polesPaged",
      });
      cache.evict({
        fieldName: "Lighting_poles",
      });
      cache.gc();
    },
  });

  const [update] = useUpdatePoleFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_updatePole.isSuccess) return;
      cache.evict({
        fieldName: "Lighting_poleById",
      });
      cache.evict({
        fieldName: "Lighting_polesPaged",
      });
      cache.evict({
        fieldName: "Lighting_poles",
      });
      cache.gc();
    },
  });

  const steps = {
    data: {
      render: PoleFormDataInputs,
      validationSchema: dataValidationSchema,
      defaultValues: dataDefaultValues,
      canBeSkipped: false,
      isEmpty: () => !formik.values,
    },
    details: {
      render: PoleFormDefaultDetailsInputs,
      validationSchema: defaultDetailsValidationSchema,
      defaultValues: defaultDetailsDefaultValues,
      canBeSkipped: false,
      isEmpty: () => !formik.values.details,
    },
    maintenanceDetails: {
      render: PoleFormMaintenanceDetailsInputs,
      validationSchema: getMaintenanceDetailsValidationSchema(
        settings.requireMaintenanceDetails
      ),
      defaultValues: maintenanceDetailsDefaultValues,
      canBeSkipped: !settings.requireMaintenanceDetails,
      isEmpty: () => !formik.values.maintenanceDetails,
    },
    roadDetails: {
      render: PoleFormRoadDetailsInputs,
      validationSchema: getRoadDetailsValidationSchema(
        settings.requireRoadDetails
      ),
      defaultValues: roadDetailsDefaultValues,
      canBeSkipped: !settings.requireRoadDetails,
      isEmpty: () => !formik.values.roadDetails,
    },
    bracketDetails: {
      render: PoleFormBracketDetailsInputs,
      validationSchema: bracketDetailsValidationSchema,
      defaultValues: bracketDetailsDefaultValues,
      canBeSkipped: true,
      isEmpty: () => !formik.values.bracketDetails,
    },
    sidewalkDetails: {
      render: PoleFormSidewalkDetailsInputs,
      validationSchema: sidewalkDetailsValidationSchema,
      defaultValues: sidewalkDetailsDefaultValues,
      canBeSkipped: true,
      isEmpty: () => !formik.values.sidewalkDetails,
    },
  } as const;

  const currentStep = Object.values(steps)[step];
  const currentStepName = Object.keys(steps)[step];

  React.useEffect(() => {
    if (currentStep.isEmpty()) {
      void formik.setFieldValue(currentStepName, currentStep.defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepName]);

  const isLastStep = (): boolean => {
    return step === Object.keys(steps).length - 1;
  };

  const handleNext = (): void => {
    const prevStep = step;
    setStep(prevStep + 1);
  };

  const handlePrev = (): void => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  const handleClear = async (): Promise<void> => {
    if (!currentStep.canBeSkipped) return;
    await formik.setFieldValue(currentStepName, null);
    await formik.validateForm();
  };

  const handleSubmit = async (values: any): Promise<void> => {
    if (!isLastStep()) {
      formik.setSubmitting(false);
      handleNext();
      return;
    }

    if (props.pole) {
      const newPole = {
        id: props.pole.id,
        version: props.pole.version,
        areaId: props.pole.areaId,
        powerBoxId: values.powerBoxId,
        name: values.name,
        location: values.location,
        address: values.address,
        details: values.details,
        maintenanceDetails: values.maintenanceDetails,
        roadDetails: values.roadDetails,
        bracketDetails: values.bracketDetails,
        sidewalkDetails: values.sidewalkDetails,
      };

      const { data } = await update({
        variables: {
          input: newPole as Lighting_UpdatePoleInput,
        },
      });

      const result = data?.Lighting_updatePole;
      if (result?.isSuccess) {
        toast.success(t(`notification.pole_updated`));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    } else {
      const { data } = await create({
        variables: {
          input: {
            areaId: area.id,
            powerBoxId: values.powerBoxId,
            name: values.name,
            location: values.location,
            address: values.address,
            details: values.details,
            maintenanceDetails: values.maintenanceDetails,
            roadDetails: values.roadDetails,
            bracketDetails: values.bracketDetails,
            sidewalkDetails: values.sidewalkDetails,
          },
        },
      });

      const result = data?.Lighting_createNewPole;
      if (result?.isSuccess) {
        toast.success(t(`notification.pole_created`));
        navigate(result.value?.id);
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    }
  };

  const formik = useFormik<PoleFormContext>({
    initialValues: props.pole ?? {
      ...steps.data.defaultValues,
      details: steps.details.defaultValues,
      maintenanceDetails: steps.maintenanceDetails.defaultValues,
      roadDetails: steps.roadDetails.defaultValues,
      bracketDetails: steps.bracketDetails.defaultValues,
      sidewalkDetails: steps.sidewalkDetails.defaultValues,
    },
    validationSchema: currentStep.validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={
          <HeaderWrapper>
            <Header
              title={t(
                "form_pole." + (props.pole ? "edit_title" : "add_title")
              )}
            />
            <Compo.Stepper>
              {Object.keys(steps).map((key, index) =>
                getStep(t, index + 1, toSnakeCase(key), step + 1)
              )}
            </Compo.Stepper>
          </HeaderWrapper>
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {isLastStep()
                ? t("submit")
                : currentStep.canBeSkipped && currentStep.isEmpty()
                ? t("skip")
                : t("next")}
            </Compo.Button>
            <Compo.Button onClick={handlePrev} disabled={step === 0}>
              {t("previous")}
            </Compo.Button>
            <Compo.Button
              disabled={!(currentStep.canBeSkipped && !currentStep.isEmpty())}
              onClick={handleClear}
            >
              {t("clear_to_skip")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <FormikProvider value={formik}>
            <currentStep.render />
          </FormikProvider>
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const getStep = (
  t: TFunction,
  order: number,
  name: string,
  currentStep: number
): React.ReactElement => (
  <Compo.StepperStep
    key={`step-${order}`}
    label={t(name)}
    order={order}
    disabled={currentStep < order}
    finished={currentStep > order}
  />
);

const Header = styled(Compo.Header)`
  width: max-content;
  padding: 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default PoleForm;
