import React from "react";
import { Outlet, Route } from "react-router-dom";
import TrafficIoTMapProxy from "../proxies/TrafficIoTMapProxy";
import TrafficIoTCountersProxy from "../proxies/TrafficIoTCountersProxy";
import TrafficIoTCountersItemProxy from "../proxies/TrafficIoTCountersItemProxy";
import { Home } from "../pages/Home";
import { Administration } from "../pages/Administration";
import { CounterCollection } from "../pages/CounterCollection";
import { CounterView } from "../pages/CounterView";

const routes = (
  <Route element={<TrafficIoTMapProxy />}>
    <Route path="traffic-iot" element={<Outlet />}>
      <Route index element={<Home />} />
      <Route path="admin" element={<Administration />} />
      <Route path="counter" element={<Outlet />}>
        <Route element={<TrafficIoTCountersProxy />}>
          <Route index element={<CounterCollection />} />
          <Route element={<TrafficIoTCountersItemProxy />}>
            <Route path=":id" element={<CounterView />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

export default routes;
