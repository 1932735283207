import React from "react";
import Compo from "@smartly-city/compo";
import moment from "moment";
import { useFormikContext } from "formik";
import {
  getFieldError,
  setIntFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import type { LightingScheduleFormContext } from "../../LightingScheduleForm";

const StartStepInputs: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const formik = useFormikContext<LightingScheduleFormContext>();

  return (
    <React.Fragment>
      <Compo.Header
        outline
        type="h2"
        title={t("form_lighting_schedule.start_step")}
      />
      <Compo.Radio
        checked={!!formik.values.startStep?.startOnTimeStep}
        label={t("form_lighting_schedule.start_on_time") ?? undefined}
        onCheck={() =>
          formik.setFieldValue("startStep", {
            startOnTimeStep: {
              startTime: null,
              brightness: null,
            },
          })
        }
      />
      <Compo.Radio
        checked={!!formik.values.startStep?.startOnSunsetStep}
        label={t("form_lighting_schedule.start_on_sunset") ?? undefined}
        onCheck={() =>
          formik.setFieldValue("startStep", {
            startOnSunsetStep: {
              offset: null,
              brightness: null,
            },
          })
        }
      />
      {formik.values.startStep?.startOnTimeStep && (
        <React.Fragment>
          <Compo.TimeInput
            wide
            id="start-time"
            name="start-time"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "startStep.startOnTimeStep.startTime")}
            label={t("form_lighting_schedule.start_time") ?? undefined}
            value={moment(
              formik.values.startStep.startOnTimeStep.startTime,
              "HH:mm"
            )}
            onChange={async (v) =>
              await setTextFieldValue(
                formik,
                "startStep.startOnTimeStep.startTime"
              )(v?.format("HH:mm"))
            }
          />
          <Compo.TextInput
            wide
            id="brightness"
            name="brightness"
            type="number"
            disabled={formik.isSubmitting}
            error={getFieldError(
              formik,
              "startStep.startOnTimeStep.brightness"
            )}
            label={t("form_lighting_schedule.brightness") ?? undefined}
            value={toStringValue(
              formik.values.startStep.startOnTimeStep.brightness
            )}
            onChange={setIntFieldValue(
              formik,
              "startStep.startOnTimeStep.brightness"
            )}
          />
        </React.Fragment>
      )}
      {formik.values.startStep?.startOnSunsetStep && (
        <React.Fragment>
          <Compo.TextInput
            wide
            id="offset"
            name="offset"
            unit="min"
            type="number"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "startStep.startOnSunsetStep.offset")}
            label={t("form_lighting_schedule.offset") ?? undefined}
            value={toStringValue(
              formik.values.startStep.startOnSunsetStep.offset
            )}
            onChange={setIntFieldValue(
              formik,
              "startStep.startOnSunsetStep.offset"
            )}
          />
          <Compo.TextInput
            wide
            id="brightness"
            name="brightness"
            type="number"
            disabled={formik.isSubmitting}
            error={getFieldError(
              formik,
              "startStep.startOnSunsetStep.brightness"
            )}
            label={t("form_lighting_schedule.brightness") ?? undefined}
            value={toStringValue(
              formik.values.startStep.startOnSunsetStep.brightness
            )}
            onChange={setIntFieldValue(
              formik,
              "startStep.startOnSunsetStep.brightness"
            )}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StartStepInputs;
