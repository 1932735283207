import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PolesFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetPolesInput;
}>;


export type PolesFromLightingQuery = { __typename?: 'Query', Lighting_poles: { __typename?: 'Lighting_GetPolesPayload', count: number, items: Array<{ __typename?: 'Lighting_PoleRowModel', id: any, version: number, ordinalNumber: number, areaId: any, powerBoxId: any, powerBoxName: string, name: string, location: { __typename?: 'Lighting_CoordinatesModel', latitude: number, longitude: number } }> } };


export const PolesFromLightingDocument = gql`
    query polesFromLighting($input: Lighting_GetPolesInput!) {
  Lighting_poles(input: $input) {
    count
    items {
      id
      version
      ordinalNumber
      areaId
      powerBoxId
      powerBoxName
      name
      location {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __usePolesFromLightingQuery__
 *
 * To run a query within a React component, call `usePolesFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolesFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolesFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePolesFromLightingQuery(baseOptions: Apollo.QueryHookOptions<PolesFromLightingQuery, PolesFromLightingQueryVariables> & ({ variables: PolesFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PolesFromLightingQuery, PolesFromLightingQueryVariables>(PolesFromLightingDocument, options);
      }
export function usePolesFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PolesFromLightingQuery, PolesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PolesFromLightingQuery, PolesFromLightingQueryVariables>(PolesFromLightingDocument, options);
        }
export function usePolesFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PolesFromLightingQuery, PolesFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PolesFromLightingQuery, PolesFromLightingQueryVariables>(PolesFromLightingDocument, options);
        }
export type PolesFromLightingQueryHookResult = ReturnType<typeof usePolesFromLightingQuery>;
export type PolesFromLightingLazyQueryHookResult = ReturnType<typeof usePolesFromLightingLazyQuery>;
export type PolesFromLightingSuspenseQueryHookResult = ReturnType<typeof usePolesFromLightingSuspenseQuery>;
export type PolesFromLightingQueryResult = Apollo.QueryResult<PolesFromLightingQuery, PolesFromLightingQueryVariables>;