import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePowerBoxFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_DeletePowerBoxInput;
}>;


export type DeletePowerBoxFromLightingMutation = { __typename?: 'Mutation', Lighting_deletePowerBox: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const DeletePowerBoxFromLightingDocument = gql`
    mutation deletePowerBoxFromLighting($input: Lighting_DeletePowerBoxInput!) {
  Lighting_deletePowerBox(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type DeletePowerBoxFromLightingMutationFn = Apollo.MutationFunction<DeletePowerBoxFromLightingMutation, DeletePowerBoxFromLightingMutationVariables>;

/**
 * __useDeletePowerBoxFromLightingMutation__
 *
 * To run a mutation, you first call `useDeletePowerBoxFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePowerBoxFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePowerBoxFromLightingMutation, { data, loading, error }] = useDeletePowerBoxFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePowerBoxFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<DeletePowerBoxFromLightingMutation, DeletePowerBoxFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePowerBoxFromLightingMutation, DeletePowerBoxFromLightingMutationVariables>(DeletePowerBoxFromLightingDocument, options);
      }
export type DeletePowerBoxFromLightingMutationHookResult = ReturnType<typeof useDeletePowerBoxFromLightingMutation>;
export type DeletePowerBoxFromLightingMutationResult = Apollo.MutationResult<DeletePowerBoxFromLightingMutation>;
export type DeletePowerBoxFromLightingMutationOptions = Apollo.BaseMutationOptions<DeletePowerBoxFromLightingMutation, DeletePowerBoxFromLightingMutationVariables>;