import type { DeepPartial } from "src/core/utils/types";
import type { Lighting_PoleModel } from "src/graphql/types";

export const dataDefaultValues: DeepPartial<Lighting_PoleModel> = {
  location: {
    latitude: null as any,
    longitude: null as any,
  },
  address: {
    town: null as any,
    number: null as any,
    street: null as any,
  },
  name: null as any,
  powerBoxId: null as any,
};
