import React from "react";

export interface FiltersUtils<T> {
  filters: T;
  status: Record<keyof T, boolean>;
  setFilters: (filters: T) => void;
  deactivateFilter: (name: keyof T) => void;
  activateFilter: (name: keyof T) => void;
  clearFilters: () => void;
}

export const useFiltersUtils = <T extends object>(
  initialFilters: T,
  initialStatus: Record<keyof T, boolean>
): FiltersUtils<T> => {
  const [filters, setFilters] = React.useState<T>(initialFilters);
  const [status, setStatus] =
    React.useState<Record<keyof T, boolean>>(initialStatus);

  const handleSetFilters = (newFilters: T): void => {
    setFilters(removeNotActive(newFilters, status));
  };

  const handleActivateFilter = (name: keyof T): void => {
    setStatus({ ...status, [name]: true });
  };

  const handleDeactivateFilter = (name: keyof T): void => {
    const { [name]: _, ...newFilters } = filters;
    setStatus({ ...status, [name]: false });
    setFilters({ ...(newFilters as T) });
  };

  const handleClearFilters = (): void => {
    setStatus(initialStatus);
    setFilters(initialFilters);
  };

  return {
    filters,
    status,
    setFilters: handleSetFilters,
    activateFilter: handleActivateFilter,
    deactivateFilter: handleDeactivateFilter,
    clearFilters: handleClearFilters,
  };
};

const removeNotActive = <T extends object>(
  items: T,
  active: { [key in keyof T]: boolean }
): T => {
  return Object.keys(items)
    .filter((key) => active?.[key as keyof T])
    .reduce<T>(
      (acc: T, key) => ({
        ...acc,
        [key]: items[key as keyof T],
      }),
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter, @typescript-eslint/consistent-type-assertions
      {} as T
    );
};
