import React from "react";
import Compo from "@smartly-city/compo";
import { Lighting_OwnerModel } from "src/graphql/types";
import {
  getFieldError,
  setBooleanFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { useFormikContext } from "formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import type { LuminaireFormContext } from "../../LuminaireForm";

const LuminaireFormMaintenanceDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<LuminaireFormContext>();

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="owner"
        name="owner"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "maintenanceDetails.owner")}
        label={t("form_luminaire.owner")}
        value={toStringValue(formik.values.maintenanceDetails?.owner)}
        onChange={setTextFieldValue(formik, "maintenanceDetails.owner")}
      >
        <option value="" />
        {Object.values(Lighting_OwnerModel).map((type) => (
          <option key={type} value={type}>
            {t(`owner.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.Toggle
        wide
        checked={
          formik.values.maintenanceDetails?.qualifiedForReplacement ?? false
        }
        onChange={setBooleanFieldValue(
          formik,
          "maintenanceDetails.qualifiedForReplacement"
        )}
        label={t("form_luminaire.qualified_for_replacement") ?? undefined}
      />
    </React.Fragment>
  );
};

export default LuminaireFormMaintenanceDetailsInputs;
