import React from "react";

export interface PowerBoxFilters {
  name?: string;
  ordinalNumber?: number;
  addressNumber?: string;
  addressStreet?: string;
  addressTown?: string;
  power?: {
    max?: number;
    min?: number;
  };
  contractedPower?: {
    max?: number;
    min?: number;
  };
  fuseAmpRating?: {
    max?: number;
    min?: number;
  };
  fuseType?: string;
  recipientCode?: string;
  notes?: string;
}

export type PowerBoxFiltersStatus = Record<keyof PowerBoxFilters, boolean>;

export interface PowerBoxFiltersContextState {
  filters: PowerBoxFilters;
  status: PowerBoxFiltersStatus;
  setFilters: (filters: PowerBoxFilters) => void;
  deactivateFilter: (name: keyof PowerBoxFilters) => void;
  activateFilter: (name: keyof PowerBoxFilters) => void;
  clearFilters: () => void;
}

export const PowerBoxFiltersContext = React.createContext<
  PowerBoxFiltersContextState | undefined
>(undefined);

export const usePowerBoxFilters = (): PowerBoxFiltersContextState => {
  const context = React.useContext(PowerBoxFiltersContext);
  return context as PowerBoxFiltersContextState;
};
