import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexObjectsFromFailuresMutationVariables = Types.Exact<{
  input: Types.Failures_Admin_IndexObjectsInput;
}>;


export type IndexObjectsFromFailuresMutation = { __typename?: 'Mutation', Failures_Admin_indexObjects: { __typename?: 'Failures_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Failures_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Failures_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const IndexObjectsFromFailuresDocument = gql`
    mutation indexObjectsFromFailures($input: Failures_Admin_IndexObjectsInput!) {
  Failures_Admin_indexObjects(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type IndexObjectsFromFailuresMutationFn = Apollo.MutationFunction<IndexObjectsFromFailuresMutation, IndexObjectsFromFailuresMutationVariables>;

/**
 * __useIndexObjectsFromFailuresMutation__
 *
 * To run a mutation, you first call `useIndexObjectsFromFailuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexObjectsFromFailuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexObjectsFromFailuresMutation, { data, loading, error }] = useIndexObjectsFromFailuresMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexObjectsFromFailuresMutation(baseOptions?: Apollo.MutationHookOptions<IndexObjectsFromFailuresMutation, IndexObjectsFromFailuresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexObjectsFromFailuresMutation, IndexObjectsFromFailuresMutationVariables>(IndexObjectsFromFailuresDocument, options);
      }
export type IndexObjectsFromFailuresMutationHookResult = ReturnType<typeof useIndexObjectsFromFailuresMutation>;
export type IndexObjectsFromFailuresMutationResult = Apollo.MutationResult<IndexObjectsFromFailuresMutation>;
export type IndexObjectsFromFailuresMutationOptions = Apollo.BaseMutationOptions<IndexObjectsFromFailuresMutation, IndexObjectsFromFailuresMutationVariables>;