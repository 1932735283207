import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignInUserFromSecurityMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.Security_SignInUserRequest_Input>;
}>;


export type SignInUserFromSecurityMutation = { __typename?: 'Mutation', Security_signInUser?: { __typename?: 'Security_SignInUserResponse', success: boolean, token?: string | null } | null };


export const SignInUserFromSecurityDocument = gql`
    mutation signInUserFromSecurity($input: Security_SignInUserRequest_Input) {
  Security_signInUser(input: $input) {
    success
    token
  }
}
    `;
export type SignInUserFromSecurityMutationFn = Apollo.MutationFunction<SignInUserFromSecurityMutation, SignInUserFromSecurityMutationVariables>;

/**
 * __useSignInUserFromSecurityMutation__
 *
 * To run a mutation, you first call `useSignInUserFromSecurityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserFromSecurityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserFromSecurityMutation, { data, loading, error }] = useSignInUserFromSecurityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInUserFromSecurityMutation(baseOptions?: Apollo.MutationHookOptions<SignInUserFromSecurityMutation, SignInUserFromSecurityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInUserFromSecurityMutation, SignInUserFromSecurityMutationVariables>(SignInUserFromSecurityDocument, options);
      }
export type SignInUserFromSecurityMutationHookResult = ReturnType<typeof useSignInUserFromSecurityMutation>;
export type SignInUserFromSecurityMutationResult = Apollo.MutationResult<SignInUserFromSecurityMutation>;
export type SignInUserFromSecurityMutationOptions = Apollo.BaseMutationOptions<SignInUserFromSecurityMutation, SignInUserFromSecurityMutationVariables>;