import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useCurrentArea } from "src/core/contexts";
import { useAddLuminairesToLightingScheduleFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTAddLuminairesToLightingSchedule";
import { useLightingIoTTranslation } from "../../utils/translation";
import type { LightingIoT_LightingSchedulePayload } from "src/graphql/types";
import { useLuminairesFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLuminaires";

export interface LuminairesLightingScheduleFormProps {
  onClose: () => void;
  schedule: LightingIoT_LightingSchedulePayload;
}

const LuminairesLightingScheduleForm: React.FC<
  LuminairesLightingScheduleFormProps
> = (props) => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();

  const { data: luminairesData } = useLuminairesFromLightingIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: {
          withLightingSchedule: false,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const luminaires = luminairesData.LightingIoT_luminaires.items;

  const [addToSchedule] =
    useAddLuminairesToLightingScheduleFromLightingIoTMutation({
      update: (cache, result) => {
        if (
          result.errors ||
          !result.data?.LightingIoT_addLuminairesToLightingSchedule.isSuccess
        )
          return;
        cache.evict({
          fieldName: "LightingIoT_luminaireById",
        });
        cache.gc();
      },
    });

  const handleSubmit = async (values: any): Promise<void> => {
    const { data } = await addToSchedule({
      variables: {
        input: {
          areaId: area.id,
          scheduleId: props.schedule.id,
          luminaires: formik.values.luminaires
            .map((id) => luminaires.find((l) => l.id === id))
            .filter((row) => !!row)
            .map((row) => ({
              luminaireId: row?.id,
              luminaireVersion: row?.version,
            })),
        },
      },
    });

    const res = data?.LightingIoT_addLuminairesToLightingSchedule;
    if (res?.isSuccess) {
      toast.success(t("notification.luminaires_added_to_schedule"));
      props.onClose();
    } else {
      res?.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
  };

  const formik = useFormik<{ luminaires: string[] }>({
    initialValues: { luminaires: [] },
    validationSchema: yup.object().shape({
      luminaires: yup.array().of(yup.string()).required(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={
          <Compo.Header
            title={t("form_add_luminaires_to_lighting_schedule.title")}
          />
        }
        buttons={
          <React.Fragment>
            <Compo.Button
              filled
              disabled={luminaires.length === 0 || formik.isSubmitting}
              type="submit"
            >
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          {luminaires.length === 0 && (
            <Compo.ListRowEmpty
              wide
              text={t(
                "form_add_luminaires_to_lighting_schedule.no_luminaires_message"
              )}
            />
          )}
          {luminaires.map((luminaire, index) => (
            <Compo.Checkbox
              wide
              key={luminaire.id}
              disabled={formik.isSubmitting}
              label={luminaire.name}
              checked={formik.values.luminaires.includes(luminaire.id)}
              onChange={async (value) =>
                await formik.setFieldValue(
                  `luminaires.${index}`,
                  value ? luminaire.id : undefined
                )
              }
            />
          ))}
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LuminairesLightingScheduleForm;
