import React from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { daysBetween } from "src/core/utils/date";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import type { LightingIoT_StatisticsReadModel } from "src/graphql/types";

ChartJS.register(CategoryScale, LinearScale, BarElement);

interface LuminaireCommunicationChartProps {
  statistics: LightingIoT_StatisticsReadModel;
}

const LuminaireCommunicationChart: React.FC<
  LuminaireCommunicationChartProps
> = (props) => {
  const { t } = useLightingIoTTranslation();

  const luminairesCommunicatedDaysAgo =
    props.statistics.latestLuminaireCommunications.map((date) =>
      daysBetween(new Date(date), new Date())
    );

  const luminaireCommunicationsChartData = {
    labels: [
      t("dashboard_luminaire_communication_chart.less_than_3_days"),
      t("dashboard_luminaire_communication_chart.more_than_3_days"),
      t("dashboard_luminaire_communication_chart.more_than_7_days"),
      t("dashboard_luminaire_communication_chart.never"),
    ],
    datasets: [
      {
        label: t("dashboard_luminaire_communication_chart.title"),
        data: [
          luminairesCommunicatedDaysAgo.filter((days) => days < 3).length,
          luminairesCommunicatedDaysAgo.filter((days) => days < 7 && days >= 3)
            .length,
          luminairesCommunicatedDaysAgo.filter((days) => days > 7).length,
          props.statistics.luminaireCount -
            props.statistics.latestLuminaireCommunications.length,
        ],
        backgroundColor: "#2463EB",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Wrapper>
      <Title>{t("dashboard_luminaire_communication_chart.title")}</Title>
      <StyledBar
        data={luminaireCommunicationsChartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: { grid: { display: false } },
            y: {
              beginAtZero: true,
              border: { display: false },
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border: 2.5px solid #eef6ff;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

const Title = styled.h3`
  color: #767676;
`;

const StyledBar = styled(Bar)`
  max-height: 12.5rem;
  width: 100%;
`;

export default LuminaireCommunicationChart;
