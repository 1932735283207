import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useLuminairesFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingLuminaires";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import type { Lighting_PoleModel } from "src/graphql/types";

export interface LuminaireListProps {
  pole: Lighting_PoleModel;
}

const LuminaireList: React.FC<LuminaireListProps> = (props) => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();

  const { data } = useLuminairesFromLightingSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: { ofPoleId: props.pole.id },
      },
    },
  });
  const luminaires = data.Lighting_luminaires.items;

  if (luminaires.length === 0) {
    return <Compo.ListRowEmpty wide text={t("message.no_luminaires")} />;
  }

  const rows = luminaires.map((luminaire) => (
    <Compo.ListRow
      wide
      key={luminaire.id}
      onClick={() => navigate(`luminaires/${luminaire.id as string}`)}
    >
      {luminaire.typeName}
    </Compo.ListRow>
  ));

  return (
    <Wrapper>
      <RowsWrapper>{rows}</RowsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const RowsWrapper = styled.div`
  overflow-y: auto;
`;

export default LuminaireList;
