import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentUserPermittedAreasFromSecurityQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserPermittedAreasFromSecurityQuery = { __typename?: 'Query', Security_getCurrentUserPermittedAreas?: { __typename?: 'Security_GetCurrentUserPermittedAreasResponse', areas: Array<{ __typename?: 'Security_AreaReadModel', id: any, version: number, name: string, enabledApis: Array<string | null>, center: { __typename?: 'Security_CoordinatesModel', latitude: number, longitude: number } } | null> } | null };


export const GetCurrentUserPermittedAreasFromSecurityDocument = gql`
    query getCurrentUserPermittedAreasFromSecurity {
  Security_getCurrentUserPermittedAreas {
    areas {
      id
      version
      name
      enabledApis
      center {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserPermittedAreasFromSecurityQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPermittedAreasFromSecurityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPermittedAreasFromSecurityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPermittedAreasFromSecurityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserPermittedAreasFromSecurityQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>(GetCurrentUserPermittedAreasFromSecurityDocument, options);
      }
export function useGetCurrentUserPermittedAreasFromSecurityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>(GetCurrentUserPermittedAreasFromSecurityDocument, options);
        }
export function useGetCurrentUserPermittedAreasFromSecuritySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>(GetCurrentUserPermittedAreasFromSecurityDocument, options);
        }
export type GetCurrentUserPermittedAreasFromSecurityQueryHookResult = ReturnType<typeof useGetCurrentUserPermittedAreasFromSecurityQuery>;
export type GetCurrentUserPermittedAreasFromSecurityLazyQueryHookResult = ReturnType<typeof useGetCurrentUserPermittedAreasFromSecurityLazyQuery>;
export type GetCurrentUserPermittedAreasFromSecuritySuspenseQueryHookResult = ReturnType<typeof useGetCurrentUserPermittedAreasFromSecuritySuspenseQuery>;
export type GetCurrentUserPermittedAreasFromSecurityQueryResult = Apollo.QueryResult<GetCurrentUserPermittedAreasFromSecurityQuery, GetCurrentUserPermittedAreasFromSecurityQueryVariables>;