import * as yup from "yup";

export const dataValidationSchema = yup.object().shape({
  name: yup.string().fromFormik().required(),
  location: yup
    .object()
    .fromFormik()
    .required()
    .shape({
      latitude: yup.number().fromFormik().min(-90).max(90).required(),
      longitude: yup.number().fromFormik().min(-180).max(180).required(),
    }),
  address: yup.object().fromFormik().required().shape({
    town: yup.string().fromFormik().required(),
    street: yup.string().fromFormik().optional(),
    number: yup.string().fromFormik().optional(),
  }),
});
