import React from "react";
import Compo from "@smartly-city/compo";
import { useList } from "src/core/components/List";
import { useCurrentArea } from "src/core/contexts";
import { useStationFilters } from "../../contexts/StationFilters";
import { toStationQueryFilters } from "../../utils/filters";
import { useWeatherIoTTranslation } from "../../utils/translation";
import { useStationsFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStations";
import { useStationsPagedFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStationsPaged";
import type { WeatherIoT_StationRowModel } from "src/graphql/types";

export interface StationListRowsProps {
  rows: WeatherIoT_StationRowModel[];
  setRows: (rows: WeatherIoT_StationRowModel[]) => void;
  checked?: string[];
  page?: number;
  pagedAmount?: number;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (station: WeatherIoT_StationRowModel) => React.ReactNode;
  onClick?: (station: WeatherIoT_StationRowModel) => void;
  onCheck?: (station: WeatherIoT_StationRowModel, value: boolean) => void;
}

const StationListRows: React.FC<StationListRowsProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 10;
  const { setRows } = props;

  const { t } = useWeatherIoTTranslation();
  const area = useCurrentArea();
  const { setCurrentPage } = useList();
  const filters = useStationFilters();
  const queryFilters = toStationQueryFilters(filters.filters);

  const checkType = props.withCheckbox
    ? "checkbox"
    : props.withRadio
    ? "radio"
    : undefined;

  const { data } = useStationsFromWeatherIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: queryFilters,
      },
    },
    skip: props.page !== undefined,
  });
  const { data: pagedData } = useStationsPagedFromWeatherIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        page: props.page ?? 1,
        amount: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
        filters: queryFilters,
      },
    },
    skip: props.page === undefined,
  });

  const stations =
    data?.WeatherIoT_stations.items ??
    pagedData?.WeatherIoT_stationsPaged.items;
  const stationsTotal =
    pagedData?.WeatherIoT_stationsPaged.total ?? stations?.length ?? 0;

  React.useEffect(() => {
    setRows(stations ?? []);
  }, [setRows, stations]);

  React.useEffect(() => {
    setCurrentPage({
      pageSize: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
      pageTotal: stationsTotal,
    });
  }, [setCurrentPage, stationsTotal, props.pagedAmount]);

  return (
    <React.Fragment>
      {props.rows.length === 0 && (
        <Compo.ListRowEmpty wide text={t("message.no_stations")} />
      )}
      {props.rows.map((station) => (
        <Compo.ListRow
          wide
          key={station.id}
          checkType={checkType}
          checked={props.checked?.includes(station.id)}
          onCheck={
            props.onCheck
              ? (value) => props.onCheck?.(station, value)
              : undefined
          }
          onClick={props.onClick ? () => props.onClick?.(station) : undefined}
        >
          {station.name}
        </Compo.ListRow>
      ))}
    </React.Fragment>
  );
};

export default StationListRows;
