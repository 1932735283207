import * as yup from "yup";
import { Lighting_FasteningModel } from "src/graphql/types";

export const defaultDetailsValidationSchema = yup.object().shape({
  details: yup
    .object()
    .fromFormik()
    .shape({
      fastening: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_FasteningModel))
        .required(),
      height: yup.number().fromFormik().min(0).required(),
    }),
});
