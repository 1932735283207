import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportsPagedFromReportsQueryVariables = Types.Exact<{
  input: Types.Reports_GetReportsPagedInput;
}>;


export type ReportsPagedFromReportsQuery = { __typename?: 'Query', Reports_reportsPaged: { __typename?: 'Reports_GetReportsPagedPayload', page: number, count: number, total: number, items: Array<{ __typename?: 'Reports_ReportRowModel', id: any, version: number, areaId: any, type: Types.Reports_ReportTypeModel, state: Types.Reports_ReportStateModel, language: Types.Reports_LanguageModel, recordsDateStart?: any | null, recordsDateEnd?: any | null, recordsScope?: Types.Reports_ReportRecordScope | null }> } };


export const ReportsPagedFromReportsDocument = gql`
    query reportsPagedFromReports($input: Reports_GetReportsPagedInput!) {
  Reports_reportsPaged(input: $input) {
    page
    count
    total
    items {
      id
      version
      areaId
      type
      state
      language
      recordsDateStart
      recordsDateEnd
      recordsScope
    }
  }
}
    `;

/**
 * __useReportsPagedFromReportsQuery__
 *
 * To run a query within a React component, call `useReportsPagedFromReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsPagedFromReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsPagedFromReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportsPagedFromReportsQuery(baseOptions: Apollo.QueryHookOptions<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables> & ({ variables: ReportsPagedFromReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables>(ReportsPagedFromReportsDocument, options);
      }
export function useReportsPagedFromReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables>(ReportsPagedFromReportsDocument, options);
        }
export function useReportsPagedFromReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables>(ReportsPagedFromReportsDocument, options);
        }
export type ReportsPagedFromReportsQueryHookResult = ReturnType<typeof useReportsPagedFromReportsQuery>;
export type ReportsPagedFromReportsLazyQueryHookResult = ReturnType<typeof useReportsPagedFromReportsLazyQuery>;
export type ReportsPagedFromReportsSuspenseQueryHookResult = ReturnType<typeof useReportsPagedFromReportsSuspenseQuery>;
export type ReportsPagedFromReportsQueryResult = Apollo.QueryResult<ReportsPagedFromReportsQuery, ReportsPagedFromReportsQueryVariables>;