import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateCountersIndexFromTrafficIoTMutationVariables = Types.Exact<{
  input: Types.TrafficIoT_Admin_ValidateCountersIndexInput;
}>;


export type ValidateCountersIndexFromTrafficIoTMutation = { __typename?: 'Mutation', TrafficIoT_Admin_validateCountersIndex: { __typename?: 'TrafficIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'TrafficIoT_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'TrafficIoT_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const ValidateCountersIndexFromTrafficIoTDocument = gql`
    mutation validateCountersIndexFromTrafficIoT($input: TrafficIoT_Admin_ValidateCountersIndexInput!) {
  TrafficIoT_Admin_validateCountersIndex(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type ValidateCountersIndexFromTrafficIoTMutationFn = Apollo.MutationFunction<ValidateCountersIndexFromTrafficIoTMutation, ValidateCountersIndexFromTrafficIoTMutationVariables>;

/**
 * __useValidateCountersIndexFromTrafficIoTMutation__
 *
 * To run a mutation, you first call `useValidateCountersIndexFromTrafficIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCountersIndexFromTrafficIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCountersIndexFromTrafficIoTMutation, { data, loading, error }] = useValidateCountersIndexFromTrafficIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateCountersIndexFromTrafficIoTMutation(baseOptions?: Apollo.MutationHookOptions<ValidateCountersIndexFromTrafficIoTMutation, ValidateCountersIndexFromTrafficIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateCountersIndexFromTrafficIoTMutation, ValidateCountersIndexFromTrafficIoTMutationVariables>(ValidateCountersIndexFromTrafficIoTDocument, options);
      }
export type ValidateCountersIndexFromTrafficIoTMutationHookResult = ReturnType<typeof useValidateCountersIndexFromTrafficIoTMutation>;
export type ValidateCountersIndexFromTrafficIoTMutationResult = Apollo.MutationResult<ValidateCountersIndexFromTrafficIoTMutation>;
export type ValidateCountersIndexFromTrafficIoTMutationOptions = Apollo.BaseMutationOptions<ValidateCountersIndexFromTrafficIoTMutation, ValidateCountersIndexFromTrafficIoTMutationVariables>;