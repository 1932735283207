import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexLuminaireTypesFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_Admin_IndexLuminaireTypesInput;
}>;


export type IndexLuminaireTypesFromLightingMutation = { __typename?: 'Mutation', Lighting_Admin_indexLuminaireTypes: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const IndexLuminaireTypesFromLightingDocument = gql`
    mutation indexLuminaireTypesFromLighting($input: Lighting_Admin_IndexLuminaireTypesInput!) {
  Lighting_Admin_indexLuminaireTypes(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type IndexLuminaireTypesFromLightingMutationFn = Apollo.MutationFunction<IndexLuminaireTypesFromLightingMutation, IndexLuminaireTypesFromLightingMutationVariables>;

/**
 * __useIndexLuminaireTypesFromLightingMutation__
 *
 * To run a mutation, you first call `useIndexLuminaireTypesFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexLuminaireTypesFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexLuminaireTypesFromLightingMutation, { data, loading, error }] = useIndexLuminaireTypesFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexLuminaireTypesFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<IndexLuminaireTypesFromLightingMutation, IndexLuminaireTypesFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexLuminaireTypesFromLightingMutation, IndexLuminaireTypesFromLightingMutationVariables>(IndexLuminaireTypesFromLightingDocument, options);
      }
export type IndexLuminaireTypesFromLightingMutationHookResult = ReturnType<typeof useIndexLuminaireTypesFromLightingMutation>;
export type IndexLuminaireTypesFromLightingMutationResult = Apollo.MutationResult<IndexLuminaireTypesFromLightingMutation>;
export type IndexLuminaireTypesFromLightingMutationOptions = Apollo.BaseMutationOptions<IndexLuminaireTypesFromLightingMutation, IndexLuminaireTypesFromLightingMutationVariables>;