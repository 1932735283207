import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitializeAllControllersFromLightingIoTMutationVariables = Types.Exact<{
  input: Types.LightingIoT_Admin_InitializeAllControllersInput;
}>;


export type InitializeAllControllersFromLightingIoTMutation = { __typename?: 'Mutation', LightingIoT_Admin_initializeAllControllers: { __typename?: 'LightingIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'LightingIoT_GraphQLError', key: string, message: string }> } };


export const InitializeAllControllersFromLightingIoTDocument = gql`
    mutation initializeAllControllersFromLightingIoT($input: LightingIoT_Admin_InitializeAllControllersInput!) {
  LightingIoT_Admin_initializeAllControllers(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type InitializeAllControllersFromLightingIoTMutationFn = Apollo.MutationFunction<InitializeAllControllersFromLightingIoTMutation, InitializeAllControllersFromLightingIoTMutationVariables>;

/**
 * __useInitializeAllControllersFromLightingIoTMutation__
 *
 * To run a mutation, you first call `useInitializeAllControllersFromLightingIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeAllControllersFromLightingIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeAllControllersFromLightingIoTMutation, { data, loading, error }] = useInitializeAllControllersFromLightingIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeAllControllersFromLightingIoTMutation(baseOptions?: Apollo.MutationHookOptions<InitializeAllControllersFromLightingIoTMutation, InitializeAllControllersFromLightingIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeAllControllersFromLightingIoTMutation, InitializeAllControllersFromLightingIoTMutationVariables>(InitializeAllControllersFromLightingIoTDocument, options);
      }
export type InitializeAllControllersFromLightingIoTMutationHookResult = ReturnType<typeof useInitializeAllControllersFromLightingIoTMutation>;
export type InitializeAllControllersFromLightingIoTMutationResult = Apollo.MutationResult<InitializeAllControllersFromLightingIoTMutation>;
export type InitializeAllControllersFromLightingIoTMutationOptions = Apollo.BaseMutationOptions<InitializeAllControllersFromLightingIoTMutation, InitializeAllControllersFromLightingIoTMutationVariables>;