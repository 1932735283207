import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useCurrentArea } from "src/core/contexts";
import { setTextFieldValue, toStringValue } from "src/core/utils/formik";
import { useAddLuminaireToLightingScheduleFromLightingIoTMutation } from "src/graphql/__generated__/LightingIoTAddLuminaireToLightingSchedule";
import { useLightingSchedulesFromLightingIoTSuspenseQuery } from "src/graphql/__generated__/LightingIoTLightingSchedules";
import { useLightingIoTTranslation } from "../../utils/translation";
import type { LightingIoT_LuminaireModel } from "src/graphql/types";

export interface LuminaireLightingScheduleFormProps {
  onClose: () => void;
  luminaire: LightingIoT_LuminaireModel;
}

const LuminaireLightingScheduleForm: React.FC<
  LuminaireLightingScheduleFormProps
> = (props) => {
  const { t } = useLightingIoTTranslation();
  const area = useCurrentArea();

  const { data: scheduleData } =
    useLightingSchedulesFromLightingIoTSuspenseQuery({
      variables: { input: { areaId: area.id } },
    });
  const schedules = scheduleData.LightingIoT_lightingSchedules.items;

  const [addToSchedule] =
    useAddLuminaireToLightingScheduleFromLightingIoTMutation({
      update: (cache, result) => {
        if (
          result.errors ||
          !result.data?.LightingIoT_addLuminaireToLightingSchedule.isSuccess
        )
          return;
        cache.evict({
          fieldName: "LightingIoT_luminaireById",
        });
        cache.gc();
      },
    });

  const handleSubmit = async (values: any): Promise<void> => {
    const { data } = await addToSchedule({
      variables: {
        input: {
          areaId: area.id,
          luminaireId: props.luminaire.id,
          luminaireVersion: props.luminaire.version,
          scheduleId: formik.values.id,
        },
      },
    });

    const res = data?.LightingIoT_addLuminaireToLightingSchedule;
    if (res?.isSuccess) {
      toast.success(t("notification.luminaire_added_to_schedule"));
      props.onClose();
    } else {
      res?.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
  };

  const formik = useFormik<{ id: string }>({
    initialValues: { id: null as any },
    validationSchema: yup.object().shape({
      id: yup.string().required(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        header={
          <Compo.Header title={t("form_add_to_lighting_schedule.title")} />
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {t("submit")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <Compo.SelectInput
            wide
            id="id"
            name="id"
            disabled={formik.isSubmitting}
            error={formik.touched.id && formik.errors.id}
            label={t("lighting_schedule")}
            value={toStringValue(formik.values.id)}
            onChange={setTextFieldValue(formik, "id")}
          >
            <option />
            {schedules.map((schedule) => (
              <option key={schedule.id} value={schedule.id}>
                {schedule.name}
              </option>
            ))}
          </Compo.SelectInput>
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default LuminaireLightingScheduleForm;
