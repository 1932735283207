import React from "react";
import Compo, { useModal } from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import { useNavigate } from "react-router-dom";
import { useWeatherIoTTranslation } from "../../utils/translation";
import StationList from "../../containers/StationList/StationList";
import StationListSkeleton from "../../containers/StationList/StationList.skeleton";
import { usePermissionMatch } from "src/core/contexts";
import { StationForm } from "../../containers/StationForm";

const StationCollection: React.FC = () => {
  const { t } = useWeatherIoTTranslation();
  const navigate = useNavigate();
  const modal = useModal();
  const addPermitted = usePermissionMatch("weather_iot.station.add");

  return (
    <Page permission="weather_iot.station.get">
      <StyledHeader title={t("page_station_collection.title")}>
        {addPermitted && (
          <Compo.Button
            filled
            icon={Compo.PlusIcon}
            onClick={() => modal.setIsOpen(true)}
          />
        )}
      </StyledHeader>
      <React.Suspense fallback={<StationListSkeleton />}>
        <StationList
          paged
          filtered
          onClick={(station) => navigate(station.id)}
        />
      </React.Suspense>
      {modal.isOpen &&
        modal.withModalWrapper(
          <React.Suspense fallback={<Compo.Spinner />}>
            <StationForm onClose={() => modal.setIsOpen(false)} />
          </React.Suspense>
        )}
    </Page>
  );
};

const StyledHeader = styled(Compo.Header)`
  /* margin-bottom: 1.5rem; */
`;

export default StationCollection;
