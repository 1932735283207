import React from "react";
import Compo from "@smartly-city/compo";
import { useList } from "src/core/components/List";
import { useCurrentArea } from "src/core/contexts";
import { useCounterFilters } from "../../contexts/CounterFilters";
import { toCounterQueryFilters } from "../../utils/filters";
import { useTrafficIoTTranslation } from "../../utils/translation";
import { useCountersFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCounters";
import { useCountersPagedFromTrafficIoTSuspenseQuery } from "src/graphql/__generated__/TrafficIoTCountersPaged";
import type { TrafficIoT_CounterRowModel } from "src/graphql/types";

export interface CounterListRowsProps {
  rows: TrafficIoT_CounterRowModel[];
  setRows: (rows: TrafficIoT_CounterRowModel[]) => void;
  checked?: string[];
  page?: number;
  pagedAmount?: number;
  withCheckbox?: boolean;
  withRadio?: boolean;
  actions?: (counter: TrafficIoT_CounterRowModel) => React.ReactNode;
  onClick?: (counter: TrafficIoT_CounterRowModel) => void;
  onCheck?: (counter: TrafficIoT_CounterRowModel, value: boolean) => void;
}

const CounterListRows: React.FC<CounterListRowsProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 10;
  const { setRows } = props;

  const { t } = useTrafficIoTTranslation();
  const area = useCurrentArea();
  const { setCurrentPage } = useList();
  const filters = useCounterFilters();
  const queryFilters = toCounterQueryFilters(filters.filters);

  const checkType = props.withCheckbox
    ? "checkbox"
    : props.withRadio
    ? "radio"
    : undefined;

  const { data } = useCountersFromTrafficIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        filters: queryFilters,
      },
    },
    skip: props.page !== undefined,
  });
  const { data: pagedData } = useCountersPagedFromTrafficIoTSuspenseQuery({
    variables: {
      input: {
        areaId: area.id,
        page: props.page ?? 1,
        amount: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
        filters: queryFilters,
      },
    },
    skip: props.page === undefined,
  });

  const counters =
    data?.TrafficIoT_counters.items ??
    pagedData?.TrafficIoT_countersPaged.items;
  const countersTotal =
    pagedData?.TrafficIoT_countersPaged.total ?? counters?.length ?? 0;

  React.useEffect(() => {
    setRows(counters ?? []);
  }, [setRows, counters]);

  React.useEffect(() => {
    setCurrentPage({
      pageSize: props.pagedAmount ?? DEFAULT_PAGE_SIZE,
      pageTotal: countersTotal,
    });
  }, [setCurrentPage, countersTotal, props.pagedAmount]);

  return (
    <React.Fragment>
      {props.rows.length === 0 && (
        <Compo.ListRowEmpty wide text={t("message.no_counters")} />
      )}
      {props.rows.map((counter) => (
        <Compo.ListRow
          wide
          key={counter.id}
          checkType={checkType}
          checked={props.checked?.includes(counter.id)}
          onCheck={
            props.onCheck
              ? (value) => props.onCheck?.(counter, value)
              : undefined
          }
          onClick={props.onClick ? () => props.onClick?.(counter) : undefined}
        >
          {counter.name}
        </Compo.ListRow>
      ))}
    </React.Fragment>
  );
};

export default CounterListRows;
