import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StationByIdFromWeatherIoTQueryVariables = Types.Exact<{
  input: Types.WeatherIoT_GetStationByIdInput;
}>;


export type StationByIdFromWeatherIoTQuery = { __typename?: 'Query', WeatherIoT_stationById: { __typename?: 'WeatherIoT_GetStationByIdPayload', station?: { __typename?: 'WeatherIoT_StationModel', id: any, version: number, created: any, changed: any, published: boolean, areaId: any, name: string, smartlyId: string, location: { __typename?: 'WeatherIoT_CoordinatesModel', latitude: number, longitude: number, hash?: string | null } } | null } };


export const StationByIdFromWeatherIoTDocument = gql`
    query stationByIdFromWeatherIoT($input: WeatherIoT_GetStationByIdInput!) {
  WeatherIoT_stationById(input: $input) {
    station {
      id
      version
      created
      changed
      published
      areaId
      name
      location {
        latitude
        longitude
        hash
      }
      smartlyId
    }
  }
}
    `;

/**
 * __useStationByIdFromWeatherIoTQuery__
 *
 * To run a query within a React component, call `useStationByIdFromWeatherIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationByIdFromWeatherIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationByIdFromWeatherIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStationByIdFromWeatherIoTQuery(baseOptions: Apollo.QueryHookOptions<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables> & ({ variables: StationByIdFromWeatherIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables>(StationByIdFromWeatherIoTDocument, options);
      }
export function useStationByIdFromWeatherIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables>(StationByIdFromWeatherIoTDocument, options);
        }
export function useStationByIdFromWeatherIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables>(StationByIdFromWeatherIoTDocument, options);
        }
export type StationByIdFromWeatherIoTQueryHookResult = ReturnType<typeof useStationByIdFromWeatherIoTQuery>;
export type StationByIdFromWeatherIoTLazyQueryHookResult = ReturnType<typeof useStationByIdFromWeatherIoTLazyQuery>;
export type StationByIdFromWeatherIoTSuspenseQueryHookResult = ReturnType<typeof useStationByIdFromWeatherIoTSuspenseQuery>;
export type StationByIdFromWeatherIoTQueryResult = Apollo.QueryResult<StationByIdFromWeatherIoTQuery, StationByIdFromWeatherIoTQueryVariables>;