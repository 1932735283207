import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePoleFromLightingMutationVariables = Types.Exact<{
  input: Types.Lighting_DeletePoleInput;
}>;


export type DeletePoleFromLightingMutation = { __typename?: 'Mutation', Lighting_deletePole: { __typename?: 'Lighting_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Lighting_GraphQLError', key: string, message: string }> } };


export const DeletePoleFromLightingDocument = gql`
    mutation deletePoleFromLighting($input: Lighting_DeletePoleInput!) {
  Lighting_deletePole(input: $input) {
    isSuccess
    errors {
      key
      message
    }
    created
  }
}
    `;
export type DeletePoleFromLightingMutationFn = Apollo.MutationFunction<DeletePoleFromLightingMutation, DeletePoleFromLightingMutationVariables>;

/**
 * __useDeletePoleFromLightingMutation__
 *
 * To run a mutation, you first call `useDeletePoleFromLightingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoleFromLightingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoleFromLightingMutation, { data, loading, error }] = useDeletePoleFromLightingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePoleFromLightingMutation(baseOptions?: Apollo.MutationHookOptions<DeletePoleFromLightingMutation, DeletePoleFromLightingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePoleFromLightingMutation, DeletePoleFromLightingMutationVariables>(DeletePoleFromLightingDocument, options);
      }
export type DeletePoleFromLightingMutationHookResult = ReturnType<typeof useDeletePoleFromLightingMutation>;
export type DeletePoleFromLightingMutationResult = Apollo.MutationResult<DeletePoleFromLightingMutation>;
export type DeletePoleFromLightingMutationOptions = Apollo.BaseMutationOptions<DeletePoleFromLightingMutation, DeletePoleFromLightingMutationVariables>;