import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toSnakeCase } from "src/core/utils/string";
import { useCurrentArea } from "src/core/contexts";
import { useUpdatePowerBoxFromLightingMutation } from "src/graphql/__generated__/LightingUpdatePowerBox";
import { useCreateNewPowerBoxFromLightingMutation } from "src/graphql/__generated__/LightingCreateNewPowerBox";
import type { TFunction } from "i18next";
import type { DeepPartial } from "src/core/utils/types";
import type {
  Lighting_PowerBoxModel,
  Lighting_CreateNewPowerBoxInput,
  Lighting_UpdatePowerBoxInput,
} from "src/graphql/types";
import {
  PowerBoxFormDataInputs,
  dataDefaultValues,
  dataValidationSchema,
} from "./elements/PowerBoxFormData";
import {
  PowerBoxFormDetailsInputs,
  detailsDefaultValues,
  detailsValidationSchema,
} from "./elements/PowerBoxFormDetails";
import { useLightingTranslation } from "../../utils/translation";

export interface PowerBoxFormProps {
  onClose: () => void;
  powerBox?: Lighting_PowerBoxModel;
}

export interface PowerBoxFormContext
  extends DeepPartial<Lighting_CreateNewPowerBoxInput> {}

const PowerBoxForm: React.FC<PowerBoxFormProps> = (props) => {
  const { t } = useLightingTranslation();
  const navigate = useNavigate();
  const area = useCurrentArea();
  const [step, setStep] = React.useState(0);

  const [create] = useCreateNewPowerBoxFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_createNewPowerBox.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_powerBoxes",
      });
      cache.evict({
        fieldName: "Lighting_powerBoxesPaged",
      });
      cache.gc();
    },
  });

  const [update] = useUpdatePowerBoxFromLightingMutation({
    update: (cache, result) => {
      if (result.errors || !result.data?.Lighting_updatePowerBox.isSuccess)
        return;
      cache.evict({
        fieldName: "Lighting_powerBoxById",
      });
      cache.evict({
        fieldName: "Lighting_powerBoxes",
      });
      cache.evict({
        fieldName: "Lighting_powerBoxesPaged",
      });
      cache.gc();
    },
  });

  const steps = {
    data: {
      render: PowerBoxFormDataInputs,
      validationSchema: dataValidationSchema,
      defaultValues: dataDefaultValues,
      canBeSkipped: false,
      isEmpty: (): boolean => !formik.values,
    },
    details: {
      render: PowerBoxFormDetailsInputs,
      validationSchema: detailsValidationSchema,
      defaultValues: detailsDefaultValues,
      canBeSkipped: false,
      isEmpty: (): boolean => !formik.values.details,
    },
  } as const;

  const currentStep = Object.values(steps)[step];
  const currentStepName = Object.keys(steps)[step];

  React.useEffect(() => {
    if (currentStep.isEmpty()) {
      void formik.setFieldValue(currentStepName, currentStep.defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepName]);

  const isLastStep = (): boolean => {
    return step === Object.keys(steps).length - 1;
  };

  const handleNext = (): void => {
    const prevStep = step;
    setStep(prevStep + 1);
  };

  const handlePrev = (): void => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  const handleClear = async (): Promise<void> => {
    if (!currentStep.canBeSkipped) return;
    await formik.setFieldValue(currentStepName, null);
    await formik.validateForm();
  };

  const handleSubmit = async (values: any): Promise<void> => {
    if (!isLastStep()) {
      formik.setSubmitting(false);
      handleNext();
      return;
    }

    if (props.powerBox) {
      const newPowerBox = {
        id: props.powerBox.id,
        version: props.powerBox.version,
        areaId: props.powerBox.areaId,
        name: values.name,
        location: values.location,
        address: values.address,
        details: values.details,
      };

      const { data } = await update({
        variables: {
          input: newPowerBox as Lighting_UpdatePowerBoxInput,
        },
      });

      const result = data?.Lighting_updatePowerBox;
      if (result?.isSuccess) {
        toast.success(t("notification.power_box_updated"));
        props.onClose();
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    } else {
      const { data } = await create({
        variables: {
          input: {
            areaId: area.id,
            name: values.name,
            location: values.location,
            address: values.address,
            details: values.details,
          },
        },
      });

      const result = data?.Lighting_createNewPowerBox;
      if (result?.isSuccess) {
        toast.success(t("notification.power_box_created"));
        navigate(result.value?.id);
      } else {
        result?.errors.map((error) =>
          toast.error(t([`error.${error.key}`, "error.unknown"]))
        );
      }
    }
  };

  const formik = useFormik<PowerBoxFormContext>({
    initialValues: props.powerBox ?? {
      ...steps.data.defaultValues,
      details: steps.details.defaultValues,
    },
    validationSchema: currentStep.validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Compo.ModalBox
        size="lg"
        header={
          <HeaderWrapper>
            <Header
              title={t(
                "form_power_box." +
                  (props.powerBox ? "edit_title" : "add_title")
              )}
            />
            <Compo.Stepper>
              {Object.keys(steps).map((key, index) =>
                getStep(t, index + 1, toSnakeCase(key), step + 1)
              )}
            </Compo.Stepper>
          </HeaderWrapper>
        }
        buttons={
          <React.Fragment>
            <Compo.Button filled type="submit" disabled={formik.isSubmitting}>
              {isLastStep()
                ? t("submit")
                : currentStep.canBeSkipped && currentStep.isEmpty()
                ? t("skip")
                : t("next")}
            </Compo.Button>
            <Compo.Button onClick={handlePrev} disabled={step === 0}>
              {t("previous")}
            </Compo.Button>
            <Compo.Button
              disabled={!(currentStep.canBeSkipped && !currentStep.isEmpty())}
              onClick={handleClear}
            >
              {t("clear_to_skip")}
            </Compo.Button>
            <Compo.Button onClick={props.onClose}>{t("cancel")}</Compo.Button>
          </React.Fragment>
        }
      >
        <InputsWrapper>
          <FormikProvider value={formik}>
            <currentStep.render />
          </FormikProvider>
        </InputsWrapper>
      </Compo.ModalBox>
    </form>
  );
};

const getStep = (
  t: TFunction,
  order: number,
  name: string,
  currentStep: number
): React.ReactElement => (
  <Compo.StepperStep
    key={`step-${order}`}
    label={t(name)}
    order={order}
    disabled={currentStep < order}
    finished={currentStep > order}
  />
);

const Header = styled(Compo.Header)`
  width: max-content;
  padding: 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default PowerBoxForm;
