import * as yup from "yup";
import { Lighting_OwnerModel } from "src/graphql/types";

export const maintenanceDetailsValidationSchema = yup.object().shape({
  maintenanceDetails: yup
    .object()
    .nullable()
    .shape({
      qualifiedForReplacement: yup.boolean().fromFormik().required(),
      owner: yup
        .string()
        .fromFormik()
        .oneOf(Object.values(Lighting_OwnerModel))
        .required(),
    }),
});
