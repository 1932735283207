import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AreaByIdFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetAreaByIdInput;
}>;


export type AreaByIdFromLightingQuery = { __typename?: 'Query', Lighting_areaById: { __typename?: 'Lighting_GetAreaByIdPayload', area?: { __typename?: 'Lighting_AreaModel', id: any, settings: { __typename?: 'Lighting_AreaSettingsModel', requireMaintenanceDetails: boolean, requireRoadDetails: boolean } } | null } };


export const AreaByIdFromLightingDocument = gql`
    query areaByIdFromLighting($input: Lighting_GetAreaByIdInput!) {
  Lighting_areaById(input: $input) {
    area {
      id
      settings {
        requireMaintenanceDetails
        requireRoadDetails
      }
    }
  }
}
    `;

/**
 * __useAreaByIdFromLightingQuery__
 *
 * To run a query within a React component, call `useAreaByIdFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaByIdFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaByIdFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAreaByIdFromLightingQuery(baseOptions: Apollo.QueryHookOptions<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables> & ({ variables: AreaByIdFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables>(AreaByIdFromLightingDocument, options);
      }
export function useAreaByIdFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables>(AreaByIdFromLightingDocument, options);
        }
export function useAreaByIdFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables>(AreaByIdFromLightingDocument, options);
        }
export type AreaByIdFromLightingQueryHookResult = ReturnType<typeof useAreaByIdFromLightingQuery>;
export type AreaByIdFromLightingLazyQueryHookResult = ReturnType<typeof useAreaByIdFromLightingLazyQuery>;
export type AreaByIdFromLightingSuspenseQueryHookResult = ReturnType<typeof useAreaByIdFromLightingSuspenseQuery>;
export type AreaByIdFromLightingQueryResult = Apollo.QueryResult<AreaByIdFromLightingQuery, AreaByIdFromLightingQueryVariables>;