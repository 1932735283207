import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateObjectsIndexFromFailuresMutationVariables = Types.Exact<{
  input: Types.Failures_Admin_ValidateObjectsIndexInput;
}>;


export type ValidateObjectsIndexFromFailuresMutation = { __typename?: 'Mutation', Failures_Admin_validateObjectsIndex: { __typename?: 'Failures_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'Failures_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'Failures_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const ValidateObjectsIndexFromFailuresDocument = gql`
    mutation validateObjectsIndexFromFailures($input: Failures_Admin_ValidateObjectsIndexInput!) {
  Failures_Admin_validateObjectsIndex(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type ValidateObjectsIndexFromFailuresMutationFn = Apollo.MutationFunction<ValidateObjectsIndexFromFailuresMutation, ValidateObjectsIndexFromFailuresMutationVariables>;

/**
 * __useValidateObjectsIndexFromFailuresMutation__
 *
 * To run a mutation, you first call `useValidateObjectsIndexFromFailuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateObjectsIndexFromFailuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateObjectsIndexFromFailuresMutation, { data, loading, error }] = useValidateObjectsIndexFromFailuresMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateObjectsIndexFromFailuresMutation(baseOptions?: Apollo.MutationHookOptions<ValidateObjectsIndexFromFailuresMutation, ValidateObjectsIndexFromFailuresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateObjectsIndexFromFailuresMutation, ValidateObjectsIndexFromFailuresMutationVariables>(ValidateObjectsIndexFromFailuresDocument, options);
      }
export type ValidateObjectsIndexFromFailuresMutationHookResult = ReturnType<typeof useValidateObjectsIndexFromFailuresMutation>;
export type ValidateObjectsIndexFromFailuresMutationResult = Apollo.MutationResult<ValidateObjectsIndexFromFailuresMutation>;
export type ValidateObjectsIndexFromFailuresMutationOptions = Apollo.BaseMutationOptions<ValidateObjectsIndexFromFailuresMutation, ValidateObjectsIndexFromFailuresMutationVariables>;