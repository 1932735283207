import React from "react";
import Compo from "@smartly-city/compo";
import "./core/setup";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import routes from "./routing";

const App: React.FC = () => {
  const router = createBrowserRouter(createRoutesFromElements(routes));
  return (
    <React.Suspense fallback={<Compo.Spinner />}>
      <RouterProvider router={router} />
    </React.Suspense>
  );
};

export default App;
