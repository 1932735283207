import React from "react";
import styled from "styled-components";
import { useTrafficIoTTranslation } from "src/modules/traffic_iot/utils/translation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  TrafficIoT_CounterJournalSummaryItemTypeModel,
  type TrafficIoT_CounterJournalModel,
} from "src/graphql/types";

export interface CounterTruckJournalTabDailyCountChartProps {
  journal: TrafficIoT_CounterJournalModel;
}

const CounterTruckJournalTabDailyCountChart: React.FC<
  CounterTruckJournalTabDailyCountChartProps
> = (props) => {
  const { t, i18n } = useTrafficIoTTranslation();
  const isEnglish = i18n.language.startsWith("en");

  const startDate = new Date();
  startDate.setUTCMilliseconds(0);
  startDate.setUTCSeconds(0);
  startDate.setUTCMinutes(0);
  startDate.setUTCHours(startDate.getUTCHours() - 24 + 1);

  const endDate = new Date();
  endDate.setUTCMilliseconds(0);
  endDate.setUTCSeconds(0);
  endDate.setUTCMinutes(0);

  const dates: Date[] = [];
  // eslint-disable-next-line no-unmodified-loop-condition
  for (let d = startDate; d <= endDate; d.setUTCHours(d.getUTCHours() + 1)) {
    dates.push(new Date(d));
  }

  const data = dates.map((date) =>
    props.journal.dailySummary.items
      .filter(
        (item) =>
          item.type === TrafficIoT_CounterJournalSummaryItemTypeModel.TruckCount
      )
      .find((item) => new Date(item.date).getTime() === date.getTime())
  );

  const countData = dates.map((date, index) => ({
    x: date,
    y: data[index]?.truckCount?.count,
  }));

  const ltrCountData = dates.map((date, index) => ({
    x: date,
    y: data[index]?.truckCount?.leftToRightCount,
  }));

  const rtlCountData = dates.map((date, index) => ({
    x: date,
    y: data[index]?.truckCount?.rightToLeftCount,
  }));

  ChartJS.register(CategoryScale, TimeScale, LinearScale, BarElement, Tooltip);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>{t("page_counter_view.daily_truck_count_chart_title")}</Title>
      </HeaderWrapper>
      <StyledBar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: "index",
              filter: (item) => item.formattedValue !== "0",
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "hour",
                tooltipFormat: isEnglish ? "HHA" : "HH:mm",
                displayFormats: {
                  hour: isEnglish ? "HHA" : "HH:mm",
                },
              },
              grid: { display: false },
              stacked: true,
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
              stacked: true,
            },
            total: {
              beginAtZero: true,
              position: "right",
              grid: { display: false },
              ticks: {
                display: false,
              },
            },
          },
        }}
        data={{
          labels: dates,
          datasets: [
            {
              label: t("page_counter_view.total"),
              data: countData,
              backgroundColor: "#2463EB",
              yAxisID: "total",
              order: 1,
            },
            {
              label: t("page_counter_view.left_to_right"),
              data: ltrCountData,
              backgroundColor: "#3CB56C",
            },
            {
              label: t("page_counter_view.right_to_left"),
              data: rtlCountData,
              backgroundColor: "#FF3A3A",
            },
          ],
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border: 2.5px solid #2463eb;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 0.5rem;
`;

const Title = styled.h3`
  color: #767676;
  margin: 0;
`;

const StyledBar = styled(Bar)`
  max-height: 12.5rem;
  width: 100%;
`;

export default CounterTruckJournalTabDailyCountChart;
