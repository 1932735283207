import React from "react";
import { useFiltersUtils } from "src/core/utils/filters";
import { StationFiltersContext } from "./StationFiltersContext";
import type {
  StationFiltersStatus,
  StationFilters,
} from "./StationFiltersContext";

export interface StationFiltersProviderProps {
  children?: React.ReactNode;
}

const StationFiltersProvider: React.FC<StationFiltersProviderProps> = (
  props
) => {
  const initStatus: StationFiltersStatus = {
    name: false,
  };

  const utils = useFiltersUtils<StationFilters>({}, initStatus);

  return (
    <StationFiltersContext.Provider
      value={{
        filters: utils.filters,
        status: utils.status,
        setFilters: utils.setFilters,
        activateFilter: utils.activateFilter,
        deactivateFilter: utils.deactivateFilter,
        clearFilters: utils.clearFilters,
      }}
    >
      {props.children}
    </StationFiltersContext.Provider>
  );
};

export default StationFiltersProvider;
