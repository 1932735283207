import React from "react";

export interface LuminaireFilters {
  name?: string;
  typeName?: string;
  scheduleName?: string;
  controllerId?: string;
  hasController?: boolean;
  hasLightingSchedule?: boolean;
  communicationDate?: {
    min?: Date;
    max?: Date;
  };
  energyMeteringDate?: {
    min?: Date;
    max?: Date;
  };
  signalStrength?: {
    min?: number;
    max?: number;
  };
  signalToNoiseRatio?: {
    min?: number;
    max?: number;
  };
  temperature?: {
    min?: number;
    max?: number;
  };
  consumption?: {
    min?: number;
    max?: number;
  };
  powerFactor?: {
    min?: number;
    max?: number;
  };
  voltage?: {
    min?: number;
    max?: number;
  };
}

export type LuminaireFiltersStatus = Record<keyof LuminaireFilters, boolean>;

export interface LuminaireFiltersContextState {
  filters: LuminaireFilters;
  status: LuminaireFiltersStatus;
  setFilters: (filters: LuminaireFilters) => void;
  deactivateFilter: (name: keyof LuminaireFilters) => void;
  activateFilter: (name: keyof LuminaireFilters) => void;
  clearFilters: () => void;
}

export const LuminaireFiltersContext = React.createContext<
  LuminaireFiltersContextState | undefined
>(undefined);

export const useLuminaireFilters = (): LuminaireFiltersContextState => {
  const context = React.useContext(LuminaireFiltersContext);
  return context as LuminaireFiltersContextState;
};
