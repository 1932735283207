import React from "react";
import Compo from "@smartly-city/compo";
import {
  Lighting_OwnerModel,
  Lighting_PoleLocationModel,
} from "src/graphql/types";
import {
  getFieldError,
  setBooleanFieldValue,
  setDateFieldValue,
  setFloatFieldValue,
  setTextFieldValue,
  toDateValue,
  toStringValue,
} from "src/core/utils/formik";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import { useFormikContext } from "formik";
import type { PoleFormContext } from "../../PoleForm";

const PoleFormMaintenanceDetailsInputs: React.FC = () => {
  const { t } = useLightingTranslation();
  const formik = useFormikContext<PoleFormContext>();

  return (
    <React.Fragment>
      <Compo.SelectInput
        wide
        id="owner"
        name="owner"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "maintenanceDetails.owner")}
        label={t("form_pole.owner")}
        value={toStringValue(formik.values.maintenanceDetails?.owner)}
        onChange={setTextFieldValue(formik, "maintenanceDetails.owner")}
      >
        <option value="" />
        {Object.values(Lighting_OwnerModel).map((type) => (
          <option key={type} value={type}>
            {t(`owner.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.DateInput
        wide
        id="date-of-painting"
        name="date-of-painting"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "maintenanceDetails.dateOfPainting")}
        label={t("form_pole.date_of_painting")}
        value={toDateValue(formik.values.maintenanceDetails?.dateOfPainting)}
        onChange={setDateFieldValue(
          formik,
          "maintenanceDetails.dateOfPainting"
        )}
      />
      <Compo.TextInput
        wide
        id="plot-number"
        name="plot-number"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "maintenanceDetails.plotNumber")}
        label={t("form_pole.plot_number")}
        value={toStringValue(formik.values.maintenanceDetails?.plotNumber)}
        onChange={setTextFieldValue(formik, "maintenanceDetails.plotNumber")}
      />
      <Compo.SelectInput
        wide
        id="location"
        name="location"
        disabled={formik.isSubmitting}
        error={getFieldError(formik, "maintenanceDetails.location")}
        label={t("form_pole.location")}
        value={toStringValue(formik.values.maintenanceDetails?.location)}
        onChange={setTextFieldValue(formik, "maintenanceDetails.location")}
      >
        <option value="" />
        {Object.values(Lighting_PoleLocationModel).map((type) => (
          <option key={type} value={type}>
            {t(`pole_location.${type.toLowerCase()}`)}
          </option>
        ))}
      </Compo.SelectInput>
      <Compo.TextInput
        wide
        id="distance-to-closest-pole"
        name="distance-to-closest-pole"
        type="number"
        unit="m"
        disabled={formik.isSubmitting}
        error={getFieldError(
          formik,
          "maintenanceDetails.distanceToClosestPole"
        )}
        label={t("form_pole.distance_to_closest_pole")}
        value={toStringValue(
          formik.values.maintenanceDetails?.distanceToClosestPole
        )}
        onChange={setFloatFieldValue(
          formik,
          "maintenanceDetails.distanceToClosestPole"
        )}
      />
      <Compo.Toggle
        wide
        checked={
          formik.values.maintenanceDetails?.qualifiedForReplacement ?? false
        }
        onChange={setBooleanFieldValue(
          formik,
          "maintenanceDetails.qualifiedForReplacement"
        )}
        label={t("form_pole.qualified_for_replacement") ?? undefined}
      />
    </React.Fragment>
  );
};

export default PoleFormMaintenanceDetailsInputs;
