import React from "react";
import Compo from "@smartly-city/compo";
import Page from "src/core/components/Page";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useWeatherIoTTranslation } from "../../utils/translation";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useIndexStationsFromWeatherIoTMutation } from "src/graphql/__generated__/WeatherIoTIndexStations";
import { useIndexStationJournalsFromWeatherIoTMutation } from "src/graphql/__generated__/WeatherIoTIndexStationJournals";
import { useValidateStationsIndexFromWeatherIoTMutation } from "src/graphql/__generated__/WeatherIoTValidateStationsIndex";
import { useValidateStationJournalsIndexFromWeatherIoTMutation } from "src/graphql/__generated__/WeatherIoTValidateStationJournalsIndex";

const Administration: React.FC = () => {
  const { t } = useWeatherIoTTranslation();
  const area = useCurrentArea();
  const [tab, setTab] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  const hasValidateStationsIndexPermission = usePermissionMatch(
    "weather_iot.admin.validate_stations_index"
  );
  const [validateStations] = useValidateStationsIndexFromWeatherIoTMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleValidateStations = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateStations();
    if (result.data?.WeatherIoT_Admin_validateStationsIndex.isSuccess) {
      toast.success(t(`notification.stations_index_validated`));
    } else {
      result.data?.WeatherIoT_Admin_validateStationsIndex.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasValidateStationJournalsIndexPermission = usePermissionMatch(
    "weather_iot.admin.validate_station_journals_index"
  );
  const [validateStationJournals] =
    useValidateStationJournalsIndexFromWeatherIoTMutation({
      variables: { input: { areaId: area.id } },
    });
  const handleValidateStationJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await validateStationJournals();
    if (result.data?.WeatherIoT_Admin_validateStationJournalsIndex.isSuccess) {
      toast.success(t(`notification.station_journals_index_validated`));
    } else {
      result.data?.WeatherIoT_Admin_validateStationJournalsIndex.errors.map(
        (error) => toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexStationsPermission = usePermissionMatch(
    "weather_iot.admin.index_stations"
  );
  const [indexStations] = useIndexStationsFromWeatherIoTMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleIndexStations = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexStations();
    if (result.data?.WeatherIoT_Admin_indexStations.isSuccess) {
      toast.success(t(`notification.stations_indexed`));
    } else {
      result.data?.WeatherIoT_Admin_indexStations.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  const hasIndexStationJournalsPermission = usePermissionMatch(
    "weather_iot.admin.index_station_journals"
  );
  const [indexStationJournals] = useIndexStationJournalsFromWeatherIoTMutation({
    variables: { input: { areaId: area.id } },
  });
  const handleIndexStationJournals = async (): Promise<void> => {
    setSubmitting(true);
    const result = await indexStationJournals();
    if (result.data?.WeatherIoT_Admin_indexStationJournals.isSuccess) {
      toast.success(t(`notification.station_journals_indexed`));
    } else {
      result.data?.WeatherIoT_Admin_indexStationJournals.errors.map((error) =>
        toast.error(t([`error.${error.key}`, "error.unknown"]))
      );
    }
    setSubmitting(false);
  };

  return (
    <Page wide permission="weather_iot.admin.get">
      <Compo.Tabs
        wide
        current={tab}
        onChange={(index) => setTab(index)}
        tabs={[
          {
            icon: Compo.SearchIcon,
            content: (
              <Wrapper>
                <Compo.Header title={t("administration")} />
                <Compo.Button
                  small
                  outline
                  disabled={submitting || !hasValidateStationsIndexPermission}
                  onClick={handleValidateStations}
                >
                  {t("page_administration.validate_stations_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  disabled={
                    submitting || !hasValidateStationJournalsIndexPermission
                  }
                  onClick={handleValidateStationJournals}
                >
                  {t("page_administration.validate_station_journals_index")}
                </Compo.Button>
                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexStationsPermission}
                  onClick={handleIndexStations}
                >
                  {t("page_administration.index_stations")}
                </Compo.Button>

                <Compo.Button
                  small
                  outline
                  variant="alert"
                  disabled={submitting || !hasIndexStationJournalsPermission}
                  onClick={handleIndexStationJournals}
                >
                  {t("page_administration.index_station_journals")}
                </Compo.Button>
              </Wrapper>
            ),
          },
        ]}
      />
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem;
`;

export default Administration;
