import React from "react";
import Compo from "@smartly-city/compo";
import { useNavigate } from "react-router-dom";
import { useCurrentArea } from "src/core/contexts";
import { useLuminaireTypesPagedFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingLuminaireTypesPaged";
import { useLightingTranslation } from "src/modules/lighting/utils/translation";
import styled from "styled-components";
import { usePagination } from "src/core/utils/pagination";

const LuminaireTypeList: React.FC = () => {
  const ITEMS_AMOUNT = 10;

  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const { page, setPage } = usePagination();

  const { data } = useLuminaireTypesPagedFromLightingSuspenseQuery({
    variables: { input: { areaId: area.id, page, amount: ITEMS_AMOUNT } },
  });
  const luminaireTypes = data.Lighting_luminaireTypesPaged.items;

  if (luminaireTypes.length === 0) {
    return <Compo.ListRowEmpty wide text={t("message.no_luminaire_types")} />;
  }

  const rows = luminaireTypes.map((luminaireType) => (
    <Compo.ListRow
      wide
      key={luminaireType.id}
      onClick={() => navigate(luminaireType.id)}
    >
      {luminaireType.name}
    </Compo.ListRow>
  ));

  return (
    <Wrapper>
      <RowsWrapper>{rows}</RowsWrapper>
      <PaginationWrapper>
        <Compo.Pagination
          wide
          page={page}
          itemCount={ITEMS_AMOUNT}
          totalCount={data.Lighting_luminaireTypesPaged.total}
          onNext={() => setPage(page + 1)}
          onPrev={() => setPage(page - 1)}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const RowsWrapper = styled.div`
  overflow-y: auto;
`;

const PaginationWrapper = styled.div`
  margin-top: auto;
`;

export default LuminaireTypeList;
