import React from "react";
import Compo from "@smartly-city/compo";
import moment from "moment";
import { useFormikContext } from "formik";
import {
  getFieldError,
  setIntFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import type { LightingScheduleFormContext } from "../../LightingScheduleForm";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";

const StopStepInputs: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const formik = useFormikContext<LightingScheduleFormContext>();

  return (
    <React.Fragment>
      <Compo.Header
        outline
        type="h2"
        title={t("form_lighting_schedule.stop_step")}
      />
      <Compo.Radio
        checked={!!formik.values.stopStep?.stopOnTimeStep}
        label={t("form_lighting_schedule.stop_on_time") ?? undefined}
        onCheck={() =>
          formik.setFieldValue("stopStep", {
            stopOnTimeStep: {
              stopTime: null,
              brightness: 0,
            },
          })
        }
      />
      <Compo.Radio
        checked={!!formik.values.stopStep?.stopOnSunriseStep}
        label={t("form_lighting_schedule.stop_on_sunrise") ?? undefined}
        onCheck={() =>
          formik.setFieldValue("stopStep", {
            stopOnSunriseStep: {
              offset: null,
              brightness: 0,
            },
          })
        }
      />
      {formik.values.stopStep?.stopOnTimeStep && (
        <React.Fragment>
          <Compo.TimeInput
            wide
            id="stop-time"
            name="stop-time"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "stopStep.stopOnTimeStep.stopTime")}
            label={t("form_lighting_schedule.stop_time") ?? undefined}
            value={moment(
              formik.values.stopStep.stopOnTimeStep.stopTime,
              "HH:mm"
            )}
            onChange={async (v) =>
              await setTextFieldValue(
                formik,
                "stopStep.stopOnTimeStep.stopTime"
              )(v?.format("HH:mm"))
            }
          />
        </React.Fragment>
      )}
      {formik.values.stopStep?.stopOnSunriseStep && (
        <React.Fragment>
          <Compo.TextInput
            wide
            id="offset"
            name="offset"
            unit="min"
            type="number"
            disabled={formik.isSubmitting}
            error={getFieldError(formik, "stopStep.stopOnSunriseStep.offset")}
            label={t("form_lighting_schedule.offset") ?? undefined}
            value={toStringValue(
              formik.values.stopStep.stopOnSunriseStep.offset
            )}
            onChange={setIntFieldValue(
              formik,
              "stopStep.stopOnSunriseStep.offset"
            )}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StopStepInputs;
