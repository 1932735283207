import React from "react";
import Compo, { media } from "@smartly-city/compo";
import { useCurrentArea, usePermissionMatch } from "src/core/contexts";
import { useStatisticsFromLightingSuspenseQuery } from "src/graphql/__generated__/LightingStatistics";
import { useLightingTranslation } from "../../utils/translation";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const LightingStatistics: React.FC = () => {
  const { t } = useLightingTranslation();
  const area = useCurrentArea();
  const navigate = useNavigate();
  const hasStatisticsPermission = usePermissionMatch("lighting.statistics.get");
  const hasPowerBoxPermission = usePermissionMatch("lighting.power_box.get");
  const hasPolePermission = usePermissionMatch("lighting.pole.get");

  const { data } = useStatisticsFromLightingSuspenseQuery({
    variables: { input: { areaId: area?.id } },
  });
  const statistics = data.Lighting_statistics.statistics;

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("api_lighting")}>
        {hasStatisticsPermission && (
          <Compo.Button
            small
            filled
            icon={Compo.ExternalLinkIcon}
            onClick={() => navigate("/lighting")}
          />
        )}
      </Compo.Header>
      {hasStatisticsPermission && data ? (
        <TilesWrapper>
          <Compo.DashboardTile
            wide
            title={t("power_boxes")}
            onClick={
              hasPowerBoxPermission
                ? () => navigate("/lighting/power-box")
                : undefined
            }
          >
            {statistics.powerBoxCount.toString()}
          </Compo.DashboardTile>
          <Compo.DashboardTile
            wide
            title={t("poles")}
            onClick={
              hasPolePermission ? () => navigate("/lighting/pole") : undefined
            }
          >
            {statistics.poleCount.toString()}
          </Compo.DashboardTile>
          <Compo.DashboardTile wide title={t("luminaires")}>
            {statistics.luminaireCount.toString()}
          </Compo.DashboardTile>
        </TilesWrapper>
      ) : (
        <StyledActionTile text={t("banner.no_statistics_access")} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem 1rem;
  background-color: #fff;
  border-radius: 1rem;
`;

const TilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media ${media.max.sm} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledActionTile = styled(Compo.ActionTile)`
  width: 100%;
  padding: 2.5rem 7.5rem;
`;

export default LightingStatistics;
