import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useTrafficIoTTranslation } from "src/modules/traffic_iot/utils/translation";
import CounterBikeJournalTabDailyCountChart from "./charts/CounterBikeJournalTabDailyCountChart";
import CounterBikeJournalTabDailySpeedChart from "./charts/CounterBikeJournalTabDailySpeedChart";
import CounterBikeJournalTabMonthlyCountChart from "./charts/CounterBikeJournalTabMonthlyCountChart";
import CounterBikeJournalTabMonthlySpeedChart from "./charts/CounterBikeJournalTabMonthlySpeedChart";
import type { TrafficIoT_CounterJournalModel } from "src/graphql/types";

export interface CounterBikeJournalTabProps {
  journal: TrafficIoT_CounterJournalModel;
}

const CounterBikeJournalTab: React.FC<CounterBikeJournalTabProps> = (props) => {
  const { t } = useTrafficIoTTranslation();

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("page_counter_view.bike_journal")} />
      <CounterBikeJournalTabDailyCountChart journal={props.journal} />
      <CounterBikeJournalTabDailySpeedChart journal={props.journal} />
      <CounterBikeJournalTabMonthlyCountChart journal={props.journal} />
      <CounterBikeJournalTabMonthlySpeedChart journal={props.journal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default CounterBikeJournalTab;
