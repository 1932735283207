import React from "react";
import Compo from "@smartly-city/compo";
import moment from "moment";
import { FieldArray, useFormikContext } from "formik";
import {
  getFieldError,
  setIntFieldValue,
  setTextFieldValue,
  toStringValue,
} from "src/core/utils/formik";
import { useLightingIoTTranslation } from "src/modules/lighting_iot/utils/translation";
import type { LightingScheduleFormContext } from "../../LightingScheduleForm";

const ChangeStepInputs: React.FC = () => {
  const { t } = useLightingIoTTranslation();
  const formik = useFormikContext<LightingScheduleFormContext>();

  return (
    <React.Fragment>
      <Compo.Header
        outline
        type="h2"
        title={t("form_lighting_schedule.change_step")}
      />
      <FieldArray
        name="changeSteps"
        render={(helpers) => (
          <React.Fragment>
            {formik.values.changeSteps?.map((step, index) => (
              <React.Fragment key={index}>
                <Compo.Header
                  type="h2"
                  title={`${t("form_lighting_schedule.step")} ${index + 1}`}
                >
                  <Compo.Button
                    small
                    variant="alert"
                    icon={Compo.CloseIcon}
                    onClick={() => helpers.remove(index)}
                  />
                </Compo.Header>
                <Compo.Radio
                  checked={!!step?.changeOnTimeStep}
                  label={
                    t("form_lighting_schedule.change_on_time") ?? undefined
                  }
                  onCheck={() =>
                    formik.setFieldValue(`changeSteps[${index}]`, {
                      changeOnTimeStep: {
                        changeTime: null,
                        brightness: null,
                      },
                    })
                  }
                />
                {formik.values.changeSteps?.[index]?.changeOnTimeStep && (
                  <React.Fragment>
                    <Compo.TimeInput
                      wide
                      id="change-time"
                      name="change-time"
                      disabled={formik.isSubmitting}
                      error={getFieldError(
                        formik,
                        `changeSteps[${index}].changeOnTimeStep.changeTime`
                      )}
                      label={t("form_lighting_schedule.change_time")}
                      value={moment(
                        formik.values.changeSteps[index]?.changeOnTimeStep
                          ?.changeTime,
                        "HH:mm"
                      )}
                      onChange={async (v) =>
                        await setTextFieldValue(
                          formik,
                          `changeSteps[${index}].changeOnTimeStep.changeTime`
                        )(v?.format("HH:mm"))
                      }
                    />
                    <Compo.TextInput
                      wide
                      id="brightness"
                      name="brightness"
                      type="number"
                      disabled={formik.isSubmitting}
                      error={getFieldError(
                        formik,
                        `changeSteps[${index}].changeOnTimeStep.brightness`
                      )}
                      label={t("form_lighting_schedule.brightness")}
                      value={toStringValue(
                        formik.values.changeSteps[index]?.changeOnTimeStep
                          ?.brightness
                      )}
                      onChange={setIntFieldValue(
                        formik,
                        `changeSteps[${index}].changeOnTimeStep.brightness`
                      )}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
            <Compo.Button
              wide
              icon={Compo.PlusIcon}
              disabled={
                formik.values.changeSteps?.length
                  ? formik.values.changeSteps.length > 4
                  : false
              }
              onClick={() => helpers.push({})}
            >
              {t("form_lighting_schedule.add_step")}
            </Compo.Button>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default ChangeStepInputs;
