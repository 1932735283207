import React from "react";
import Compo, { type Types } from "@smartly-city/compo";
import styled from "styled-components";
import Page from "src/core/components/Page";
import StationViewSkeleton from "./StationView.skeleton";
import { useCurrentArea } from "src/core/contexts";
import { Navigate, useParams } from "react-router-dom";
import { StationDetailsTab } from "./elements/StationDetailsTab";
import { useStationByIdFromWeatherIoTSuspenseQuery } from "src/graphql/__generated__/WeatherIoTStationById";
import type { WeatherIoT_StationModel } from "src/graphql/types";

const StationView: React.FC = () => (
  <Page wide permission="weather_iot.station.get">
    <React.Suspense fallback={<StationViewSkeleton />}>
      <StationViewContent />
    </React.Suspense>
  </Page>
);

const StationViewContent: React.FC = () => {
  const area = useCurrentArea();
  const params = useParams<{ id: string }>();
  const [current, setCurrent] = React.useState(0);

  const { data: stationData } = useStationByIdFromWeatherIoTSuspenseQuery({
    variables: { input: { areaId: area.id, id: params.id as string } },
  });
  const station = stationData.WeatherIoT_stationById
    .station as WeatherIoT_StationModel;

  if (!station) {
    return <Navigate replace to="/404" />;
  }

  const tabs: Record<string, Types.TabProps> = {
    details: {
      icon: Compo.InformationCircleIcon,
      content: <StationDetailsTab station={station} />,
    },
  };

  return (
    <Wrapper>
      <Compo.Tabs
        current={current}
        onChange={setCurrent}
        wide
        tabs={Object.values(tabs)}
      >
        {(content) => <ContentWrapper>{content}</ContentWrapper>}
      </Compo.Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding: 1.5rem;
`;

export default StationView;
