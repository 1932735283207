import React from "react";
import { daysBetween } from "../../../core/utils/date";
import type { MapLayer } from "@smartly-city/compo-map";
import type {
  LightingIoT_LuminaireJournalRowModel,
  LightingIoT_LuminaireRowModel,
} from "src/graphql/types";

export const LUMINAIRE_LAYER_ID = "luminaire";

export const useLuminaireLayerBuilder = (): ((
  luminaires: LightingIoT_LuminaireRowModel[],
  journals: LightingIoT_LuminaireJournalRowModel[]
) => MapLayer) => {
  return React.useCallback(
    (luminaires, journals) => ({
      id: LUMINAIRE_LAYER_ID,
      color: "#767676",
      items: luminaires.map((luminaire) => ({
        id: luminaire.id,
        coordinates: luminaire.location,
        name: luminaire.name,
        color: getLuminaireColor(
          journals.find((j) => j.luminaireId === luminaire.id)
        ),
      })),
    }),
    []
  );
};

const getLuminaireColor = (
  journal?: LightingIoT_LuminaireJournalRowModel
): string => {
  const communicatedAgo = journal ? getCommunicatedDays(journal) : null;
  switch (true) {
    case communicatedAgo && communicatedAgo < 3:
      return "#3CB56C";
    case communicatedAgo && communicatedAgo < 7:
      return "#F9E850";
    case communicatedAgo && communicatedAgo < 14:
      return "#FFB03A";
    case communicatedAgo && communicatedAgo < 30:
      return "#FF3A3A";
    default:
      return "#767676";
  }
};

const getCommunicatedDays = (
  journal: LightingIoT_LuminaireJournalRowModel
): number | undefined => {
  return journal.status.communicationDate
    ? daysBetween(new Date(journal.status.communicationDate), new Date())
    : undefined;
};
