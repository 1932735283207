import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PowerBoxesPagedFromLightingQueryVariables = Types.Exact<{
  input: Types.Lighting_GetPowerBoxesPagedInput;
}>;


export type PowerBoxesPagedFromLightingQuery = { __typename?: 'Query', Lighting_powerBoxesPaged: { __typename?: 'Lighting_GetPowerBoxesPagedPayload', count: number, total: number, items: Array<{ __typename?: 'Lighting_PowerBoxRowModel', id: any, version: number, ordinalNumber: number, areaId: any, name: string, location: { __typename?: 'Lighting_CoordinatesModel', latitude: number, longitude: number } }> } };


export const PowerBoxesPagedFromLightingDocument = gql`
    query powerBoxesPagedFromLighting($input: Lighting_GetPowerBoxesPagedInput!) {
  Lighting_powerBoxesPaged(input: $input) {
    count
    total
    items {
      id
      version
      ordinalNumber
      areaId
      name
      location {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __usePowerBoxesPagedFromLightingQuery__
 *
 * To run a query within a React component, call `usePowerBoxesPagedFromLightingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerBoxesPagedFromLightingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerBoxesPagedFromLightingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePowerBoxesPagedFromLightingQuery(baseOptions: Apollo.QueryHookOptions<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables> & ({ variables: PowerBoxesPagedFromLightingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables>(PowerBoxesPagedFromLightingDocument, options);
      }
export function usePowerBoxesPagedFromLightingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables>(PowerBoxesPagedFromLightingDocument, options);
        }
export function usePowerBoxesPagedFromLightingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables>(PowerBoxesPagedFromLightingDocument, options);
        }
export type PowerBoxesPagedFromLightingQueryHookResult = ReturnType<typeof usePowerBoxesPagedFromLightingQuery>;
export type PowerBoxesPagedFromLightingLazyQueryHookResult = ReturnType<typeof usePowerBoxesPagedFromLightingLazyQuery>;
export type PowerBoxesPagedFromLightingSuspenseQueryHookResult = ReturnType<typeof usePowerBoxesPagedFromLightingSuspenseQuery>;
export type PowerBoxesPagedFromLightingQueryResult = Apollo.QueryResult<PowerBoxesPagedFromLightingQuery, PowerBoxesPagedFromLightingQueryVariables>;