import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LuminaireJournalsFromLightingIoTQueryVariables = Types.Exact<{
  input: Types.LightingIoT_GetLuminaireJournalsInput;
}>;


export type LuminaireJournalsFromLightingIoTQuery = { __typename?: 'Query', LightingIoT_luminaireJournals: { __typename?: 'LightingIoT_GetLuminaireJournalsPayload', count: number, total: number, items: Array<{ __typename?: 'LightingIoT_LuminaireJournalRowModel', id: any, version: number, created: any, changed: any, areaId: any, luminaireId: any, status: { __typename?: 'LightingIoT_LuminaireJournalStatusModel', communicationDate?: any | null, energyMeteringDate?: any | null, signalStrength?: number | null, signalToNoiseRatio?: number | null, temperature?: number | null, consumption?: number | null, powerFactor?: number | null, voltage?: number | null } }> } };


export const LuminaireJournalsFromLightingIoTDocument = gql`
    query luminaireJournalsFromLightingIoT($input: LightingIoT_GetLuminaireJournalsInput!) {
  LightingIoT_luminaireJournals(input: $input) {
    count
    total
    items {
      id
      version
      created
      changed
      areaId
      luminaireId
      status {
        communicationDate
        energyMeteringDate
        signalStrength
        signalToNoiseRatio
        temperature
        consumption
        powerFactor
        voltage
      }
    }
  }
}
    `;

/**
 * __useLuminaireJournalsFromLightingIoTQuery__
 *
 * To run a query within a React component, call `useLuminaireJournalsFromLightingIoTQuery` and pass it any options that fit your needs.
 * When your component renders, `useLuminaireJournalsFromLightingIoTQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLuminaireJournalsFromLightingIoTQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLuminaireJournalsFromLightingIoTQuery(baseOptions: Apollo.QueryHookOptions<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables> & ({ variables: LuminaireJournalsFromLightingIoTQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables>(LuminaireJournalsFromLightingIoTDocument, options);
      }
export function useLuminaireJournalsFromLightingIoTLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables>(LuminaireJournalsFromLightingIoTDocument, options);
        }
export function useLuminaireJournalsFromLightingIoTSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables>(LuminaireJournalsFromLightingIoTDocument, options);
        }
export type LuminaireJournalsFromLightingIoTQueryHookResult = ReturnType<typeof useLuminaireJournalsFromLightingIoTQuery>;
export type LuminaireJournalsFromLightingIoTLazyQueryHookResult = ReturnType<typeof useLuminaireJournalsFromLightingIoTLazyQuery>;
export type LuminaireJournalsFromLightingIoTSuspenseQueryHookResult = ReturnType<typeof useLuminaireJournalsFromLightingIoTSuspenseQuery>;
export type LuminaireJournalsFromLightingIoTQueryResult = Apollo.QueryResult<LuminaireJournalsFromLightingIoTQuery, LuminaireJournalsFromLightingIoTQueryVariables>;