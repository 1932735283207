import React from "react";
import Compo from "@smartly-city/compo";
import styled from "styled-components";
import { useTrafficIoTTranslation } from "src/modules/traffic_iot/utils/translation";
import CounterTruckJournalTabDailyCountChart from "./charts/CounterTruckJournalTabDailyCountChart";
import CounterTruckJournalTabDailySpeedChart from "./charts/CounterTruckJournalTabDailySpeedChart";
import CounterTruckJournalTabMonthlyCountChart from "./charts/CounterTruckJournalTabMonthlyCountChart";
import CounterTruckJournalTabMonthlySpeedChart from "./charts/CounterTruckJournalTabMonthlySpeedChart";
import type { TrafficIoT_CounterJournalModel } from "src/graphql/types";

export interface CounterTruckJournalTabProps {
  journal: TrafficIoT_CounterJournalModel;
}

const CounterTruckJournalTab: React.FC<CounterTruckJournalTabProps> = (
  props
) => {
  const { t } = useTrafficIoTTranslation();

  return (
    <Wrapper>
      <Compo.Header type="h2" title={t("page_counter_view.truck_journal")} />
      <CounterTruckJournalTabDailyCountChart journal={props.journal} />
      <CounterTruckJournalTabDailySpeedChart journal={props.journal} />
      <CounterTruckJournalTabMonthlyCountChart journal={props.journal} />
      <CounterTruckJournalTabMonthlySpeedChart journal={props.journal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default CounterTruckJournalTab;
