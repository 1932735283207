import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndexCountersFromTrafficIoTMutationVariables = Types.Exact<{
  input: Types.TrafficIoT_Admin_IndexCountersInput;
}>;


export type IndexCountersFromTrafficIoTMutation = { __typename?: 'Mutation', TrafficIoT_Admin_indexCounters: { __typename?: 'TrafficIoT_GraphQLResult', isSuccess: boolean, created: any, errors: Array<{ __typename?: 'TrafficIoT_GraphQLError', key: string, message: string, variables: Array<{ __typename?: 'TrafficIoT_KeyValuePairOfStringAndString', key: string, value: string }> }> } };


export const IndexCountersFromTrafficIoTDocument = gql`
    mutation indexCountersFromTrafficIoT($input: TrafficIoT_Admin_IndexCountersInput!) {
  TrafficIoT_Admin_indexCounters(input: $input) {
    isSuccess
    errors {
      key
      message
      variables {
        key
        value
      }
    }
    created
  }
}
    `;
export type IndexCountersFromTrafficIoTMutationFn = Apollo.MutationFunction<IndexCountersFromTrafficIoTMutation, IndexCountersFromTrafficIoTMutationVariables>;

/**
 * __useIndexCountersFromTrafficIoTMutation__
 *
 * To run a mutation, you first call `useIndexCountersFromTrafficIoTMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexCountersFromTrafficIoTMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexCountersFromTrafficIoTMutation, { data, loading, error }] = useIndexCountersFromTrafficIoTMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIndexCountersFromTrafficIoTMutation(baseOptions?: Apollo.MutationHookOptions<IndexCountersFromTrafficIoTMutation, IndexCountersFromTrafficIoTMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndexCountersFromTrafficIoTMutation, IndexCountersFromTrafficIoTMutationVariables>(IndexCountersFromTrafficIoTDocument, options);
      }
export type IndexCountersFromTrafficIoTMutationHookResult = ReturnType<typeof useIndexCountersFromTrafficIoTMutation>;
export type IndexCountersFromTrafficIoTMutationResult = Apollo.MutationResult<IndexCountersFromTrafficIoTMutation>;
export type IndexCountersFromTrafficIoTMutationOptions = Apollo.BaseMutationOptions<IndexCountersFromTrafficIoTMutation, IndexCountersFromTrafficIoTMutationVariables>;